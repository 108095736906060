import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class SecServAdminApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) { }

  getNewsletter(reonUrlId: string): Observable<INewsletterModel> {
    return this.http.get<INewsletterModel>(this.baseUrlReonApi + "Newsletter/GetNewsletter", 
    { withCredentials: true });
  }

  getLatestSecServ(): Observable<INewsletterModel[]> {
    return this.http.get<INewsletterModel[]>(this.baseUrlReonApi + "Newsletter/GetLatestSecServ", 
    { withCredentials: true });
  }

  getAllSecServ(): Observable<INewsletterModel[]> {
    return this.http.get<INewsletterModel[]>(this.baseUrlReonApi + "Newsletter/GetAllNewslettersSecServ", 
    { withCredentials: true });
  }

  getMailingListAccountLogSecServ(): Observable<IMailinglistAccountLog[]> {
    return this.http.get<IMailinglistAccountLog[]>(this.baseUrlReonApi + "Newsletter/GetMailingListAccountLogSecServ?take=20", 
    { withCredentials: true });
  }

  downloadSecServ(): Observable<IMailinglistAccountLog> {
    return this.http.get<IMailinglistAccountLog>(this.baseUrlReonApi + "Newsletter/DownloadSecServ", 
    { withCredentials: true });
  }

  downloadAll(): Observable<IMailinglistAccountLog[]> {
    return this.http.get<IMailinglistAccountLog[]>(this.baseUrlReonApi + "Newsletter/DownloadAllMailinglists", 
    { withCredentials: true });
  }

  updateVisibility(id: any, value: any): Observable<IMailinglistAccountLog> {
    return this.http
    .post<IMailinglistAccountLog>(this.baseUrlReonApi + "Newsletter/UpdateVisibility", 
    { Nid: id, Value: value}, 
    { withCredentials: true });
  }
  

}

export interface IMailinglistAccountLog {
  Id: number;
  MailinglistAccountId: number;
  Created: Date;
  Status: string;
  LogMessage: string;
}

export interface IMailinglist {

  characterSet: string;
  created: string;
  description: string;
  folderID: string;
  fromEmail: string;
  fromName: string;
  id: string;
  mailinglistAccountId: string;
  mailinglistId: string;
  name: string;
  newsletters?: INewsletterModel[];
  updated: string;
}

export interface IMailinglistAccount {
  id: number;
  name: string;
  apiKey: string;
  description: string;
  contactPerson: string;
  created: Date;
  updated: Date;
}

export interface INewsletterModel {
  NewsletterId: number;
  Id: number;
  MailinglistId: number;
  MailinglistAccountId: number;
  FolderId: number;
  Name: string;
  Subject: string;
  Description: string;
  Created: Date;
  Updated: Date;
  visibleReon: boolean;
  ContentHtmlVersion: string;
  ContentTextVersion: string;
  ReonUrl: string;
  IsPublished: boolean;
  IsNewsLetter: boolean;
  ReonUrlComposed: string;
}
