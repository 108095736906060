import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FileModel, NmwCalendarEventsApi } from "./nmw-calendarevents-api";

@Component({
  selector: "app-nmw-calendarevents",
  templateUrl: "./nmw-calendarevents.component.html"
})
export class NmwCalendarEventsComponent implements OnInit {
  newFile: File;
  @ViewChild("fileInput", {static: true}) fileInput: ElementRef;

  fileReadyForUpload: boolean;
  error: string;
  calendarFile: FileModel = {
    DownloadLink: null,
    FileName: "not found(!)",
    FilePath: "not found(!)",
    LastModified: "1800-01-01",
    Type: "Weekly calendar events",
    Exists: false
  };

  constructor(private api: NmwCalendarEventsApi) { }

  async ngOnInit(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.api.getNmwCalendarEventFile().subscribe(
        data => {
          this.calendarFile = data;
          resolve();
        },
        error => {
          reject("Error: " + error);
          this.error = "Error: " + error;
        }
      );
    });
  }

  selectFile(event: EventTarget) {
    //const eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    //const target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    const target = <HTMLInputElement>this.fileInput.nativeElement;
    const files: FileList = target.files;
    this.newFile = files[0];
    this.fileReadyForUpload = this.newFile != null;
  }

  resetFile() {
    this.fileInput.nativeElement.value = "";
  }

  async submit(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.newFile) {
        this.api.updateNmwCalendarEventFile(this.newFile).subscribe(
          data => {
            this.newFile = null;
            this.fileReadyForUpload = false;
            this.calendarFile = data;
            resolve();
          },
          error => {
            this.error = "Error: " + error;
            reject();
          }
        );
      } else {
        resolve();
      }
    });
  }

  saveIsDisabled() {
    return false;
  }
}
