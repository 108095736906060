import { RouterModule, Routes } from "@angular/router";
import { DownloadComponent } from "./makroSvepetPlusAdmin.component";
import { MakroSvepetPlusAddUserComponent } from "./makroSvepetPlusAddUser.component";
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: "MakrosvepetPlusAdmin",
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    component: DownloadComponent,
    children: [
      { path: "Download", component: DownloadComponent },
      { path: "Nyhetsbrev", component: DownloadComponent }
    ]
  },
  {
    canActivate: [AuthorizeGuard],
    path: "MakrosvepetPlusAdmin/Users",
    component: MakroSvepetPlusAddUserComponent,
    data: { Platform: "MakrosvepetPlus", navCheck: true }
  }
];

export const routing = RouterModule.forChild(routes);
