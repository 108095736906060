import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { KeyValuePair } from '../../_models/keyValuePair.model';
import { CreateRuleService } from '../_service/create-rule.service';

@Component({
  selector: 'app-create-equity',
  templateUrl: './create-equity.component.html',
  styleUrls: ['./create-equity.component.sass'],
  host: { 'style': 'display: block; position: relative' },
})
export class CreateEquityComponent implements OnInit {
  _selectedPublicationTypes: Array<KeyValuePair> = []
  @Input() selectedPublicationTypes: Array<KeyValuePair> = []
  @Output() updatedPublicationTypes: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updatePublicationTypes(event: Array<KeyValuePair>) {
    this._selectedPublicationTypes = event
    this.updatedPublicationTypes.emit([...this._selectedPublicationTypes])
  }

  _selectedCompanies: Array<KeyValuePair> = []
  @Input() selectedCompanies: Array<KeyValuePair> = []
  @Output() updatedCompanies: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updateCompanies(event: Array<KeyValuePair>) {
    this._selectedCompanies = event
    this.updatedCompanies.emit([...this._selectedCompanies])
  }

  _selectedSectors: Array<KeyValuePair> = []
  @Input() selectedSectors: Array<KeyValuePair> = []
  @Output() updatedSectors: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updateSectors(event: Array<KeyValuePair>) {
    this._selectedSectors = event
    this.updatedSectors.emit([...this._selectedSectors])
  }

  _selectedCountries: Array<KeyValuePair> = []
  @Input() selectedCountries: Array<KeyValuePair> = []
  @Output() updatedCountries: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updateCountries(event: Array<KeyValuePair>) {
    this._selectedCountries = event
    this.updatedCountries.emit([...this._selectedCountries])
  }

  _selectedAnalysts: Array<KeyValuePair> = []
  @Input() selectedAnalysts: Array<KeyValuePair> = []
  @Output() updatedAnalysts: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updateAnalysts(event: Array<KeyValuePair>) {
    this._selectedAnalysts = event
    this.updatedAnalysts.emit([...this._selectedAnalysts])
  }

  _selectedMarketCap: Array<number> = [0, 0]
  @Input() selectedMarketCap: Array<number> = []
  @Output() updatedMarketCap: EventEmitter<Array<number>> = new EventEmitter<Array<number>>()
  updateMarketCap(min: number, max: number) {
    if (min != null) this._selectedMarketCap[0] = min
    if (max != null) this._selectedMarketCap[1] = max
    this.updatedMarketCap.emit([...this._selectedMarketCap])
  }

  isLoading: boolean = false
  errorString: string = null

  publicationTypeOptions: KeyValuePair[] = [];
  sectorOptions: KeyValuePair[] = []
  companyOptions: KeyValuePair[] = []
  countryOptions: KeyValuePair[] = []
  analystOptions: KeyValuePair[] = []

  constructor(private createRuleService: CreateRuleService) { }

  ngOnInit(): void {
    this.isLoading = true
    this.createRuleService.getEquityOptions()
      .pipe(
        catchError(x => {
          this.errorString = x
          this.isLoading = false
          return null
        })
      )
      .subscribe(x => {
        if (!x)
          return
        this.sectorOptions = x.sectors
        this.companyOptions = x.companies
        this.countryOptions = x.countries
        this.analystOptions = x.analysts
        this.publicationTypeOptions = x.publicationTypes
        this.isLoading = false
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("equityChange", changes)
    if (changes.selectedPublicationTypes)
      this._selectedPublicationTypes = [...changes.selectedPublicationTypes.currentValue]
    if (changes.selectedCompanies)
      this._selectedCompanies = [...changes.selectedCompanies.currentValue]
    if (changes.selectedSectors)
      this._selectedSectors = [...changes.selectedSectors.currentValue]
    if (changes.selectedCountries)
      this._selectedCountries = [...changes.selectedCountries.currentValue]
    if (changes.selectedAnalysts)
      this._selectedAnalysts = [...changes.selectedAnalysts.currentValue]
    if (changes.selectedMarketCap)
      this._selectedMarketCap = [...changes.selectedMarketCap.currentValue]
  }

  get isSectorMode(): boolean {
    return this._selectedSectors.length > 0 || this._selectedCountries.length > 0
      || this._selectedAnalysts.length > 0 || this._selectedMarketCap[0] > 0 || this._selectedMarketCap[1] > 0
  }
  get isCompanyMode(): boolean { 
    return this._selectedCompanies.length > 0 
  }
}
