import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserApiService } from "../user/user-api.service";
import { Form, NgForm } from "@angular/forms";
import { catchError } from 'rxjs/operators';

@Component({
  selector: "app-ms-plus-adduser",
  templateUrl: "makroSvepetPlusAddUser.component.html"
})
export class MakroSvepetPlusAddUserComponent {
  public makroSvepetRoles: { title: string; expDate: string }[] = [
    { title: "Branch office client", expDate: this.getDefdate() },
    {
      title: "SHB employee non chargeable",
      expDate: this.getShbClientDefdate()
    }
    // { title: "SHB employee chargeable", expDate: this.getDefdate() },
    // { title: "Institutional client", expDate: this.getDefdate() }
  ];

  public user: {
    email: string;
    role: string;
    expdate: string;
    userType: string;
  } = {
    email: "",
    role: "SHB employee non chargeable",
    expdate: "1800-01-01",
    userType: "MakrosvepetPlus"
  };

  public saveError: string = null;
  public lastSave: string = null;
  public isSubmitting: boolean = false;

  public errors: string[] = [];

  public addedUsers: {
    email: string;
    role: string;
    expdate: string;
  }[] = [];

  constructor(
    private router: Router,
    private userApi: UserApiService,
    private route: ActivatedRoute
  ) {}

  getDefdate(): string {
    const d = new Date(Date.now() + 27 * 864e5); //now plus 27 days
    const retVal =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);

    return retVal;
  }

  getShbClientDefdate(): string {
    const d = new Date(1800, 1, 1); //now plus 27 days
    const retVal =
      d.getFullYear() +
      "-" +
      ("0" + d.getMonth()).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);

    return retVal;
  }

  public changeRole() {
    this.user.expdate = this.makroSvepetRoles.filter(
      msr => msr.title == this.user.role
    )[0].expDate;
  }

  async submitUser($event) {
    //$event.preventDefault();

    try {
      this.isSubmitting = true;

      await this.doSubmitUser();

      var now = new Date(Date.now());
      this.lastSave =
        ("0" + now.getHours()).slice(-2) +
        ":" +
        ("0" + now.getMinutes()).slice(-2);

      this.addedUsers.push(this.user);

      this.user = Object.assign({}, this.user);
      this.user.email = "";
    } catch (e) {
      console.error(e);
    }

    this.isSubmitting = false;
  }

  doSubmitUser() {
    const { email, role, expdate, userType } = this.user;
    return new Promise(resolve => {
      setTimeout(() => {
        this.userApi
          .setNewUser(email, role, expdate, userType)
          .pipe(
            catchError(err => (this.saveError = "Error " + err))
          )
          .subscribe(() => {
            resolve(this.user);
          });
      });
    });
  }

  public getErrors(myForm: NgForm) {
    this.errors = [];
    Object.keys(myForm.control.controls).forEach(cName => {
      let control = myForm.control.controls[cName];

      const errors = control.errors;
      if (errors === null || errors.count === 0) {
        return;
      }

      if (errors.required) {
        this.errors.push(`${cName} is required`);
      }
    });

    return this.errors;
  }
}
