import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, from } from "rxjs";

import { environment } from "environments/environment";
import { CommandDataResponse } from "app/commandResponse";
import {
  CompanyCustomizationModel,
  CompanyNameModel
} from "./CompanyCustomizationModel";

@Injectable()
export class CompanyCustomizationApi {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) {}

  getAll(): Observable<CompanyCustomizationModel[]> {
    return this.call("get", "/All");
  }

  searchCompanyName(name: string): Observable<CompanyNameModel[]> {
    if (name == null || name === "") return from([]);

    return this.call("get", `/GetCompanyName?search=${encodeURIComponent(name)}`);
  }

  get(companyId: number, area: string): Observable<CompanyCustomizationModel[]> {
    return this.call("get", `/Get?companyId=${companyId}&area=${area}`);
  }

  post(model: CompanyCustomizationModel): Observable<CommandDataResponse<number>> {
    return this.call("post", "/Post", model);
  }

  put(model: CompanyCustomizationModel): Observable<CommandDataResponse<number>> {
    return this.call("put", "/Put", model);
  }

  delete(companyId: number, area: string): Observable<CommandDataResponse<number>> {
    return this.call("delete", `/Delete?companyId=${companyId}&area=${area}`);
  }

  call(method: string, relativeUrl: string, body?: any): Observable<any> {
    return this.http
      .request(method, this.baseUrlReonApi + "CompanyCustomization" + relativeUrl, {
        withCredentials: true,
        body
      });
  }


}
