import { DecimalPipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { KeyValuePair } from '../_models/keyValuePair.model';

@Component({
  selector: 'app-string-builder',
  templateUrl: './string-creator.component.html',
  styleUrls: ['./string-creator.component.sass']
})
export class StringCreatorComponent implements OnInit {
  @Input() publicationTypes: Array<KeyValuePair> = []
  @Input() sectors: Array<KeyValuePair> = []
  @Input() countries: Array<KeyValuePair> = []
  @Input() companies: Array<KeyValuePair> = []
  @Input() analysts: Array<KeyValuePair> = []
  @Input() currencies: Array<KeyValuePair> = []
  @Input() marketCap: Array<number> = []
  @Input() languages: Array<KeyValuePair> = []
  @Input() regions: Array<KeyValuePair> = []

  ruleString: string = "";

  constructor(private _decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("stringCreatorChange")
    this.ruleString = this.createRuleString()
  }

  private createRuleString() {
    let publicationTypeValue = this.publicationTypes;
    let sectorValue = this.sectors;
    let countryValue = this.countries;
    let marketCapValue = this.marketCap;
    let companyValue = this.companies;
    let analystValue = this.analysts
    let currencyValue = this.currencies
    let languageValue = this.languages
    let regionValue = this.regions

    let publicationTypeString: string = "";
    if (publicationTypeValue.length > 0) {
      publicationTypeValue.forEach((item, i) => {
        if (i != publicationTypeValue.length - 1) {
          publicationTypeString += "<span class='publication-span'>" + item.value + "</span> "
        }
        else {
          publicationTypeString += "<span class='publication-span'>" + item.value + "</span>"
        }

      })
    }

    let sectorString: string = "";
    if (sectorValue.length > 0) {
      sectorString += " and when sector is "
      sectorValue.forEach((item, i) => {
        if (i != sectorValue.length - 1) {
          sectorString += "<span class='sector-span'>" + item.value + "</span> "
        }
        else {
          sectorString += "<span class='sector-span'>" + item.value + "</span>"
        }
      })
    }

    let countryString: string = "";
    if (countryValue.length > 0) {
      countryString += " and when country is "
      countryValue.forEach((item, i) => {
        if (i != countryValue.length - 1) {
          countryString += "<span class='country-span'>" + item.value + "</span> "
        }
        else {
          countryString += "<span class='country-span'>" + item.value + "</span>"
        }
      })
    }

    let marketCapString: string = "";
    if (marketCapValue != undefined && (marketCapValue[0] != 0 || marketCapValue[1] != 0)) {
      marketCapString += ` and when market cap is between <span class='market-cap-span'>${this._decimalPipe.transform(marketCapValue[0], '1.')} - ${this._decimalPipe.transform(marketCapValue[1], '1.')} M€</span>`
      // marketCapValue.forEach((item, i) => {
      //   if (i != marketCapValue.length-1) {
      //     marketCapString += "<span class='market-cap-span'>" + item + "</span>" + ", "
      //   }
      //   else {
      //     marketCapString += "<span class='market-cap-span'>" + item + "</span>"
      //   }
      // })
    }

    let companyString: string = "";
    if (companyValue.length > 0) {
      companyString += " and when company is "
      companyValue.forEach((item, i) => {
        if (i != companyValue.length - 1) {
          companyString += "<span class='company-span'>" + item.value + "</span> "
        }
        else {
          companyString += "<span class='company-span'>" + item.value + "</span>"
        }
      })
    }

    let analystString: string = "";
    if (analystValue.length > 0) {
      analystString += " and when analyst is "
      analystValue.forEach((item, i) => {
        if (i != analystValue.length - 1) {
          analystString += "<span class='analyst-span'>" + item.value + "</span> "
        }
        else {
          analystString += "<span class='analyst-span'>" + item.value + "</span>"
        }
      })
    }

    let currencyString: string = "";
    if (currencyValue.length > 0) {
      currencyString += " and when currency is "
      currencyValue.forEach((item, i) => {
        if (i != currencyValue.length - 1) {
          currencyString += "<span class='currency-span'>" + item.value + "</span> "
        }
        else {
          currencyString += "<span class='currency-span'>" + item.value + "</span>"
        }
      })
    }

    let languageString: string = "";
    if (languageValue.length > 0) {
      languageString += " and when language is "
      languageValue.forEach((item, i) => {
        if (i != languageValue.length - 1) {
          languageString += "<span class='language-span'>" + item.value + "</span> "
        }
        else {
          languageString += "<span class='language-span'>" + item.value + "</span>"
        }
      })
    }

    let regionString: string = "";
    if (regionValue.length > 0) {
      regionString += " and when region is "
      regionValue.forEach((item, i) => {
        if (i != regionValue.length - 1) {
          regionString += "<span class='region-span'>" + item.value + "</span> "
        }
        else {
          regionString += "<span class='region-span'>" + item.value + "</span>"
        }
      })
    }

    let ruleString: string = publicationTypeString + regionString + languageString + sectorString + countryString + marketCapString + companyString + analystString + currencyString;
    return ruleString == "" ? "" : `Receive all ${ruleString}.`
  }

}
