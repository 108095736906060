import { Component, OnInit } from '@angular/core';
import { EmployeeApiService, EditEmployeeModel } from './employee-api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'employee-edit',
  templateUrl: 'employee-edit.component.html'
})

export class EmployeeEditComponent implements OnInit {
  employee: EditEmployeeModel;
  emailQuery: string;

  constructor(private contentApi: EmployeeApiService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.emailQuery = this.route.snapshot.queryParams['userId'];
    if (this.emailQuery) { this.getUser(); }
  }

  get isExistingEmployee(): boolean {
    return !!this.emailQuery;
  }
  getUser() {
    this.contentApi.getEmployee(this.emailQuery).subscribe(employee => (this.employee = employee));
  }
}
