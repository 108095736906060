export const environment = {
  production: true,
  applicationName: "#{Release.EnvironmentName}#-reonadmin-frontend",
  baseUrlOidcConfig: '#{Ng.Authority}#',
  baseUrlReonApi: "#{Ng.BaseUrlReonApi}#",
  baseUrlReon: "#{Ng.BaseUrlReon}#",
  baseUrlEmailSubApi: "#{Ng.BaseUrlEmailSubApi}#",
  appInsights: {
    instrumentationKey: '#{ApplicationInsights.InstrumentationKey}#'
  }
};
