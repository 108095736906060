import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeApiService, EmployeeModel } from '../employee-api.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'employee-search',
    templateUrl: 'employee-search.component.html'
})

export class EmployeeSearchComponent implements OnInit {
    searchForm = new FormGroup({
        Active: new FormControl(true, {}),
      });

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    displayedColumns: string[] = ['UserId', 'First name', 'Last name', 'action'];
    // activeOnly = true;
    employeeList = new MatTableDataSource(<EmployeeModel[]>[]);
    loading = false;
    filterModel = new FormControl('');
    error;

    constructor(private contentApi: EmployeeApiService, private router: Router) { }

    ngOnInit() {
        this.doSearch(this.searchForm.get('Active').value);
        this.employeeList.paginator = this.paginator;
        this.searchForm.valueChanges.subscribe(c => this.doSearch(this.searchForm.get('Active').value));
    }

    doSearch(active: boolean): void {
        this.loading = true;

        this.contentApi.searchEmployees(active).subscribe(empList => {
            this.employeeList.data = empList
            this.loading = false;
        }, error => {
            this.error = error.error
        });
    }

    selectEmployee(e: EmployeeModel) {
        this.router.navigate(['/Employee/Edit'], { queryParams: { userId: e.UserId } });
    }
    updateFilterModel(value) {
        this.employeeList.filter = value.trim();
      }

    sortData(sort: Sort) {
        const data = this.employeeList.data.slice();
        if (!sort.active || sort.direction === '') {
          this.employeeList.data = data;
          return;
        }

        this.employeeList.data = data.sort((a, b) => {
          const isAsc = sort.direction === 'asc';
          switch (sort.active) {
            case 'UserId': return compare(a.UserId, b.UserId, isAsc);
            case 'First name': return compare(a.FirstName, b.FirstName, isAsc);
            case 'Last name': return compare(a.LastName, b.LastName, isAsc);
            default: return 0;
          }
        });
      }
}
function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
