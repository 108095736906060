import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserApiService, UserExModel } from "./user-api.service";
import { catchError } from 'rxjs/operators';
@Component({
  selector: "temp-newUser",
  templateUrl: "TempNewUsers.component.html"
})
export class TempNewUserComponent {
  input_text: string = "";
  input_user: {
    row: number;
    email: string;
    role: string;
    expdate: string;
    status: string;
  }[];
  valid_user: {
    row: number;
    email: string;
    role: string;
    expdate: string;
    status: string;
  }[];
  parsed_text: string[];
  submit_status: boolean = false;
  file: any;
  file_status: string = "Waiting";
  user_status: string = "Waiting";
  isLoadingUser: boolean = false;
  user: UserExModel = {};
  error: string = "";
  userType: string;
  title: string = "Choose platform in the menu to the left.";
  desc: string = "Choose platform in the menu to the left.";

  constructor(
    private router: Router,
    private userApi: UserApiService,
    private route: ActivatedRoute
  ) {
    route.data.subscribe(data => {
      this.userType = data.Platform;
      switch (data.Platform) {
        case "MakrosvepetPlus":
          this.title = "Lägg till användare till MakrosvepetPlus";
          this.desc = "Makrosvepet PLUS";
          break;
        case "SecServUser":
          this.title = "Add users to Securities Services";
          this.desc = "Securities Services";
          break;
        default:
          this.title = data.type;
          break;
      }
    });
    this.input_user = [];
    this.valid_user = [];
    this.file = null;
  }
  FileException(message) {
    this.file_status = "Stopped.";
    this.error = message;
    throw message;
  }
  inputfile(e) {
    this.error = "";
    this.input_user = [];
    this.file_status = "Starting...";
    this.file = e.target.files[0];
    this.validatefile();
    this.parsefile();
  }
  validatefile() {
    if (this.file == null) {
      this.FileException("No file found.");
    } else if (this.file.type != "application/vnd.ms-excel") {
      this.FileException(
        "Unexpected file type. Make sure it is an exported Excel .csv file"
      );
    }
  }
  parsefile() {
    let filereader = new FileReader();
    filereader.onloadstart = e => {
      this.file_status = "Reading file...";
    };
    filereader.onload = e => {
      this.file_status = "Parsing file...";
      this.parse(filereader.result);
    };
    filereader.onerror = e => {
      filereader.abort();
      this.FileException("Reading file failed.");
    };
    filereader.readAsText(this.file);
  }
  parse(t) {
    let p_t = t.split("\n");
    let row = 1;
    p_t.forEach(e => {
      this.validate(e, row);
      ++row;
    });
    this.file_status = "Done!";
    if (this.valid_user.length > 0) {
      this.submit_status = true;
    } else {
      this.file_status = "No valid rows found";
    }
  }
  validate(e: string, row: number) {
    let regex = new RegExp(/[\0\x08\x09\x1a\n\r"'\\\%]/g);
    let escaper = function escaper(char) {
      let m = [
        "\\0",
        "\\x08",
        "\\x09",
        "\\x1a",
        "\\n",
        "\\r",
        "'",
        '"',
        "\\",
        "\\\\",
        "%"
      ];
      let r = [
        "\\\\0",
        "\\\\b",
        "\\\\t",
        "\\\\z",
        "\\\\n",
        "\\\\r",
        "''",
        '""',
        "\\\\",
        "\\\\\\\\",
        "\\%"
      ];
      return r[m.indexOf(char)];
    };
    e.replace(regex, escaper);
    let status = "false";
    let u = e.split(";");
    if (u.length != 3) {
      status = "Unexpected row count";
    }
    let email = this.valid_email(u[0]);
    let role = this.valid_category(u[1]);
    let expdate = this.valid_date(u[2]);
    if (
      email != "invalid" &&
      role != "invalid" &&
      expdate != "invalid" &&
      status != "Unexpected row count"
    ) {
      status = "true";
      this.valid_user.push({ row, email, role, expdate, status });
    }
    this.input_user.push({ row, email, role, expdate, status });
    return;
  }
  valid_email(e: string): string {
    //If giving problems, downgrade this regex check to string@string instead: /\S+@\S+/;
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e == "" || e == null || !re.test(e)) {
      return "invalid";
    }
    return e;
  }
  valid_category(e: string): string {
    var valid_categories = [
      "Branch office client",
      "SHB employee non chargeable",
      "SHB employee chargeable",
      "Institutional client"
    ];
    if (e == null || valid_categories.indexOf(e) == -1) {
      return "invalid";
    }
    return e;
  }
  valid_date(e: string): string {
    let re = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/; //YYYY-MM-DD
    if (!re.test(e)) {
      return "invalid";
    }
    return e;
  }
  submitUsers() {
    let promises = [];
    this.input_user = this.valid_user;
    this.isLoadingUser = true;
    this.user_status = "Connecting with API";
    this.valid_user.forEach(e => {
      promises.push(this.submitTempUser(e));
    });
    Promise.all(promises)
      .then(() => {
        this.user_status = "Completed";
        this.validateUsers();
      })
      .catch(e => {
        console.log("submitUsers() " + e);
      });
  }
  submitTempUser(e: {
    row: number;
    email: string;
    role: string;
    expdate: string;
    status: string;
  }) {
    return new Promise(resolve => {
      setTimeout(() => {
        this.userApi
          .setNewUser(e.email, e.role, e.expdate, this.userType)
          .pipe(
            catchError((err) => this.user_status = "Error " + err)
          )
          .subscribe(() => {
            this.user_status += ".";
            e.status = "pending...";
            resolve(e);
          });
      });
    });
  }
  validateUsers() {
    this.input_user.forEach(e => {
      e.status = "validating...";
      this.userApi.getUser(e.email).subscribe(user => {
        e.status = user.ExpireDate.toString();
      });
    });
    this.isLoadingUser = false;
  }
}
