import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { Rule, RuleCategoryEnum } from '../../_models/rule.model';

@Injectable({
  providedIn: 'root'
})
export class CreateRuleService {

  constructor(private http: HttpClient) { }

  private getEquityOptionsData = null
  private getCreditOptionsData = null
  private getMacroOptionsData = null
  private getFifxOptionsData = null

  public getCreditOptions(): Observable<any> {
    return new Observable(observer => {
      if (this.getCreditOptionsData) {
        observer.next(this.getCreditOptionsData)
        observer.complete()
        return
      }
      this.http.get<any>(
        `${environment.baseUrlEmailSubApi}RuleCreationOptions/credit`
      ).pipe(
        map(data => {
          return data
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete()
          return data.error.title
        })
      ).subscribe(data => {
        this.getCreditOptionsData = data
        observer.next(data)
        observer.complete()
      });
    })
  }

  public getMacroOptions(): Observable<any> {
    return new Observable(observer => {
      if (this.getMacroOptionsData) {
        observer.next(this.getMacroOptionsData)
        observer.complete()
        return
      }
      this.http.get<any>(
        `${environment.baseUrlEmailSubApi}RuleCreationOptions/macro`
      ).pipe(
        map(data => {
          return data
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete()
          return data.error.title
        })
      ).subscribe(data => {
        this.getMacroOptionsData = data
        observer.next(data)
        observer.complete()
      });
    })
  }

  public getFifxOptions(): Observable<any> {
    return new Observable(observer => {
      if (this.getFifxOptionsData) {
        observer.next(this.getFifxOptionsData)
        observer.complete()
        return
      }
      this.http.get<any>(
        `${environment.baseUrlEmailSubApi}RuleCreationOptions/fifx`
      ).pipe(
        map(data => {
          return data
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete()
          return data.error.title
        })
      ).subscribe(data => {
        this.getFifxOptionsData = data
        observer.next(data)
        observer.complete()
      });
    })
  }

  public getEquityOptions(): Observable<any> {
    return new Observable(observer => {
      if (this.getEquityOptionsData) {
        observer.next(this.getEquityOptionsData)
        observer.complete()
        return
      }
      this.http.get<any>(
        `${environment.baseUrlEmailSubApi}RuleCreationOptions/equity`
      ).pipe(
        map(data => {
          return data
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete()
          return data.error.title
        })
      ).subscribe(data => {
        this.getEquityOptionsData = data
        observer.next(data)
        observer.complete()
      });
    })
  }

  public createRule(rule: Rule, userId: string): Observable<Rule> {
    return new Observable(observer => {
      this.http.post<any>(
        `${environment.baseUrlEmailSubApi}Rule`,
        {
          UserId: userId,
          CategoryType: rule.categoryCode as RuleCategoryEnum,
          Publicationtypes: rule.publicationTypes.map(x => x.key),
          Sectors: rule.sectors.map(x => x.key),
          Countries: rule.countries.map(x => x.key),
          Companies: rule.companies.map(x => x.key),
          Analysts: rule.analysts.map(x => x.key),
          Currencies: rule.currencies.map(x => x.key),
          Languages: rule.languages.map(x => x.key),
          Regions: rule.regions.map(x => x.key),
          Marketcap: (!rule.marketCap || rule.marketCap.length == 2) && RuleCategoryEnum[rule.categoryCode] == RuleCategoryEnum.EQUITY ? { Min: rule.marketCap[0], Max: rule.marketCap[1]} : null
        }
      ).pipe(
        map(data => {
          return new Rule(data.rule.id, RuleCategoryEnum[data.rule.categoryType], data.rule.publicationtypes, data.rule.sectors, data.rule.countries, 
            data.rule.companies, data.rule.analysts, data.rule.currencies, data.rule.marketcap ? [data.rule.marketcap?.min, data.rule.marketcap?.max] : null, data.rule.languages, data.rule.regions)
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete();
          return data.error.title;
        })
      ).subscribe(data => {
        observer.next(data as Rule)
        observer.complete();
      });
    })
  }

  public updateRule(rule: Rule): Observable<Rule> {
    return new Observable(observer => {
      this.http.put<any>(
        `${environment.baseUrlEmailSubApi}Rule`,
        {
          RuleId: rule.id,
          CategoryType: rule.categoryCode as RuleCategoryEnum,
          Publicationtypes: rule.publicationTypes.map(x => x.key),
          Sectors: rule.sectors.map(x => x.key),
          Countries: rule.countries.map(x => x.key),
          Companies: rule.companies.map(x => x.key),
          Analysts: rule.analysts.map(x => x.key),
          Currencies: rule.currencies.map(x => x.key),
          Languages: rule.languages.map(x => x.key),
          Regions: rule.regions.map(x => x.key),
          Marketcap: (!rule.marketCap || rule.marketCap.length == 2) && RuleCategoryEnum[rule.categoryCode] == RuleCategoryEnum.EQUITY ? { Min: rule.marketCap[0], Max: rule.marketCap[1]} : null
        }
      ).pipe(
        map(data => {
          return new Rule(data.rule.id, RuleCategoryEnum[data.rule.categoryType], data.rule.publicationtypes, data.rule.sectors, data.rule.countries, 
            data.rule.companies, data.rule.analysts, data.rule.currencies, data.rule.marketcap ? [data.rule.marketcap?.min, data.rule.marketcap?.max] : null, data.rule.languages, data.rule.regions)
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete();
          return data.error.title;
        })
      ).subscribe(data => {
        observer.next(data as Rule)
        observer.complete();
      });
    })
  }
}
