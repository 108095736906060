import { Component } from '@angular/core';
import { UserExModel, UserApiService } from './user-api.service';
import { ActivatedRoute } from '@angular/router';
import { UserEmailPublicApiService } from './user-email/_service/user-email-public-api.service';
import { AuthorizeService } from 'api-authorization/authorize.service';

@Component({
  selector: 'user-tabs',
  templateUrl: 'user-tabs.component.html'
})
export class UserTabsComponent {
  user: UserExModel;
  emailQuery: string;
  whoAmI: string;

  constructor(private authService: AuthorizeService, private userApi: UserApiService, private route: ActivatedRoute, private userEmailPublicApi: UserEmailPublicApiService) {
    this.emailQuery = this.route.snapshot.queryParams['email'];
  }

  ngOnInit() {
    if (this.emailQuery) this.getUser();
    this.authService.getUser().subscribe(x => {
      this.whoAmI = x.name
    })
  }

  get isExistingUser(): boolean {
    return !!this.emailQuery;
  }
  getUser() {
    this.userApi.getUser(this.emailQuery).subscribe(user => (this.user = user));
  }

  userUpdated(event: {enabled: boolean, isEmailUser: boolean}){
    this.user.Enabled = event.enabled
    if(!event.enabled)
      this.userEmailPublicApi.disableDistribution(this.user.EMail)
    else if(event.isEmailUser)
      this.userEmailPublicApi.enableDistribution(this.user.EMail)
  }
}
