<h3># {{discl.Id}}</h3>
<div class="row">
  <div class="col-4"><b>Name</b> <input [(ngModel)]="discl.Name" class="form-control" /></div>
  <div class="col-4"><b>Url</b> <input [(ngModel)]="discl.Url" class="form-control" /></div>
  <div class="col-2"><b>Top menu</b> <select [(ngModel)]="discl.TopMenu" class="form-control"><option value="Equity">Equity</option><option value="Credit">Credit</option><option value="Macrores">Macrores</option><option value="FIFX">FIFX</option><option value="Swe">Svenska</option><option value="Bond">Bond indices</option></select></div>
  <div class="col-2"><b>Sort order</b> <input [(ngModel)]="discl.SortOrder" class="form-control" /></div>
</div>

<b>Content</b>
&nbsp;&nbsp;&nbsp;&nbsp;<label class="radio-inline"><input [(ngModel)]="discl.ContentType" [value]="0" type="radio" name="ContentType" /> Html</label>
<label class="radio-inline"><input [(ngModel)]="discl.ContentType" [value]="1" type="radio" name="ContentType" /> PDF</label>
<span [hidden]="discl.ContentType !== 0" style="float:right"><button class="btn-link" (click)="toggleHelp()">Special commands to insert</button></span>
<div [hidden]="!showHelp" class="htmlCommandHelp">
  <h6>[[TodayDate]]<button class="btn-link" (click)="copyCommand('TodayDate', $event)">copy</button></h6>
  <span>Current date</span>
  <h6>[[RecommendationStructure]]<button class="btn-link" (click)="copyCommand('RecommendationStructure', $event)">copy</button></h6>
  <span>Equity recommendation structure</span>
  <h6>[[RecommendationList]]<button class="btn-link" (click)="copyCommand('RecommendationList', $event)">copy</button></h6>
  <span>Equity recommendation list</span>
  <h6>[[MarknadsinfoRekommendationshistorik]]<button class="btn-link" (click)="copyCommand('MarknadsinfoRekommendationshistorik', $event)">copy</button></h6>
  <span>Equity recommendation list på svenska</span>
  <h6>[[CompanySpecificDisclosures]]<button class="btn-link" (click)="copyCommand('CompanySpecificDisclosures', $event)">copy</button></h6>
  <span>Equity company specific disclosures</span>
  <h6>[[CreditRecommendationStructure]]<button class="btn-link" (click)="copyCommand('CreditRecommendationStructure', $event)">copy</button></h6>
  <span>Credit recommendation structure</span>
  <h6>[[CreditRecommendationList]]<button class="btn-link" (click)="copyCommand('CreditRecommendationList', $event)">copy</button></h6>
  <span>Credit recommendation list</span>
  <h6>[[RecommendationStructureLongTerm]]<button class="btn-link" (click)="copyCommand('RecommendationStructureLongTerm', $event)">copy</button></h6>
  <span>Equity recommendation structure long term</span>
  <input style="visibility:hidden;" id="copyTextFix" />
</div>
<div style="width: 900px;">
  <div [hidden]="discl.ContentType !== 0" class="disclaimer">
    <textarea style="width:100%;min-height: 300px;" id="htmlContentArea" name="htmlContentArea"></textarea>
  </div>
  <div [hidden]="discl.ContentType !== 1">
    <div style="padding-bottom:4px;">
      Lägg till/uppdatera pdf
      <input type="file" (change)="fileChange($event)" placeholder="Select PDF to upload" accept=".pdf">
    </div>
    <!--<embed type="application/pdf" [src]="pdfUrl" style="height: 800px; width: 100%;" />-->
    <iframe *ngIf="pdfUrl" [src]="pdfUrl" style="height: 800px; width: 100%;"></iframe>
  </div>
</div>

<div style="float:right;padding-top:8px;">
  <button (click)="saveDisclaimer()" class="btn btn-success">{{discl.Id == 0? "Create" : "Update"}}</button>
  <button (click)="deleteDisclaimer()" class="btn btn-danger">Delete</button>
</div>
