import { Injectable } from '@angular/core';
import { RulesService } from './rules.service';

@Injectable({
  providedIn: 'root'
})
export class UserEmailPublicApiService {

  constructor(private rulesService: RulesService) { }

  disableDistribution(userId: string){
    this.rulesService.updateUser(userId, true).subscribe()
  }

  enableDistribution(userId: string){
    this.rulesService.updateUser(userId, false).subscribe()
  }
}
