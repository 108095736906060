<div class="container-fluid" style="margin-left:0; width:auto; height:100vh">

  <div class="hide-menu hover" (click)="navToogle()" [ngStyle]="marginleft()" *ngIf="isAuthenticated">
    <fa-icon [icon]="['fas', navShow ? 'angle-left' : 'angle-right']"></fa-icon>
  </div>

  <div class="row" style="height:100%">
    <div *ngIf="navShow && isAuthenticated" class="col, no-scrollVisible" style="position:fixed; height: 100vh; background-color: #F1F1F1; padding: 1em; padding-right:0; overflow-y:scroll; width:216px">
      <navigation-list></navigation-list>
    </div>
 
    <div class="col-" style="padding-left:2rem; padding-top:2em; overflow-y:scroll; " [ngStyle]="marginleftFullWidth()">
      <div>
        <router-outlet></router-outlet>
        <br/><br/><br/><br/>
      </div>
    </div>
  </div>
</div>

