<h2 mat-dialog-title>{{mmForm.get('id').value.id ? "Edit contribution" : "New contribution"}}</h2>
<mat-divider></mat-divider>
<mat-dialog-content class="mat-typography" *ngIf="mmForm">
    <p *ngIf="mmForm.get('id').value">Last update: {{mmForm.get('updated').value | date}}</p>
    <p>
        <mat-icon inline="true">person</mat-icon> Author: {{ user_analyst }}
    </p>
    <form [formGroup]="mmForm">
        <div>
            <div class="form-group row">
                <mat-form-field class="field-full-width" appearance="outline">
                    <mat-label>Company</mat-label>
                    <input type="text" matInput [formControl]="companyControl" [matAutocomplete]="auto" (blur)="checkCompany()">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option disabled *ngIf="loadingCompanies | async">
                            <mat-spinner diameter="15"></mat-spinner>
                        </mat-option>
                        <mat-option disabled
                            *ngIf="(filteredCompanies| async)?.length === 0 && !(loadingCompanies | async)">
                            No company found
                        </mat-option>
                        <mat-option (onSelectionChange)="assignAdHoc()" [value]="Ad-hoc">
                            Ad-hoc <mat-hint>-Not company specific</mat-hint>
                        </mat-option>
                        <mat-option (onSelectionChange)="assignCompany(option)"
                            *ngFor="let option of filteredCompanies | async" [value]="option.Name">
                            {{option.Name}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint>Start typing company name</mat-hint>
                    <mat-error *ngIf="mmForm.get('companyId').hasError('required')">
                        Company name is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group row">
                <mat-form-field class="field-full-width" appearance="outline">
                    <mat-label>Headline</mat-label>
                    <input type="text" matInput formControlName="headline">
                    <mat-hint></mat-hint>
                    <mat-error *ngIf="mmForm.get('headline').hasError('required')">
                        Headline is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="mmForm.get('headline').hasError('minlength') && !mmForm.get('headline').hasError('required')">
                        Headline must be at least <strong>2</strong> character long.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group row">
                <div class="col-md-6 col-sm-12">
                    <label class="btn btn-primary btn-file">
                        <mat-icon>attach_file</mat-icon>
                        {{ppt ? 'Replace ' : 'Add ' }}powerpoint file
                        <input type="file" hidden (change)="handleFileInput($event);">
                    </label>
                    <div class="attachment" *ngIf="ppt">
                        {{ppt.name}} ({{humanFileSize(ppt.size)}})
                        <button class="close" (click)="clearFile()" type="file">
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <mat-checkbox formControlName="fastComment"> Fast comment </mat-checkbox>
                    <br>
                    <mat-checkbox formControlName="recChange"> Recommendation change </mat-checkbox>
                    <br>
                    <textarea matInput hidden></textarea>
                </div>
            </div>
            <div>
                <mat-error>{{errorMessage}}</mat-error>
            </div>
            <mat-divider></mat-divider>
        </div>
    </form>

</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button (click)="delete()" *ngIf="this.mmForm.get('id').value"><mat-icon>delete</mat-icon></button>
    <mat-spinner style="color:white;" *ngIf="uploading" [diameter]="20" mode="indeterminate">
    </mat-spinner>
    <button mat-button mat-dialog-close color="accent">Cancel</button>
    <button mat-button color="primary" (click)="onSubmit($event)" cdkFocusInitial>Save</button>
</mat-dialog-actions>