import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './nmw-contributions.routing';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  ContributeComponent,
  NMW_Timeout
} from './contribute/ContributeComponent';
import { ReviewComponent } from "./review/ReviewComponent";
import { MorningMeetingApi } from './nmw-contributions-api.service';
import { NmwCalendarEventsComponent } from './calendar/nmw-calendarevents.component';
import { NmwCalendarEventsApi } from './calendar/nmw-calendarevents-api';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { MmEditComponent } from './mm-edit/mm-edit.component';
import { MaterialModule } from 'app/shared/material/material.module';
import { IconPack } from '@fortawesome/fontawesome-svg-core';

@NgModule({
  declarations: [
    ContributeComponent,
    ReviewComponent,
    NMW_Timeout,
    NmwCalendarEventsComponent,
    MmEditComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    BrowserAnimationsModule,
    MaterialModule,
    routing
  ],
  exports: [
    ContributeComponent,
    ReviewComponent,
    NmwCalendarEventsComponent
  ]
})
export class MorningWrapModule {
  static forRoot(): ModuleWithProviders<MorningWrapModule> {
    return {
      ngModule: MorningWrapModule,
      providers: [MorningMeetingApi, NmwCalendarEventsApi]
    };
  }
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas as IconPack, far as IconPack);
  }
}
