import { Component } from '@angular/core';
import { MiscApiService } from './misc-api.service'

// New
@Component({
    selector: 'misc-morningMeeting',
    template: '<h2>Morning meeting users</h2><p>Under construction</p>'
})

export class MorningMeetingComponent {

  constructor(private contentApi: MiscApiService){}

}