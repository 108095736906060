<div class="container-fluid">
    <div class="row" style="margin-top:20px;"></div>
    <div class="col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title> Search Employee </mat-card-title>
                <mat-card-subtitle> <br> </mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12 col-lg-3" style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
                        <form [formGroup]="searchForm">
                            <mat-card-subtitle> Parameters</mat-card-subtitle>
                            <mat-divider></mat-divider>
                            <div class="col-12">
                                <mat-card-subtitle>Show active employees </mat-card-subtitle>
                                <mat-form-field class="field-full-width" appearance="none">
                                    <mat-checkbox formControlName="Active"> Active </mat-checkbox>
                                    <textarea matInput hidden></textarea>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                        </form>

                    </div>
                    <div class="col-md-12 col-lg-9">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Filter</mat-label>
                                <input matInput placeholder="Type filter term" [formControl]="filterModel"
                                    (ngModelChange)="updateFilterModel($event)">
                                    <mat-icon matPrefix>filter_alt</mat-icon>
                            </mat-form-field>
                            <mat-spinner *ngIf="loading" [diameter]="20" mode="indeterminate"></mat-spinner>
                            <pre *ngIf="error" style="color:red;">{{error}}</pre>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-label *ngIf="employeeList.data">{{employeeList.data.length}} results</mat-label>
                        <div class="col-12">
                            <table mat-table [dataSource]="employeeList" style="width: 100%;" *ngIf="employeeList"  matSort 
                            (matSortChange)="sortData($event)" matSortActive="EditDate" matSortDirection="desc" matSortDisableClear>
                        
                                <ng-container matColumnDef="UserId">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> UserId </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> {{element.UserId}} </td>
                                </ng-container>
                                <ng-container matColumnDef="First name">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> First name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.FirstName}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Last name">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Last name </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.LastName}}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-raised-button color="primary" (click)="selectEmployee(element)">
                                            <mat-icon>arrow_right_alt</mat-icon> Edit
                                        </button>
                                    </td>
                                </ng-container>
                                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 100, 1000]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>
<!-- 
<h2>Search Employee</h2>

<div style="width:600px">
    <div class="form-group row yCenter">
            <label class="col-sm-4" for="activeOnly">
                    Show active employees
            </label>    
        <div class="col-sm-8">
            <div class="form-check">
                    
                      <input class="form-check-input" type="checkbox"                      
                      [(ngModel)]="activeOnly" 
                      (change)="doSearch()"
                      >                   
            </div>           
        </div>   
    </div>
</div>

<table class="table table-hover" style="margin-top:2rem">
    <thead>
        <tr>
          <th>UserId</th>
          <th>First name</th>
          <th>Last name</th>
        </tr>
    </thead>
    <tbody style="font-size:13px;">
      <tr class="hover" *ngFor="let e of employeeList" (click)="selectEmployee(e)"><td>{{e.UserId}}</td><td>{{e.FirstName}}</td><td>{{e.LastName}}</td></tr>
    </tbody>
</table>
 -->