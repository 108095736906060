import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EventShort, EventApiService, EventStatus } from '../event-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-event-search',
  templateUrl: './event-search.component.html',
  styleUrls: ['./event-search.component.css']
})
export class EventSearchComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  searchForm = new FormGroup({
    Name: new FormControl('', {}),
    Category: new FormControl('', {}),
    Upcoming: new FormControl(false, {}),
    defineUpcoming: new FormControl(false , {}),
    Status: new FormControl('', {}),
    filter: new FormControl('', {}),
  });
  categories: string[] = [''];
  statusTypes = Object.keys(EventStatus).map(key => EventStatus[key]).filter(value => typeof value === 'string') as string[];
  displayedColumns: string[] = ['Category', 'Name', 'City/Location', 'StartDate', 'action'];
  loading = false;
  eventList = new MatTableDataSource(<EventShort[]>[]);
  constructor(
    public eventApi: EventApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.searchForm.patchValue({
        Name: params['Name'] ? params['Name'] : '',
        Category: params['Category'] ? params['Category'] : '',
        Upcoming: params['Upcoming'] !== undefined ? (params['Upcoming'] === 'true') : true,
        defineUpcoming: params['defineUpcoming'] !== undefined ? (params['defineUpcoming'] === 'true') : true,
        Status: params['Status'] ? params['Status'] : '',
        filter: params['filter'] ? params['filter'] : '',
      });
      this.searchForm.get('defineUpcoming').valueChanges.subscribe(val =>
        val ? this.searchForm.get('Upcoming').enable() : this.searchForm.get('Upcoming').disable());
    });
  }

  ngOnInit() {
    this.loading = true;
    this.eventApi.getCategories().subscribe(categories => {
      this.categories = categories;
      this.loading = false;
    });
    this.searchForm.get('filter').valueChanges.subscribe(val => this.eventList.filter = val.trim());
    this.searchForm.get('filter').updateValueAndValidity();
    this.eventList.paginator = this.paginator;
    this.onSubmit();
  }

  selectEvent(ev: EventShort) {
    this.router.navigate(['/Event/Edit'], { queryParams: { eventId: encodeURIComponent(ev.EventId) } });
  }
  sortData(sort: Sort) {
    const data = this.eventList.data.slice();
    if (!sort.active || sort.direction === '') {
      this.eventList.data = data;
      return;
    }

    this.eventList.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Category': return compare(a.Category, b.Category, isAsc);
        case 'Name': return compare(a.Name, b.Name, isAsc);
        case 'City/Location': return compare(a.City, b.City, isAsc);
        case 'StartDate': return compare(a.StartDate, b.StartDate, isAsc);
        default: return 0;
      }
    });
  }
  onSubmit() {
    this.router.navigate(['/Event/Search'], { queryParams: this.searchForm.getRawValue() }).then(() => {
      this.searchForm.get('Upcoming').updateValueAndValidity();
    });

    this.loading = true;
    this.eventApi.searchEvents(this.searchForm.value).subscribe(data => {
      this.eventList.data = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error)
    });
  }
}
function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
