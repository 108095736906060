import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MorningMeetingModel, CompanyNameModel, MorningMeetingApi } from '../nmw-contributions-api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { startWith, map, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-mm-edit',
  templateUrl: './mm-edit.component.html',
  styleUrls: ['./mm-edit.component.css']
})
export class MmEditComponent implements OnInit {
  morningmeeting = new BehaviorSubject<MorningMeetingModel>(null);
  loadingCompanies = new Subject<boolean>();
  mmForm;

  errorMessage: string;
  user_analyst: string;
  companyControl = new FormControl('', { validators: [Validators.required] });
  filteredCompanies: Observable<CompanyNameModel[]>;
  ppt: File;
  uploading: boolean;
  success: boolean;
  constructor(
    private mmApi: MorningMeetingApi,
    public authorization: AuthorizeService,
    public dialogRef: MatDialogRef<MmEditComponent>,
    @Inject(MAT_DIALOG_DATA) public morningmeetingInput: MorningMeetingModel) {
    this.mmForm = new FormGroup({
      id: new FormControl('', {
        validators: [],
        updateOn: 'change'
      }),
      companyId: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      companyName: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      headline: new FormControl('', {
        validators: [Validators.required, Validators.minLength(2)],
        updateOn: 'change'
      }),
      created: new FormControl('', {
        validators: [],
        updateOn: 'change'
      }),
      updated: new FormControl('', {
        validators: [],
        updateOn: 'change'
      }),
      analystId: new FormControl('', {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      fastComment: new FormControl(false, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      recChange: new FormControl(false, {
        validators: [Validators.required],
        updateOn: 'change'
      }),
      salesSelected: new FormControl('', {
        validators: [],
        updateOn: 'change'
      }),
      attachmentId: new FormControl('', {
        validators: [],
        updateOn: 'change'
      }),
      template: new FormControl('', {
        validators: [],
        updateOn: 'change'
      }),
    }, { updateOn: 'submit' });
    this.mmChanged(morningmeetingInput)
    this.morningmeeting.subscribe(this.mmChanged);
  }

  ngOnInit(): void {
    this.whoAmI();
    this.filteredCompanies = this.companyControl.valueChanges
      .pipe(
        tap(() => this.loadingCompanies.next(true)),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(val => {
          return this._filter(val || '')
        })
      );
  }

  whoAmI() {
    this.authorization
      .getUser()
      .subscribe(u => {
        this.user_analyst = u.name.substring(0, u.name.indexOf('@'));
        this.mmForm.patchValue({
          analystId: this.user_analyst
        });
      });
  }

  assignCompany(company: CompanyNameModel) {
    this.mmForm.patchValue({
      companyId: company.Id,
      companyName: company.Name
    });
  }

  FileException(message) {
    alert('Error: ' + message);
  }

  handleFileInput(e) {
    const file = e.target.files[0];
    if (this.validatefile(file)) {
      this.ppt = file;
    }
    e.target.value = ''
  }
  validatefile(file: File) {
    if (file == null) {
      this.FileException('No file found.');
      return false;
    } else if (
      file.type !== 'application/vnd.ms-powerpoint' &&
      file.type !==
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow' &&
      file.type !==
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      this.FileException('Only accepting ppt and pptx files');
      return false;
    }
    return true;
  }

  mmChanged(mm: MorningMeetingModel) {
    if (mm == null) {
      return;
    }
    this.mmForm.patchValue({
      id: mm.id,
      companyId: mm.companyId,
      companyName: mm.companyName,
      headline: mm.headline,
      created: mm.created,
      updated: mm.updated,
      analystId: mm.analystId,
      fastComment: mm.fastComment == null ? false : mm.fastComment,
      recChange: mm.recChange == null ? false : mm.recChange,
      salesSelected: mm.salesSelected,
      attachmentId: mm.attachmentId,
      template: mm.template
    });
    this.companyControl.patchValue(mm.companyName);
  }
  onSubmit(event) {
    this.errorMessage = '';
    event.preventDefault()
    if (this.mmForm.invalid) {
      this.getFormValidationErrors();
      this.mmForm.markAllAsTouched();
      this.companyControl.markAllAsTouched();
      return;
    }
    const mm_name = this.mmForm.get('companyName').value;
    this.mmApi.companyExists(mm_name).subscribe(existsIfPositive => {
      if (existsIfPositive < 1 && mm_name !== 'Ad-hoc') {
        this.errorMessage = 'The company does not exists. Select one from the drop-down menu.';
        return;
      } else {
        this.save(this.mmForm.value);
      }
    });
  }

  getFormValidationErrors() {
    let controlErrors: ValidationErrors;
    Object.keys(this.mmForm.controls).forEach(key => {
      controlErrors = this.mmForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });

    controlErrors = this.companyControl.errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
        console.log(this.errorMessage = 'Company input' + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
      });
    }

  }
  assignAdHoc() {
    this.assignCompany({Name: 'Ad-hoc', Id: 1})
    this.companyControl.patchValue('Ad-hoc');
  }
  clearFile() {
    this.ppt = null;
  }

  checkCompany() {
    const checked_input = this.mmForm.get('companyName').value;
    const new_input = this.companyControl.value;
    if (checked_input !== new_input && checked_input !== 'Ad-hoc') {
      this.errorMessage = 'Please select a company from the dropdown list';
      this.companyControl.setErrors({ required: 'true' });
    } else {
      this.errorMessage = '';
    }
  }

  humanFileSize(bytes) {
    if (bytes === 0) { return '0.00 B'; }
    const e = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, e)).toFixed(2) + ' ' + ' KMGTP'.charAt(e) + 'B';
  }

  save(mm: MorningMeetingModel) {
    this.uploading = true;
    if (this.ppt == null) {
      this.saveMM(mm);
    } else {
      this.mmApi
        .saveAttachment(this.ppt)
        .subscribe(res => {
          mm.attachmentId = res;
          this.saveMM(mm);
        }, err => {
          this.uploading = false;
          this.success = false;
          alert('Could not upload file. ' + err);
        });
    }
  }

  private saveMM(mm: MorningMeetingModel) {
    this.mmApi
      .saveContribution(mm)
      .subscribe(() => {
        this.uploading = false;
        this.success = true;
        this.dialogRef.close(this.mmForm.value)
      }, err => {
        this.uploading = false;
        this.success = false;
        this.errorMessage = 'Error during save, reason: ' + err.message;
        throw err;
      });
  }

  private _filter(value: string): Observable<CompanyNameModel[]> {
    const filterValue = value.toLowerCase();
    return this.mmApi.searchCompanies(filterValue)
      .pipe(
        tap(() => this.loadingCompanies.next(false)),
        map(response => response.filter(option => {
          return option.Name.toLowerCase().indexOf(value.toLowerCase()) === 0
        })))
  }

  delete() {
    const mm_id = this.mmForm.get('id').value;
    if (confirm('Are you sure you want to delete this?')) {
      this.mmApi
        .deleteContribution(mm_id)
        .subscribe(res => {
          this.dialogRef.close(res)
        }, err => {
          alert('Error during delete, reason: ' + err);
          throw err;
        });
    }
  }
}
