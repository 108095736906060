<h2>Document Statistics</h2>

<p>
  You might need to enlarge the width of the browser to se full width of the
  table
</p>

<br />

<i>Choose desired start and end date:</i><br />
<div class="form-group row yCenter">
  <label for="startDate" class="col-2 col-form-label">Read From/To: *</label>
  <div class="col-8">
    <div class="form-check">
      <label class="form-check-label">
        <input type="date" class="form-control" [(ngModel)]="readStartdate" />
        <input style="margin-top:10px" type="date" class="form-control" [(ngModel)]="readEnddate"
          [ngStyle]="{ border: checkError('read') }" />
      </label>
    </div>
  </div>
</div>
<div class="form-group row yCenter">
  <label for="endtDate" class="col-2 col-form-label">Published From/To: *</label>
  <div class="col-8">
    <div class="form-check">
      <label class="form-check-label">
        <input type="date" class="form-control" [(ngModel)]="pubStartdate" />
        <input style="margin-top:10px" type="date" class="form-control" [(ngModel)]="pubEnddate"
          [ngStyle]="{ border: checkError('published') }" />
      </label>
    </div>
  </div>
</div>
<div class="form-group row yCenter">
  <label for="expireDate" class="col-2 col-form-label">Channels: *</label>
  <div class="col-8">
    <div class="form-check">
      <label class="form-check-label">
          <label *ngFor="let ch of fileDistrPos; let i=index" class="form-check-label" style="padding-right:1rem;margin-right:1rem">
            <input type="checkbox" class="form-check-input" (click)="addRemoveChannel(ch)" [checked]="channels.indexOf(ch) > -1" />
            {{ ch }}
          </label>
      </label>
    </div>
  </div>
</div>
<!--<div class="form-group row yCenter">
  <label for="expireDate" class="col-2 col-form-label">Match with email:<br><span style="font-size: 8px; color: gray">(for
      example @handelsbanken)</span></label>
  <div class="col-9">
    <div class="form-check">
      <label class="form-check-label">
        <input type="text" class="form-control" [(ngModel)]="searchString">
      </label>
    </div>
  </div>
</div>-->
<button type="button" class="btn btn-outline-warning" (click)="submit()" [disabled]="disabledGetResult">
  Show
</button>
<a class="btn btn-outline-warning {{ disabledGetResult ? 'disabled' : '' }}"
  (click)="downloadFile(downloadAggregateLink)"
  target="_blank" [attr.aria-label]="disabledGetResult" download>Download aggregate excel</a>
<a class="btn btn-outline-warning {{ disabledGetResult ? 'disabled' : '' }}" 
  (click)="downloadFile(downloadReaderDataLink)"
  target="_blank" [attr.aria-label]="disabledGetResult" download>Download reader data excel</a>
<img *ngIf="showLoading()" style="margin-left:10px; height:25px; width:auto;" src="./../assets/ajax-loader.gif"
  alt="Loading..." />
<br />
<br />
<div *ngIf="error"><pre style="color:red">{{error}}</pre></div>

<!--<div *ngFor="let item of docStats">
  {{item.Bloomberg[0].Email}}
</div>-->
<style>
  .table-border-top {
    border-top: none;
  }

  td:nth-child(3n + 0) {
    border-right: 2px solid #eceeef;
  }
</style>

<div class="form-group row yCenter" *ngIf="docStats != null">
  <label for="expireDate" class="col-2 col-form-label">Filter (be patient):
  </label>
  <div class="col-8">
    <div class="form-check">
      <label class="form-check-label">
        <label class="form-check-label" style="margin-right:1rem">
          <input type="checkbox" class="form-check-input" [(ngModel)]="showUnknownDocs" />
          Show unknown documents
        </label>
      </label>
    </div>
  </div>
</div>

<table class="table table-hover">
  <thead>
    <tr>
      <th colspan="3" style="text-align:center; border-bottom:none;"></th>
      <th colspan="3" style="text-align:center; border-bottom:none;">
        Total <span style="font-size:10px">(excl. Downloads)</span>
      </th>
      <th *ngIf="channels.includes('Reon')" colspan="3" style="text-align:center; border-bottom:none;">
        REON Downloads
      </th>
      <th *ngIf="channels.includes('Reon')" colspan="3" style="text-align:center; border-bottom:none;">
        REON Pageviews
      </th>
      <th *ngIf="channels.includes('Bloomberg')" colspan="3" style="text-align:center; border-bottom:none;">
        Bloomberg
      </th>
      <th *ngIf="channels.includes('Thomson Reuters')" colspan="3" style="text-align:center; border-bottom:none;">
        Thomson Reuters
      </th>
      <th *ngIf="channels.includes('Factset')" colspan="3" style="text-align:center; border-bottom:none;">
        Factset
      </th>
      <th *ngIf="channels.includes('Capital IQ')" colspan="3" style="text-align:center; border-bottom:none;">
        Capital IQ
      </th>
      <th *ngIf="channels.includes('Markit')" colspan="3" style="text-align:center; border-bottom:none;">
        Markit
      </th>
      <!--<th *ngFor="let i of channels" colspan="3" style="text-align:center; border-bottom:none;">{{i}}</th>-->
    </tr>
    <tr style="font-size:11px;">
      <th class="table-border-top">Pub. Date</th>
      <th class="table-border-top">Headline</th>
      <th class="table-border-top">Pub. type</th>

      <th class="table-border-top">Tot</th>
      <th class="table-border-top">Unique</th>
      <th class="table-border-top">Anon</th>

      <th *ngIf="channels.includes('Reon')" class="table-border-top">Tot</th>
      <th *ngIf="channels.includes('Reon')" class="table-border-top">Unique</th>
      <th *ngIf="channels.includes('Reon')" class="table-border-top">Anon</th>

      <th *ngIf="channels.includes('Reon')" class="table-border-top">Tot</th>
      <th *ngIf="channels.includes('Reon')" class="table-border-top">Unique</th>
      <th *ngIf="channels.includes('Reon')" class="table-border-top">Anon</th>

      <th *ngIf="channels.includes('Bloomberg')" class="table-border-top">
        Tot
      </th>
      <th *ngIf="channels.includes('Bloomberg')" class="table-border-top">
        Unique
      </th>
      <th *ngIf="channels.includes('Bloomberg')" class="table-border-top">
        Anon
      </th>

      <th *ngIf="channels.includes('Thomson Reuters')" class="table-border-top">
        Tot
      </th>
      <th *ngIf="channels.includes('Thomson Reuters')" class="table-border-top">
        Unique
      </th>
      <th *ngIf="channels.includes('Thomson Reuters')" class="table-border-top">
        Anon
      </th>

      <th *ngIf="channels.includes('Factset')" class="table-border-top">Tot</th>
      <th *ngIf="channels.includes('Factset')" class="table-border-top">
        Unique
      </th>
      <th *ngIf="channels.includes('Factset')" class="table-border-top">
        Anon
      </th>

      <th *ngIf="channels.includes('Capital IQ')" class="table-border-top">
        Tot
      </th>
      <th *ngIf="channels.includes('Capital IQ')" class="table-border-top">
        Unique
      </th>
      <th *ngIf="channels.includes('Capital IQ')" class="table-border-top">
        Anon
      </th>

      <th *ngIf="channels.includes('Markit')" class="table-border-top">Tot</th>
      <th *ngIf="channels.includes('Markit')" class="table-border-top">
        Unique
      </th>
      <th *ngIf="channels.includes('Markit')" class="table-border-top">Anon</th>
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let item of docStats; let i = index">
      <tr *ngIf="showFilter(item)" style="font-size:11px;">
        <td>{{ item.PubDate | date: "yyyy-MM-dd" }}</td>
        <td>{{ item.Headline }}</td>
        <td>{{ item.PubType }}</td>

        <!-- Pageviews -->
        <td>{{ readerCount(item, "total") }}</td>
        <td>{{ readerCount(item, "unique") }}</td>
        <td>{{ readerCount(item, "anon") }}</td>

        <!-- REON Download -->
        <td *ngIf="channels.includes('Reon')">
          {{ valueNull(item.ReonDownload.TotalReaders) }}
        </td>
        <td *ngIf="channels.includes('Reon')" data-toggle="modal" data-target=".bd-example-modal-lg"
          (click)="moreInfo(i, 'Reon Download')" class="hover" style="color:#0275d8">
          {{ valueNull(item.ReonDownload.UniqueReaders) }}
        </td>
        <td *ngIf="channels.includes('Reon')">
          {{ valueNull(item.ReonDownload.AnonymousReaders) }}
        </td>

        <!-- REON -->
        <td *ngIf="channels.includes('Reon')">
          {{ valueNull(item.Reon.TotalReaders) }}
        </td>
        <td *ngIf="channels.includes('Reon')" data-toggle="modal" data-target=".bd-example-modal-lg"
          (click)="moreInfo(i, 'Reon')" class="hover" style="color:#0275d8">
          {{ valueNull(item.Reon.UniqueReaders) }}
        </td>
        <td *ngIf="channels.includes('Reon')">
          {{ valueNull(item.Reon.AnonymousReaders) }}
        </td>

        <!-- Bloomberg -->
        <td *ngIf="channels.includes('Bloomberg')">
          {{ valueNull(item.Bloomberg.TotalReaders) }}
        </td>
        <td *ngIf="channels.includes('Bloomberg')" data-toggle="modal" data-target=".bd-example-modal-lg"
          (click)="moreInfo(i, 'Bloomberg')" class="hover" style="color:#0275d8">
          {{ valueNull(item.Bloomberg.UniqueReaders) }}
        </td>
        <td *ngIf="channels.includes('Bloomberg')">
          {{ valueNull(item.Bloomberg.AnonymousReaders) }}
        </td>

        <!-- Thomson Reuters -->
        <td *ngIf="channels.includes('Thomson Reuters')">
          {{ valueNull(item.Thomson.TotalReaders) }}
        </td>
        <td *ngIf="channels.includes('Thomson Reuters')" data-toggle="modal" data-target=".bd-example-modal-lg"
          (click)="moreInfo(i, 'Thomson Reuters')" class="hover" style="color:#0275d8">
          {{ valueNull(item.Thomson.UniqueReaders) }}
        </td>
        <td *ngIf="channels.includes('Thomson Reuters')">
          {{ valueNull(item.Thomson.AnonymousReaders) }}
        </td>

        <!-- Factset -->
        <td *ngIf="channels.includes('Factset')">
          {{ valueNull(item.Factset.TotalReaders) }}
        </td>
        <td *ngIf="channels.includes('Factset')" data-toggle="modal" data-target=".bd-example-modal-lg"
          (click)="moreInfo(i, 'Factset')" class="hover" style="color:#0275d8">
          {{ valueNull(item.Factset.UniqueReaders) }}
        </td>
        <td *ngIf="channels.includes('Factset')">
          {{ valueNull(item.Factset.AnonymousReaders) }}
        </td>

        <!-- Capital IQ -->
        <td *ngIf="channels.includes('Capital IQ')">
          {{ valueNull(item.CIQ.TotalReaders) }}
        </td>
        <td *ngIf="channels.includes('Capital IQ')" data-toggle="modal" data-target=".bd-example-modal-lg"
          (click)="moreInfo(i, 'Capital IQ')" class="hover" style="color:#0275d8">
          {{ valueNull(item.CIQ.UniqueReaders) }}
        </td>
        <td *ngIf="channels.includes('Capital IQ')">
          {{ valueNull(item.CIQ.AnonymousReaders) }}
        </td>

        <!-- Markit -->
        <td *ngIf="channels.includes('Markit')">
          {{ valueNull(item.Markit.TotalReaders) }}
        </td>
        <td *ngIf="channels.includes('Markit')" data-toggle="modal" data-target=".bd-example-modal-lg"
          (click)="moreInfo(i, 'Markit')" class="hover" style="color:#0275d8">
          {{ valueNull(item.Markit.UniqueReaders) }}
        </td>
        <td *ngIf="channels.includes('Markit')">
          {{ valueNull(item.Markit.AnonymousReaders) }}
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<!--Pop up-->
<div class="modal fade bd-example-modal-lg" role="dialog" aria-hidden="true" *ngIf="currentMoreInfo != null">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">
            {{ currentMoreInfo.Channel }} - Detailed info about unique readers
          </h6>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body">
          <h5 style="margin-top: 0.5rem">{{ currentMoreInfo.Headline }}</h5>
          <br />
          <table class="table table-hover">
            <thead>
              <tr style="font-size:12px;">
                <th>Read Date</th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of currentMoreInfo.Readers">
                <ng-container *ngIf="item.Email.length > 4">
                  <td>{{ item.ReadDate | date: "yyyy-MM-dd HH:mm:ss" }}</td>
                  <td>{{ item.Email }}</td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!---->