import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { routing } from './SecServAdmin.routing';

import { DownloadComponent } from "./SecServAdmin.component";
import { SecServAdminApiService } from "./SecServAdmin-api.service";

import { environment } from 'environments/environment';


@NgModule({
  declarations: [
    DownloadComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    routing
  ],
  exports: [
    DownloadComponent,
  ]
})
export class SecServAdminModule {
  static forRoot(): ModuleWithProviders<SecServAdminModule> {
    return {
      ngModule: SecServAdminModule,
      providers: [SecServAdminApiService]
    }
  }
}

