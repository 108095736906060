import { Component, Input } from "@angular/core";
import {
  UserStatItem,
  UserStats,
  UserApiService,
  UserExModel
} from "../user-api.service";

@Component({
  selector: "user-statistics",
  templateUrl: "user-statistics.component.html"
})
export class UserStatisticsComponent {
  @Input() user: UserExModel | null;
  userStats: UserStatItem[] = null;
  allUserStats: UserStats = null;
  typeFilter: string = "";

  constructor(private userApi: UserApiService) {}

  getUserStats() {
    this.userStats = null;
    this.userApi.getUserStats(this.user.Id).subscribe(userStats => {
      this.allUserStats = userStats;
      this.filterStats();
    });
  }

  filterStats() {
    if (this.typeFilter == "") {
      this.userStats = this.allUserStats.Items;
    } else {
      this.userStats = this.allUserStats.Items.filter(
        x => x.Type == this.typeFilter
      );
    }
  }

  getShortUrl(url: string): string {
    return url.replace(/[^\/]*\/\/[^\/]+(\/research\/equity)*/gi, "");
  }
}
