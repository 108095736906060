import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NouisliderComponent } from 'ng2-nouislider';

@Component({
  selector: 'app-rule-slider',
  templateUrl: './rule-slider.component.html',
  styleUrls: ['./rule-slider.component.sass']
})
export class RuleSliderComponent implements OnInit {
  @Input() name: string
  @Input() max: number = 5
  @Input() min: number = 0
  @Input() step: number = 1
  @Input() minValue: number
  @Input() maxValue: number
  @Input() disabled: boolean

  @Output() selectedMinValue: EventEmitter<number> = new EventEmitter<number>()
  @Output() selectedMaxValue: EventEmitter<number> = new EventEmitter<number>()

  _values: Array<number> = [0, 0]
  _latestSlidedValues: Array<number> = [0, 0]

  range = {
    'min': [0, 1000],
    '40%': [10000, 10000],
    '85%': [100000, 100000],
    'max': [500000],
  }

  //@ViewChild('slider') public slider: NouisliderComponent

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //this.slider.slider.updateOptions({margin: this.step})
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("RuleSliderChanges", changes)
    let valueWasNotSlided = false
    if (changes?.minValue?.currentValue != changes?.minValue?.previousValue) {
      this._values[0] = changes.minValue.currentValue == 0 ? this.min : changes.minValue.currentValue
      if (this._values[0] != this._latestSlidedValues[0])
        valueWasNotSlided = true
    }
    if (changes?.maxValue?.currentValue != changes?.maxValue?.previousValue) {
      this._values[1] = changes.maxValue.currentValue == 0 ? this.min : changes.maxValue.currentValue
      if (this._values[1] != this._latestSlidedValues[1])
        valueWasNotSlided = true
    }
    if (valueWasNotSlided) {
      this._latestSlidedValues = [...this._values]
      this._values = [...this._values]
    }
  }

  updateValues(event: Array<number>) {
    if (event[0] != this._values[0]) {
      this._latestSlidedValues[0] = event[0]
      this.selectedMinValue.emit(event[0])
    }
    if (event[1] != this._values[1]) {
      this._latestSlidedValues[1] = event[1]
      this.selectedMaxValue.emit(event[1])
    }
  }

}
