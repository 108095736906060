<h2>Company alerts</h2>

<table class="table table-hover" style="margin-top:2rem">
  <thead>
    <tr>
      <th></th>
      <th>Company</th>
      <th>Area</th>
      <th>Disabled recommendation</th>
      <th>Code</th>
      <th>Message</th>
    </tr>
    <tr>
      <td>
        <button class="btn btn-sm {{
            isInEditMode() ? 'btn-primary' : 'btn-success'
          }}" (click)="saveAlert(companyAlert)" [disabled]="!canSave()">
          {{ isInEditMode() ? "Update" : "Add" }}
        </button>
      </td>
      <td>

        <div class="dropdown">
          <input type="text" class="form-control form-control-sm" value="{{ companyAlert.CompanyName }}"
            (keyup)="companyNameInput($event)" />

          <ng-container *ngIf="companyNames.length > 0 ">
            <div class="dropdown-menu show" aria-labelledby="companyDropDown">
              <ng-container *ngFor="let cn of companyNames">
                <a class="dropdown-item" href="#" (click)="selectCompany(cn)">
                  {{ cn.Name }}
                </a>
              </ng-container>
            </div>
          </ng-container>

        </div>

      </td>
      <td>
        <select class="form-control form-control-sm" [(ngModel)]="companyAlert.Area">
          <option *ngFor="let a of AreaList">{{ a }}</option>
        </select>
      </td>
      <td style="text-align: center;">
        <input class="checkbox" type="checkbox" [(ngModel)]="companyAlert.DisableRecommendation" />
      </td>
      <td>
        <select class="form-control form-control-sm" [(ngModel)]="companyAlert.Code">
          <option *ngFor="let c of CodeList">{{ c }}</option>
        </select>
      </td>
      <td>
        <textarea type="text" class="form-control form-control-sm" [(ngModel)]="companyAlert.Message"
          cols="3"></textarea>
      </td>
    </tr>
  </thead>
  <tbody style="font-size:13px;">
    <tr class="hover" *ngFor="let ca of companyAlertList" (click)="selectAlert(ca)">
      <td>
        <button class="btn btn-sm btn-danger" (click)="deleteAlert(ca)">
          Delete
        </button>
      </td>
      <td>{{ ca.CompanyName }}</td>
      <td>{{ ca.Area }}</td>
      <td style="text-align: center;">
        <input class="checkbox" type="checkbox" [checked]="ca.DisableRecommendation" disabled />
      </td>
      <td>{{ ca.Code }}</td>
      <td>{{ ca.Message }}</td>
    </tr>
  </tbody>
</table>