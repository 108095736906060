<div class="mm-manager">
  <div class="row">
    <div class="col-12">
      <div class="header">
        <span class="subtitle">
          <p>Accepting contributions until:
            <a *ngIf="!editTime" (click)="editTime = true" style="cursor: grab;">
              {{ deadline | date: "HH:mm" }} <mat-icon [inline]=true>edit</mat-icon></a>
            <a *ngIf="editTime">
              <input autofocus type="text" class="input" placeholder="hh:mm" [(ngModel)]="deadlineTime"
                (focusout)="noFocus($event)" />
              <button mat-flat-button (click)="saveTime()">Set</button></a>
          </p>
        </span>
        <span class="title">Review morning meeting
          <button mat-button (click)="LoadMorningWrap()">
            <mat-icon style="color:white;" *ngIf="!isLoading">refresh</mat-icon>
          </button>
          <mat-spinner style="color:white;" *ngIf="isLoading" [diameter]="20" mode="indeterminate">
          </mat-spinner>
        </span>
      </div>
      <mat-card>
        <span>
          <b>All ({{ morningmeetingList.data.length }})</b>
          <b>Selected ({{ getChosenLength() }})</b>
        </span>
        <table mat-table [dataSource]="morningmeetingList.data" style="width: 100%;" cdkDropList
          (cdkDropListDropped)="drop($event)">>

          <ng-container matColumnDef="file">
            <th mat-header-cell *matHeaderCellDef> PowerPoint file </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <button [disabled]="!element.attachmentId" mat-flat-button (click)="getAttachmet(element.attachmentId)">
                <mat-icon>save_alt</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="Company">
            <th mat-header-cell *matHeaderCellDef> Company</th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.companyName}}</td>
          </ng-container>
          <ng-container matColumnDef="Headline">
            <th mat-header-cell *matHeaderCellDef> Headline </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.headline}}</td>
          </ng-container>
          <ng-container matColumnDef="RecChange">
            <th mat-header-cell *matHeaderCellDef> Recommendation change </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <mat-checkbox class="example-margin" disabled="true" [checked]="element.recChange"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="Selected">
            <th mat-header-cell *matHeaderCellDef> Selected </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <mat-checkbox class="example-margin" [(ngModel)]="element.salesSelected"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="Order">
            <th mat-header-cell *matHeaderCellDef> Order </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <div class="example-handle">
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                  </path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-row" cdkDragLockAxis="y" cdkDrag>
          </tr>
        </table>
        <div *ngIf="morningmeetingList.data.length === 0">
          <p>The agenda is empty. </p><br />
        </div>

        <br />
        <mat-divider></mat-divider>
        <br />
        <mat-card-actions>
          <mat-hint>Only <strong>selected</strong> contributions will be included in the powerpoint generation.
          </mat-hint>
          <br>
          <br />
          <button mat-flat-button color="primary" [disabled]="!canSave()" (click)="save()">
            Save
          </button>&nbsp;
          <button mat-flat-button color="accent" [disabled]="!canSave()" (click)="generate()">
            Generate ppt
          </button>
          <mat-spinner style="color:white;" *ngIf="uploading" [diameter]="20" mode="indeterminate">
          </mat-spinner>
          <mat-hint *ngIf="!uploading && success" style="background-color: white;">
            <mat-icon style="color:green;">done</mat-icon>
          </mat-hint>
          <mat-error>
            <ng-container *ngIf="error">
              <br />
              <br />
              <pre style="color:red">{{error}}</pre>
            </ng-container>
          </mat-error>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>