<div class="box-container">
    <h3>Team management</h3>
    <mat-divider></mat-divider>
    <div class="row">
        <div class="col-md-12 col-lg-3 card-deck-container">
            <mat-form-field floatLabel=never class="field-full-width">
                <input matInput type="text" placeholder="Search titles"
                    [ngModel]="searchModel" (ngModelChange)="updateSearchModel($event)">
                <button mat-button matPrefix mat-icon-button type="button">
                    <mat-icon>search</mat-icon>
                </button>
            </mat-form-field>
            <br>
            <mat-card-subtitle> Select a team below for details or add a new team 

                <button  mat-icon-button color="primary" (click)="addNewTeam()" type="button">
                    <mat-icon>post_add</mat-icon>
                </button>
            </mat-card-subtitle>

            <mat-spinner *ngIf="isLoading" [diameter]="20" mode="indeterminate"></mat-spinner>
            <mat-accordion>
                <mat-expansion-panel hideToggle *ngFor="let team of teams | listFilter: searchModel">
                  <mat-expansion-panel-header (click)="selectTeam(team)">
                    <mat-panel-title class="mat-expansion-panel-header-title">
                        {{team.TeamType}}
                    </mat-panel-title>
                    <mat-panel-description class="mat-expansion-panel-header-description">
                        <mat-icon>people</mat-icon>({{team.Members.length}}) {{team.Description}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                </mat-expansion-panel>
              </mat-accordion>
        </div>
        <div class="col-md-12 col-lg-9">
            <form [formGroup]="teamForm" (ngSubmit)="saveTeam()">
                <mat-card class="" *ngIf="selectedTeam && teamForm">
                    <mat-card-header>
                        <mat-card-title>{{ selectedTeam.Id ? "Edit team " : "Add team" }}</mat-card-title>
                        <mat-card-subtitle>Id: {{selectedTeam.Id}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="form-group row">
                            <div class="col-sm-10">
                                <mat-form-field class="field-full-width" appearance="outline">
                                    <mat-label>Team type</mat-label>
                                    <input type="text" matInput placeholder="" formControlName="TeamType">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="teamForm.get('TeamType').hasError('required')">
                                        Team type is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div class="col-sm-10">
                                <mat-form-field class="field-full-width" appearance="outline">
                                    <mat-label>Team name</mat-label>
                                    <input type="text" matInput placeholder="" formControlName="TeamName">
                                    <mat-hint>Unique</mat-hint>
                                    <mat-error *ngIf="teamForm.get('TeamName').hasError('required')">
                                        Team name is <strong>required</strong>
                                    </mat-error>
                                    <mat-error *ngIf="teamForm.get('TeamName').hasError('unique')">
                                        Team name must be <strong>unique</strong>
                                    </mat-error>
                                </mat-form-field>

                            </div>
                            <div class="col-sm-10">
                                <mat-form-field class="field-full-width" appearance="outline">
                                    <mat-label>Description</mat-label>
                                    <input type="text" matInput placeholder="" formControlName="Description">
                                    <mat-hint></mat-hint>
                                    <mat-error *ngIf="teamForm.get('Description').hasError('required')">
                                        Description is <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-accordion [multi]="true" formArrayName="Members" 
                            cdkDropList
                            [cdkDropListData] = "Members"
                            (cdkDropListDropped)="drop($event)">
                            <br>
                            <mat-card-subtitle>Members: {{teamForm.get('Members').value.length}}</mat-card-subtitle>
                            <mat-expansion-panel cdkDrag *ngFor="let member of teamForm.get('Members').controls; let i = index">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <mat-card-header>
                                            <div mat-card-avatar
                                                [ngStyle]="{ 'background-image': 'url(' + getPictureUrl(member.value.UserId, member.value.HasPicture) + ')'}"
                                                style="background-size: cover; "></div>
                                            <mat-card-title>{{member.value.FirstName}} {{member.value.LastName}}
                                            </mat-card-title>
                                        </mat-card-header>
                                    </mat-panel-title>
                                    <mat-panel-description>
                                        Edit position and title...
                                    </mat-panel-description>
                                </mat-expansion-panel-header>
                                <mat-card-subtitle>{{member.value.UserId}}</mat-card-subtitle>
                                <mat-hint><a [routerLink]="['/Employee/Edit']" [queryParams]="{userId: member.value.UserId}">Edit employee details</a></mat-hint>
                                <div class="form-group row" [formGroupName]="i">
                                    <div class="col-sm-10">
                                        <mat-form-field class="field-full-width" appearance="outline">
                                            <mat-label>Title</mat-label>
                                            <input type="text" matInput placeholder="" formControlName="Title">
                                            <mat-hint></mat-hint>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <button mat-stroked-button color="accent" (click)="removeMember(i, $event)">
                                    <mat-icon>remove</mat-icon> Remove from team
                                </button>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <button mat-stroked-button color="accent" (click)="toggleSearchInput()" type="button">
                            <mat-icon>person_add</mat-icon> Add team member
                        </button>
                        <mat-form-field class="field-full-width" appearance="outline" *ngIf="showSearchInput">
                            <mat-label>User id</mat-label>
                            <input type="text" matInput [formControl]="customSearchControl"
                                (input)="customSearchInput($event)" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTeamMember($event)">
                                <mat-option *ngFor="let option of filteredSearch" [value]="option">
                                    {{option.UserId}} - {{option.FirstName}} {{option.LastName}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button type="submit" color="primary">
                            <mat-icon>add</mat-icon> Save changes
                        </button>
                        <button mat-icon-button class="delete" *ngIf="teamForm.get('Id').value != null" 
                            type="button" (click)="delete()">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-spinner *ngIf="teamSaving" [diameter]="20" mode="indeterminate"></mat-spinner>
                        <pre *ngIf="lastSave"> Last save: {{lastSave}}</pre>
                    </mat-card-actions>
                </mat-card>
            </form>
            <!-- 
            <div class="error" *ngIf="teamForm.invalid">
                <pre>Form errors: <code>{{ getAllErrors(teamForm) | json }}</code></pre>
            </div>

            <p>
                Form Status: {{ teamForm.status }}
            </p>

            <pre>Form values: <code>{{ teamForm.value |json }}</code></pre>
 -->
        </div>
    </div>
</div>