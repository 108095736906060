import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './bond-admin.routing';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { BondAdminComponent } from './bond-admin.component';
import { BondApiService } from './bond-admin-api.service';
import { MaterialModule } from 'app/shared/material/material.module';

@NgModule({
    declarations: [
        BondAdminComponent],
  imports: [
      CommonModule,
      FormsModule, ReactiveFormsModule,
      routing,
      HttpClientModule,
      MaterialModule,
      DragDropModule
  ],
  exports: [
  ]
})
export class BondAdminModule {
    static forRoot(): ModuleWithProviders<BondAdminModule> {
        return {
            ngModule: BondAdminModule,
            providers: [BondApiService]
        }
    }
}
