import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContentApiService } from './content-api.service'
import { InFocusPublicationMod, DocumentsModel } from "./model";
import { MatTableDataSource } from '@angular/material/table';
import { Logger } from 'app/_helpers';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';


@Component({
  selector: 'content-focusPublication',
  templateUrl: 'inFocusPublication.component.html',
  styleUrls: ['inFocusPublication.component.css']
})

export class FocusPublicationComponent {
  displayedColumns: string[] = ['Headline', 'Ingress', 'InFocusId'];
  dataSource: MatTableDataSource<InFocusPublicationMod>;

  inFocusPublication: InFocusPublicationMod[] = [];
  inFocusPublicationSearch: DocumentsModel;
  currentClickedNmr: number;
  currentIngress: string;
  currentHeadline: string;
  title: string;
  type: string;

  constructor(private contentApi: ContentApiService, private route: ActivatedRoute, private logger: Logger) {
    route.data.subscribe(data => {
      this.type = data.type;
      switch (data.type) {
        case "General":
          this.title = "Equity";
          break;
        case "MarketingMaterial":
          this.title = "Marketing material";
          break;
        case "Macro":
          this.title = "Macro research";
          break;
        default:
          this.title = data.type;
          break;
      }
    });
  }

  ngOnInit() {
    this.getInFocusPublication();
    this.inFocusPublicationSearch = {};
  }

  getInFocusPublication() {
    this.contentApi.getInFocusPublications(this.type).subscribe(
      userContent => {
        this.inFocusPublication = userContent as InFocusPublicationMod[];
        this.updateDatasource();
      }
    );
  }

  updateDatasource() {
    this.dataSource = new MatTableDataSource(this.inFocusPublication);
  }

  onListDrop(event: CdkDragDrop<any[]>) {
    // Swap the elements around
    this.logger.debug("onListDrop", event);

    const previousIndex = this.inFocusPublication.findIndex(row => row === event.item.data)
    moveItemInArray(this.inFocusPublication, previousIndex, event.currentIndex);
    this.updateDatasource();
  }
  
  //CHANGE - Should really not make an API call for every new character user writes...
  getInFocusPublicationSearch(term: string) {
    this.contentApi.getInFocusPublicationsSearch(term).subscribe(
      userContent => {
        this.inFocusPublicationSearch = userContent;
      }
    );
  }

  currentClicked(index: number) {
    this.currentClickedNmr = index;
    this.currentHeadline = this.inFocusPublication[this.currentClickedNmr].Headline;
    var withBr = this.inFocusPublication[this.currentClickedNmr].Ingress;
    this.currentIngress = withBr.replace(/<br>/g, "\n")

    this.logger.debug("currentClickedNmr", this.currentClickedNmr);
    this.logger.debug("currentHeadline", this.currentHeadline);
    this.logger.debug("currentIngress", this.currentIngress);
  }

  getHead() {
    if (this.currentClickedNmr != null) {
      return this.inFocusPublication[this.currentClickedNmr].Headline;
    }
  }

  deleteElement(index: number) {

    let pub = this.inFocusPublication[index];
    if (pub.InFocusId) {
      pub.Removed = !pub.Removed;
    }
    else {
      this.inFocusPublication.splice(index, 1);
    }
    this.updateDatasource();

  }

  // moveUp(index: number) {
  //   if (index > 0) {
  //     var toMove = this.inFocusPublication[index];
  //     this.inFocusPublication[index] = this.inFocusPublication[index - 1];
  //     this.inFocusPublication[index - 1] = toMove;
  //     this.updateDatasource();
  //   }
  // }
  // moveDown(index: number) {
  //   if (index < this.inFocusPublication.length - 1) {
  //     var toMove = this.inFocusPublication[index];
  //     this.inFocusPublication[index] = this.inFocusPublication[index + 1];
  //     this.inFocusPublication[index + 1] = toMove;
  //     this.updateDatasource();
  //   }
  // }

  updateRow() {
    this.inFocusPublication[this.currentClickedNmr].Headline = this.currentHeadline;
    this.inFocusPublication[this.currentClickedNmr].Ingress = this.currentIngress;
    this.updateDatasource();
  }

  save() {
    for (var i = 0; i < this.inFocusPublication.length; i++) {
      if (this.type)
        this.inFocusPublication[i].DataIdType = this.type;
      this.inFocusPublication[i].SortOrder = i;
    }
    this.logger.debug("save", this.inFocusPublication);
    
    this.contentApi.updateAllInFocusPublication(this.inFocusPublication).subscribe(
      updatedList => {
        this.inFocusPublication = updatedList as InFocusPublicationMod[];
        this.updateDatasource();
      });

  }

  addPublication(index: number) {
    this.inFocusPublication.push({
      Guid: this.inFocusPublicationSearch.Docs[index].Guid,
      Headline: this.inFocusPublicationSearch.Docs[index].Headline + " - " + this.inFocusPublicationSearch.Docs[index].SubHeadline,
      Ingress: ""
    });
    this.updateDatasource();
  }

  fixBr(txt: string): string {
    return txt.replace(/(\r)*\n/g, "<br>");
  }
}
