<h2>Upload Statistics</h2>

<p>Upload statistics from third-party channels to the database and see the result in a table below. 
  <br/>Files supported are .csv (convert .xlsx and .xls files to a .csv comma separated file, by using for example Excel)</p>
<br/>
<form>
  <div class="form-check">
    <i>Choose desired channel and file *:</i><br/>
    <label *ngFor="let ch of fileDistrPos; let i = index" class="form-check-label" style="margin-right:2rem">
      <input type="radio" class="form-check-input" name="fileDistr" value="{{i}}" (click)="fileDistrCurr=ch">
      {{ch}}
    </label>
  </div>
  <input type="file" style="width:550px;" (change)="fileToParse($event)" placeholder="Upload file" accept=".csv" required><br/><br/>
  <button type="button" class="btn btn-outline-warning" (click)="submit();" [disabled]="!isValid || fileDistrCurr == null || showLoading()">Submit</button>
  <img *ngIf="showLoading()" style="margin-left:10px; height:25px; width:auto;" src="./../assets/ajax-loader.gif" alt="Loading..." />
</form>

<br/>

<p *ngIf="stats?.length == 0" class="colorWarning">The file could not be read. Did you choose the right Channel? Is the .csv file comma separated (aka , or ;)?</p>
<div *ngIf="stats?.length > 0">
  <p style="font-style:italic;" >Legend:</p>
  <span style="background-color: #ffe6cc; padding:10px; font-size:13px;">{{errorValidate}}</span>
  <br/>
  <br/>
  <span style="background-color: #ffb3b3; padding:10px; font-size:13px;">{{errorReportID}}</span>
  <br/>
  <br/>
  <span style="background-color: #b3b3ff; padding:10px; font-size:13px;">{{errorPublication}}</span>

  <br/>
  <br/>
  <br/>
  <style>
    .table-border-top{
      border-top:none;
    }
  </style>
  <table class="table table-hover">
    <thead>
      <tr>
        <th colspan="1" style="border-bottom:none;"></th>
        <th colspan="4" style="border-bottom:none;">Reader</th>
        <th colspan="7" style="border-bottom:none;">Publication</th>
      </tr>
      <tr style="font-size:11px;">
        <th class="table-border-top">RF</th>

        <th class="table-border-top">DocID</th>
        <th class="table-border-top">Reader Email</th>
        <th class="table-border-top">Reader ID</th>
        <th class="table-border-top">Read Date</th>

        <th class="table-border-top">Headline</th>
        <th class="table-border-top">Subheadline</th>
        <th class="table-border-top">Create Date</th>
        <th class="table-border-top">Analyst ID</th>
        <th class="table-border-top">Doc. Type</th>
        <th class="table-border-top">Doc. Id</th>
        <th class="table-border-top">Sub. Comp. Id</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of stats" [ngStyle]="{'background-color': checkError(item.StatisticInfo.Error)}" style="font-size:12px;">
        <td>{{item.StatisticInfo.FileRow}}</td>

        <td>{{item.StatisticInfo.DocID}}</td>
        <td [ngStyle]="{'background-color': checkNull(item.StatisticInfo.Email)}">{{item.StatisticInfo.Email}}</td>
        <td>{{item.StatisticInfo.ReaderID}}</td>
        <td [ngStyle]="{'background-color': checkNull(item.StatisticInfo.ReadDate)}">{{item.StatisticInfo.ReadDate | date: 'yyyy-MM-dd HH:mm:ss'}}</td>

        <td *ngIf="item.PublicationInfo != null">{{item.PublicationInfo.Headline}}</td>
        <td *ngIf="item.PublicationInfo != null">{{item.PublicationInfo.Subheadline}}</td>
        <td *ngIf="item.PublicationInfo != null">{{item.PublicationInfo.PublishDateTime}}</td>
        <td *ngIf="item.PublicationInfo != null">{{item.PublicationInfo.AnalystPrimaryId}}</td>
        <td *ngIf="item.PublicationInfo != null">{{item.PublicationInfo.DocumentType}}</td>
        <td *ngIf="item.PublicationInfo != null">{{item.PublicationInfo.DocId}}</td>
        <td *ngIf="item.PublicationInfo != null">{{item.PublicationInfo.SubjectCompanyId}}</td>
      </tr>
    </tbody>
  </table>
</div>