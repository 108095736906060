<div class="box-container">
  <div class="row">
    <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{ isExistingUser ? "Edit User " : "Create User" }}
          </mat-card-title>
          <mat-card-subtitle>{{ isExistingUser ? user.EMail : "" }}</mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>

        <mat-card-content>
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <div formGroupName="userModelForm">
              <div class="form-group row">
                <label class="col-sm-2 col-form-label userform_label">Email</label>
                <div class="col-sm-10">
                  <mat-form-field class="field-full-width" appearance="outline">
                    <input type="email" matInput placeholder="Email" formControlName="EMail"
                      [errorStateMatcher]="matcher">
                    <mat-hint></mat-hint>
                    <mat-error
                      *ngIf="userForm.get('userModelForm').get('EMail').getError('emailstatus') === 'pending'  && !userForm.get('userModelForm').get('EMail').hasError('email') && !userForm.get('userModelForm').get('EMail').hasError('required')">
                      Pending...
                    </mat-error>
                    <mat-error
                      *ngIf="userForm.get('userModelForm').get('EMail').getError('emailstatus') === 'error'  && !userForm.get('userModelForm').get('EMail').hasError('email') && !userForm.get('userModelForm').get('EMail').hasError('required')">
                      Network error.
                    </mat-error>
                    <mat-error
                      *ngIf="userForm.get('userModelForm').get('EMail').getError('emailstatus') === 'duplicate' && !userForm.get('userModelForm').get('EMail').hasError('email') && !userForm.get('userModelForm').get('EMail').hasError('required')">
                      Email already exists. <strong>Unique</strong> email is required
                    </mat-error>
                    <mat-error
                      *ngIf="userForm.get('userModelForm').get('EMail').hasError('email') && !userForm.get('userModelForm').get('EMail').hasError('required')">
                      Not a <strong>valid</strong> email address
                    </mat-error>
                    <mat-error *ngIf="userForm.get('userModelForm').get('EMail').hasError('required')">
                      Email is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>

                </div>
              </div>

              <div class="form-group row" ><!--*ngIf="isExistingUser"-->
                <label class="col-sm-2 col-form-label userform_label">Id</label>
                <div class="col-sm-10">
                  <mat-form-field class="field-full-width" appearance="outline">
                    <input matInput formControlName="Id">
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label userform_label">Client category</label>
                <div class="col-sm-10">
                  <mat-form-field class="field-full-width" appearance="fill">
                    <!-- <mat-label>Client category</mat-label> -->
                    <mat-select name="client_category" formControlName="ClientCategory" (ngModelChange)="clientCategoryChange($event)">
                      <mat-option *ngFor="let cats of clientCategories" [value]="cats">
                        {{cats.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="userForm.get('userModelForm').get('ClientCategory').getError('clientcat') === 'lazy'">
                      Type in a <strong>new</strong> category.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field *ngIf=" userForm.get('userModelForm').get('ClientCategory').value.type === 'custom'"
                    appearance="outline">
                    <input matInput placeholder="New client category"
                      (input)="setCustomClientCategory($event.target.value)">
                  </mat-form-field>
                  <div class="alert alert-warning" role="alert" *ngIf="isEmailUser">An email user does not have access to REON, it can only subscribe to email send outs</div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label userform_label">Max screenings</label>
                <div class="col-sm-10">
                  <mat-form-field class="field-full-width" appearance="outline">
                    <input matInput type="number" placeholder="Max screenings" formControlName="MaxScreenings">
                    <mat-error *ngIf="userForm.get('userModelForm').get('MaxScreenings').hasError('min') ">
                      Must be a <strong>positive</strong> number.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-2 col-form-label userform_label">Expire date</label>
                <div class="col-sm-8">
                  <mat-form-field class="field-full-width" appearance="outline">
                    <input matInput [matDatepicker]="picker" placeholder="Expire date" formControlName="ExpireDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="month"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-sm-2">
                  <label>Infinite</label>
                  <br>
                  <mat-checkbox [disabled]="isEmailUser" formControlName="NeverExpire" (input)="expireDateChange($event.target)">
                  </mat-checkbox>
                </div>
              </div>
              <div class="form-group row" *ngIf="lastUserStat">
                <mat-accordion>
                  <mat-expansion-panel class="mat-elevation-z0">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Last activity
                      </mat-panel-title>
                      <mat-panel-description>
                        Date: {{ lastUserStat.Timestamp | date : 'short' }}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="col-12" *ngIf="lastUserStat">
                      <p>Date: {{ lastUserStat.Timestamp | date : 'long' }}</p>
                      <p>Type: {{ lastUserStat.Type }}</p>
                      <p>url: {{ lastUserStat.Url }}</p>
                    </div>

                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <p *ngIf="!lastUserStat">No activity data found</p>

              <ng-container *ngIf="isExistingUser">
                <div  class="row">
                  <div class="col">
                    <mat-checkbox formControlName="Enabled">Enabled</mat-checkbox>
                  </div>
                </div>
                <div *ngIf="!isEmailUser && !userForm.get('userModelForm').get('Enabled')?.value" class="alert alert-danger" role="alert">Note that email sendouts will also be disabled. Change <i>Client Category</i> to <b>Email subscriber</b> to let the user receive emails.</div>
              </ng-container>
              <br>
              <mat-checkbox [formControl]="sendEmailInvite" color="primary">
                Send email invite
              </mat-checkbox>
              <div class="alert alert-warning" role="alert" *ngIf="isEmailUser">Welcome email is not applicable for an email user</div>
              <br>
              <button type="submit" mat-raised-button color="primary" *ngIf="isExistingUser">
                <mat-icon>upgrade</mat-icon> Update
              </button>
              <button type="submit" mat-raised-button color="primary" *ngIf="!isExistingUser">
                <mat-icon>add</mat-icon> Add
              </button>
              <div class="col-12" *ngIf="progress_value > 0">
                <mat-card-subtitle>
                  <mat-icon>{{syncUser ? 'done' : 'clear'}}</mat-icon> Syncing user details
                </mat-card-subtitle>
                <mat-card-subtitle>
                  <mat-icon>{{syncRoles ? 'done' : 'clear'}}</mat-icon> Saving user roles
                </mat-card-subtitle>
                <mat-card-subtitle>
                  <mat-icon>{{syncSSO ? 'done' : 'clear'}}</mat-icon> Setting user login
                </mat-card-subtitle>
                <mat-card-subtitle *ngIf="sendEmailInvite.value">
                  <mat-icon>{{sendEmail ? 'done' : 'clear'}}</mat-icon> Sending email invite
                </mat-card-subtitle>
              </div>

              <!-- <div class="error" *ngIf="userForm.invalid">
              <pre>Form errors:<code>{{ getAllErrors(userForm) | json }}</code></pre>
             </div> -->
            </div>
          </form>
        </mat-card-content>
        <mat-progress-bar *ngIf="progress_value" mode="determinate" [value]="progress_value" color="accent">
        </mat-progress-bar>
      </mat-card>
      <mat-error *ngIf="error">
        {{error | json}}
      </mat-error>

    </div>

    <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            {{ isExistingUser ? "Edit roles " : "Assign roles" }}
          </mat-card-title>
          <mat-card-subtitle>
            <div class="alert alert-warning" role="alert" *ngIf="isEmailUser">An email user can not be assigned roles</div>
            <br *ngIf="isExistingUser && !isEmailUser"/>
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <mat-tab-group dynamicHeight>
            <mat-tab label="Smart view">
              <br>
              <role-tree [disabled]="isEmailUser"></role-tree>
            </mat-tab>
            <mat-tab label="List view">
              <div>
                <form [formGroup]="userForm">
                  <div formGroupName="checkboxControl">
                    <table matSort (matSortChange)="sortData($event)" class="table">
                      <tr>
                        <th mat-sort-header="role">Role</th>
                        <th mat-sort-header="system">System</th>
                        <th mat-sort-header="access">Access</th>
                      </tr>
                      <tr *ngFor="let item of userForm.controls.checkboxControl.value | keyvalue">
                        <td style="word-break:break-all;">
                          <div *ngIf="getCheckboxRequired(item.key).length <= 0">{{ getCheckboxName(item.key) }}</div>
                          <mat-expansion-panel *ngIf="getCheckboxRequired(item.key).length > 0" class="mat-elevation-z0"
                            [@.disabled]="true">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                {{ getCheckboxName(item.key) }}
                              </mat-panel-title>
                              <mat-panel-description>
                                <!--(at least one:) -->
                              </mat-panel-description>
                            </mat-expansion-panel-header>
                            <p *ngFor="let dep of getCheckboxRequired(item.key)">- {{dep}}</p>
                          </mat-expansion-panel>
                        </td>
                        <td [style.background-color]="getRowStyle(item.key)">{{ getCheckboxType(item.key) }}</td>
                        <td>
                          <mat-checkbox [formControlName]="item.key"></mat-checkbox>
                        </td>
                      </tr>
                    </table>
                  </div>
                </form>
              </div>
            </mat-tab>
          </mat-tab-group>
          <mat-error *ngIf="userForm.get('userModelForm').get('Id').hasError('roles')">
            Assign at least <strong>1</strong> role.
          </mat-error>
          <mat-divider></mat-divider>
          <br>
          <mat-card-subtitle>
            <mat-checkbox [disabled]="isEmailUser" [(ngModel)]="setDefaultAdminAccessRules">Set default reon-admin access</mat-checkbox>
          </mat-card-subtitle>
        </mat-card-content>

      </mat-card>
    </div>
  </div>
  <div class="row" *ngIf="isExistingUser">
    <div class="col-xl-5 col-lg-6 col-md-12 col-sm-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Activity</mat-card-title>
          <mat-card-subtitle>
            Check if the user has finished the setup of their account.
          </mat-card-subtitle>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content>
          <button mat-button matPrefix mat-icon-button (click)="checkConfirmedEmailstatus()" type="button">
            <mat-icon>find_replace</mat-icon> Load status
            <mat-spinner *ngIf="emailconfirmedLoading" [diameter]="20" mode="indeterminate"></mat-spinner>
          </button>
          <div *ngIf="emailconfirmedLoaded">
            <mat-icon *ngIf="emailconfirmed == null">warning</mat-icon>
            <mat-icon>{{emailconfirmed ? 'done' : 'clear'}}</mat-icon>
            <mat-label>{{emailconfirmedStatus}}</mat-label>
          </div>
          <mat-divider></mat-divider>
          <br>

        </mat-card-content>

      </mat-card>
    </div>
  </div>
  <br>
</div>

<!-- <p>
  Form Status: {{ userForm.status }}
</p>

<pre>Form values: <code>{{ userForm.value |json }}</code></pre> -->