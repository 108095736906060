import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";

import {
  PublicationDownloads,
  DocUploadStatistics,
  DocumentStatistics,
  StatsQuery,
  UserStats
} from "./model";
import { environment } from 'environments/environment';

@Injectable()
export class StatisticsApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  test: PublicationDownloads[];

  constructor(private http: HttpClient) { }

  getDocStatsList() {
    return this.test;
  }
  // Document Statistics
  getDocStats(startdate: Date, enddate: Date) {
    this.test = [];
    return (
      this.http
        //.get(this.baseUrlReonApi + "statistics/getstatisticspublicationdownloads/?activityFrom=2013-06-01&activityTo=2017-12-31&publFrom=2013-06-01&publTo=2017-12-31", { withCredentials: true })
        .get(
          this.baseUrlReonApi +
          "statistics/GetDocumentStats/?From=" +
          startdate +
          "&To=" +
          enddate +
          "&publishedFrom=" +
          startdate +
          "&publishedTo=" +
          enddate +
          "&clientcategory=&uniqueUsers=false",
          { withCredentials: true }
        )
        .pipe(
          map((res: any) => {
            var docstat = res.Items;
            for (var i = 0; i < docstat.length; i++) {
              this.test.push({
                noDocumentViewsTot: docstat[i].PageViewsTotal,
                noDocumentViewUnique: docstat[i].PageViewsUnique,
                noDocumentViewAnon: docstat[i].PageViewsAnon,
                noSiteAttachmentDownloadsTot: docstat[i].DownloadsTotal,
                noSiteAttachmentDownloadsUnique: docstat[i].DownloadsUnique,
                noSiteAttachmentDownloadsAnon: docstat[i].DownloadsAnon,
                Company: "",
                FullHeadline: docstat[i].Headline,
                DocTime: docstat[i].Date,
                DocType: docstat[i].PubType,
                DocId: docstat[i].DocId
              });
            }
          }))
        .subscribe(x => x)
    );
  }

  insertDocument(
    channel: string,
    file: File
  ): Observable<InsertDocumentResponse> {
    let formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.http
      .post<InsertDocumentResponse>(
        this.baseUrlReonApi +
        "statistics/insertstatisticsdata/?channel=" + channel,
        formData,
        { withCredentials: true }
      );
  }

  getUserStats(statsQuery: StatsQuery): Observable<UserStats> {
    return this.http
      .get<UserStats>(
        this.baseUrlReonApi +
        "userStats/GetStats?" +
        Object.keys(statsQuery)
          .map(
            key =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                statsQuery[key]
              )}`
          )
          .join("&"),
        { withCredentials: true }
      );
  }

  getDocumentStatistics(
    pubStartdate: string,
    pubEnddate: string,
    readStartdate: string,
    readEnddate: string,
    channels: string[],
    searchString: string
  ): Observable<DocumentStatistics[]> {
    var sb = "";
    for (let o of channels) {
      sb = sb + "&" + o.replace(/\s+/g, "") + "=true";
    }

    return this.http
      .get<DocumentStatistics[]>(
        this.baseUrlReonApi +
        "statistics/getdocumentstatistics/?fromPublishDate=" +
        pubStartdate +
        "&toPublishDate=" +
        pubEnddate +
        "&fromReadDate=" +
        readStartdate +
        "&toReadDate=" +
        readEnddate +
        "&searchString=" +
        searchString +
        sb,
        { withCredentials: true }
      );
  }

  downloadAggregateHref(
    pubStartdate: string,
    pubEnddate: string,
    readStartdate: string,
    readEnddate: string,
    channels: string[],
    searchString: string
  ): string {
    var sb = "";
    for (let o of channels) {
      sb = sb + "&" + o.replace(/\s+/g, "") + "=true";
    }

    return (
      this.baseUrlReonApi +
      "statistics/DownloadDocumentStatisticsAggregate?fromPublishDate=" +
      pubStartdate +
      "&toPublishDate=" +
      pubEnddate +
      "&fromReadDate=" +
      readStartdate +
      "&toReadDate=" +
      readEnddate +
      "&searchString=" +
      (searchString || "") +
      sb
    );
  }

  downloadReaderDataHref(
    pubStartdate: string,
    pubEnddate: string,
    readStartdate: string,
    readEnddate: string,
    channels: string[],
    searchString: string
  ): string {
    var sb = "";
    for (let o of channels) {
      sb = sb + "&" + o.replace(/\s+/g, "") + "=true";
    }

    return (
      this.baseUrlReonApi +
      "statistics/DownloadDocumentReaderDataFull?fromPublishDate=" +
      pubStartdate +
      "&toPublishDate=" +
      pubEnddate +
      "&fromReadDate=" +
      readStartdate +
      "&toReadDate=" +
      readEnddate +
      "&searchString=" +
      (searchString || "") +
      sb
    );
  }


}

export interface InsertDocumentResponse {
  List: DocUploadStatistics[];
  ErrorMessage: string
}

