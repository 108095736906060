import { RouterModule, Routes } from "@angular/router";

import {
  ImagesComponent,
  BannersComponent,
  PublicationTypeComponent
} from "./content.component";
import { CompanyAlertsComponent } from "./company-alerts/company-alerts.component";
import { InternalPublicationsComponent } from "./InternalPublications.component";
import {
  DisclaimerComponent,
  DisclaimerEditComponent
} from "./disclaimer.component";
import { FocusPublicationComponent } from "./inFocusPublication.component";
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: "Content",
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
      {
        path: "Disclaimers",
        component: DisclaimerComponent,
        children: [
          { path: "New", component: DisclaimerEditComponent },
          { path: "Edit", component: DisclaimerEditComponent }
        ]
      },
      {
        path: "In-Focus-Publications",
        component: FocusPublicationComponent,
        data: { type: "General" }
      },
      {
        path: "In-Focus-Marketing-material",
        component: FocusPublicationComponent,
        data: { type: "MarketingMaterial" }
      },
      {
        path: "In-Focus-Credit",
        component: FocusPublicationComponent,
        data: { type: "Credit" }
      },
      {
        path: "In-Focus-Macro",
        component: FocusPublicationComponent,
        data: { type: "Macro" }
      },
      {
        path: "In-Focus-Strategy",
        component: FocusPublicationComponent,
        data: { type: "Strategy" }
      },
      {
        path: "Internal-Publications",
        component: InternalPublicationsComponent
      },
      { path: "Company-alerts", component: CompanyAlertsComponent },
      //{ path: 'Images', component: ImagesComponent },
      //{ path: 'Banners', component: BannersComponent },
      { path: "Images-and-banners", component: ImagesComponent },
      { path: "Publication-Type", component: PublicationTypeComponent }
    ]
  }
];

export const routing = RouterModule.forChild(routes);
