import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { routing } from './app.routes';
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavComponent, NavbarExceptionDialog } from './nav.component';
import { UserModule } from './user/user.module';
import { ContentModule } from './content/content.module';
import { EmployeeModule } from './employee/employee.module';
import { BondAdminModule } from './bond-admin/bond-admin.module';
import { AdminModule } from './admin/admin.module';
import { StatisticsModule } from './statistics/statistics.module';
import { MiscModule } from './misc/misc.module';
import { AuthService } from './auth/auth.service';
import { MakroSvepetPlusAdminModule } from './makroSvepetPlusAdmin/makroSvepetPlusAdmin.module';
import { SecServAdminModule } from './SecServAdmin/SecServAdmin.module';
import { MacroAdminModule } from './macro-admin/MacroAdmin.module';
import { EventModule } from './event/event.module';
import { MorningWrapModule } from './nmw-contributions/nmw-contributions.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxLoggerLevel, LoggerModule } from 'ngx-logger';
import { Logger } from './_helpers';
import { environment } from 'environments/environment';
import { AuthorizeInterceptor } from 'api-authorization/authorize.interceptor';
import { UnauthorizedInterceptor } from 'api-authorization/unauthorized.interceptor';
import { ApiAuthorizationModule } from 'api-authorization/api-authorization.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { ErrorHandlerService } from './common/error-handler-service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IconPack } from '@fortawesome/fontawesome-svg-core';

@NgModule({
  declarations: [AppComponent, NavComponent, NavbarExceptionDialog, HomeComponent],
  entryComponents: [NavbarExceptionDialog],
  imports: [
    ApiAuthorizationModule,
    BrowserModule,
    DragDropModule,
    FontAwesomeModule,
    HttpClientModule,
    routing,
    MatExpansionModule, MatDatepickerModule, MatNativeDateModule, MatFormFieldModule, MatInputModule,
    MatDialogModule,
    ContentModule.forRoot(),
    UserModule.forRoot(),
    EmployeeModule.forRoot(),
    BondAdminModule.forRoot(),
    AdminModule.forRoot(),
    StatisticsModule.forRoot(),
    MiscModule.forRoot(),
    MakroSvepetPlusAdminModule.forRoot(),
    SecServAdminModule.forRoot(),
    MacroAdminModule.forRoot(),
    EventModule.forRoot(),
    MorningWrapModule.forRoot(),
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: environment.production,
    })
  ],
  exports: [DragDropModule, MatDialogModule],
  providers: [
    Logger,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas as IconPack, far as IconPack);
  }

}
