import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from './employee.routing';

import { EmployeeApiService } from './employee-api.service';
import { EmployeeEditComponent } from './employee-edit.component';
import { EmployeeSearchComponent } from './search/employee-search.component';
import { EmployeeViewComponent } from './employee-view/employee-view.component';
import { TeamsComponent, ListFilterPipe } from './teams/teams.component'
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MaterialModule } from 'app/shared/material/material.module';

@NgModule({
    declarations: [
        EmployeeEditComponent,
        EmployeeSearchComponent,
        EmployeeViewComponent,
        TeamsComponent,
        ListFilterPipe],
    imports: [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        routing,
        HttpClientModule,
        BrowserAnimationsModule,
        MaterialModule,
        DragDropModule
    ],
    exports: [
    ]
})
export class EmployeeModule {
    static forRoot(): ModuleWithProviders<EmployeeModule> {
        return {
            ngModule: EmployeeModule,
            providers: [EmployeeApiService]
        }
    }
}
