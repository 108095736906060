import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './MacroAdmin.routing';
import { MaterialModule } from '../shared/material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MacroAdminComponent } from './MacroAdmin.component';
import { MacroAdminForecastComponent } from './MacroAdminForecast.component';
import { MacroAdminApiService } from './MacroAdmin-api.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { IconPack } from '@fortawesome/fontawesome-svg-core';

@NgModule({
  declarations: [
    MacroAdminComponent,
    MacroAdminForecastComponent,
  ],
  imports: [
    MaterialModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule, ReactiveFormsModule,
    routing,
    BrowserAnimationsModule
  ],
  exports: [
    DragDropModule,
    MacroAdminComponent,
    MacroAdminForecastComponent,
  ]
})
export class MacroAdminModule {
  static forRoot(): ModuleWithProviders<MacroAdminModule> {
    return {
      ngModule: MacroAdminModule,
      providers: [MacroAdminApiService]
    }
  }
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas as IconPack, far as IconPack);
  }
}

