<h2>Manage images & banners</h2>

<button
  [disabled]="editing"
  type="button"
  class="btn btn-outline-warning"
  data-toggle="modal"
  data-target=".bd-upload-image"
  data-backdrop="static"
  data-keyboard="false"
  style="box-shadow: 0 10px 20px rgba(134, 134, 134, 0.644)"
>
  Upload new image
</button>
<br />
<br />

<p class="tab">
  <button
    class="btn btn-secondary"
    type="button"
    (click)="imageFocus = true"
    [disabled]="imageFocus"
  ><fa-icon [icon]="['fas', 'image']"></fa-icon> Image view</button>
  <button
    class="btn btn-secondary"
    type="button"
    (click)="imageFocus = false"
    [disabled]="!imageFocus"
  >
  <fa-icon [icon]="['fas', 'window-maximize']"></fa-icon> Banner view
  </button>
</p>

<div *ngIf="imageFocus">
  <div id="photos">
    <div
      class="card"
      *ngFor="let pic of images; let i = index"
      style="width: 18rem; display:inline-block; margin:0 5px 5px 0;"
    >
      <img
        class="card-img-top"
        src="data:image/jpeg;base64,{{ pic.File }}"
        alt="{{ pic.Filename }}"
        style="width:100%"
      />
      <input
        type="file"
        style="line-height:normal"
        (change)="fileToParse($event)"
        placeholder="Upload file"
        accept=".jpg, .png"
        *ngIf="editImage.Id == pic.Id"
      />
      <div
        class="card-body"
        style="margin:0 20px 20px 20px; border-top: 1px solid rgba(0, 0, 0, 0.125)"
      >
        <textarea
          type="text"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          rows="2"
          class="card-title form-control ng-pristine ng-valid ng-touched"
          [(ngModel)]="pic.Name"
          [disabled]="editImage.Id != pic.Id"
        ></textarea>
        <textarea
          type="text"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          class="card-text form-control ng-pristine ng-valid ng-touched"
          rows="1"
          style="font-size:11px; color:grey"
          [(ngModel)]="pic.Id"
          [disabled]="true"
        ></textarea>
        <textarea
          type="text"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          class="card-text form-control ng-pristine ng-valid ng-touched"
          rows="3"
          [(ngModel)]="pic.Description"
          [disabled]="editImage.Id != pic.Id"
        ></textarea>
        <button
          class="btn btn-secondary hover"
          (click)="edit(i)"
          *ngIf="editImage.Id != pic.Id"
          [disabled]="editing"
        ><fa-icon [icon]="['fas', 'pencil']"></fa-icon></button>
        <button
          class="btn btn-success hover"
          (click)="editSave(i, true)"
          *ngIf="editing && editImage.Id == pic.Id"
        ><fa-icon [icon]="['fas', 'check']"></fa-icon></button>
        <button
          class="btn btn-outline-secondary hover"
          (click)="editSave(i, false)"
          *ngIf="editing && editImage.Id == pic.Id"
        ><fa-icon [icon]="['fas', 'close']"></fa-icon></button>
        <button
          class="btn btn-secondary hover"
          *ngIf="editImage.Id != pic.Id"
          [disabled]="editing"
          data-toggle="modal"
          data-target=".bd-banner"
          data-backdrop="static"
          (click)="editBanner(i)"
        ><fa-icon [icon]="['fas', 'window-maximize']"></fa-icon></button>
        <img
          *ngIf="deleteLoading && deleteIndex == i"
          style="margin-left:10px; height:25px; width:auto;"
          src="./../assets/ajax-loader.gif"
          alt="Loading..."
        />
        <span *ngIf="deleteError && deleteIndex == i" style="Color:red">
          Could not delete the image</span
        >
      </div>
    </div>
  </div>
</div>
<div *ngIf="!imageFocus">
  <banner-view [images]="images"></banner-view>
</div>

<banner-add-dialog
  *ngIf="editBannerImage != null"
  [image]="editBannerImage"
></banner-add-dialog>

<!--Pop up, upload image-->
<div class="modal fade bd-upload-image" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">
            Upload new image
          </h6>
          <!--<button type="button" class="close" data-dismiss="modal">&times;</button>-->
        </div>
        <div class="modal-body">
          <div class="form-group row yCenter">
            <label for="email" class="col-2 col-form-label">Name*</label>
            <div class="col-8">
              <input type="text" class="form-control" #name />
            </div>
          </div>

          <div class="form-group row yCenter">
            <label for="email" class="col-2 col-form-label">Description*</label>
            <div class="col-8">
              <input type="text" class="form-control" #description />
            </div>
          </div>

          <div class="form-group row yCenter">
            <label for="email" class="col-2 col-form-label">File*</label>
            <div class="col-8">
              <img
                *ngIf="isString(editImage.File)"
                style="width:100%; height:300px; object-fit:contain;"
                src="{{ editImage.File }}"
              />
              <input
                type="file"
                style="line-height:normal"
                #file
                placeholder="Upload file"
                accept=".jpg, .png"
                (change)="fileSelected($event)"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span
            *ngIf="saveImageError != ''"
            style="color: red; padding-right: 10px"
            >{{ saveImageError }}</span
          >
          <span
            *ngIf="saveImageSuccess"
            style="color: green; padding-right: 10px"
            >Image saved to database</span
          >
          <img
            *ngIf="saveImageLoading"
            style="margin-left:10px; height:25px; width:auto;"
            src="./../assets/ajax-loader.gif"
            alt="Loading..."
          />
          <button
            [disabled]="saveImageLoading"
            class="btn btn-success hover"
            (click)="saveImage(name.value, description.value, file.files)"
          ><fa-icon [icon]="['fas', 'check']"></fa-icon></button>
          <button
            [disabled]="saveImageLoading"
            class="btn btn-outline-secondary hover"
            data-dismiss="modal"
          ><fa-icon [icon]="['fas', 'close']"></fa-icon></button>
        </div>
      </div>
    </div>
  </div>
</div>
<!---->

<style>
  #photos {
    /* Prevent vertical gaps */
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap: 5px;
    -moz-column-count: 3;
    -moz-column-gap: 5px;
    column-count: 3;
    column-gap: 5px;
    max-width: 1000px;
  }

  #photos .card {
    /* Just in case there are inline attributes */
    width: 100% !important;
    height: auto !important;
  }

  .card-text {
    margin-bottom: 1rem;
    width: 100%;
  }

  .card-title {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    margin-top: 2rem;
    font-size: 1.25rem;
    width: 100%;
  }

  textarea {
    line-height: normal;
    resize: none;
  }

  textarea[disabled] {
    background-color: white;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    cursor: text !important;
  }

  @media (max-width: 1000px) {
    #photos {
      -moz-column-count: 2;
      -webkit-column-count: 2;
      column-count: 2;
    }
  }

  @media (max-width: 750px) {
    #photos {
      -moz-column-count: 1;
      -webkit-column-count: 1;
      column-count: 1;
    }
  }

  @media (max-width: 550px) {
    #photos {
      width: 300px;
    }
  }

  .tab button {
    border-radius: 0;
    border: 3px solid #fff;
  }

  .tab button[disabled] {
    border-bottom: 3px solid #0275d8;
    opacity: 1;
  }
</style>
