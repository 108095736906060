<div class="box-container">
  <div class="row">
    <div class="col-xl-5 col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Create a new subscription rule
          </mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="mat-card-content" style="margin: 0 16px">
          <app-create-rule *ngIf="!isDistributionDisabled" [existingRule]="editRule" [userId]="user.EMail" (rule)="createdRule($event)">
          </app-create-rule>
          <div class="alert alert-danger" role="alert" *ngIf="isDistributionDisabled">Distribution disabled</div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-xl-7 col-lg-12">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            Rules for <i>{{user.EMail}}</i>
          </mat-card-title>
        </mat-card-header>
        <div style="margin: 0 0 12px 16px">
          <mat-checkbox color="primary" (change)="distributionDisabled($event.checked)" [checked]="isDistributionDisabled" [disabled]="!user.Enabled">
            Disable distribution
          </mat-checkbox>
        </div>
        <mat-divider></mat-divider>
        <mat-card-content class="mat-card-content" style="margin: 0 16px">

          <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
          <div class="alert alert-danger" role="alert" *ngIf="errorString">{{errorString}}</div>

          <ng-container *ngIf="rules.length < 1 && !isLoading && !errorString">
            No existing subscriptions
          </ng-container>
          <ul class="existing-rule">
            <li *ngFor="let rule of rules; let i = index"
              [class.inactive]="(editRule != null && rule.id != editRule?.id) || isDistributionDisabled">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu" [disabled]="isDistributionDisabled">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editRule = rule">
                  <mat-icon>edit</mat-icon>
                  <span>Edit rule</span>
                </button>
                <button mat-menu-item color="error" (click)="deleteRule(i, rule.id)">
                  <mat-icon>delete</mat-icon>
                  <span>Delete rule</span>
                </button>
              </mat-menu>
              <div>
                <b style="font-size: 12px;">{{rule.categoryCode}} subscription
                </b>
                <app-string-builder [publicationTypes]="rule.publicationTypes" [companies]="rule.companies"
                  [sectors]="rule.sectors" [countries]="rule.countries" [analysts]="rule.analysts"
                  [currencies]="rule.currencies" [marketCap]="rule.marketCap" [languages]="rule.languages"
                  [regions]="rule.regions"></app-string-builder>
              </div>
            </li>
          </ul>
          <!-- <div class="existing-rule" *ngFor="let rule of rules; let i = index"
            [class.inactive]="editRule != null && rule.id != editRule?.id">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editRule = rule">
                <mat-icon>edit</mat-icon>
                <span>Edit rule</span>
              </button>
              <button mat-menu-item color="error" (click)="deleteRule(i, rule.id)">
                <mat-icon>delete</mat-icon>
                <span>Delete rule</span>
              </button>
            </mat-menu>
            <div>
              <b style="font-size: 12px;">{{rule.categoryCode}} subscription
              </b>
              <app-string-builder [publicationTypes]="rule.publicationTypes" [companies]="rule.companies"
                [sectors]="rule.sectors" [countries]="rule.countries" [analysts]="rule.analysts"
                [currencies]="rule.currencies" [marketCap]="rule.marketCap"></app-string-builder>
            </div>
          </div> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>