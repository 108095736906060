import { RouterModule, Routes } from "@angular/router";
import { MacroAdminComponent } from "./MacroAdmin.component";
import { MacroAdminForecastComponent } from "./MacroAdminForecast.component";
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: "MacroAdmin",
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
      { path: "media", component: MacroAdminComponent },
      { path: "forecast", component: MacroAdminForecastComponent }
    ]
  }
];

export const routing = RouterModule.forChild(routes);
