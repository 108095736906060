import { RouterModule, Routes } from '@angular/router';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';
import { BondAdminComponent } from './bond-admin.component';

const routes: Routes = [ {
    path: 'Bond',
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
        { path: 'Files', component: BondAdminComponent },
    ]
},
];

export const routing = RouterModule.forChild(routes);
