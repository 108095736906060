import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { KeyValuePair } from '../../_models/keyValuePair.model';
import { CreateRuleService } from '../_service/create-rule.service';

@Component({
  selector: 'app-create-macro',
  templateUrl: './create-macro.component.html',
  styleUrls: ['./create-macro.component.sass'],
  host: { 'style': 'display: block; position: relative' },
})
export class CreateMacroComponent implements OnInit {
  _selectedPublicationTypes: Array<KeyValuePair> = []
  @Input() selectedPublicationTypes: Array<KeyValuePair> = []
  @Output() updatedPublicationTypes: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updatePublicationTypes(event: Array<KeyValuePair>) {
    this._selectedPublicationTypes = event
    this.updatedPublicationTypes.emit([...this._selectedPublicationTypes])
  }

  _selectedLanguages: Array<KeyValuePair> = []
  @Input() selectedLanguages: Array<KeyValuePair> = []
  @Output() updatedLanguages: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updateLanguages(event: Array<KeyValuePair>) {
    this._selectedLanguages = event
    this.updatedLanguages.emit([...this._selectedLanguages])
  }

  _selectedRegions: Array<KeyValuePair> = []
  @Input() selectedRegions: Array<KeyValuePair> = []
  @Output() updatedRegions: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>()
  updateRegions(event: Array<KeyValuePair>) {
    this._selectedRegions = event
    this.updatedRegions.emit([...this._selectedRegions])
  }

  isLoading: boolean = false
  errorString: string = null

  publicationTypeOptions: KeyValuePair[] = []
  languageOptions: KeyValuePair[] = []
  regionOptions: KeyValuePair[] = []

  constructor(private createRuleService: CreateRuleService) { }

  ngOnInit(): void {
    this.isLoading = true
    this.createRuleService.getMacroOptions()
    .pipe(
      catchError(x => {
        this.errorString = x
        this.isLoading = false
        return null
      })
    )
    .subscribe(x => {
      if(!x)
        return
      this.publicationTypeOptions = x.publicationTypes
      this.languageOptions = x.languages
      this.regionOptions = x.regions
      this.isLoading = false
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("macroChange", changes)
    if (changes.selectedPublicationTypes)
      this._selectedPublicationTypes = [...changes.selectedPublicationTypes.currentValue]
    if (changes.selectedLanguages)
      this._selectedLanguages = [...changes.selectedLanguages.currentValue]
    if (changes.selectedRegions)
      this._selectedRegions = [...changes.selectedRegions.currentValue]
  }

}
