<h2>{{title}}</h2>
<div style="width:auto">
  <div class="form-group row">
    <div class="col-10">
      <label> File: 
        <input (change)="inputfile($event)" type="file" >  
        <p>Files supported are .csv (convert .xlsx and .xls files to a .csv comma separated file, by using for example Excel)</p>
        <p style="color:green;">File status: {{file_status}}</p>
      </label> 
      <p style="color:red;">{{error}}</p>
      <table class="simple-table" >
          <thead>
            <tr style="font-weight: bold; border-bottom: 1px solid black;">
              <th>Row</th>
              <th>Email</th>
              <th>Role</th>
              <th>Expire Date</th>
              <th>Ready </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of input_user">
              <td>{{row.row}}</td>
              <td>{{row.email}}</td>
              <td>{{row.role}}</td>
              <td>{{row.expdate }}</td>
              <td>{{row.status}}</td>
            </tr>
          </tbody>
        </table>
    </div>
    <div class="col-10">
      <p>{{valid_user.length}} users ready.</p>
      <button [disabled]="submit_status == false || isLoadingUser == true" class="btn btn-outline-warning" (click)="submitUsers()">Add ready users</button>
      <p style="color:red;">These users will be added to {{desc}}</p>
      <p style="color:green;">Submit status: {{user_status}}</p>
    </div>
  </div>
</div>
