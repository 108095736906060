import { RouterModule, Routes } from '@angular/router';
import {
    MorningMeetingComponent
} from './misc.component';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
	{
		path: 'Misc', canActivate: [AuthorizeGuard], children: [
			{ path: 'Morning-meeting-users', component: MorningMeetingComponent }
		]
	},
];

export const routing = RouterModule.forChild(routes);
