import { RouterModule, Routes } from '@angular/router';
import { EventCalendarThirdComponent } from './third-party-calendar/event-calendar-third.component';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';
import { EventEditComponent } from './event-edit/event-edit.component';
import { EventSearchComponent } from './event-search/event-search.component';
import { EventParticipantsComponent } from './event-participants/event-participants.component';

const routes: Routes = [{
    path: 'Event',
    canActivate: [AuthorizeGuard],
    data: { navCheck: true },
    children: [
        { path: 'New', component: EventEditComponent },
        { path: 'Edit', component: EventEditComponent },
        { path: 'Search', component: EventSearchComponent },
        { path: 'Participants', component: EventParticipantsComponent },
        { path: 'Event-Calendar-Third', component: EventCalendarThirdComponent, /* data: { type: "General" }*/ },
    ]
}]

export const routing = RouterModule.forChild(routes);
