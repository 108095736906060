<h2>Failed login attempts</h2>

<br />

<i>Choose desired start and end date:</i><br />
<div class="form-group row yCenter">
  <label for="startDate" class="col-2 col-form-label"
    >Occured between from/to: *</label
  >
  <div class="col-8">
    <div class="form-check">
      <label class="form-check-label">
        <input
          type="date"
          class="form-control"
          [ngModel]="startDate | date: 'y-MM-dd'"
          (ngModelChange)="startDate = convertStr2Date($event)"
        />
        <input
          style="margin-top:10px"
          type="date"
          class="form-control"
          [ngModel]="endDate | date: 'y-MM-dd'"
          (ngModelChange)="endDate = convertStr2Date($event)"
        />
      </label>
    </div>
  </div>
</div>

<button type="button" class="btn btn-outline-warning" (click)="submit()">
  Show
</button>
<br />
<br />
<!--<div *ngFor="let item of docStats">
  {{item.Bloomberg[0].Email}}
</div>-->
<style>
  .table-border-top {
    border-top: none;
  }
  td:nth-child(3n + 0) {
    border-right: 2px solid #eceeef;
  }
</style>

<table class="table table-hover" *ngIf="failedLoginStats">
  <thead>
    <tr>
      <th>User</th>
      <th>Failed Login Date</th>
      <th>Referrer</th>
      <!--<th *ngFor="let i of channels" colspan="3" style="text-align:center; border-bottom:none;">{{i}}</th>-->
    </tr>
  </thead>

  <tbody>
    <ng-container *ngFor="let item of failedLoginStats.Items">
      <tr>
        <td>{{ item.CromId }}</td>
        <td>{{ item.TimestampIso | date: "yyyy-MM-dd HH:mm:ss" }}</td>
        <td>{{ item.Referrer }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
