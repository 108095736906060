import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './user.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewComponent } from './user-view/user.component';
import { TempNewUserComponent } from './TempNewUserComponent';
import { SearchComponent } from './user-search/SearchComponent';
import { UserApiService } from './user-api.service';
import { UserNavAccess } from './user-nav-access/user-nav-access.component';
import { UserStatisticsComponent } from './user-statistics/user-statistics.component';
import { UserTabsComponent } from './user-tabs.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { RoleTreeComponent } from './user-view/role-tree/role-tree.component';
import { EmailConfirmedComponent } from './active/active.component';
import { MaterialModule } from 'app/shared/material/material.module';
import { UserEmailModule } from './user-email/user-email.module';
import { IconPack } from '@fortawesome/fontawesome-svg-core';

@NgModule({
  declarations: [
    NewComponent,
    SearchComponent,
    TempNewUserComponent,
    UserNavAccess,
    UserStatisticsComponent,
    UserTabsComponent,
    RoleTreeComponent,
    EmailConfirmedComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    UserEmailModule,
    routing,
  ],
  exports: [
    NewComponent,
    SearchComponent,
    TempNewUserComponent,
    UserNavAccess,
    UserStatisticsComponent,
    UserTabsComponent
  ]
})
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [UserApiService]
    };
  }
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas as IconPack, far as IconPack);
  }
}
