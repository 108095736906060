<ul class="nav flex-column">
  <li class="nav-item" *ngFor="let nav of menu" style="margin-bottom:0.5em; text-transform:none;font-size: 14px">
    <div (click)="this['toggle' + nav.Id] = !this['toggle' + nav.Id]" class="hover">
      <a class="nav-link">{{nav.Name}}
        <fa-icon [icon]="['fas', this['toggle' + nav.Id] ? 'angle-down' : 'angle-up']" 
        [styles]="{ 'float': 'right', 'color': 'grey', 'padding-right': '0'}" size="lg"></fa-icon>
        </a>
    </div>
    <ul class="nav flex-column" *ngIf="!this['toggle' + nav.Id]" is-open="false" style="font-size:14px;">
      <ng-container *ngFor="let sub of nav.Sub">
        <li class="nav-item" [class.notImpl]="!sub.IsImplemented" *ngIf="sub.Name != null"
          style="padding-left:1em; text-transform:none">
          <a class="nav-link" routerLink="{{subLink(nav.Name, sub)}}">{{sub.Name}}</a>
        </li>
      </ng-container>
    </ul>
  </li>
</ul>


<ng-container *ngIf="isLoaded">
  <ul class="nav flex-column" is-open="false" style="font-size:14px;margin-bottom: 30px;">
    <li style="vertical-align: middle;">
      <a class="nav-link" [routerLink]='["/authentication/logout"]' [state]='{ local: true }' style="vertical-align: middle;margin-top:0"
        title="Logout">Logout<fa-icon [icon]="['fas','sign-out-alt']" size="lg"
          [styles]="{ 'float': 'right', 'padding-right': '0'}"></fa-icon>
        </a>
    </li>
  </ul>
</ng-container>