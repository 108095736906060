import { Component } from "@angular/core";
import { ContentApiService } from "./content-api.service";
import { Image } from "./model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

// Images
@Component({
  selector: "content-images",
  templateUrl: "images.component.html"
})
export class ImagesComponent {
  images: Image[];
  editImage: Image;
  editing: boolean = false;

  editBannerImage: Image;

  saveImageError: string;
  saveImageLoading: boolean;
  saveImageSuccess: boolean;

  imageFocus: boolean = true;

  deleteLoading: boolean;
  deleteError: boolean;
  deleteIndex: number;

  constructor(private contentApi: ContentApiService) { }

  ngOnInit() {
    this.getAllImages();
    this.edit(null);

    this.saveImageError = "";
    this.saveImageLoading = false;
  }

  getAllImages() {

    this.contentApi.getAllImages()
      .subscribe((data: Image[]) => {
        this.images = data;
      });
  }

  edit(i: number) {
    if (i == null) {
      this.editing = false;
      this.editImage = {
        Id: "",
        Name: "",
        Description: "",
        File: [],
        Filename: ""
      };
    } else {
      this.editing = true;
      let objCopy = JSON.parse(JSON.stringify(this.images[i]));
      this.editImage = objCopy;
    }
  }

  editBanner(i: number) {
    let objCopy = JSON.parse(JSON.stringify(this.images[i]));
    this.editBannerImage = objCopy;
  }

  editSave(i: number, save: boolean) {
    if (save) {
      this.contentApi.updateImage(this.images[i]).subscribe();
    } else {
      this.images[i] = this.editImage;
    }
    this.edit(null);
  }

  fileToParse() { }

  newImage() {
    //this.images.unshift(hej);
  }

  deleteImage(guid: string, position: number) {
    this.deleteIndex = position;

    this.deleteLoading = true;
    this.deleteError = false;

    return this.contentApi.deleteImage(guid)
      .pipe(
        map((data) => {
          this.images.splice(position, 1);
          this.deleteLoading = false;
          return data;
        })
      );

  }

  async fileSelected(event: any) {
    var reader = new FileReader();

    reader.onloadend = (ev: ProgressEvent) => {
      if (!reader.error) {
        this.editImage.File = <string>reader.result;
      }
    };

    reader.readAsDataURL(event.target.files[0]);
  }

  isString(obj: any): boolean {
    return typeof obj === "string";
  }

  async saveImage(name: string, description: string, file: any) {
    if (name.length < 1 || description.length < 1 || file.length != 1) {
      this.saveImageError = "All input fields are mandatory";
    } else {
      this.saveImageError = "";
      this.saveImageLoading = true;

      var reader = new FileReader();
      reader.onloadend = () => {
        if (reader.error) {
          var error = <DOMError>reader.error;
          this.saveImageError = `Error during file read before upload: ${error.name}`;
          this.saveImageLoading = false;
          throw reader.error;
        }

        var arrayBufferResult = <ArrayBuffer>reader.result;

        this.saveImageHelper(
          name,
          description,
          arrayBufferResult,
          file[0].name
        ).subscribe((image: Image) => {
          this.images.unshift(image);
        });
      };

      reader.readAsArrayBuffer(file[0]);
    }
  }

  saveImageHelper(
    name: string,
    description: string,
    arrayBuffer: ArrayBuffer,
    fileName: string
  ): Observable<Image> {
    var byteArr = new Uint8Array(arrayBuffer);
    if (byteArr.length < 1) throw Error;

    this.saveImageLoading = true;

    return this.contentApi
      .insertImage(name, description, byteArr, fileName)
      .pipe(
        map((data: Image) => {
          this.saveImageLoading = false;
          this.saveImageSuccess = true;
          return data;
        }));
  }
}

@Component({
  selector: "content-banners",
  template: "<h2>Under construction</h2>"
})
export class BannersComponent {
  constructor() { }
}

@Component({
  selector: "content-publicationType",
  template: "<h2>Under construction</h2>"
})
export class PublicationTypeComponent {
  constructor() { }
}
