import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, ObservableLike } from 'rxjs';
import { environment } from 'environments/environment';
import { FileData } from 'app/common/file-data';
import { FileService } from 'app/common/file-service';

@Injectable()
export class MorningMeetingApi {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient, private fileService: FileService) { }

  getmorningmeeting(): Observable<MorningMeetingModel[]> {
    return this.http
      .get<MorningMeetingModel[]>(this.baseUrlReonApi + 'morningmeeting/getmorningmeeting', {
        withCredentials: true
      });
  }

  searchCompanies(value: string): Observable<CompanyNameModel[]> {
    return this.http
      .get<CompanyNameModel[]>(`${this.baseUrlReonApi}morningmeeting/searchcompanies?value=${encodeURIComponent(value)}`, {
        withCredentials: true
      });
  }

  getAttachment(id: number): Observable<any> {
    const httpOptions: Object = {
      responseType: 'blob',
      withCredentials: true
    };
    return this.http
      .get<any>(`${this.baseUrlReonApi}morningmeeting/DownloadAttachment?id=${id}`,
        httpOptions);
  }

  companyExists(company: string): Observable<number> {
    console.log("companyExists", company);
    return this.http
      .get<number>(`${this.baseUrlReonApi}morningmeeting/companyexists?company=${encodeURIComponent(company)}`, {
        withCredentials: true
      });
  }

  getDeadline(value: string): Observable<DeadlineModel> {
    return this.http
      .get<DeadlineModel>(`${this.baseUrlReonApi}morningmeeting/getdeadline?value=${encodeURIComponent(value)}`, {
        withCredentials: true
      });
  }

  getTemplates(): Observable<string[]> {
    return this.http
      .get<string[]>(this.baseUrlReonApi + 'morningmeeting/getchildtemplatefiles', {
        withCredentials: true
      });
  }

  whoAmI(): Observable<string> {
    return this.http
      .get<string>(this.baseUrlReonApi + 'morningmeeting/whoami', {
        withCredentials: true
      });
  }

  generate(): Observable<FileData> {
    return this.fileService.downloadAndSave(`${this.baseUrlReonApi}morningmeeting/CreateMorningMeetingPPT`);
  }

  // generate(): Observable<any> {
  //   return this.http
  //     .get<any>(
  //       `${this.baseUrlReonApi}morningmeeting/CreateMorningMeetingPPT`,
  //       {withCredentials: true}
  //     );
  // }

  setDeadline(dm: DeadlineModel): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrlReonApi}morningmeeting/setDeadline`, dm, {
        withCredentials: true
      });
  }

  saveContribution(mm: MorningMeetingModel): Observable<number> {
    return this.http
      .post<number>(`${this.baseUrlReonApi}morningmeeting/UpdateMorningMeeting`, mm, {
        withCredentials: true
      });
  }

  saveAttachment(att: File): Observable<number> {
    const formData: FormData = new FormData();
    formData.append('file', att, att.name);

    return this.http
      .post<number>(`${this.baseUrlReonApi}morningmeeting/InsertAttachment`, formData, {
        withCredentials: true
      });

  }
  deleteContribution(id: number): Observable<any> {
    return this.http
      .delete(
        `${this.baseUrlReonApi}morningmeeting/DeleteMorningMeeting?id=${id}`,
        { withCredentials: true }
      );
  }

}
export interface PublicationModel {
  Name?: string;
  Id?: number;
}

export interface CompanyNameModel {
  Name?: string;
  Id?: number;
}
export interface MorningMeetingModel {
  id?: number;
  companyId?: number;
  companyName?: string;
  headline: string;
  created?: Date;
  updated?: Date;
  analystId?: string;
  fastComment?: boolean;
  recChange?: boolean;
  salesSelected?: boolean;
  sortOrder?: number;
  attachmentId?: number;
  template?: string;
}
export interface PowerPointModel {
  file: any[];
  fileName: string;
  uniqueUrl?: string;
}
export interface DeadlineModel {
  dt: Date;
  value: string;
}
