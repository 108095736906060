<div>
  <h2>SHB Forecast</h2>
  <div>
    <h3>Sync</h3>
    <p>Sync with Macrobond is scheduled for 02:00 every night. Use the button below for starting an immediate manual
      sync.</p>
    <br />
    <button mat-raised-button color="primary" (click)="SyncMacrobond()" [disabled]="syncing">Sync</button>
    <mat-spinner *ngIf="syncing" [diameter]="20" mode="indeterminate"></mat-spinner>
    <br />
  </div>
  <div>
    <h3>Names & Dates</h3>
    <p>Below you can see some information displayed on the Macro Forecast page. </p>

    <div layout="row">
      <mat-form-field>
        <mat-label>New "Forecast Updated" date</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date" [formControl]="latestDateControl">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>Updated immediately</mat-hint>
      </mat-form-field>
      <mat-spinner *ngIf="date_sync" [diameter]="20" mode="indeterminate"></mat-spinner>
    </div>
    <br/>
    <mat-accordion multi="true">
      <mat-expansion-panel (opened)="request('policy')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Policy rates
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="policy">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('interbank')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            3m interbank rates
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="three_mrates">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('bond2')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            2y govt. yields
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="govt2y">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('bond5')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            5y govt. yields
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="govt5y">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('bond10')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            10y govt. yields
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="govt10y">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('swap2')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            2y swaps
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="swaps2y">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('swap5')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            5y swaps
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="swaps5y">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('swap10')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            10y swaps
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="swaps10y">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('fx')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            FX
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="fx_meta">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('gdp')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            GDP forecast
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="gdp">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="request('inflation')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Inflation forecast
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="inflation">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>


      <mat-expansion-panel (opened)="request('unemployment')">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Unemployment forecast
          </mat-panel-title>
          <mat-panel-description>
            view details...
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="example-container mat-elevation-z8" matSort>
          <mat-table #table [dataSource]="unemployment">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Key </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.key}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="friendly_title">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Friendly title</mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.friendlyTitle}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="latest_change">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Latest Macrobond change </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.lastModified | date : 'shortDate'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sort_order">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Sort order </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.sortOrder}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

  </div>
</div>