import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { routing } from './admin.routing';
import { AdminApiService } from './admin-api.service';

import {
    DeviceMessageComponent
} from './admin.component';


@NgModule({
  declarations: [
    DeviceMessageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    routing,
    HttpClientModule,
  ],
  exports: [
      
  ]
})
export class AdminModule { 
    static forRoot(): ModuleWithProviders<AdminModule> {
        return {
            ngModule: AdminModule,
            providers: [AdminApiService]
        }
    }
}
