import { Component, Input } from "@angular/core";
import { catchError } from "rxjs/operators";
import { UserExModel } from "../user-api.service";
import { Rule } from "./_models/rule.model";
import { RulesService } from "./_service/rules.service";

@Component({
  selector: "user-email",
  templateUrl: "user-email.component.html",
  styleUrls: ['user-email.component.sass'],
})
export class UserEmailComponent {
  @Input() user: UserExModel | null;

  typeFilter: string = "";
  panelOpenState = false;

  rules: Array<Rule> = new Array<Rule>();

  editRule: Rule;

  isLoading: boolean = true
  errorString: string = null

  isDistributionDisabled: boolean = true

  constructor(private ruleService: RulesService) { }

  ngOnInit() {
    this.ruleService.getUserRules(this.user.OriginalEMail).pipe(
      catchError(x => {
        this.errorString = x
        this.isLoading = false
        return null
      })
    ).subscribe(({ rules, distributionDisabled }) => {
      this.isDistributionDisabled = distributionDisabled
      if (rules) {
        this.rules = rules as Array<Rule>
      }
      this.isLoading = false
    })

    this.ruleService.userUpdated.subscribe(user => {
      this.isDistributionDisabled = user.distributionDisabled
    })
  }

  createdRule(rule: Rule) {
    if (!rule) {
      this.editRule = null
      return
    }
    let index = this.rules.findIndex(x => x.id === rule.id)
    index >= 0 ? this.rules[index] = rule : this.rules.push(rule)
    this.editRule = null
  }

  deleteRule(index: number, ruleId: number) {
    this.isLoading = true
    this.ruleService.deleteRule(ruleId).pipe(
      catchError(x => {
        this.errorString = x
        this.isLoading = false
        return null
      })
    ).subscribe(rules => {
      if (rules) {
        this.rules.splice(index, 1)
      }
      this.isLoading = false
    })
  }

  distributionDisabled(checked: boolean) {
    this.isLoading = true
    this.ruleService.updateUser(this.user.EMail, checked).pipe(
      catchError(x => {
        this.errorString = x
        this.isLoading = false
        return null
      })
    ).subscribe((user: any) => {
      this.isLoading = false
    })
  }

}
