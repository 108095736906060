<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="alert alert-danger" role="alert" *ngIf="errorString">{{errorString}}</div>

<ng-container *ngIf="!isLoading && !errorString">
    <app-rule-select [options]="publicationTypeOptions" name="Publication types"
        [selectedValues]="_selectedPublicationTypes" (selections)="updatePublicationTypes($event)">
    </app-rule-select>

    <!-- <app-rule-select [options]="sectorOptions" name="Sectors" [selectedValues]="_selectedSectors"
        [disabled]="_selectedCompanies.length > 0" (selections)="updateSectors($event)">
    </app-rule-select>

    <app-rule-select [options]="countryOptions" name="Countries" [selectedValues]="_selectedCountries"
        [disabled]="_selectedCompanies.length > 0" (selections)="updateCountries($event)">
    </app-rule-select> -->

    <app-rule-select [options]="companyOptions" name="Company" [selectedValues]="_selectedCompanies"
        [disabled]="isSectorMode"
        (selections)="updateCompanies($event)">
    </app-rule-select>

    <app-rule-select [options]="analystOptions" name="Analysts" [selectedValues]="_selectedAnalysts" [disabled]="isCompanyMode"
        (selections)="updateAnalysts($event)">
    </app-rule-select>

    <app-rule-select [options]="currencyOptions" name="Currencies" [selectedValues]="_selectedCurrencies" [disabled]="isCompanyMode"
        (selections)="updateCurrencies($event)">
    </app-rule-select>
</ng-container>