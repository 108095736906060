import { KeyValuePair } from "./keyValuePair.model";

export enum RuleCategoryEnum {
    EQUITY = "Equity",
    CREDIT = "Credit",
    MACRO = "Macro",
    FI_FX = "FI & FX strategy"
}

export class Rule {
    readonly id: number;
    readonly categoryCode: RuleCategoryEnum;

    publicationTypes: Array<KeyValuePair>;
    sectors: Array<KeyValuePair>
    countries: Array<KeyValuePair>
    companies: Array<KeyValuePair>
    analysts: Array<KeyValuePair>
    currencies: Array<KeyValuePair>
    languages: Array<KeyValuePair>
    regions: Array<KeyValuePair>;
    private _marketCap: Array<number>;
    public get marketCap(): Array<number> {
        return this._marketCap;
    }
    public set marketCap(value: Array<number>) {
        let MARKET_CAP_MAX = 500000
        let MARKET_CAP_MIN = 0
        let caps = [0,0]
        if(value?.length == 2)
            caps = [...value]
        if(caps[0] <= MARKET_CAP_MIN && caps[1] <= MARKET_CAP_MIN)
            caps = [0,0]
        if(caps[1] > MARKET_CAP_MIN && caps[0] <= MARKET_CAP_MIN)
            caps[0] = MARKET_CAP_MIN
        this._marketCap = caps
    }

    constructor(
        id: number
        , category: RuleCategoryEnum
        , publicationTypes: Array<KeyValuePair> = []
        , sectors: Array<KeyValuePair> = []
        , countries: Array<KeyValuePair> = []
        , companies: Array<KeyValuePair> = []
        , analysts: Array<KeyValuePair> = []
        , currencies: Array<KeyValuePair> = []
        , marketCap: Array<number> = [0,0]
        , languages: Array<KeyValuePair> = []
        , regions: Array<KeyValuePair> = []
    ) {
        this.id = id
        this.categoryCode = category
        this.publicationTypes = publicationTypes
        this.sectors = sectors
        this.countries = countries
        this.companies = companies
        this.analysts = analysts
        this.currencies = currencies
        this.marketCap = marketCap
        this.languages = languages
        this.regions = regions
    }

    isError(): { isError: boolean, errorText: string } {
        if (!this.publicationTypes?.length && !this.sectors?.length 
            && !this.countries?.length && !this.companies?.length 
            && !this.analysts?.length && !this.currencies?.length
            && !this.languages?.length && !this.regions?.length
            && !(this.marketCap[0] != 0 && this.marketCap[1] != 0))
            return { isError: true, errorText: "Rule can't be empty"}
        if(!this.publicationTypes?.length)
            return { isError: true, errorText: "Publication type required"}
        return { isError: false, errorText: null }
    }
}