import {
  Component,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef
} from "@angular/core";

import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { ContentApiService } from "./content-api.service";
import { Banner, Image, BannerPage, BannerFile } from "./model";

@Component({
  selector: "banner-add-dialog",
  templateUrl: "banner-add-dialog.component.html"
})
export class BannerAddDialogComponent {
  @ViewChild("file", {static: true} ) fileInput: ElementRef;

  @Input() image: Image;
  @Input() bannerInput: Banner;

  banner: Banner;
  bannerFile: BannerFile;

  saveError: string;
  saveLoading: boolean;
  saveSuccess: boolean;

  bannerLocations: Array<string>;
  bannerPages: Array<BannerPage>;
  bannerPage: BannerPage;

  //so one knows wether to update or create a new banner.
  updateBanner: boolean;

  constructor(private contentApi: ContentApiService) { }

  ngOnInit() {
    this.atStart(this.bannerInput);
    this.contentApi
      .getBannerLocations()
      .subscribe(data => (this.bannerLocations = data));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.atStart(
      changes.bannerInput != null
        ? changes.bannerInput.currentValue
        : changes.bannerInput
    );
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
  }

  atStart(banner: Banner) {
    this.bannerPage = new BannerPage();

    if (banner != null) this.newBanner(banner);
    else this.newBanner(new Banner());

    if (banner != null) this.updateBanner = true;
    else this.updateBanner = false;

    if (this.banner.Id != null)
      this.contentApi
        .getBannerPagesByBannerId(this.banner.Id)
        .subscribe(data => {
          data.forEach(element => {
            if (element.LocationContextId == null)
              element.LocationContextId = 0;
          });
          this.bannerPages = data;
        });
    else this.bannerPages = new Array<BannerPage>();

    this.fileInput.nativeElement.value = "";
    this.bannerFile = new BannerFile();
    this.bannerFile.Name = "";
    this.bannerFile.File = null;

    this.saveError = "";
    this.saveLoading = false;
    this.saveSuccess = false;
  }

  newBanner(banner: Banner) {
    this.saveError = "";
    this.saveLoading = false;
    this.banner = new Banner();
    if (this.bannerInput != null) this.banner = banner;
    if (this.banner.OpenInNewWindow == null)
      this.banner.OpenInNewWindow = false;
    if (this.banner.SortOrder == null) this.banner.SortOrder = 0;

    if (this.image != null) {
      if (this.banner.Name == null) this.banner.Name = this.image.Name;
      if (this.banner.Description == null)
        this.banner.Description = this.image.Description;
    }
  }

  tryReadFile(file: any): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      if (file.length > 0) {
        const reader = new FileReader();
        reader.onloadend = (event: any) => {
          if (!reader.error) {
            this.bannerFile.Name = file[0].name;
            this.bannerFile.File = new Uint8Array(<ArrayBuffer>reader.result);
            resolve("resolved");
          } else {
            console.error("error loading file", reader.error);
            reject("error during read file");
          }
        };

        reader.readAsArrayBuffer(file[0]);
      } else {
        resolve("no file to load!");
      }
    });

    return promise;
  }

  async saveBanner(file: any) {
    if (!this.formCheck()) {
      this.saveError =
        "'Name', 'Description', 'Show From/To' and 'Sort order' (>= 0) are all mandatory fields";
    } else {
      this.saveLoading = false;
      await this.tryReadFile(file);
      this.saveError = "";
      this.saveLoading = true;
      this.banner.ImageId = this.image.Id;
      let action: Observable<any> | null = null;

      if (this.updateBanner) {
        action = this.contentApi.updateBanner(
          this.banner,
          this.bannerPages,
          this.bannerFile
        );
      } else {
        action = this.contentApi.insertBanner(
          this.banner,
          this.bannerPages,
          this.bannerFile
        );
      }

      action.subscribe(
        data => {
          this.saveSuccess = true;
          return data;
        },
        err => {
          this.saveSuccess = false;
          this.saveError = "Something went wrong when trying to save";
          return [];
        },
        () => {
          this.saveLoading = false;
        }
      );
    }
  }

  resolveAfterXMilliseconds(time: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve("resolved");
      }, time);
    });
  }

  formCheck(): boolean {
    if (this.banner.Name.length < 1) return false;
    if (this.banner.Description.length < 1) return false;
    if (this.banner.ShowFrom == null) return false;
    if (this.banner.ShowUntil == null) return false;
    if (this.banner.SortOrder < 0 || this.banner.SortOrder == null)
      return false;
    return true;
  }

  addBannerPage() {
    if (this.banner.Id != null) this.bannerPage.BannerId = this.banner.Id;
    let bp = JSON.parse(JSON.stringify(this.bannerPage)); //Deep copy
    this.bannerPages.push(bp);
  }
}

@Component({
  selector: "banner-view",
  templateUrl: "banner-view.component.html"
})
export class BannerViewComponent {
  @Input() images: Image[];

  constructor(private contentApi: ContentApiService) { }

  banners: Banner[];
  bannerImages: Image[];

  deleteLoading: boolean;
  deleteError: boolean;
  deleteBannerIndex: number;

  editBanner: any;

  ngOnInit() {
    this.banners = [];
    this.bannerImages = [];
    this.getAllBanners();

    this.deleteLoading = false;
    this.deleteError = false;
  }

  // Ugh, ugly and slow to get the correct images from already fetched data
  getAllBanners() {
    this.contentApi.getAllBanners().subscribe(banners => {
      banners.forEach(banner => {
        this.images.forEach(image => {
          if (
            banner.ImageId.toLocaleLowerCase() === image.Id.toLocaleLowerCase()
          ) {
            this.bannerImages.push(image);
            this.banners.push(banner);
          }
        });
      });
    });
  }

  deleteBanner(id: number, position: number): Observable<any> | null {
    this.deleteBannerIndex = position;

    this.deleteLoading = true;
    this.deleteError = false;
    return this.contentApi.deleteBanner(id)
      .pipe(
        map((data) => {
          this.bannerImages.splice(position, 1);
          this.banners.splice(position, 1);
          this.deleteLoading = false;
          return data;
        }),
        catchError(() => {
          this.deleteLoading = false;
          this.deleteError = true;
          return [];
        })
      );

  }

  edit(banner: Banner, index: number) {
    this.editBanner = {
      Banner: banner,
      Image: this.bannerImages[index]
    };
  }

}
