import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { routing } from "./makroSvepetPlusAdmin.routing";

import { DownloadComponent } from "./makroSvepetPlusAdmin.component";
import { MakrosvepetPlusAdminApiService } from "./makroSvepetPlusAdmin-api.service";
import { MakroSvepetPlusAddUserComponent } from "./makroSvepetPlusAddUser.component";
import { environment } from "../../environments/environment";

@NgModule({
  declarations: [DownloadComponent, MakroSvepetPlusAddUserComponent],
  imports: [CommonModule, FormsModule, routing],
  exports: [DownloadComponent, MakroSvepetPlusAddUserComponent]
})
export class MakroSvepetPlusAdminModule {
  static forRoot(): ModuleWithProviders<MakroSvepetPlusAdminModule> {
    return {
      ngModule: MakroSvepetPlusAdminModule,
      providers: [MakrosvepetPlusAdminApiService]
    };
  }
}
