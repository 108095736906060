<h2>{{title}} In Focus Publication</h2>

<p>Rearrange the order of the listitems by dragging them to the desired position. And remember to save the changes!</p>

<br />

<mat-table *ngIf="inFocusPublication.length > 0" [dataSource]="dataSource" class="infocus-container" 
    (cdkDropListDropped)="onListDrop($event)"
    cdkDropList>
    <ng-container matColumnDef="Headline">
        <mat-header-cell *matHeaderCellDef> Headline </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
            <span data-toggle="modal" data-target=".bd-example-modal-lg"
            (click)="currentClicked(i)"
                [class]="element.Removed ? 'lineThrough': null">{{element.Headline}}</span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Ingress">
        <mat-header-cell *matHeaderCellDef> Ingress </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" >
            <span data-toggle="modal" data-target=".bd-example-modal-lg"
            (click)="currentClicked(i)" [class]="element.Removed ? 'lineThrough': null" [innerHTML]="fixBr(element.Ingress)"></span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="InFocusId">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
            <fa-icon [icon]="['fas','trash']" (click)="deleteElement(i)"></fa-icon>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"></mat-row>
</mat-table>


<!--Pop up-->
<div class="modal fade bd-example-modal-lg" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-content">
                <div class="modal-header">
                    <h6 class="modal-title">
                        Edit Publication
                    </h6>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <input type="text" class="form-control" style="width:100%; font-weight:bold; margin-bottom:2rem"
                        [(ngModel)]='currentHeadline'>
                    <textarea class="form-control" id="ingressArea" rows="10" [(ngModel)]='currentIngress'></textarea>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-dismiss="modal"
                        (click)="updateRow()">Done</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>

<h5>Find / Add publication</h5>
<div class="col-6" style="padding-left:0">
    <div class="input-group">
        <input type="text" class="form-control" placeholder="Find publication..." #st
            (keyup)="getInFocusPublicationSearch(st.value)">
        <span class="input-group-btn">
            <button class="btn btn-outline-secondary" type="button"
                (click)="st.value = ''; inFocusPublicationSearch = {}">
                <fa-icon [icon]="['fas','search']"></fa-icon>
            </button>
        </span>
    </div>
</div>

<table class="table table-hover">
    <thead>
        <tr>
            <th style="width:20%;">Pub. date</th>
            <th>Headline</th>
            <th style="width:5%">&nbsp;</th>
        </tr>
    </thead>
    <tbody class="hover">
        <tr *ngFor="let item of inFocusPublicationSearch.Docs; let i = index" (click)="addPublication(i);">
            <td>{{item.Date | date: 'yyyy-MM-dd'}}</td>
            <th>{{item.Headline}} - {{item.SubHeadline}}</th>
            <td><i [ngClass]="{'fa fa-check': h, '': !h}"></i></td>
        </tr>
    </tbody>
</table>

<div class="row">
    <div id="sticky-save">
        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="save()">Save</button>
        <!--<button type="button" class="btn btn-secondary" data-dismiss="modal">Reset</button>-->
        <b style="margin-left: 1rem;">Don't forget to save!</b>
    </div>
</div>