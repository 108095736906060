import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MakrosvepetPlusAdminApiService, IMailinglistAccountLog, INewsletterModel } from './makroSvepetPlusAdmin-api.service'
import { UserApiService, UserModel, UserExModel, UserStats, UserStatItem } from '../user/user-api.service'
import { environment } from '../../environments/environment';

@Component({
  selector: 'downloadMakrosvepetPlus',
  templateUrl: 'makroSvepetPlusAdmin.component.html'
})

export class DownloadComponent {
  mailingListAccountLog: IMailinglistAccountLog;
  mailingListAccountLogHistory: IMailinglistAccountLog[];
  newsletterLatest: INewsletterModel[];
  newsletter: INewsletterModel;
  downloadDone: boolean = false;
  downloadDisabled: boolean = false;
  baseUrlReon = environment.baseUrlReon;

  constructor(private api: MakrosvepetPlusAdminApiService, private router: Router, private userApi: UserApiService) {
    this.getNewsletterList();
    this.getMakrosvepetLog();
  }

  isDownloadDisabled(): boolean {
    return this.downloadDisabled;
  }

  copyLink(text: string) {
    var event = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
      document.removeEventListener('copy', event);
    }
    document.addEventListener('copy', event);
    document.execCommand('copy');

  }

  downloadMakrosvepet(): void {
    this.downloadDisabled = true;
    this.api.downloadMakrosvepet().subscribe(data => {
      this.mailingListAccountLog = data;
      this.downloadDisabled = false;
      this.getMakrosvepetLog();
      this.getNewsletterList();
    });
  }

  getMakrosvepetLog(): void {
    this.api.getMailingListAccountLogMakrosvepet().subscribe(log => this.mailingListAccountLogHistory = log);
  }

  showNewsletter(reonUrlId: string): void {
    this.api.getNewsletter(reonUrlId).subscribe(x => this.newsletter = x);
  }

  getNewsletterList(): void {
    this.api.getLatestMakrosvepet().subscribe(x => this.newsletterLatest = x);
  }
}

