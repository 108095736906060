import { Component } from '@angular/core';

@Component({
  selector: 'home',
  template: 
    `<h2>Welcome to REON admin web</h2><p>Use the menu to navigate the site</p>`
  
})

export class HomeComponent {
}