<mat-radio-group aria-label="Research types" (change)="researchTypeChange($event)" [ngModel]="selectedResearchType">
    <mat-radio-button *ngFor="let type of researchTypes" [value]="type">{{type.value}}</mat-radio-button>
</mat-radio-group>

<ng-container [ngSwitch]="selectedResearchType?.key">
    <app-create-equity *ngSwitchCase="'EQUITY'" [selectedPublicationTypes]="_rule.publicationTypes"
        (updatedPublicationTypes)="_rule.publicationTypes = $event" [selectedCompanies]="_rule.companies"
        (updatedCompanies)="_rule.companies = $event" [selectedSectors]="_rule.sectors"
        (updatedSectors)="_rule.sectors = $event" [selectedCountries]="_rule.countries"
        (updatedCountries)="_rule.countries = $event" [selectedAnalysts]="_rule.analysts"
        (updatedAnalysts)="_rule.analysts = $event" [selectedMarketCap]="_rule.marketCap"
        (updatedMarketCap)="_rule.marketCap = $event"></app-create-equity>

    <app-create-credit *ngSwitchCase="'CREDIT'" [selectedPublicationTypes]="_rule.publicationTypes"
        (updatedPublicationTypes)="_rule.publicationTypes = $event" [selectedCompanies]="_rule.companies"
        (updatedCompanies)="_rule.companies = $event" [selectedSectors]="_rule.sectors"
        (updatedSectors)="_rule.sectors = $event" [selectedCountries]="_rule.countries"
        (updatedCountries)="_rule.countries = $event" [selectedAnalysts]="_rule.analysts"
        (updatedAnalysts)="_rule.analysts = $event" [selectedCurrencies]="_rule.currencies"
        (updatedCurrencies)="_rule.currencies = $event"></app-create-credit>

    <app-create-macro *ngSwitchCase="'MACRO'" [selectedPublicationTypes]="_rule.publicationTypes"
        (updatedPublicationTypes)="_rule.publicationTypes = $event" [selectedLanguages]="_rule.languages"
        (updatedLanguages)="_rule.languages = $event" [selectedRegions]="_rule.regions"
        (updatedRegions)="_rule.regions = $event"></app-create-macro>

    <app-create-fifx *ngSwitchCase="'FI_FX'" [selectedPublicationTypes]="_rule.publicationTypes"
        (updatedPublicationTypes)="_rule.publicationTypes = $event" [selectedLanguages]="_rule.languages"
        (updatedLanguages)="_rule.languages = $event" [selectedRegions]="_rule.regions"
        (updatedRegions)="_rule.regions = $event"></app-create-fifx>

    <i *ngSwitchDefault>Chose a research area above</i>
</ng-container>

<app-string-builder *ngIf="_rule" [publicationTypes]="_rule.publicationTypes" [companies]="_rule.companies"
    [sectors]="_rule.sectors" [countries]="_rule.countries" [analysts]="_rule.analysts" [currencies]="_rule.currencies"
    [marketCap]="_rule.marketCap" [languages]="_rule.languages" [regions]="_rule.regions"></app-string-builder>

<div class="button-row" *ngIf="selectedResearchType && !isLoading">
    <button mat-raised-button color="primary" (click)="saveOrUpdateRule()" [ngSwitch]="STATE">
        <ng-container *ngSwitchCase="'EDIT'">Update rule</ng-container>
        <ng-container *ngSwitchDefault>Create rule</ng-container>
    </button>
    <button mat-button color="error" (click)="resetRule()" [ngSwitch]="STATE">
        <ng-container *ngSwitchCase="'EDIT'">Discard changes</ng-container>
        <ng-container *ngSwitchDefault>Clear rule</ng-container>
    </button>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

<div class="alert alert-danger" role="alert" *ngIf="errorString">{{errorString}}</div>