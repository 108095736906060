import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateRuleComponent } from './create-rule.component';
import { RuleSelectComponent } from './rule-select/rule-select.component';
import { CreateEquityComponent } from './create-equity/create-equity.component';
import { CreateCreditComponent } from './create-credit/create-credit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RuleStringBuilderModule } from '../string-creator/rule-string-builder.module';
import { NouisliderModule } from 'ng2-nouislider';
import { RuleSliderComponent } from './rule-slider/rule-slider.component';
import { CreateMacroComponent } from './create-macro/create-macro.component';
import { CreateFifxComponent } from './create-fifx/create-fifx.component';

@NgModule({
  declarations: [
    CreateRuleComponent,
    RuleSelectComponent,
    CreateEquityComponent,
    CreateCreditComponent,
    RuleSliderComponent,
    CreateMacroComponent,
    CreateFifxComponent
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    BrowserAnimationsModule,

    RuleStringBuilderModule,

    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRadioModule,
    MatProgressBarModule,
    MatCheckboxModule,

    NouisliderModule
  ],
  exports: [
    CreateRuleComponent
  ]
})
export class CreateRuleModule { }
