import { Component, OnInit } from "@angular/core";
import { CompanyCustomizationApi } from "./company-customization-api";
import {
  CompanyCustomizationModel,
  CompanyNameModel
} from "./CompanyCustomizationModel";
import { CommandDataResponse } from "app/commandResponse";

@Component({
  selector: "content-company-alerts",
  templateUrl: "./company-alerts.component.html",
  styleUrls: ["./company-alerts.component.css"]
})
export class CompanyAlertsComponent implements OnInit {
  AreaList: string[] = ["CREDIT", "EQUITY"];
  CodeList: string[] = ["Red", "Black"];

  companyAlert: CompanyCustomizationModel = {
    CompanyId: -1,
    Area: this.AreaList[0],
    Code: this.CodeList[0],
    CompanyName: "",
    DisableRecommendation: true,
    Message: ""
  };

  companyAlertList: CompanyCustomizationModel[] = [];

  companyNames: CompanyNameModel[] = [];

  constructor(private companyCustomizationApi: CompanyCustomizationApi) {}

  ngOnInit() {
    this.initCompanyCustomizations();
  }
  private initCompanyCustomizations() {
    this.companyCustomizationApi
      .getAll()
      .toPromise()
      .then(companyAlerts => {
        this.companyAlertList = companyAlerts;
        this.sortCompanyAlerts();
      });
  }

  // ngOnDestroy(): void {}

  companyNameInput($event: any) {
    this.companyAlert.CompanyName = $event.target.value;

    console.log(this.companyAlert.CompanyName);

    return this.searchInput(this.companyAlert.CompanyName);
  }

  private rejectPreviousSearch: () => any = () => {};

  searchInput(name: string) {
    this.companyAlert.CompanyId = -1;
    this.rejectPreviousSearch();
    return new Promise<CompanyNameModel[]>((resolve, reject) => {
      this.initiateAsyncSearch(name, resolve, reject);
    }).then(names => {
      this.companyNames = names;
    });
  }

  private initiateAsyncSearch(
    name: string,
    resolve: (value: CompanyNameModel[]) => void,
    reject: (reason?: any) => void
  ) {
    const timerHandler = window.setTimeout(() => {
      this.companyCustomizationApi
        .searchCompanyName(name)
        .toPromise()
        .then(res => resolve(res))
        .catch(res => reject(res));
    }, 500); //delay 500ms
    this.rejectPreviousSearch = () => clearTimeout(timerHandler);
  }

  selectCompany(cn: CompanyNameModel) {
    this.companyAlert.CompanyName = cn.Name;
    this.companyAlert.CompanyId = cn.Id;
    this.hideCompanyNameMenu();
    return false;
  }

  selectAlert(cc: CompanyCustomizationModel) {
    this.companyAlert = Object.assign({}, cc);
    this.hideCompanyNameMenu();
  }

  private hideCompanyNameMenu() {
    this.companyNames = [];
  }

  canSave(): boolean {
    return (
      this.companyAlert.CompanyId > -1 &&
      this.companyAlert.Area &&
      this.companyAlert.Code &&
      typeof this.companyAlert.DisableRecommendation === "boolean" &&
      this.companyAlert.Message.length > 0
    );
  }

  isInEditMode() {
    return (
      this.companyAlertList.findIndex(
        ca =>
          ca.CompanyId == this.companyAlert.CompanyId &&
          ca.Area == this.companyAlert.Area
      ) > -1
    );
  }

  saveAlert(cc: CompanyCustomizationModel) {
    this.companyCustomizationApi
      .put(cc)
      .toPromise()
      .then(commandResponse => {
        this.HandlePutCommandResponse(commandResponse, cc);
      })
      .catch(reason => {
        alert("Error during save, reason: " + reason);
        throw reason;
      });
  }

  HandlePutCommandResponse(
    commandResponse: CommandDataResponse<number>,
    cc: CompanyCustomizationModel
  ): any {
    if (commandResponse.Ok) {
      const idx = this.companyAlertList.findIndex(
        ccL => ccL.CompanyId == cc.CompanyId && ccL.Area == cc.Area
      );

      if (idx > -1) {
        this.companyAlertList[idx] = cc;
        return;
      }

      this.companyAlertList.push(cc);
      this.sortCompanyAlerts();
    } else {
      alert("Error during delete!");
    }
  }

  sortCompanyAlerts() {
    this.companyAlertList.sort((a, b) => {
      if (a.CompanyName < b.CompanyName) return -1;
      if (a.CompanyName > b.CompanyName) return 1;

      return a.Code < b.Code ? -1 : 1;
    });
  }

  deleteAlert(cc: CompanyCustomizationModel) {
    if (confirm("Are you sure you want to delete the alert?")) {
      this.companyCustomizationApi
        .delete(cc.CompanyId, cc.Area)
        .toPromise()
        .then(commandResponse => {
          this.HandleDeleteCommandResponse(commandResponse, cc);
        })
        .catch(reason => {
          alert("Error during delete, reason: " + reason);
          throw reason;
        });
    }
  }

  private HandleDeleteCommandResponse(
    commandResponse: CommandDataResponse<number>,
    cc: CompanyCustomizationModel
  ) {
    if (commandResponse.Ok) {
      this.companyAlertList = this.companyAlertList.filter(
        ccL => ccL.CompanyId != cc.CompanyId || ccL.Area != cc.Area
      );
    } else {
      alert("Error during delete!");
    }
  }
}
