import { Component, OnInit, Input } from '@angular/core';
import { EditEmployeeModel, EmployeeApiService } from '../employee-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { FormGroup, FormControl, Validators, AbstractControl, FormArray } from '@angular/forms';


@Component({
  selector: 'employee-view',
  templateUrl: './employee-view.component.html',
  styleUrls: ['./employee-view.component.css']
})
export class EmployeeViewComponent implements OnInit {
  @Input() employee: EditEmployeeModel | null;
  @Input() isExistingEmployee = false;

  userId: string;
  isLoadingUser = true;
  imageSrc: string = null;
  newImageFile: File = null;
  saveError = false;
  lastSave: string = null;
  success_delete = false;
  is_saving = false;
  contactMap: { [key: string]: number } = {};
  displayedColumns: string[] = ['TeamName', 'TeamDescription', 'TeamTitle'];
  displayedColumnsTitles: string[] = ['select', 'Code', 'Description'];
  contactErrorId = "Comment";

  employeeForm = new FormGroup({
    UserId: new FormControl('', {
      validators: [Validators.required, this.uniqueUserIdValidator.bind(this)],
      updateOn: 'change'
    }),
    FirstName: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change'
    }),
    LastName: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change'
    }),
    Comment: new FormControl('', {
      validators: [],
      updateOn: 'change'
    }),
    Active: new FormControl(true, {
      validators: [],
      updateOn: 'change'
    }),
    ContactInfoList: new FormArray([
      /* empty by design. populated in ngOnInit */
    ]),
    Memberships: new FormControl('', {
      validators: [],
      updateOn: 'change'
    }),
    HasPicture: new FormControl({ value: false, disabled: true }, {
      validators: [],
      updateOn: 'change'
    })
  }, { updateOn: 'submit' });
  errorMessage: string[];

  constructor(
    private employeeApi: EmployeeApiService,
    private route: ActivatedRoute,
    private router: Router) { }


  ngOnInit() {
    this.getEmployeeObj().then(emp => {
      this.employee = emp;
      this.isLoadingUser = false;

      //ts parameter prevents caching
      this.imageSrc = this.employee.HasPicture ?
        `${environment.baseUrlReonApi}employee/Image?id=${this.employee.UserId}&ts=${this.getTimeStamp()}` :
        `${environment.baseUrlReonApi}employee/Image?id=None`;

      this.employeeForm.patchValue({
        UserId: emp.UserId,
        FirstName: emp.FirstName,
        LastName: emp.LastName,
        Comment: emp.Comment,
        Active: emp.Active,
        HasPicture: emp.HasPicture,
        Memberships: emp.Memberships
      });

      this.employeeApi.getContactTypes().subscribe(data => {
        data.forEach((d, i) => {
          this.contactMap[d.ContactType] = i;
          (<FormArray>this.employeeForm.get('ContactInfoList')).push(
            new FormControl(d, { validators: [this.customContactValidator.bind(this)], updateOn: 'change' }));
        });
        emp.ContactInfoList.forEach(contact => {
          const index = this.contactMap[contact.ContactType];
          (<FormArray>this.employeeForm.get('ContactInfoList')).at(index).patchValue({
            ...contact
          });
        });
      }, (error) => console.log(error));
    });
  }

  getTimeStamp(): number {
    return new Date().getTime();
  }

  async getEmployeeObj() {
    if (this.employee && this.isExistingEmployee) {
      return this.employee;
    } else {
      const newEmployee: EditEmployeeModel = {
        UserId: '',
        FirstName: '',
        LastName: '',
        Comment: '',
        Active: true,
        Memberships: [],
        ContactInfoList: [],
        HasPicture: false
      }
      return newEmployee;
    }
  }


  customContactValidator(control: AbstractControl): { [key: string]: any } | null {
    if (this.employeeForm === undefined || control.value === undefined) {
      (<FormArray>this.employeeForm.get(this.contactErrorId)).setErrors({ 'contact': 'err' });
    }
    if ((control.value.Value === null || control.value.Value === '') && control.value.Required === true) {
      (<FormArray>this.employeeForm.get(this.contactErrorId)).setErrors({ 'contact': false });
    } else {
      (<FormArray>this.employeeForm.get(this.contactErrorId)).setErrors(null);
    }
    return null;
  }

  customSetValue(value, input) {
    this.employeeForm.get('ContactInfoList').get(value.key).setValue({ ...value.value, 'Value': input.target.value });
  }
  trackByFn(index: any, item: any) {
    return index;
  }

  uniqueUserIdValidator(control: AbstractControl): { [key: string]: any } | null {
    if (this.employeeForm === undefined || control.value === undefined || this.employee === undefined) {
      return { 'useridstatus': false };
    }
    if (control.value === this.employee.UserId) {
      return null;
    }
    if (control.value.length > 4) {
      this.employeeApi.isUnique(control.value).subscribe(isUnique => {
        if (isUnique) {
          return null;
        } else {
          control.setErrors({ 'useridstatus': 'duplicate' })
        }
      },
        (err) => {
          control.setErrors({ 'useridstatus': 'error' })
        });
    } else {
      control.setErrors({ 'useridstatus': false })
    }
  }

  readFile(event: Event) {

    this.newImageFile = null;

    const fileElement = <HTMLInputElement>event.target;

    if (!fileElement.files || !fileElement.files[0]) {
      console.log('No files selected');
      this.imageSrc = null;
    }

    const file = fileElement.files[0];

    if (!file.type.match(/image.*/)) {
      throw Error('Must choose an image file!');
    }

    this.newImageFile = file;
    this.employee.HasPicture = true;
    this.employeeForm.get('HasPicture').setValue(true);
    this.employeeForm.get('HasPicture').disable();
    const reader = new FileReader();

    reader.onload = e => this.imageSrc = <string>reader.result;
    reader.readAsDataURL(file);

  }

  clearEmployeeImage() {
    this.employee.HasPicture = false;
    this.employeeForm.get('HasPicture').setValue(false);
    this.employeeForm.get('HasPicture').disable();
    this.imageSrc = `${environment.baseUrlReonApi}employee/Image?id=None`
  }

  deleteEmployee() {
    if (!this.isExistingEmployee) {
      return;
    }
    this.employeeApi.deleteEmployee(this.employee.UserId).subscribe(data => {
      this.router.navigate(['/Employee/Search'])
    })
  }

  onSubmit() {
    this.saveError = false;
    this.errorMessage = null;
    const missingContactInfo = (<FormArray>this.employeeForm.get('ContactInfoList')).value.find(el => {
      return el.Required && (el.Value === '' || el.Value === undefined)
    })
    if (missingContactInfo) {
      (<FormArray>this.employeeForm.get(this.contactErrorId)).setErrors({ 'contact': false });
      return;
    }
    if (this.employeeForm.invalid) {
      return;
    }
    this.updateEmployee((<FormGroup>this.employeeForm).getRawValue());
  }

  updateEmployee(employeeData) {
    const fd = new FormData();
    fd.append('employeeData', JSON.stringify(employeeData));

    if (this.newImageFile) {
      fd.append('imageFile', this.newImageFile);
    }
    this.is_saving = true;
    this.employeeApi.saveEmployee(fd).subscribe(r => {
      this.is_saving = false;
      if (!r.Ok) {
        this.saveError = true;
        this.errorMessage = r.ExceptionList;
        console.log('Exceptionlist', r.ExceptionList);
        throw Error('Error during save');
      } else {
        const now = new Date(Date.now());
        this.lastSave = ('0' + now.getHours()).slice(-2) + ':' + ('0' + now.getMinutes()).slice(-2);
        this.router.navigate(['/Employee/Edit/'], { queryParams: { 'userId': this.employeeForm.get('UserId').value } });
      }
    }, error => {
      this.saveError = true;
      this.is_saving = false;
      throw Error(error);
    });
  }

}
