import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';

import { Observable } from "rxjs";
import { environment } from 'environments/environment';

@Injectable()
export class NmwCalendarEventsApi {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) {}

  getNmwCalendarEventFile(): Observable<FileModel> {
    return this.http
      .get<FileModel>(
        this.baseUrlReonApi +
          "morningmeeting/GetNordicMorningWrapCalendarEventFile",
        {
          withCredentials: true
        }
      );
  }

  updateNmwCalendarEventFile(file: File): Observable<FileModel> {
    let formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.http
      .post<FileModel>(
        this.baseUrlReonApi +
          "morningmeeting/UpdateNordicMorningWrapCalendarEventFile",
        formData,
        { withCredentials: true }
      );
  }

  handleError(error: any): Observable<{}> {
    console.log(error);
    return error;
  }
}

export interface FileModel {
  FileName: string;
  FilePath: string;
  Type: string;
  LastModified: string;
  DownloadLink: string;
  Exists: boolean;
}
