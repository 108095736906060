<h2>Admin for Securities Services</h2>
<div style="width:600px">
  <div class="form-group row">
    <div class="col-10">
      <button [disabled]="isDownloadDisabled()" class="btn btn-outline-warning" (click)="downloadSecServ()">Renew APSIS download</button>
      <div *ngIf="isDownloadDisabled()">
        <div class="loader"></div>
      </div>
      <div *ngIf="mailingListAccountLog">
        <span>Download complete! {{mailingListAccountLog.LogMessage}}</span>
      </div>

    </div>
  </div>
</div>
<div class="table-wrapper">
  <div>
    <h2>Articles and Newsletters - {{amount}}</h2>
  </div>
  <div *ngIf="!newsletterAll">
    <div class="loader"></div>
  </div>
  <table class="simple-table" sortable="True">
    <thead>
      <tr style="font-weight: bold; border-bottom: 1px solid black;">
        <th></th>
        <th>Name</th>
        <th>Subject</th>
        <th>Created</th>
        <th>Updated</th>
        <th>Preview</th>
        <th>Show on REON</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of newsletterAll">
        <td><button class="btn" id="copyUrl" #url value="{{baseUrlReon}}securitiesservices/library/{{i.ReonUrl}}" (click)="copyLink(url.value)">Copy</button></td>
        <td>{{i.Name}}</td>
        <td style="text-align: left">{{i.Subject}}</td>
        <td><span>{{i.Created | date:'yyyy-MM-dd HH:mm:ss'}}</span></td>
        <td><span>{{i.Updated | date:'yyyy-MM-dd HH:mm:ss'}}</span></td>
        <td><a id="preview" target="_blank" href="{{baseUrlReon}}securitiesservices/library/{{i.ReonUrl}}" hreflang="SV" title="{{i.Name}} (MakrosvepetPlus nyhetsbrev)" translate="no">{{i.Name}}</a></td>
        <td><input [disabled]="sending == true" type="checkbox" [(ngModel)]="i.visibleReon" (change)="setVisibilityNewsLetter(i)"></td>
      </tr>
    </tbody>
  </table>
  <button [hidden]="amount != '20 latest'" class="btn btn-outline-primary" (click)="getNewsletterList()">Load everything</button>
</div>

<div>
  <div>
    <h2>Log</h2>
  </div>

  <div class="table-wrapper">
    <p *ngIf="!mailingListAccountLogHistory">Loading...</p>
    <div *ngIf="mailingListAccountLogHistory">
      <div>
        <table class="newsletter-table">
          <thead>
            <tr style="font-weight: bold; border-bottom: 1px solid black;">
              <th>Id</th>
              <th>Created</th>
              <th>Status</th>
              <th>Status text</th>
          </thead>
          <tr *ngFor="let i of mailingListAccountLogHistory">
            <td>{{i.Id}}</td>
            <td>
              <span>{{i.Created | date:'yyyy-MM-dd HH:mm'}}</span>
            </td>
            <td>{{i.Status}}</td>
            <td>{{i.LogMessage}}</td>
          </tr>
        </table>
      </div>
    </div>
    <br />
  </div>
</div>

