<div class="file-manager">
    <div class="row">
        <div class="col-12">
            <div class="header">
                <span class="title">Administrate files
                    <button mat-button (click)="GetFileInfo()">
                        <mat-icon style="color:white;" *ngIf="!isLoading">refresh</mat-icon>
                    </button>
                    <mat-spinner style="color:white;" *ngIf="isLoading" [diameter]="20" mode="indeterminate">
                    </mat-spinner>
                </span>
            </div>

            <table mat-table [dataSource]="fileList" style="width: 100%;" *ngIf="fileList">

                <ng-container matColumnDef="icon">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element" style="word-break:normal;">
                        <mat-icon>{{getFileIcon(element.Type)}}</mat-icon>
                    </td>
                </ng-container>
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef> Name</th>
                    <td mat-cell *matCellDef="let element" style="word-break:normal;">
                        <span *ngIf="element.Exists">{{element.Name}}</span> 
                        <span *ngIf="!element.Exists">missing file: {{element.Path}}</span> 
                    </td>
                </ng-container>
                <ng-container matColumnDef="Size">
                    <th mat-header-cell *matHeaderCellDef> Size </th>
                    <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.Size}}</td>
                </ng-container>
                <ng-container matColumnDef="Created">
                    <th mat-header-cell *matHeaderCellDef> Uploaded </th>
                    <td mat-cell *matCellDef="let element" style="word-break:normal;">
                        {{ element.Created | date:'short' }}</td>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <label class="btn btn-outline-primary btn-file" *ngIf="!element.Loading">
                            <mat-icon>arrow_upward</mat-icon>Replace
                            <input type="file" hidden (change)="readFile($event, element);">
                        </label>
                        <mat-spinner style="display: flex" [diameter]="20" mode="indeterminate" *ngIf="element.Loading">
                        </mat-spinner>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-row"></tr>
            </table>
            <mat-error *ngIf="errors && errors != [] && errors != ''">
                <pre>Error: {{errors | json}}</pre>
            </mat-error>
        </div>
    </div>
</div>