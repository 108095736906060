<div class="mm-manager">
  <div class="row">
    <div class="col-12">
      <div class="header">
        <span class="subtitle">
          Open until:
          <a> {{ deadline | date: "MMM, d HH:mm" }}</a>
        </span>
        <span class="title">Morning meeting agenda
          <button mat-button (click)="loadMMContributions()">
            <mat-icon style="color:white;" *ngIf="!isLoading">refresh</mat-icon>
          </button>
          <mat-spinner style="color:white;" *ngIf="isLoading" [diameter]="20" mode="indeterminate">
          </mat-spinner>
        </span>
      </div>
      <mat-card>
        <table mat-table [dataSource]="morningmeetingList" style="width: 100%;">

          <ng-container matColumnDef="button">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <button mat-stroked-button color="accent" [disabled]="!canEdit(element)"
                (click)="editMM(element)">Edit</button>
            </td>
          </ng-container>
          <ng-container matColumnDef="Company">
            <th mat-header-cell *matHeaderCellDef> Company</th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.companyName}}</td>
          </ng-container>
          <ng-container matColumnDef="Headline">
            <th mat-header-cell *matHeaderCellDef> Headline </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.headline}}</td>
          </ng-container>
          <ng-container matColumnDef="file">
            <th mat-header-cell *matHeaderCellDef> PowerPoint file </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <button [disabled]="!element.attachmentId" mat-flat-button (click)="getAttachmet(element.attachmentId)">
                <mat-icon>save_alt</mat-icon>
              </button>
              
            </td>
          </ng-container>
          <ng-container matColumnDef="RecChange">
            <th mat-header-cell *matHeaderCellDef> Recommendation change </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <mat-checkbox class="example-margin" disabled="true" [checked]="element.recChange"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="FastComment">
            <th mat-header-cell *matHeaderCellDef> Fast comment </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">
              <mat-checkbox class="example-margin" disabled="true" [checked]="element.fastComment"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="Analyst">
            <th mat-header-cell *matHeaderCellDef> Analyst </th>
            <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.analystId}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-row"></tr>
        </table>
        <div *ngIf="morningmeetingList.length === 0">
          <p>The agenda is empty. </p><br />
        </div>

        <mat-divider></mat-divider>
        <mat-card-actions>
          <button mat-flat-button color="primary" (click)="editMM(null)">New</button>
        </mat-card-actions>
        <mat-error *ngIf="error && error != [] && error != ''">
          <pre>Error: {{error | json}}</pre>
        </mat-error>
      </mat-card>
    </div>
  </div>
</div>