import { RouterModule, Routes } from "@angular/router";
import { SearchComponent } from "./user-search/SearchComponent";
import { UserTabsComponent } from "./user-tabs.component";
import { AuthorizeGuard } from 'api-authorization/authorize.guard';
import { EmailConfirmedComponent } from './active/active.component';

const routes: Routes = [
  {
    path: "User",
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
      { path: "New", component: UserTabsComponent },
      { path: "Edit", component: UserTabsComponent },
      { path: "Search", component: SearchComponent },
      { path: "Active", component: EmailConfirmedComponent }
    ]
  }
];

export const routing = RouterModule.forChild(routes);
