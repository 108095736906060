<nav mat-tab-nav-bar>
  <mat-tab-group dynamicHeight style="width: 90%">
    <mat-tab label="User" *ngIf="user !== undefined || isExistingUser === false">
      <br>
      <user-view [user]="user" [isExistingUser]="isExistingUser" (userUpdated)="userUpdated($event)"></user-view>
    </mat-tab>

    <mat-tab label="Email subscriptions" *ngIf="user !== undefined && isExistingUser !== false">
      <user-email [user]="user"></user-email>
    </mat-tab>

    <mat-tab label="Statistics" *ngIf="user !== undefined && isExistingUser !== false">
      <user-statistics [user]="user"></user-statistics>
    </mat-tab>
  </mat-tab-group>
</nav>