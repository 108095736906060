<div class="container-fluid">
    <div class="row" style="margin-top:20px;"></div>
    <div class="col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title> Participant requests </mat-card-title>
                <mat-card-subtitle> view and administrate requests for event participation</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12 col-lg-2" style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
                        <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                            <mat-card-subtitle> Parameters</mat-card-subtitle>
                            <mat-divider></mat-divider>
                            <div class="col-12">
                                <mat-form-field class="field-full-width" appearance="fill">
                                    <mat-label>Status</mat-label>
                                    <mat-select name="status" formControlName="Status">
                                        <mat-option>--Any--</mat-option>
                                        <mat-option *ngFor="let type of responseTypes" [value]="type">
                                            {{type}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                        </form>

                    </div>
                    <div class="col-md-12 col-lg-10">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Filter</mat-label>
                                <input matInput placeholder="Filter requests" [formControl]="filterModel"
                                    (ngModelChange)="updateFilterModel($event)">
                                    <mat-icon matPrefix>filter_alt</mat-icon>
                            </mat-form-field>
                            <mat-spinner *ngIf="loading" [diameter]="20" mode="indeterminate"></mat-spinner>
                            <pre *ngIf="error" style="color:red;">{{error | json}}</pre>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-label *ngIf="participantList.data">{{participantList.data.length}} results</mat-label>
                        <div class="col-12">
                            <table mat-table [dataSource]="participantList" style="width: 100%;" *ngIf="participantList"  matSort 
                            (matSortChange)="sortData($event)" matSortActive="EditDate" matSortDirection="desc" matSortDisableClear multiTemplateDataRows>

                                <ng-container matColumnDef="status">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <mat-icon>{{ element.UserEmail == null || element.EventName == null ? 'warning' : '' }}</mat-icon>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="User email">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> User email </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> {{element.UserEmail ? element.UserEmail : element.UserId }} </td>
                                </ng-container>
                                <ng-container matColumnDef="Event name">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Event name </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> {{element.EventName ? element.EventName :  element.EventId}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Status">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Status </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.Status }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="Message">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Message </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> {{element.Message }} </td>
                                </ng-container>

                                <ng-container matColumnDef="EditDate">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> EditDate </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> {{element.EditDate | date:'shortDate'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <button mat-icon-button (click)="toggleElement(element)">
                                            <mat-icon>{{ expandedElement === element ? 'cancel' : 'edit' }}</mat-icon>
                                          </button>
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="expandedDetail">
                                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                      <div class="expandable-element-detail"
                                           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <div class="expandable-element-description">
                                            <form [formGroup]="editForm">
                                                <mat-card-subtitle> Respond to request</mat-card-subtitle>
                                                <p style="color:red;">{{element.UserEmail ? null :  'warning: no user with that ID found'}}</p>
                                                <p style="color:red;">{{element.EventName ? null :  'warning: no event with that ID found'}}</p>
                                                <div class="col-12" *ngIf="element.EventInfo">
                                                    <p>Event title: {{element.EventName}}</p>
                                                    <p>Event description: {{element.EventDescription}}</p>
                                                </div>
                                                <div class="row col-12">
                                                    <div class="col-5">
                                                        <mat-form-field class="field-full-width" appearance="fill">
                                                            <mat-label>Status</mat-label>
                                                            <mat-select name="status" formControlName="Status">
                                                                <mat-option *ngFor="let type of responseTypes" [value]="type">
                                                                    {{type}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-5">
                                                        <mat-form-field class="field-full-width" appearance="outline">
                                                            <mat-label>Message</mat-label>
                                                            <textarea type="text" matInput placeholder="Hello..." formControlName="Message"></textarea>
                                                        </mat-form-field>
                                                        <mat-hint>Attach an optional message with your response</mat-hint>
                                                    </div>
                                                    <div class="col-2">
                                                        <button type="submit" mat-raised-button color="primary" (click)="onSave()">
                                                            <mat-icon>upgrade</mat-icon> Save
                                                        </button>
                                                        <mat-spinner *ngIf="isSaving" [diameter]="20" mode="indeterminate"></mat-spinner>
                                                        <mat-hint *ngIf="saveSuccess" style="background-color: white;">
                                                            <mat-icon style="color:green;">done</mat-icon>
                                                        </mat-hint>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <mat-form-field>
                                                        <mat-label>More</mat-label>
                                                        <mat-select name="delete">
                                                            <mat-option (click)="deleteRequest()"
                                                                style="background-color: rgba(255, 0, 0, 0.377);">
                                                                <mat-icon matTooltip="This will remove this request without notice to the requesting user. ">info</mat-icon>
                                                                Delete request
                                                            </mat-option>
                                                            <mat-option></mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <pre *ngIf="errorMessage" style="color:red;"><code>{{ errorMessage | json }}</code></pre>
                                                </div>
                                            </form>
                                        </div>
                                      </div>
                                    </td>
                                  </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                    class="expandable-element-row"
                                    [class.expandable-expanded-row]="expandedElement === element" >
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-detail-row"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 100, 1000]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>
