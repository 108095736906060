import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ContentApiService } from './content-api.service'
import { Disclaimer } from "./model";
import { environment } from '../../environments/environment';


// Disclaimer
@Component({
  selector: 'content-disclaimer',
  templateUrl: 'disclaimer.component.html'
})
export class DisclaimerComponent
{
  disclaimers: Disclaimer[];
  selectedDisclaimer: Disclaimer;
  file: File;

  constructor(private contentApi: ContentApiService, private router: Router)
  { }

  ngOnInit()
  {
    this.getDisclaimers();
  }

  selectDisclaimer(d: Disclaimer): void
  {
    if (this.selectedDisclaimer)
      this.selectedDisclaimer.selected = false;
    if (!d)
      d = { Id: 0, Name: "<new disclaimer>", ContentType: 0 };
    this.selectedDisclaimer = d;
    this.selectedDisclaimer.selected = true;
  }

  getDisclaimers()
  {
    this.contentApi.getDisclaimers().subscribe(
      disclaimers =>
      {
        this.disclaimers = disclaimers.sort((a, b) => { return a.TopMenu > b.TopMenu ? 1 : (a.TopMenu < b.TopMenu ? -1 : (a.SortOrder > b.SortOrder ? 1 : (a.SortOrder < b.SortOrder ? -1 : 0))); });
        this.disclaimers.forEach(x =>
        {
          if (!x.ContentType)
            x.ContentType = 0;
        });
      }
    );
  }
  newDisclaimerSaved(event)
  {
    this.getDisclaimers();
    this.selectedDisclaimer = null;
  }
}

//TODO:Flytta till bättre plats
declare class nicEditor
{
  constructor(a: any);
  panelInstance(elementName: string): nicEditor;
  removeInstance(elementName: string): nicEditor;
  instanceById(elementName: string): nicEditorInstanse;
}
declare class nicEditorInstanse
{
  getContent(): string;
  setContent(html: string): void;
  saveContent(); void;//syncs the content of the editor with the textarea value
}

@Component({
  selector: 'content-disclaimer-edit',
  templateUrl: 'disclaimerEdit.component.html'
})
export class DisclaimerEditComponent implements OnInit
{
  _disclaimer: Disclaimer;
  discl: Disclaimer;
  htmlContentArea: nicEditor;
  selectedPdf: File;
  pdfUrl: SafeResourceUrl;
  showHelp = false;

  constructor(private contentApi: ContentApiService, private route: ActivatedRoute, private sanitizer: DomSanitizer)
  {
  }

  ngOnInit(): void
  {
    this.htmlContentArea = new nicEditor({ fullPanel: true, iconsPath: 'assets/nicEditorIcons.gif' }).panelInstance('htmlContentArea');

    if (this.discl && this.discl.HtmlContent)
      this.htmlContentArea.instanceById('htmlContentArea').setContent(this.discl.HtmlContent || "");
  }
  @Output() newDisclaimerSaved = new EventEmitter<Disclaimer>();
  @Input()
  set disclaimer(d: Disclaimer)
  {
    this._disclaimer = d;
    this.discl = d ?
      { Id: d.Id, Name: d.Name, ContentType: d.ContentType, TopMenu: d.TopMenu, SortOrder: d.SortOrder, Url: d.Url, HtmlContent: d.HtmlContent, PdfContent: d.PdfContent } :
      { Name: "Not set" };
    if (this.htmlContentArea)
      this.htmlContentArea.instanceById('htmlContentArea').setContent(this.discl.HtmlContent || "");

    this.setPdfData(d);
  }
  get disclaimer(): Disclaimer { return this._disclaimer; }

  setPdfData(d: Disclaimer)
  {
    if (d.ContentType == 1)
    {
      let pdfUrl = environment.baseUrlReonApi + "content/GetDisclaimerPdfFile?url=" + d.Url;
      this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl);
    }
    else
    {
      this.pdfUrl = null;
    }
  }

  fileChange(event)
  {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0)
    {
      this.selectedPdf = fileList[0];
    }
  }

  saveDisclaimer()
  {
    if (!this.discl.Name || !this.discl.SortOrder || !this.discl.TopMenu || !this.discl.Url)
    {
      alert("Du måste fylla i alla fält");
      return;
    }

    if (this.selectedPdf && this.discl.ContentType == 1)
    {
      //Has a new file and is pdf
      this.discl.HtmlContent = null;
      this.contentApi.savePdfDisclaimer(this.selectedPdf, this.discl).subscribe(() =>
      {
        this.updateDisclaimerData(this._disclaimer, this.discl);
      });
    }
    else
    {
      this.discl.HtmlContent = this.htmlContentArea.instanceById('htmlContentArea').getContent();
      this.contentApi.saveDisclaimer(this.discl).subscribe(() =>
      {
        this.updateDisclaimerData(this._disclaimer, this.discl);
      });
    }
  }
  updateDisclaimerData(to: Disclaimer, from: Disclaimer)
  {
    //to.Id = from.Id;
    to.Name = from.Name;
    to.ContentType = from.ContentType;
    to.TopMenu = from.TopMenu;
    to.SortOrder = from.SortOrder;
    to.Url = from.Url;
    to.HtmlContent = from.HtmlContent;
    to.PdfContent = from.PdfContent;

    this.setPdfData(to);

    if (!from.Id)
      this.newDisclaimerSaved.emit(from);
  }
  deleteDisclaimer()
  {
    if (confirm("Are you sure you want to delete this?"))
    {
      this.contentApi.deleteDisclaimer(this.discl.Id).subscribe(() =>
      {
        this.newDisclaimerSaved.emit(this.discl);
      });
    }
  }

  toggleHelp()
  {
    this.showHelp = !this.showHelp;
  }
  copyCommand(text: string, event: Event)
  {
    var a = document.getElementById("copyTextFix") as HTMLInputElement;
    a.value = "[[" + text + "]]";
    a.style.visibility = "visible";
    a.select();
    document.execCommand('copy');
    a.style.visibility = "hidden";
    this.showHelp = false;
  }
}
