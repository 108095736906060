import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CommandResponse } from 'app/commandResponse';
import { environment } from 'environments/environment';

@Injectable()
export class BondApiService {
    baseUrlReonApi = environment.baseUrlReonApi;

    constructor(private http: HttpClient) { }

    loadExistingFileInfo(): Observable<any[]> {
        return this.http
            .get<any[]>(`${this.baseUrlReonApi}BondIndices/FileInfo`,
                { withCredentials: true });
    }

    uploadFile(form: FormData): Observable<CommandResponse> {
        return this.http.post<CommandResponse>(`${this.baseUrlReonApi}BondIndices/File`, form,
            { withCredentials: true });
    }

}

export interface BondFileInfo {
    Name: string;
    Path: string;
    Created: Date;
    DownloadLink: string;
    Exists: boolean;
    Type: string;
    Size: string;
    Loading: boolean;
}
