import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { KeyValuePair } from '../_models/keyValuePair.model';
import { Rule, RuleCategoryEnum } from '../_models/rule.model';
import { CreateRuleService } from './_service/create-rule.service';

@Component({
  selector: 'app-create-rule',
  templateUrl: './create-rule.component.html',
  styleUrls: ['./create-rule.component.sass'],
  host: { 'style': 'display: block; position: relative' },
})
export class CreateRuleComponent implements OnInit {
  @Input() existingRule: Rule;
  @Input() userId: string;

  @Output() rule: EventEmitter<Rule> = new EventEmitter();

  STATE: "EDIT" | "CREATE" = "CREATE";

  selectedResearchType: KeyValuePair;
  researchTypes: KeyValuePair[];

  errorString: string = null;
  isLoading: boolean = false;

  _rule: Rule;

  constructor(private createRuleService: CreateRuleService) {
    this.researchTypes = Object.keys(RuleCategoryEnum).map(key => { return { value: RuleCategoryEnum[key], key: key } });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.existingRule?.currentValue) {
      this.selectedResearchType = this.researchTypes.find(x => x.value == changes.existingRule.currentValue.categoryCode)//Object.keys(RuleCategoryEnum).find(key => RuleCategoryEnum[key] === changes?.existingRule?.currentValue.categoryCode)
      this.reset()
      this._rule = new Rule(
        changes.existingRule.currentValue.id
        , this.selectedResearchType.key as RuleCategoryEnum
        , changes.existingRule.currentValue.publicationTypes
        , changes.existingRule.currentValue.sectors
        , changes.existingRule.currentValue.countries
        , changes.existingRule.currentValue.companies
        , changes.existingRule.currentValue.analysts
        , changes.existingRule.currentValue.currencies
        , changes.existingRule.currentValue.marketCap
        , changes.existingRule.currentValue.languages
        , changes.existingRule.currentValue.regions)
      this.STATE = "EDIT"
    }
  }

  researchTypeChange({ value }) {
    this.selectedResearchType = value
    this.reset()
  }
  
  saveOrUpdateRule() {
    if(this.STATE == "CREATE")
      this.saveRule()
    else
      this.updateRule()
  }

  private saveRule(){
    this.errorString = null
    let ruleCheck = this._rule.isError()
    if (ruleCheck.isError) {
      this.errorString = ruleCheck.errorText
      setTimeout(() => this.errorString = null, 2000)
      return
    }
    this.isLoading = true
    this.createRuleService.createRule(this._rule, this.userId).pipe(
      catchError(x => {
        this.errorString = x
        this.isLoading = false
        return null
      })
    ).subscribe(newRule => {
      if (newRule) {
        this.rule.emit(newRule as Rule)
        this.reset()
      }
      this.isLoading = false
    })
  }

  private updateRule(){
    this.errorString = null
    let ruleCheck = this._rule.isError()
    if (ruleCheck.isError) {
      this.errorString = ruleCheck.errorText
      setTimeout(() => this.errorString = null, 2000)
      return
    }
    this.isLoading = true
    this.createRuleService.updateRule(this._rule).pipe(
      catchError(x => {
        this.errorString = x
        this.isLoading = false
        return null
      })
    ).subscribe(newRule => {
      if (newRule) {
        this.rule.emit(newRule as Rule)
        this.reset()
      }
      this.isLoading = false
    })
  }

  resetRule(){
    this.reset()
    this.rule.emit(null)
  }

  private reset() {
    this.STATE = "CREATE"
    this._rule = new Rule(null, this.selectedResearchType.key as RuleCategoryEnum)
  }

}
