import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KeyValuePair } from '../../_models/keyValuePair.model';

@Component({
  selector: 'app-rule-select',
  templateUrl: './rule-select.component.html',
  styleUrls: ['./rule-select.component.sass']
})
export class RuleSelectComponent implements OnInit {
  @Input() name: string;
  @Input() selectedValues: Array<KeyValuePair>;
  @Input() options: Array<KeyValuePair>;
  @Input() disabled: boolean;

  @Output() selections: EventEmitter<Array<KeyValuePair>> = new EventEmitter<Array<KeyValuePair>>();

  formControl: FormControl = new FormControl();

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("ruleSelect", changes, this.options)
    if (changes?.disabled?.previousValue != changes?.disabled?.currentValue) {
      changes.disabled.currentValue ? this.formControl.disable() : this.formControl.enable()
    }
    if (changes?.selectedValues?.previousValue != changes?.selectedValues?.currentValue) {
      this.formControl.setValue(changes.selectedValues.currentValue);
    }
  }

  emitSelect() {
    this.selections.emit(this.formControl.value)
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.key === o2.key && o1.value === o2.value;
  }

}
