import { Component } from "@angular/core";
import { StatisticsApiService } from "./statistics-api.service";
import { UserStats, StatsLogType } from "./model";

@Component({
  selector: "admin-failedLogins",
  templateUrl: "failed-logins.component.html"
})
export class FailedLoginsComponent {
  failedLoginStats: UserStats | undefined;
  startDate: Date;
  endDate: Date;

  constructor(private contentApi: StatisticsApiService) {
    this.endDate = new Date(Date.now());
    const startDateTemp = new Date(Date.now());
    startDateTemp.setFullYear(startDateTemp.getFullYear() - 1);
    this.startDate = startDateTemp;
  }

  submit() {
    this.contentApi
      .getUserStats({
        From: this.startDate.toISOString(),
        To: this.endDate.toISOString(),
        Types: [StatsLogType.UserLoginFailed],
        PageSize: 10000
      })
      .subscribe(stats => {
        this.failedLoginStats = stats;
        return stats;
      });
  }

  convertStr2Date(dateStr: string) {
    const dateVal = new Date(dateStr);
    return dateVal;
  }
}
