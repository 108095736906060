import { Component } from '@angular/core';
import { ThirdPartyEventApiService } from './event-calendar-third-api.service';
import { FileData } from 'app/common/file-data';

//TODO: Add fileSaved handling

@Component({
    selector: 'event-calendar-third',
    templateUrl: 'event-calendar-third.component.html'
})
export class EventCalendarThirdComponent {

    fileSaved = false;

    saveData = (function () {
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			return function (blob: any, fileName: string) { window.navigator.msSaveOrOpenBlob(blob, fileName); };
		} else {
			const a = document.createElement('a');
			document.body.appendChild(a);
			a.style.display = 'none';
			return function (blob: any, fileName: string) {
				const url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);
			};
		}
    }());
    constructor(public api: ThirdPartyEventApiService) { }

    getExcel() {
        return this.api.getExcel()
            .subscribe((fileData: FileData) => {
                this.saveData(fileData.blob, fileData.fileName);
            });
    }

}
