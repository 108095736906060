import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { UserApiService } from '../user-api.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-active',
  templateUrl: './active.component.html',
  styleUrls: ['./active.component.css']
})
export class EmailConfirmedComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  disableActiveStatus = false;

  loading = true;
  displayedColumns: string[] = ['Email', 'Confirmed', 'action'];
  userList = new MatTableDataSource(<{ 'email': string, 'confirmed': boolean }[]>[]);
  confirmedStatus = false;
  filterModel;

  constructor(
    private auth: AuthorizeService,
    private userApi: UserApiService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userApi.getSSOEmailConfirmed(this.auth.getEmailConfirmedPath()).subscribe(dataList => {
      this.userList.data = dataList.filter(data => data.confirmed === false);
      this.userList.paginator = this.paginator;
      this.loading = false;
    });
  }

  selectUser(email: string): void {
    this.router.navigate(['/User/Edit'], { queryParams: { email: encodeURIComponent(email) } });
  }

  updateFilterModel(value) {
    this.userList.filter = value.trim();
  }

  sortData(sort: Sort) {
    const data = this.userList.data.slice();
    if (!sort.active || sort.direction === '') {
      this.userList.data = data;
      return;
    }

    this.userList.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Email': return compare(a.email.toLowerCase(), b.email.toLowerCase(), isAsc);
        case 'Confirmed': return compare(a.confirmed, b.confirmed, isAsc);
        default: return 0;
      }
    });
  }
}
function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
