import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { CommandResponse } from 'app/commandResponse';
import { environment } from 'environments/environment';

@Injectable()
export class EmployeeApiService {
    baseUrlReonApi = environment.baseUrlReonApi;

    constructor(private http: HttpClient) { }

    getContactTypes(): Observable<ContactInfoModel[]> {
        return this.http
            .get<ContactInfoModel[]>(`${this.baseUrlReonApi}employee/GetContactInfoTypes`,
                { withCredentials: true });
    }

    getTeams(): Observable<TeamModel[]> {
      return this.http
        .get<TeamModel[]>(`${this.baseUrlReonApi}employee/GetAllTeams`,
          { withCredentials: true });
    }
    getEventTeams(): Observable<TeamModel[]> {
        return this.http.get<TeamModel[]>(this.baseUrlReonApi + 'Employee/EventTeams',
          { withCredentials: true });
      }
    searchEmployees(active?: boolean): Observable<EmployeeModel[]> {
        return this.http
            .get<EmployeeModel[]>(`${this.baseUrlReonApi}employee/Search?active=${active}`,
                { withCredentials: true });
    }
    searchEmployeesUserId(userId: string, active?: boolean): Observable<EditEmployeeModel[]> {
        return this.http
            .get<EditEmployeeModel[]>(`${this.baseUrlReonApi}employee/SearchUserId?keyword=${userId}&active=${active}`,
                { withCredentials: true });
    }

    isUnique(userId: string): Observable<boolean> {
        return this.http
            .get<boolean>(`${this.baseUrlReonApi}employee/IsUnique?userId=${userId}`,
                { withCredentials: true });
    }
    getEmployee(userId: string): Observable<EditEmployeeModel> {
        return this.http
            .get<EditEmployeeModel>(`${this.baseUrlReonApi}employee/GetEditEmployee?userId=${userId}`,
                { withCredentials: true });
    }

    getEmployeeRegular(userId: string): Observable<EmployeeModel> {
        return this.http
            .get<EmployeeModel>(`${this.baseUrlReonApi}employee/GetEditEmployee?userId=${userId}`,
                { withCredentials: true });
    }

    saveEmployee(form: FormData): Observable<CommandResponse> {
        return this.http.post<CommandResponse>(`${this.baseUrlReonApi}employee/SaveEmployee`, form,
            { withCredentials: true });
    }

    saveTeam(team: TeamModel): Observable<StatusReport> {
        return this.http.post<StatusReport>(`${this.baseUrlReonApi}employee/SaveTeam`, team,
            { withCredentials: true });
    }

    deleteEmployee(id: string): Observable<boolean> {
        return this.http.delete<boolean>(`${this.baseUrlReonApi}employee/Delete/${id}`,
            { withCredentials: true });
    }

    deleteTeam(id: string): Observable<StatusReport> {
        return this.http.delete<StatusReport>(`${this.baseUrlReonApi}employee/DeleteTeam/${id}`,
            { withCredentials: true });
    }

}

export interface EmployeeModel {
    UserId: string;
    FirstName: string;
    LastName: string;
    Phone: string;
    Mobile: string;
    Email: string;
    Active: boolean;
    TeamTitle: string;
    IsAnalyst: boolean;
}

export interface EditEmployeeModel {
    UserId: string;
    FirstName: string;
    LastName: string;
    Comment: string;
    Active: boolean;
    Memberships: Membership[]
    ContactInfoList: Array<ContactInfoModel>;
    HasPicture: boolean;
}

export interface Membership {
    TeamId: string;
    TeamName: string;
    TeamDescripton: string;
    SortOrder: number;
    Title: string;
}

export interface ContactInfoModel {
    ContactType: string;
    ContactTypeGroup: string;
    Value: string;
    Required?: boolean;
}

export interface TeamModel {
    Id: string;
    TeamType: string;
    TeamName: string;
    Description: string;
    Members: TeamMemberModel[];
}
export interface TeamMemberModel extends EditEmployeeModel {
    Position: number;
    Title: string;
}
export enum enumStatus {
    Ok, Failed, OkWithExceptions
}
export class StatusReport {
    ExceptionList: string[];
    Status: enumStatus = enumStatus.Failed
}


