import { Component } from '@angular/core';
import { ContentApiService } from './content-api.service';
import { InternalPublicationAttachmentModel, UploadInternalPublicationAttachmentModel } from './model';

@Component({
  selector: 'content-internalPublications',
  templateUrl: 'internalPublications.component.html'
})
export class InternalPublicationsComponent {
  constructor(private contentApi: ContentApiService) { }

  latestAttachments: InternalPublicationAttachmentModel[] = [];

  defaultDocumentType: string = "ADHOC";

  newAttachmentData: UploadInternalPublicationAttachmentModel = { ContentType: null, DocumentType: this.defaultDocumentType, FileName: null };
  newAttachmentFile: File = null;
  saveError: boolean = false;
  lastSave: string = null;

  documentTypes: { Text: string, Value: string }[] =
    [
      { Text: "Latest Time Series", Value: "TIMESERIES" },
      { Text: "Planned Client Trips", Value: "PLANNEDCLIENTTRIPS" },
      { Text: "ADHOC", Value: "ADHOC" }
    ];

  isLoadingData: boolean = false;

  ngOnInit() {
    this.getTopLatestInternalPublicationAttachments();
  }

  getTopLatestInternalPublicationAttachments() {
    this.contentApi.GetTopLatestInternalPublicationAttachments()
      .toPromise()
      .then(latestList => {
        this.latestAttachments = latestList;
      });
  }

  readFile(event: Event) {
    this.newAttachmentFile = null;

    const fileElement = <HTMLInputElement>event.target;

    if (!fileElement.files || !fileElement.files[0]) {
      console.log("No files selected");
    }

    const file = fileElement.files[0];
    this.newAttachmentFile = file;

    this.newAttachmentData.ContentType = file.type;
    this.newAttachmentData.FileName = file.name;
  }

  clearFile() {
    this.newAttachmentData.ContentType = null;
    this.newAttachmentData.FileName = null;
    this.newAttachmentFile = null;
  }

  uploadDocument(event: Event) {
    this.saveError = null;
    this.lastSave = null;

    const fd = new FormData();

    fd.append("newAttachmentData", JSON.stringify(this.newAttachmentData));
    fd.append("fileData", this.newAttachmentFile);

    this.contentApi.uploadInternalPublicationAttachment(fd)
      .toPromise()
      .then(r => {
        if (!r.Ok) {
          this.saveError = true;
          console.log("Exceptionlist", r.ExceptionList);
          throw Error("Error during save");
        }

        if (r.Data) {
          this.clearFile();
          this.getTopLatestInternalPublicationAttachments();
        }

        var now = new Date(Date.now());

        this.lastSave = ("0" + now.getHours()).slice(-2) + ":" + ("0" + now.getMinutes()).slice(-2);
      }).catch(r => {
        this.saveError = true;
        throw Error(r);
      });
  }
}

