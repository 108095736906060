import { Component, OnInit, ViewChild } from '@angular/core';
import { EventApiService, EventParticipantStatus, EventParticipantRequestView } from '../event-api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-event-participants',
  templateUrl: './event-participants.component.html',
  styleUrls: ['./event-participants.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EventParticipantsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchForm = new FormGroup({
    Status: new FormControl(EventParticipantStatus[EventParticipantStatus.Pending], {}),
  });
  editForm = new FormGroup({
    RequestId: new FormControl('', {}),
    UserId: new FormControl('', {}),
    UserEmail: new FormControl('', {}),
    EventId: new FormControl('', {}),
    EventName: new FormControl('', {}),
    EventDescription: new FormControl('', {}),
    EditDate: new FormControl('', {}),
    Status: new FormControl('', {}),
    Message: new FormControl('', {}),
  });
  filterModel = new FormControl('');
  participantList = new MatTableDataSource(<EventParticipantRequestView[]>[]);
  responseTypes = Object.keys(EventParticipantStatus).map(
    key => EventParticipantStatus[key]).filter(value => typeof value === 'string') as string[];
  displayedColumns: string[] = ['status', 'User email', 'Event name', 'Status', 'Message', 'EditDate', 'action'];
  loading = false;
  error;
  expandedElement: EventParticipantRequestView | null;
  isSaving = false;
  errorMessage;
  saveSuccess;

  constructor(
    public eventApi: EventApiService,
  ) { }

  ngOnInit() {
    this.participantList.paginator = this.paginator;
    this.searchForm.valueChanges.subscribe(() => this.onSubmit());
    this.onSubmit();
  }
  toggleElement(element: EventParticipantRequestView) {
    this.expandedElement = this.expandedElement === element ? null : element
    if (this.expandedElement) {
      this.editForm.get('RequestId').setValue(element.RequestId);
      this.editForm.get('UserId').setValue(element.UserId);
      this.editForm.get('UserEmail').setValue(element.UserEmail);
      this.editForm.get('EventId').setValue(element.EventId);
      this.editForm.get('EventName').setValue(element.EventName);
      this.editForm.get('EventDescription').setValue(element.EventDescription);
      this.editForm.get('EditDate').setValue(element.EditDate);
      this.editForm.get('Status').setValue(element.Status);
      this.editForm.get('Message').setValue(element.Message);
    }
  }
  updateFilterModel(value) {
    this.participantList.filter = value.trim();
  }

  sortData(sort: Sort) {
    const data = this.participantList.data.slice();
    if (!sort.active || sort.direction === '') {
      this.participantList.data = data;
      return;
    }

    this.participantList.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'EditDate': return compare(a.EditDate, b.EditDate, isAsc);
        case 'Status': return compare(a.Status, b.Status, isAsc);
        case 'User email': return compare(a.UserEmail, b.UserEmail, isAsc);
        case 'Event name': return compare(a.EventName, b.EventName, isAsc);
        default: return 0;
      }
    });
  }
  onSubmit() {
    this.error = '';
    this.loading = true;
    this.eventApi.getEventParticipants(this.searchForm.value).subscribe(data => {
      this.participantList.data = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error);
      this.error = error.error;
    });
  }

  onSave() {
    this.errorMessage = '';
    this.isSaving = true;
    const tempVal = this.editForm.get('Status').value;
    this.editForm.get('Status').setValue(EventParticipantStatus[tempVal]);
    this.eventApi.updateParticipantRequest(this.editForm.value).subscribe(statusreport => {
      if (statusreport.Status !== 'Ok') {
        this.errorMessage = statusreport.ExceptionList.toString();
      }
      this.isSaving = false;
      this.editForm.get('Status').setValue(tempVal);
      this.saveSuccess = true;
    }, (error) => {
      this.isSaving = false;
      this.errorMessage = error.error;
      this.editForm.get('Status').setValue(tempVal);
    });
  }

  deleteRequest() {
    this.isSaving = true;
    this.eventApi.deleteRequest(this.editForm.get('RequestId').value).subscribe(() => {
      this.isSaving = false;
      this.saveSuccess = true;
      this.onSubmit();
    }, (error) => {
      this.isSaving = false;
      console.log(error);
      this.error = error.error;
    });
  }
}
function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
