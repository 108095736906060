<div class="box-container">
    <div class="row">
        <div class="col-xl-8 col-lg-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        {{ isExistingEvent ? "Edit event " : "Add event" }}
                        <mat-spinner *ngIf="isLoading" [diameter]="20" mode="indeterminate"></mat-spinner>
                    </mat-card-title>
                    <mat-card-subtitle>{{ isExistingEvent ? eventForm.get('EventId').value : "" }}</mat-card-subtitle>
                    <mat-card-subtitle *ngIf="isExistingEvent">Last edit:
                        {{ eventForm.get('EditDate').value | date:'medium'  }}</mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <mat-tab-group mat-align-tabs="start">
                        <mat-tab label="Information">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12">
                                        <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
                                            <mat-card-subtitle>General information</mat-card-subtitle>
                                            <div class="row">
                                                <div class="form-group col-lg-6 col-md-6">
                                                    <mat-form-field class="field-full-width" appearance="fill">
                                                        <mat-label>Category*</mat-label>
                                                        <mat-select name="category" formControlName="Category">
                                                            <mat-option value="Banner seminar">Banner seminar
                                                                <mat-icon matTooltip="Front page banner event">info
                                                                </mat-icon>
                                                            </mat-option>
                                                            <mat-option *ngFor="let cats of categories" [value]="cats">
                                                                {{cats}}
                                                            </mat-option>
                                                            <mat-select-trigger>
                                                                {{ this.eventForm.get('Category').value }}
                                                            </mat-select-trigger>
                                                        </mat-select>
                                                        <mat-hint>Category that decides what box the event will appear
                                                            under
                                                        </mat-hint>
                                                        <mat-error
                                                            *ngIf="eventForm.get('Category').hasError('required')">
                                                            A category is <strong>required</strong>.
                                                        </mat-error>
                                                        <mat-error
                                                            *ngIf="eventForm.get('Category').hasError('duplicate')">
                                                            A new category must be <strong>unique</strong>.
                                                        </mat-error>
                                                    </mat-form-field>

                                                    <div class="col-12">
                                                        <mat-hint (click)="add_new_category = true"
                                                            style="cursor: pointer; " *ngIf="!add_new_category">
                                                            Type new category<mat-icon>add</mat-icon>
                                                        </mat-hint>
                                                        <mat-hint (click)="add_new_category = false"
                                                            style="cursor: pointer; " *ngIf="add_new_category">
                                                            Close<mat-icon>not_interested</mat-icon>
                                                        </mat-hint>

                                                        <mat-form-field *ngIf="add_new_category" appearance="outline">
                                                            <mat-label>Category</mat-label>
                                                            <input matInput
                                                                (input)="setCustomCategory($event.target.value)">
                                                            <button *ngIf="add_new_category" mat-button matSuffix
                                                                mat-icon-button (click)="saveCustomCategory($event)">
                                                                <mat-icon>post_add</mat-icon>
                                                            </button>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="form-group col-lg-6 col-md-6">
                                                    <mat-form-field class="field-full-width" appearance="fill">
                                                        <mat-label>Status*</mat-label>
                                                        <mat-select name="status" formControlName="Status">
                                                            <mat-option *ngFor="let status of statusTypes"
                                                                [value]="status">
                                                                {{status}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-hint>Only open events will be shown on researchonline.se
                                                        </mat-hint>
                                                        <mat-error *ngIf="eventForm.get('Status').hasError('required')">
                                                            A status option is <strong>required</strong>.
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-group row col-12">
                                                <mat-form-field class="field-full-width" appearance="outline">
                                                    <mat-label>Title*</mat-label>
                                                    <input type="text" matInput formControlName="Name">
                                                    <mat-hint>Displayed at top and in all event lists</mat-hint>
                                                    <mat-error *ngIf="eventForm.get('Name').hasError('required')">
                                                        A title is <strong>required</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="form-group row col-12">
                                                <img class="" src="data:image/jpeg;base64,{{ bannerDisplay }}"
                                                    style="width:100%" *ngIf="bannerDisplay" />
                                                <mat-form-field class="field-full-width" appearance="outline">
                                                    <input type="file" (change)="onBannerChange($event.target.files)">
                                                    <mat-hint>Optional banner image. (Required for Banner events) </mat-hint>
                                                    <textarea matInput hidden></textarea>
                                                </mat-form-field>
                                            </div>
                                            <div class="form-group row col-12">
                                                <mat-form-field class="field-full-width" appearance="outline">
                                                    <mat-label>Description</mat-label>
                                                    <textarea style="width:100%; min-height: 200px;" matInput
                                                        id="htmlContentArea" name="htmlContentArea"
                                                        formControlName="Description"></textarea>
                                                    <mat-hint>Text displayed when user clicks on event</mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </form>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
                                            <mat-card-subtitle>Date information</mat-card-subtitle>
                                            <div class="form-group row">
                                                <label class="col-2 userform_label">Start*</label>
                                                <div class="col-7">
                                                    <mat-form-field class="field-full-width" appearance="outline">
                                                        <input formControlName="StartDate" matInput
                                                            type="datetime-local" placeholder="Start date"
                                                            format-value="DD/MM/YYYY HH:mm">
                                                        <mat-hint>Dates and times will be displayed as is (no timezone
                                                            conversions)
                                                        </mat-hint>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-lg-2 col-md-4">
                                                    <mat-form-field class="field-full-width" floatLabel="auto"
                                                        appearance="none">
                                                        <mat-checkbox [formControl]="disableStartTimeInput"> Exclude
                                                            start time
                                                        </mat-checkbox>
                                                        <textarea matInput hidden></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-2 userform_label">End*</label>
                                                <div class="col-7">
                                                    <mat-form-field class="field-full-width" appearance="outline">
                                                        <input formControlName="EndDate" matInput type="datetime-local"
                                                            placeholder="End date">
                                                        <mat-hint>Dates and times will be displayed as is (no timezone
                                                            conversions)
                                                        </mat-hint>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-lg-2 col-md-4">
                                                    <mat-form-field class="field-full-width" floatLabel="auto"
                                                        appearance="none">
                                                        <mat-checkbox [formControl]="disableEndTimeInput"> Exclude end
                                                            time
                                                        </mat-checkbox>
                                                        <textarea matInput hidden></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </form>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
                                            <mat-card-subtitle>Location information</mat-card-subtitle>
                                            <div class="row">
                                                <div class="form-group col-lg-6 col-md-12" *ngIf="cities">
                                                    <mat-form-field class="field-full-width" appearance="outline">
                                                        <mat-label>City*</mat-label>
                                                        <input type="text" placeholder="City" matInput
                                                            formControlName="City" [matAutocomplete]="auto">
                                                        <mat-autocomplete #auto="matAutocomplete">
                                                            <mat-option *ngFor="let option of filteredCities | async"
                                                                [value]="option">
                                                                {{option}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-hint> Required city of event </mat-hint>
                                                        <mat-error *ngIf="eventForm.get('City').hasError('required')">
                                                            A city location is <strong>required</strong>
                                                        </mat-error>
                                                    </mat-form-field>
                                                </div>
                                                <div class="form-group col-lg-6 col-md-12" *ngIf="countries">
                                                    <mat-form-field class="field-full-width" appearance="outline">
                                                        <mat-label>Country</mat-label>
                                                        <input type="text" placeholder="Country" matInput
                                                            formControlName="Country" [matAutocomplete]="auto">
                                                        <mat-autocomplete #auto="matAutocomplete">
                                                            <mat-option *ngFor="let option of filteredCountries | async"
                                                                [value]="option">
                                                                {{option}}
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                        <mat-hint> Country of event</mat-hint>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div class="form-group row col-12" *ngIf="locations">
                                                <mat-form-field class="field-full-width" appearance="outline">
                                                    <mat-label>Location</mat-label>
                                                    <textarea type="text" matInput formControlName="Location"
                                                        [matAutocomplete]="auto"></textarea>

                                                    <mat-autocomplete #auto="matAutocomplete">
                                                        <mat-option *ngFor="let option of filteredLocations | async"
                                                            [value]="option">
                                                            {{option}}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-hint>Optional field for e.g. street address or virtual link
                                                    </mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </form>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <form [formGroup]="eventForm" (ngSubmit)="onSubmit()">
                                            <mat-card-subtitle>Additional information</mat-card-subtitle>
                                            <div class="row">
                                                <div class="form-group col-lg-6 col-md-12">
                                                    <mat-form-field class="field-full-width" appearance="outline">
                                                        <input type="file" id="file"
                                                            (change)="handleFileInput($event.target.files)">
                                                        <mat-hint> Attach an agenda or file to be displayed on the event
                                                            details
                                                            page </mat-hint>
                                                        <textarea matInput hidden></textarea>
                                                    </mat-form-field>
                                                    <a *ngIf="attachment" target="_self" href="{{attachment.Url}}"
                                                        download="{{attachment.FileName}}">
                                                        Download attached document</a>
                                                    <br>
                                                    <mat-form-field class="field-full-width" appearance="outline"
                                                        *ngIf="companyList">
                                                        <mat-label>Companies</mat-label>
                                                        <input type="text" placeholder="Name"
                                                            aria-label="Type and select" matInput
                                                            [matAutocomplete]="auto" [formControl]="companyControl">
                                                        <mat-hint> </mat-hint>
                                                        <mat-autocomplete #auto="matAutocomplete" multiple>
                                                            <mat-option
                                                                *ngFor="let company of filteredcompanyList | async"
                                                                [value]="company.Id">
                                                                <div (click)="optionClicked($event)">
                                                                    <mat-checkbox [checked]="company.selected"
                                                                        (change)="toggleSelectionCompany(company, $event)"
                                                                        (click)="$event.stopPropagation()">
                                                                        {{ company.Name }}
                                                                    </mat-checkbox>
                                                                </div>
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <mat-chip-list>
                                                        <mat-card-subtitle>
                                                            {{eventForm.get('GrowCompanyId').value.length}} selected
                                                        </mat-card-subtitle>
                                                        <mat-chip
                                                            *ngFor="let chip of eventForm.get('GrowCompanyId').value"
                                                            selected color="primary">
                                                            {{chip.Name ? chip.Name : chip.Id}}
                                                        </mat-chip>
                                                    </mat-chip-list>
                                                    <mat-form-field class="field-full-width" appearance="outline"
                                                        *ngIf="sectorsList">
                                                        <mat-label>Sectors</mat-label>
                                                        <input type="text" placeholder="Name"
                                                            aria-label="Type and select" matInput
                                                            [matAutocomplete]="auto" [formControl]="sectorControl">
                                                        <mat-hint> </mat-hint>
                                                        <mat-autocomplete #auto="matAutocomplete" multiple>
                                                            <mat-option
                                                                *ngFor="let sector of filteredSectorsList | async"
                                                                [value]="sector.Id">
                                                                <div (click)="optionClicked($event)">
                                                                    <mat-checkbox [checked]="sector.selected"
                                                                        (change)="toggleSelectionSector(sector, $event)"
                                                                        (click)="$event.stopPropagation()">
                                                                        {{ sector.Name }}
                                                                    </mat-checkbox>
                                                                </div>
                                                            </mat-option>
                                                        </mat-autocomplete>
                                                    </mat-form-field>
                                                    <mat-chip-list>
                                                        <mat-card-subtitle>
                                                            {{eventForm.get('GrowSectorId').value.length}} selected
                                                        </mat-card-subtitle>
                                                        <mat-chip
                                                            *ngFor="let chip of eventForm.get('GrowSectorId').value"
                                                            selected color="primary">
                                                            {{chip.Name ? chip.Name : chip.Id}}
                                                        </mat-chip>
                                                    </mat-chip-list>

                                                </div>
                                                <div class="form-group col-lg-6 col-md-12" *ngIf="eventteams">
                                                    <mat-form-field class="field-full-width" appearance="outline">
                                                        <mat-label>Event team</mat-label>
                                                        <mat-select [formControl]="teamControl">
                                                            <mat-option>--</mat-option>
                                                            <mat-option *ngFor="let team of eventteams" [value]="team">
                                                                {{team.Location}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-hint> Add responsible event team contact</mat-hint>
                                                    </mat-form-field>

                                                    <mat-accordion [multi]="true" *ngIf="selectedEventTeam">
                                                        <br>
                                                        <mat-card-subtitle>Members: {{selectedEventTeam.length}}
                                                        </mat-card-subtitle>
                                                        <mat-expansion-panel
                                                            *ngFor="let member of selectedEventTeam; let i = index">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    <mat-card-header>
                                                                        <div mat-card-avatar
                                                                            [ngStyle]="{ 'background-image': 'url(' + api + 'employee/Image?id=' + member.UserId + ')'}"
                                                                            style="background-size: cover; "></div>
                                                                        <mat-card-title>{{member.FirstName}}
                                                                            {{member.LastName}}
                                                                        </mat-card-title>
                                                                    </mat-card-header>
                                                                </mat-panel-title>

                                                            </mat-expansion-panel-header>
                                                            <mat-card-subtitle>{{member.UserId}}</mat-card-subtitle>

                                                        </mat-expansion-panel>
                                                    </mat-accordion>
                                                </div>
                                            </div>
                                        </form>
                                        <mat-divider></mat-divider>
                                    </div>
                                </div>
                                <br>
                                <mat-card-actions>
                                    <br>
                                    <button type="submit" mat-raised-button color="primary" *ngIf="isExistingEvent"
                                        (click)="onSubmit()">
                                        <mat-icon>upgrade</mat-icon> Save changes
                                    </button>
                                    <button type="submit" mat-raised-button color="primary" *ngIf="!isExistingEvent"
                                        (click)="onSubmit()">
                                        <mat-icon>add</mat-icon> Add event
                                    </button>
                                    <button mat-raised-button color="warn" *ngIf="isExistingEvent"
                                        (click)="deleteEvent()"
                                        matTooltip="This will delete the event and any related participation requests"
                                        #tooltip="matTooltip">
                                        <mat-icon>delete</mat-icon> Delete event
                                    </button>
                                    <mat-spinner *ngIf="isSaving" [diameter]="20" mode="indeterminate"></mat-spinner>
                                    <mat-hint *ngIf="saveSuccess" style="background-color: white;">
                                        <mat-icon style="color:green;">done</mat-icon> {{Date}}
                                    </mat-hint>
                                    <pre
                                        *ngIf="!saveSuccess && errorMessage">Error: <code>{{ errorMessage | json }}</code></pre>
                                </mat-card-actions>
                            </div>
                        </mat-tab>
                        <mat-tab label="Participants" *ngIf="isExistingEvent">
                            <div class="col-12">
                            <div class="col-12">
                                <p>To edit and respond to participation requests, go to the <a
                                    routerLink="/Event/Participants">participation requests </a> tab. On this page you can 
                                    view existing requests and invite specific users to the event.  </p>
                            </div>
                            <mat-hint>({{participantList.data.length}}) participation requests</mat-hint>
                            <table mat-table [dataSource]="participantList" style="width: 100%;" matSort
                                (matSortChange)="sortData($event)" matSortActive="EditDate" matSortDirection="desc"
                                matSortDisableClear *ngIf="participantList.data.length > 0">

                                <ng-container matColumnDef="Status">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Status </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;">
                                        {{element.Status}} </td>
                                </ng-container>
                                <ng-container matColumnDef="UserEmail">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> User email </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;">
                                        {{element.UserEmail}} </td>
                                </ng-container>
                                <ng-container matColumnDef="EventName">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Event name</th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> 
                                        {{element.EventName}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Last change">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Edited </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;">
                                        {{element.EditDate | date:'medium'}}</td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-divider></mat-divider>
                            <form [formGroup]="inviteForm">
                                <mat-card-subtitle>Invite user: </mat-card-subtitle>
                                <div class="row">
                                    <div class="col-5">
                                        <mat-form-field class="field-full-width" appearance="outline">
                                            <mat-label>User email</mat-label>
                                            <input matInput placeholder="Complete address..." formControlName="UserEmail">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-5">
                                        <mat-form-field class="field-full-width" appearance="outline">
                                            <mat-label>Message</mat-label>
                                            <textarea type="text" matInput placeholder="Welcome..." formControlName="Message"></textarea>
                                        </mat-form-field>
                                        <mat-hint>Add optional personal note to the end of the email invite. </mat-hint>
                                    </div>
                                    <div class="col-2">
                                        <button type="submit" mat-raised-button color="primary" (click)="sendInvite()">
                                            <mat-icon>email</mat-icon> Invite
                                        </button>
                                        <mat-spinner *ngIf="isSaving" [diameter]="20" mode="indeterminate"></mat-spinner>
                                        <mat-hint *ngIf="saveSuccess" style="background-color: white;">
                                            <mat-icon style="color:green;">done</mat-icon>
                                        </mat-hint>
                                        <pre *ngIf="!saveSuccess && errorMessage">Error: <code>{{ errorMessage | json }}</code></pre>
                                    </div>
                                </div>
                            </form>
                        </div>
                        </mat-tab>
                    </mat-tab-group>
                </mat-card-content>
            </mat-card>

        </div>
    </div>
    <br>
</div>