<div id="photos" style="background-color: #fff;">
  <div
    class="card"
    *ngFor="let pic of banners; let i = index"
    style="max-width: 1000px; margin:5px"
  >
    <div class="row">
      <div class="col" style="display: flex; align-items: center">
        <img
          class="card-img-top"
          src="data:image/jpeg;base64,{{ bannerImages[i].File }}"
          alt="{{ pic.Filename }}"
          style="width:100%"
        />
      </div>
      <div
        class="card-body col-5"
        style="margin: 20px 0 20px 0; border-left: 1px solid rgba(0, 0, 0, 0.125)"
      >
        <p class="card-title">{{ pic.Name }}</p>
        <p class="card-text" style="font-size:11px; color:grey">
          {{ pic.ShowFrom }} - {{ pic.ShowUntil }}
        </p>
        <p class="card-text">{{ pic.Description }}</p>
        <button
          class="btn btn-secondary hover"
          data-toggle="modal"
          data-target=".bd-banner"
          data-backdrop="static"
          (click)="edit(pic, i)"
        ><fa-icon [icon]="['fas', 'pencil']"></fa-icon></button>
        <img
          *ngIf="deleteLoading && deleteBannerIndex == i"
          style="margin-left:10px; height:25px; width:auto;"
          src="./../assets/ajax-loader.gif"
          alt="Loading..."
        />
        <span *ngIf="deleteError && deleteBannerIndex == i" style="Color:red">
          Could not delete the banner</span
        >
      </div>
    </div>

  </div>
</div>

<banner-add-dialog
  *ngIf="editBanner != null"
  [image]="editBanner.Image"
  [bannerInput]="editBanner.Banner"
></banner-add-dialog>

<style>
  #photos {
    max-width: 1000px;
  }

  #photos .card {
    /* Just in case there are inline attributes */
    width: 100% !important;
    height: auto !important;
  }

  .card-text {
    margin-bottom: 1rem;
    width: 100%;
  }

  .card-title {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
    /*margin-top: 2rem;*/
    font-size: 1.25rem;
    width: 100%;
  }

  textarea {
    line-height: normal;
    resize: none;
  }

  textarea[disabled] {
    background-color: white;
    border: none;
    padding-left: 0px;
    padding-right: 0px;
    cursor: text !important;
  }

  @media (max-width: 800px) {
    .col {
      display: block;
    }
  }
</style>
