<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="alert alert-danger" role="alert" *ngIf="errorString">{{errorString}}</div>

<!-- <b>Use CROM for Equity subscriptions</b> -->

<ng-container>
    <ng-container *ngIf="!isLoading && !errorString">
    <app-rule-select [options]="publicationTypeOptions" name="Publication types"
        [selectedValues]="_selectedPublicationTypes" (selections)="updatePublicationTypes($event)">
    </app-rule-select>

    <app-rule-select [options]="companyOptions" name="Company" [selectedValues]="_selectedCompanies"
        [disabled]="isSectorMode" (selections)="updateCompanies($event)">
    </app-rule-select>
    
    <app-rule-select [options]="sectorOptions" name="Sectors" [selectedValues]="_selectedSectors"
        [disabled]="isCompanyMode" (selections)="updateSectors($event)">
    </app-rule-select>
    
    <app-rule-select [options]="countryOptions" name="Countries" [selectedValues]="_selectedCountries"
        [disabled]="isCompanyMode" (selections)="updateCountries($event)">
    </app-rule-select>
    
    <app-rule-select [options]="analystOptions" name="Analysts" [selectedValues]="_selectedAnalysts"
        [disabled]="isCompanyMode" (selections)="updateAnalysts($event)">
    </app-rule-select>
    
    <app-rule-slider [disabled]="isCompanyMode" name="Market Cap M€" [max]="500000" [min]="0" [step]="500" [minValue]="_selectedMarketCap[0]"
        [maxValue]="_selectedMarketCap[1]" (selectedMinValue)="updateMarketCap($event, null)" (selectedMaxValue)="updateMarketCap(null, $event)"></app-rule-slider>
</ng-container>
</ng-container>
