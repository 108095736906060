<div style="margin-bottom: 20px">
    <p style="color: #666666; margin-bottom: 0">{{name}}</p>
    <div style="padding: 20px 5px">
        <nouislider [disabled]="disabled" #slider style="width:100%; max-width: 400px" [step]="step" [connect]="true" [tooltips]="[true, true]" [range]="range"
             (ngModelChange)="updateValues($event)" [ngModel]="_values">
        </nouislider>
    </div>
    <!-- {{_values[0]}} | {{_values[1]}} <br>
    {{minValue}} | {{maxValue}} <br>
    {{_latestSlidedValues[0]}} | {{_latestSlidedValues[1]}} -->
</div>