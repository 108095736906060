<div class="row">
  <div class="col-xl-12">
    <button
      type="button"
      class="btn btn-info"
      (click)="loadAccessList()"
      style="margin-bottom:12px;"
    >
      Load or Refresh admin nav access list
    </button>

    <table
      *ngIf="accessList.length > 0"
      class="table table-hover"
      style="margin-top:2rem; max-width:500px"
    >
      <thead>
        <tr>
          <th>Allowed</th>
          <th>Url</th>
        </tr>
        <tr>
          <th colspan="2">
            <label>
              <input
                type="checkbox"
                (change)="toggleAll()"
                [checked]="anySelected"
              />
              select/deselect all
            </label>
          </th>
        </tr>
      </thead>
      <tbody style="font-size:13px;">
        <tr class="hover" *ngFor="let u of accessList">
          <td>
            <input
              type="checkbox"
              [(ngModel)]="u.UserNavAccess.Allow"
              class="m-checkbox"
              (change)="changeAccessAllowed(u)"
            />
          </td>
          <td>{{ u.AdminNavUrl.RelativeUrl }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div></div>
</div>
