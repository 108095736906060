import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from '../user/user-api.service'
import { MacroAdminApiService, MediaModel } from './MacroAdmin-api.service'

export interface Group {
  id: number;
  gname: String;
  clips: MediaModel[];
}

@Component({
  selector: 'macroAdmin',
  templateUrl: './MacroAdmin.component.html',
})
export class MacroAdminComponent {
  updating: boolean = false;
  status: string = "";
  noXdate: Date = new Date()
  selectedClip: MediaModel = null;
  media: {cname: string, groups: Group[] }[] = [];

  constructor(private macroApi: MacroAdminApiService, private router: Router, private userApi: UserApiService) { 
    this.getMedia();
  }

  selectClip(d: MediaModel, group: string, cname: string): void {
    let dateObj = new Date();
    if (this.selectedClip)
      this.selectedClip.selected = false;
    if (!d){
      dateObj.setDate(dateObj.getDate() +21) //default 3 weeks
      d = { Id: 0, Name: "", MediaId: null, Xdate: dateObj, NeverExpire: false, GroupName: group, CategoryName: cname };
    }
    this.selectedClip = d;
    this.selectedClip.selected = true;
  }

  getMedia()
  {
    this.macroApi.getAllMedia().subscribe(
      data => {
        let category_collector = data.reduce(function(o, i){ 
          o[i.CategoryName] = o[i.CategoryName] ||[];
          o[i.CategoryName].push({Id: i.Id, Name:i.Name, MediaId: i.MediaId, Xdate: i.Xdate, NeverExpire: i.NeverExpire, GroupName: i.GroupName, CategoryName: i.CategoryName});
          return o
        }, {});
        this.media = Object.keys(category_collector).map((key, i) =>{
          let group_collector = category_collector[key].reduce(function(o,i){
            o[i.GroupName] = o[i.GroupName] ||[] ;
            o[i.GroupName].push(i);
            return o
          }, {});
          let groupse = Object.keys(group_collector).map(key2 =>{
            return {id: i, gname: key2, clips: group_collector[key2] }
          })
          return {cname: key, groups: groupse}
        });
      }
    );
  }

  saveClip(){
    this.updating = true;
    this.status = "... in progress"
    if (!this.selectedClip.Name || !this.selectedClip.MediaId || (!this.selectedClip.Xdate && !this.selectedClip.NeverExpire)|| !this.selectedClip.GroupName)
    {
      alert("Not all fields are filled");
      this.updating = false;
      this.status = ""
      return;
    }
    if(!this.validate()){
      alert("Not all input fields are valid");
      this.updating = false;
      this.status = ""
      return
    }
    this.macroApi.updateEmbedMedia(this.selectedClip).subscribe( res => {
      if(res ==1){
        this.status = "Request complete"
      }
      else this.status = "unhandled success response"
      this.updating = false
      this.selectedClip = null
      this.getMedia();
    }, error =>{
      this.status = "Error in request"
      this.updating = false
      console.log(error)
    });
  }

  deleteClip() {
    if (confirm("Are you sure you want to delete this?")) {
      this.updating = true;
      this.macroApi.deleteEmbedMedia(this.selectedClip).subscribe( res => {
        this.status = "Request complete"
        this.getMedia();
        this.updating = false;
        this.selectedClip = null;
      }, error => {
        this.status = "Error in request"
        this.updating = false;
        console.log(error);
      });
    }
  }

  validate() {
    let regex = new RegExp(/[\0\x08\x09\x1a\n\r"'\\\%]/g)
    let escaper = function escaper(char) {
      let m = ['\\0', '\\x08', '\\x09', '\\x1a', '\\n', '\\r', "'", '"', "\\", '\\\\', '%'];
      let r = ['\\\\0', '\\\\b', '\\\\t', '\\\\z', '\\\\n', '\\\\r', "''", '""', '\\\\', '\\\\\\\\', '\\%'];
      return r[m.indexOf(char)];
    }
    this.selectedClip.Name.replace(regex, escaper);
    this.selectedClip.CategoryName.replace(regex, escaper);
    this.selectedClip.GroupName.replace(regex, escaper);
    this.selectedClip.MediaId.replace(regex, escaper);

    return true

  }
}
