import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MorningMeetingApi, MorningMeetingModel, DeadlineModel } from '../nmw-contributions-api.service';
import { environment } from '../../../environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTableDataSource } from '@angular/material/table';
import { FileService } from 'app/common/file-service';
import { FileData } from 'app/common/file-data';

@Component({
    selector: 'app-nmw-contributions',
    templateUrl: './nmw-contributions_review.component.html',
    styleUrls: ['../nmw-contributions.component.css']
})
export class ReviewComponent implements OnInit {
    morningmeetingList = new MatTableDataSource<MorningMeetingModel>();
    uploading = false;
    isLoading = false;
    success = null;
    deadlineTime = null;
    deadline: Date = null;
    editTime = false;
    error = '';
    api = environment.baseUrlReonApi;
    displayedColumns: string[] = ['file', 'Company', 'Headline', 'RecChange', 'Selected', 'Order'];
    constructor(private mmApi: MorningMeetingApi, private fileService: FileService) { }

    ngOnInit() {
        this.LoadMorningWrap();
    }

    LoadMorningWrap() {
        this.isLoading = true;
        this.mmApi
            .getDeadline('analystClose')
            .subscribe(dt => {
                this.deadline = new Date(dt.dt);
            });
        this.mmApi
            .getmorningmeeting()
            .subscribe(meetingList => {
                this.morningmeetingList.data = meetingList.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);
                this.isLoading = false;
            });
    }

    canSave(): boolean {
        return this.uploading !== true;
    }

    getChosenLength() {
        return this.morningmeetingList.data.filter(m => m.salesSelected === true).length;
    }

    setTime($event) {
        this.deadlineTime = $event.target.value;
    }

    saveTime() {
        this.error = '';
        const reg = /(\d{2}):(\d{2})/;
        if (!reg.test(this.deadlineTime)) {
            alert('invalid format. use hh:mm');
            return;
        }
        const match = reg.exec(this.deadlineTime);
        const h = Number(match[1]);
        const m = Number(match[2]);
        const new_dl = new Date(
            this.deadline.getFullYear(),
            this.deadline.getMonth(),
            this.deadline.getDate(),
            h,
            m
        );
        this.deadline.setHours(h);
        this.deadline.setMinutes(m);
        const dm: DeadlineModel = { dt: new_dl, value: 'analystClose' };
        this.mmApi
            .setDeadline(dm)
            .toPromise()
            .then(res => {
                if (res === 1) {
                    this.editTime = false;
                    this.deadline = new_dl;
                } else {
                    this.error = 'Internal error';
                }
            });
    }

    noFocus($event?: any) {
        if (!(
            $event &&
            $event.relatedTarget &&
            $event.relatedTarget.className === 'btn btn-sm'
        )) {
            this.editTime = false;
        }
    }

    drop(event: CdkDragDrop<MorningMeetingModel[]>) {
        const tempClone = this.deepClone(this.morningmeetingList.data)
        moveItemInArray(tempClone, event.previousIndex, event.currentIndex);
        tempClone.forEach((member, index) => {
            member.sortOrder = index + 1;
        });
        this.morningmeetingList.data = tempClone;
    }
    deepClone(object: Object) {
        return JSON.parse(JSON.stringify(object))
    }

    save() {
        this.error = '';
        this.uploading = true;
        this.morningmeetingList.data.forEach(mm => {
            this.mmApi
                .saveContribution(mm)
                .toPromise()
                .then(() => {
                    this.uploading = false;
                })
                .catch(reason => {
                    this.uploading = false;
                    this.error = 'Error during save, reason: ' + reason;
                });
        });
    }

    getAttachmet(id: number) {
        this.mmApi
            .getAttachment(id)
            .subscribe(res => {
                const fileURL = URL.createObjectURL(res);
                window.open(fileURL, '_blank');
            }, err => {
                console.log(err);
            });
    }

    saveData = (function () {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          return function (blob: any, fileName: string) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          };
        } else {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          return function (blob: any, fileName: string) {
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
          };
        }
      })();
    
    generate() {
        this.error = '';
        this.uploading = true;
        this.mmApi
            .generate()
            .subscribe((fileData: FileData) => {
                if (!fileData.success)
                {
                    this.error = `Error during generation, reason: ${fileData.errorMsg}`;
                }
                this.uploading = false
            });
    }
}
