import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { routing } from './event.routing';
import { HttpClientModule } from '@angular/common/http';
import { ThirdPartyEventApiService } from './third-party-calendar/event-calendar-third-api.service';
import { EventCalendarThirdComponent } from './third-party-calendar/event-calendar-third.component';
import { EventEditComponent } from './event-edit/event-edit.component';
import { EventSearchComponent } from './event-search/event-search.component';
import { EventApiService } from './event-api.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EventParticipantsComponent } from './event-participants/event-participants.component';
import { MaterialModule } from 'app/shared/material/material.module';

@NgModule({
    declarations: [
        EventEditComponent,
        EventSearchComponent,
        EventCalendarThirdComponent,
        EventParticipantsComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        BrowserAnimationsModule,
        routing, HttpClientModule],
    exports: []
})
export class EventModule {
    static forRoot(): ModuleWithProviders<EventModule> {
        return {
            ngModule: EventModule,
            providers: [ThirdPartyEventApiService, EventApiService]
        };
    }
}
