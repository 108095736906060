<div class="container-fluid">
    <div class="row" style="margin-top:20px;"></div>
    <div class="col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title> Unconfirmed users </mat-card-title>
                <mat-card-subtitle>This is a list of user who have had their account set up and an email send out but
                    who have not finished the set up by entering a password of their own.</mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12">
                        <div class="col-12">
                            <mat-form-field class="field-full-width" appearance="outline">
                                <input matInput placeholder="Filter emails..." [(ngModel)]="filterModel"
                                    (ngModelChange)="updateFilterModel($event)">
                            </mat-form-field>
                            <mat-spinner *ngIf="loading" [diameter]="20" mode="indeterminate"></mat-spinner>
                        </div>
                        <mat-divider></mat-divider>
                        <div class="col-12">
                            <table mat-table [dataSource]="userList" style="width: 100%;" *ngIf="userList" matSort
                                (matSortChange)="sortData($event)">

                                <ng-container matColumnDef="Email">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Email </th>
                                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Confirmed">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Confirmed </th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-icon>{{element.confirmed ? 'done' : 'clear'}}</mat-icon>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-raised-button color="primary" (click)="selectUser(element.email)">
                                            <mat-icon>arrow_right_alt</mat-icon> Edit
                                        </button>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
                            <div *ngIf="userList.data?.length === 0">No unconfirmed users found</div>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>