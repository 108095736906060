<h2>Event Calendar to third party channels</h2>
<p><strong>Every monday</strong> you should send an email, containing an excel with information of upcoming events, to the following
    adresses:</p>
<ul>
    <li>events@weconvene.com (to Bloomberg, open for all)</li>
    <li>support@columbasystems.com</li>
    <li>events.oa@visiblealpha.com</li>
    <li>callstreet_info@factset.com </li>
</ul>

<div style="max-width:900px; font-size: 12px"><strong>Specialare för FactSet: (not implemented)</strong> när de stora
    seminarierna närmar sig
    har jag
    skapat en egen flik för dem i excelfilen, där jag har listat de deltagande bolagen och deras representanter i
    mer detalj än som ryms i den vanliga fliken. När jag mailar filen med en sådan flik till Factset, har jag
    klippt in innehållet direkt i mailet också, i enighet med deras önskemål om att få det så. Detta sker varje
    vecka ca 4-6 veckor innan själva seminariet. Mer info om dessa seminarium (Small Cap Seminar samt Large Cap
    Seminar) finns i inbjudan och programmet som finns på REON. Dessutom kommer det märkas i inkorgen när det finns
    mer info om seminarierna.</div>
    <br />

<h5>Do the following:</h5><br/>
<ol>
    <li>Generate the excel file and save it at <i>\\shbmain\data\Stockholm\Resources\A-M\GreatResearch\RESEARCH
            WORKFLOWS\REON\Events</i><br />
        <button style="margin-top: 1rem; margin-bottom: 2rem" type="button" class="btn btn-outline-success" (click)="getExcel();">Generate
            excel</button></li>
    <li *ngIf="fileSaved" style="margin-bottom: 2rem">The file is now saved at the desired location, go through it if you want.</li>
    <li *ngIf="fileSaved">Generate the email and don't forget to <strong>attach the file</strong><br />
        <a href="mailto:research.online@handelsbanken.se?bcc=events@weconvene.com,support@columbasystems.com,events.oa@visiblealpha.com,callstreet_info@factset.com&body=Hello,%0D%0APlease find attached a file with upcoming client events.,%0D%0ABr&subject=Handelsbanken calendar update"><button style="margin-top: 1rem; margin-bottom: 1rem" type="button" class="btn btn-outline-success">Generate
            email</button></a>
    </li>
</ol>
