<h2>Manage disclaimers</h2>

<div class="row">
  <table class="table table-hover">
    <thead>
      <tr>
        <th>Name</th>
        <th>Url</th>
        <th>Type</th>
        <th>Top menu</th>
        <th>Sort order</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let disc of disclaimers" (click)="selectDisclaimer(disc)" [ngClass]="{'table-active': disc.selected}">
        <td>{{disc.Name}}</td>
        <td>{{disc.Url}}</td>
        <td>{{disc.ContentType == 1 ? 'PDF' : 'Html'}}</td>
        <td>{{disc.TopMenu}}</td>
        <td>{{disc.SortOrder}}</td>
      </tr>
    </tbody>
  </table>

  <button (click)="selectDisclaimer(null)" class="btn btn-outline-warning">New Disclaimer</button>
</div>
<div *ngIf="selectedDisclaimer">
  <content-disclaimer-edit [disclaimer]="selectedDisclaimer" (newDisclaimerSaved)="newDisclaimerSaved($event)"></content-disclaimer-edit>
</div>
