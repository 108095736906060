import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { Nav, SubNav } from "./auth/auth.model";
import { AuthService } from "./auth/auth.service";
import { AuthorizeService, IUser } from 'api-authorization/authorize.service';
import { Logger } from './_helpers';
import { Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

const MENU: Nav[] = [{ Id: 1, Name: 'Loading...', Sub: [], IsImplemented: true }];
//const MENU: Nav[] = [];

@Component({
	selector: 'navigation-list',
	templateUrl: './nav.component.html',
	styles: [
		`::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
    }`
	]
})
export class NavComponent implements OnInit, AfterViewInit {
	menu = MENU;
	isLoaded: boolean;

	constructor(private logger: Logger, private authService: AuthService, private router: Router, private auth: AuthorizeService, 
		public dialog: MatDialog) {
			this.isLoaded = false;
		 }
	ngAfterViewInit(): void {
	}
	ngOnInit() {
		this.auth.userSubject.subscribe((user: IUser) => {
			if (user) {
				this.getNavbar();
			}
		});

	}

	private navSubscription: Subscription;

	getNavbar() {
		if (this.navSubscription)
		{
			this.navSubscription.unsubscribe();
		}

		//this.isLoaded = false;

		this.navSubscription = this.authService.getNavbar().subscribe(navs => {
			this.menu = navs.Navs;
			const aggregatedNavs: string[] = [];
			navs.Navs.forEach(nav => {
				nav.Sub.forEach(sub => {
					const itemUrl = "/" + this.subLink(nav.Name, sub);
					aggregatedNavs.push(itemUrl);
				})
			});
			this.authService.accessUrls.next(aggregatedNavs);
			this.isLoaded = true;
			const appLoader = document.querySelector(".app-loader");
			if (appLoader)
			{
				appLoader.remove();
			}

		}, err => {
			if (err.error.ExceptionType === 'Handelsbanken.TREO3.BusinessData.TREOUserAccountExpiredException') {
				this.dialog.open(NavbarExceptionDialog, {
					data: err.error.ExceptionMessage
				});
			}
		});
	}
	subLink(navName: string, sub: SubNav) {
		return sub.Link ? sub.Link : (navName + '/' + NavComponent.replaceWhite(sub.Name));
	}
	static replaceWhite(element: string) {
		return element.replace(/\s+/g, '-');
	}
}

@Component({
    selector: 'nav-exception-dialog',
    templateUrl: 'nav-exception-dialog.html',
  })
  export class NavbarExceptionDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: string) { }
  }
