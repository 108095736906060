import { RouterModule, Routes } from "@angular/router";
import { DownloadComponent } from "./SecServAdmin.component";
import { TempNewUserComponent } from "../user/TempNewUserComponent";
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: "SecServAdmin",
    component: DownloadComponent,
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
      { path: "Download", component: DownloadComponent },
      { path: "Newsletter", component: DownloadComponent }
    ]
  },
  {
    path: "SecServAdmin/Users",
    component: TempNewUserComponent,
    canActivate: [AuthorizeGuard],
    data: { Platform: "SecServUser", navCheck: true }
  }
];

export const routing = RouterModule.forChild(routes);
