<div class="modal fade bd-banner" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title">
          Set image as banner
        </h6>
        <div class="card" style="max-width: 18rem; max-height: 8rem">
          <img
            src="data:image/jpeg;base64,{{ image.File }}"
            alt="{{ image.Filename }}"
            style="max-height: 8rem"
          />
        </div>
      </div>
      <div class="modal-body">
        <div class="form-group row yCenter">
          <label for="text" class="col-3 col-form-label">Name*</label>
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              #name
              [(ngModel)]="banner.Name"
            />
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="text" class="col-3 col-form-label">Description*</label>
          <div class="col-8">
            <input
              type="text"
              class="form-control"
              #description
              [(ngModel)]="banner.Description"
            />
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="endtDate" class="col-3 col-form-label"
            >Show From/To: *</label
          >
          <div class="col-8">
            <div class="form-check">
              <label>
                <input
                  type="date"
                  class="form-control"
                  #from
                  [ngModel]="banner.ShowFrom | date: 'yyyy-MM-dd'"
                  (ngModelChange)="banner.ShowFrom = $event"
                />
                <input
                  style="margin-top:10px"
                  type="date"
                  class="form-control"
                  #to
                  [ngModel]="banner.ShowUntil | date: 'yyyy-MM-dd'"
                  (ngModelChange)="banner.ShowUntil = $event"
                />
              </label>
            </div>
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="code" class="col-3 col-form-label">Html</label>
          <div class="col-8">
            <textarea
              type="text"
              class="form-control"
              #html
              [(ngModel)]="banner.Html"
            ></textarea>
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="sortorder" class="col-3 col-form-label">Link</label>
          <div class="col-8">
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                #link
                [(ngModel)]="banner.Link"
              />
            </div>
            <small id="maxScreeningsHelp" class="form-text text-muted">
              <div class="form-check">
                <label class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    #openNewWindow
                    [(ngModel)]="banner.OpenInNewWindow"
                  />
                  <span style="color:black">Open in new window</span>
                </label>
              </div>
            </small>
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="email" class="col-3 col-form-label">Sort order*</label>
          <div class="col-8">
            <input
              type="number"
              class="form-control"
              #sortorder
              [(ngModel)]="banner.SortOrder"
              min="0"
            />
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="email" class="col-3 col-form-label"
            >Attach document</label
          >
          <div class="col-8">
            <input
              type="file"
              #file
              style="line-height:normal"
              placeholder="Upload file"
              accept=".pdf, .docx"
              #file
            />
            <small
              id="maxScreeningsHelp"
              class="form-text text-muted"
              *ngIf="banner.DocumentId != null"
            >
              <span>Current document Docid, {{ banner.DocumentId }}</span>
            </small>
          </div>
        </div>

        <style>
          td {
            padding-right: 5px;
            padding-left: 5px;
          }
        </style>
        <div class="form-group row yCenter">
          <label for="email" class="col-3 col-form-label"
            >Show on these pages</label
          >
          <div class="col-8">
            <table style="margin-bottom: 20px">
              <tbody>
                <tr style="border-bottom: 1px solid gray">
                  <td>Location</td>
                  <td>Context id</td>
                  <td></td>
                </tr>
                <tr *ngFor="let val of bannerPages; let i = index">
                  <td>{{ val.Location }}</td>
                  <td>{{ val.LocationContextId }}</td>
                  <td>
                    <span class="hover" (click)="bannerPages.splice(i, 1)">
                      <fa-icon [icon]="['fas', 'trash']"></fa-icon>
                  </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="row"
              style="display: flex; align-items: center; margin:0"
            >
              <select
                style="width: 180px; margin-right: 10px"
                placeholder="Location"
                class="form-control"
                [(ngModel)]="bannerPage.Location"
              >
                <option disabled>Location</option>
                <option *ngFor="let val of bannerLocations; let i = index">{{
                  val
                }}</option>
              </select>
              <input
                style="width: 120px; margin-right: 10px"
                type="number"
                class="form-control"
                [(ngModel)]="bannerPage.LocationContextId"
                placeholder="Context id"
              />
              <button
                style="color: #000"
                class="btn btn-outline-secondary hover"
                [disabled]="
                  bannerPage.Location == 'Location' ||
                  bannerPage.LocationContextId == null
                "
                (click)="addBannerPage()"
              ><fa-icon [icon]="['fas', 'plus']"></fa-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <span *ngIf="saveError != ''" style="color: red; padding-right: 10px">{{
          saveError
        }}</span>
        <span *ngIf="saveSuccess" style="color: green; padding-right: 10px"
          >Banner saved to database</span
        >
        <img
          *ngIf="saveLoading"
          style="margin-left:10px; height:25px; width:auto;"
          src="./../assets/ajax-loader.gif"
          alt="Loading..."
        />
        <button
          [disabled]="saveLoading"
          class="btn btn-success hover"
          (click)="saveBanner(file.files)"
        ><fa-icon [icon]="['fas', 'check']"></fa-icon></button>
        <button
          [disabled]="saveLoading"
          class="btn btn-outline-secondary hover"
          data-dismiss="modal"
        ><fa-icon [icon]="['fas', 'close']"></fa-icon></button>
      </div>
    </div>
  </div>
</div>
