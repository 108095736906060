import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from "@angular/forms";
import { routing } from "./content.routing";
import { ContentApiService } from "./content-api.service";
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { CompanyAlertsComponent } from "./company-alerts/company-alerts.component";
import {
  ImagesComponent,
  BannersComponent,
  PublicationTypeComponent
} from "./content.component";
import { InternalPublicationsComponent } from "./InternalPublications.component";
import {
  DisclaimerComponent,
  DisclaimerEditComponent
} from "./disclaimer.component";
import { FocusPublicationComponent } from "./inFocusPublication.component";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import {
  BannerAddDialogComponent,
  BannerViewComponent
} from "./banner.component";
import { CompanyCustomizationApi } from "./company-alerts/company-customization-api";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { IconPack } from "@fortawesome/fontawesome-svg-core";

@NgModule({
  declarations: [
    DisclaimerComponent,
    DisclaimerEditComponent,
    FocusPublicationComponent,
    InternalPublicationsComponent,
    CompanyAlertsComponent,
    ImagesComponent,
    BannersComponent,
    PublicationTypeComponent,
    BannerAddDialogComponent,
    BannerViewComponent
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    DragDropModule,
    FormsModule,
    FontAwesomeModule,
    routing,
    HttpClientModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    MatIconModule,
    MatTableModule,
  ],
  exports: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ContentModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas as IconPack, far as IconPack);
  }
  static forRoot(): ModuleWithProviders<ContentModule> {
    return {
      ngModule: ContentModule,
      providers: [ContentApiService, CompanyCustomizationApi]
    };
  }
}
