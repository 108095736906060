import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { routing } from './misc.routing';
import { MiscApiService } from './misc-api.service';

import {
    MorningMeetingComponent
} from './misc.component';


@NgModule({
    declarations: [
        MorningMeetingComponent
  ],
  imports: [
      CommonModule,
      FormsModule,
      routing,
      HttpClientModule,
  ],
  exports: [
  ]
})
export class MiscModule { 
    static forRoot(): ModuleWithProviders<MiscModule> {
        return {
            ngModule: MiscModule,
            providers: [MiscApiService]
        }
    }
}
