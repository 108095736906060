import { AfterViewInit, Component } from '@angular/core';
import { Router, NavigationStart } from "@angular/router";
import { AuthorizeService } from 'api-authorization/authorize.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements AfterViewInit{
  navShow: boolean;
  marginLeft: number;
  route: Router;
  isAuthenticated: boolean;
  loading: boolean = true;

  constructor(auth: AuthorizeService) {
    auth.isAuthenticated().subscribe(isLoggedIn => this.isAuthenticated = isLoggedIn);
    
  }
  ngAfterViewInit(): void {
    this.loading = false;
  }
  
  ngOnInit() {
    this.navShow = true;
    this.marginLeft = 216;
    /* Angular routing is case sensitive, major mistake in this case since users may change url */
    if(this.route) {
      this.route.events.subscribe(event => {
        if(event instanceof NavigationStart) {
          let url = (<NavigationStart>event).url;
          if(url !== url.toLowerCase()) {
            this.route.navigateByUrl((<NavigationStart>event).url.toLowerCase());
          }
        }
      });
    }
  }

  marginleft() {
    return { 'margin-left': this.marginLeft + 'px' };
  }
  marginleftFullWidth() {
    return { 'margin-left': this.marginLeft + 'px', width: '100%' };
  }

  navToogle() {
    if(this.navShow) {
      this.navShow = false;
      this.marginLeft = 0;
    }
    else {
      this.navShow = true;
      this.marginLeft = 216;
    }
  }
}
