import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthorizeService } from './authorize.service';
import { tap, map, flatMap } from 'rxjs/operators';
import { ApplicationPaths, QueryParameterNames } from './api-authorization.constants';
import { AuthService } from 'app/auth/auth.service';
import { Logger } from 'app/_helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
  constructor(private authorize: AuthorizeService, private authService: AuthService, private router: Router, private logger: Logger) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const url = state.url.split('?')[0];

    return this.authorize.isAuthenticated().pipe(
      tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state)),
      flatMap(isAuth => {
        if (_next.data.navCheck === true) {
          return this.authService.accessUrls.pipe(map(urls => isAuth && urls.includes(url)))
        } else {
          return of(isAuth);
        }
      })
    );
  }

  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
    if (!isAuthenticated) {
      this.router.navigate(ApplicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url
        }
      });
    }
  }
}
