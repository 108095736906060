import { Component, Input, OnInit, Injectable, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import {
  FormGroup,
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import {
  UserApiService,
  UserExModel,
  UserAdminNavAccess,
  ContentRole,
  UserStatItem,
} from "../user-api.service";
import { BehaviorSubject } from "rxjs";
import { Sort } from "@angular/material/sort";
import {
  AuthorizeService,
} from "api-authorization/authorize.service";
import { UserInviteModel, UserUpdateModel } from "./user.models";

export class SyncSSOObject {
  userExM: UserExModel;
  usrRoles: ContentRole[];
}

/** Error when invalid control is dirty, touched, or submitted. */
export class EmailFormValidator implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

export class UserRoleNode {
  children: UserRoleNode[];
  name: string;
  source: string;
  isSelected: boolean;
  order: number;
  key: string;
  allows: string[];
  require: string[];
}

export class UserRoleFlatNode {
  name: string;
  level: number;
  expandable: boolean;
  source: string;
  isSelected: boolean;
  order: number;
  key: string;
  allows: string[];
  require: string[];
}

@Injectable({ providedIn: "root" })
@Component({
  selector: "user-view",
  templateUrl: "userNew.component.html",
  styleUrls: ["../user.component.css"],
})
export class NewComponent implements OnInit {
  @Input() user: UserExModel | null = {};
  @Input() isExistingUser: boolean = false;

  @Output() userUpdated: EventEmitter<{enabled: boolean, isEmailUser: boolean}> = new EventEmitter()

  sendEmailInvite: FormControl;
  isLoadingUser = false;
  setDefaultAdminAccessRules = false;
  error = "";
  progress_value = null;
  focused: boolean;
  clientCategories: { name: string; type: string }[] = [];
  matcher = new EmailFormValidator();
  checkboxNameTypeLookup: { [key: string]: ContentRole } = {};
  customValidatorStore: { [key: string]: string } = {};
  accessList: UserAdminNavAccess[] = [];
  syncUser = false;
  syncRoles = false;
  syncSSO = false;
  sendEmail = false;
  roleData = [];
  dataChange = new BehaviorSubject<UserRoleNode[]>([]);
  emailconfirmed: boolean;
  emailconfirmedLoading: boolean;
  emailconfirmedLoaded: boolean;
  emailconfirmedStatus: string;
  lastUserStat: UserStatItem = null;

  get data(): UserRoleNode[] {
    return this.dataChange.value;
  }

  userForm = new FormGroup(
    {
      userModelForm: new FormGroup(
        {
          EMail: new FormControl("", {
            validators: [
              Validators.email,
              Validators.required,
              this.uniqueEmailValidator.bind(this),
            ],
            updateOn: "change",
          }),
          Id: new FormControl({ value: "" }),
          ClientCategory: new FormControl("", {
            validators: [
              Validators.required,
              this.customClientCategoryValidator.bind(this),
            ],
            updateOn: "change",
          }),
          MaxScreenings: new FormControl(10, {
            validators: [Validators.required, Validators.min(0)],
            updateOn: "change",
          }),
          ExpireDate: new FormControl(
            { value: new Date().toISOString(), disabled: true },
            {
              validators: [],
              updateOn: "change",
            }
          ),
          NeverExpire: new FormControl(true, {
            validators: [],
            updateOn: "change",
          }),
          Enabled: new FormControl(true, {
            validators: [],
            updateOn: "change",
          }),
          LastActivity: new FormControl(true, {
            validators: [],
            updateOn: "change",
          }),
          OriginalEmail: new FormControl(true, {
            validators: [],
            updateOn: "change",
          }),
          UserId: new FormControl(true, {
            validators: [],
            updateOn: "change",
          }),
        },
        { updateOn: "submit" }
      ),
      checkboxControl: new FormGroup({
        // Empty by design. Populated in ngOnInit()
      }),
    },
    { updateOn: "submit" }
  );

  isEmailUser: boolean = false
  get fUser() { return this.userForm.controls.userModelForm as FormGroup }
  get fCheckbox() { return this.userForm.controls.checkboxControl as FormGroup }

  constructor(
    private userApi: UserApiService,
    private route: ActivatedRoute,
    private auth: AuthorizeService,
    private router: Router,
    private location: Location

  ) { }


  ngOnInit() {

    this.sendEmailInvite = new FormControl(!this.isExistingUser);
    this.isEmailUser = this.user?.ClientCategory == 'Email subscriber'

    const editUser =
      this.route.snapshot.queryParams["email"] !== undefined &&
      this.isExistingUser;
    this.userApi.getClientCategories().subscribe(
      (categories) => {
        this.clientCategories = categories
          .filter((cats) => cats.length > 0)
          .map((cat) => ({ name: cat, type: "default" }));
        this.clientCategories.push({ name: "--New--", type: "custom" });
        if (editUser) {
          this.userForm.controls.userModelForm.patchValue({
            ClientCategory: this.clientCategories.find(
              (c) => c.name === this.user.ClientCategory
            ),
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );

    if (this.isExistingUser)
      this.fUser.controls.EMail.disable()
    this.fUser.controls.Id.disable()

    this.getUserObj().then((usr) => {
      this.userForm.controls.userModelForm.patchValue({
        EMail: usr.EMail,
        Id: usr.Id,
        MaxScreenings: usr.MaxScreenings,
        ExpireDate: usr.ExpireDate,
        NeverExpire: usr.NeverExpire,
        Enabled: usr.Enabled,
        LastActivity: usr.LastActivity,
        OriginalEmail: usr.EMail,
        UserId: usr.UserId,
      });
      const checkboxes = <FormGroup>this.userForm.controls.checkboxControl;
      const usrRoles = [];
      usr.Roles.forEach((role: ContentRole, index) => {
        const sortName =
          "_" + String.fromCharCode(65 + index) + "_" + role.Name;
        checkboxes.addControl(
          sortName,
          new FormControl(role.IsSet ? role.IsSet : false)
        );
        this.checkboxNameTypeLookup[sortName] = role;
        const r = role;
        r["key"] = sortName;
        usrRoles.push(r);
      });
      const grouped = usrRoles.reduce((acc, key) => {
        acc[key.Group] = acc[key.Group] || [];
        acc[key.Group].push(key);
        return acc;
      }, Object.create(null));

      const data = this.buildRoleTree(grouped, 0).sort((dn1, dn2) =>
        dn1.order < dn2.order ? -1 : 1
      );
      this.dataChange.next(data);
      this.user = usr;
    });

    this.getUserStat();
  }

  clientCategoryChange({ name }) {
    this.isEmailUser = name == 'Email subscriber'
    if (!this.isEmailUser) {
      this.fUser.controls.MaxScreenings.enable()
      this.sendEmailInvite.enable()
      this.fCheckbox.enable()
      return
    }
    this.fUser.controls.MaxScreenings.disable()
    this.fUser.controls.NeverExpire.setValue(true)
    this.fUser.controls.ExpireDate.disable()
    this.sendEmailInvite.setValue(!this.isEmailUser)
    this.sendEmailInvite.disable()
    this.fCheckbox.disable()
  }

  async getUserObj() {
    if (this.user && this.isExistingUser) {
      return this.user;
    } else {
      return this.userApi.prepareUser().toPromise();
    }
  }
  setCustomClientCategory(value: string) {
    this.userForm.controls.userModelForm.get(
      "ClientCategory"
    ).value.name = value;
    this.userForm.controls.userModelForm
      .get("ClientCategory")
      .updateValueAndValidity();
  }

  expireDateChange(value: any) {
    value.checked
      ? this.userForm.controls.userModelForm.get("ExpireDate").disable()
      : this.userForm.controls.userModelForm.get("ExpireDate").enable();
  }
  getCheckboxType(name: string): string {
    return this.checkboxNameTypeLookup[name].Source;
  }
  getCheckboxName(name: string): string {
    return this.checkboxNameTypeLookup[name].Name;
  }
  getCheckboxRequired(name: string): string[] {
    return this.checkboxNameTypeLookup[name].Require;
  }
  getRowStyle(key: string) {
    switch (this.checkboxNameTypeLookup[key].Source) {
      case "CROM":
        return "rgba(255, 65, 54, 0.3)";
      case "REON":
        return "rgba(46, 204, 64, 0.3)";
      case "admin":
        return "rgba(0, 116, 217, 0.3)";
    }
  }

  sortData(sort: Sort) {
    const data2 = this.user.Roles.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "role":
          return this.compare(
            a.Name.toLocaleLowerCase(),
            b.Name.toLocaleLowerCase(),
            isAsc
          );
        case "system":
          return this.compare(
            a.Source.toLocaleLowerCase(),
            b.Source.toLocaleLowerCase(),
            isAsc
          );
        case "access":
          return this.compareBool(a.IsSet, b.IsSet, isAsc);
        default:
          return 0;
      }
    });
    // update form of checkboxes
    const checkboxes = <FormGroup>this.userForm.controls.checkboxControl;
    Object.keys(checkboxes.controls).forEach((key) => {
      checkboxes.removeControl(key);
    });
    this.checkboxNameTypeLookup = {};
    data2.forEach((role: ContentRole, index) => {
      const sortName = "_" + String.fromCharCode(65 + index) + "_" + role.Name;
      checkboxes.addControl(
        sortName,
        new FormControl(role.IsSet ? role.IsSet : false)
      );
      this.checkboxNameTypeLookup[sortName] = role;
    });
  }
  compare(a: string, b: string, isAsc: boolean) {
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }
  compareBool(a: boolean, b: boolean, isAsc: boolean) {
    return (a === b ? 0 : b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  buildRoleTree(obj: { [key: string]: any }, level: number): UserRoleNode[] {
    return Object.keys(obj).reduce<UserRoleNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new UserRoleNode();
      node.name = key;

      if (value != null) {
        if (Array.isArray(value)) {
          node.children = this.buildRoleTree(value, level + 1);
          node.order = node.children[0].order;
        } else {
          node.name = value.Name;
          node.source = value.Source;
          node.isSelected = value.IsSet;
          node.order = value.Order;
          node.key = value.key;
          node.allows = value.allows;
          node.require = value.Require;
        }
      }
      return accumulator.concat(node);
    }, []);
  }

  getUserStat() {
    if (!this.isExistingUser) {
      return;
    }
    this.lastUserStat = null;
    this.userApi.getLastUserStat(this.user.Id).subscribe(userStat => {
      this.lastUserStat = userStat;
    });
  }

  updateItem(node: UserRoleNode, isChecked: boolean) {
    const nd = this.checkboxNameTypeLookup[node.key];
    const keys = Object.keys(this.checkboxNameTypeLookup).filter((key) =>
      nd.Allows.includes(this.checkboxNameTypeLookup[key].Name)
    );
    const names = [];
    keys.forEach((key) => {
      this.userForm.controls.checkboxControl.get(key).setValue(isChecked);
      names.push(this.checkboxNameTypeLookup[key].Name);
    });
    this.userForm.controls.checkboxControl.get(node.key).setValue(isChecked);
    return names;
  }

  // Custom form validators
  uniqueEmailValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    if (
      this.userForm === undefined ||
      this.customValidatorStore === undefined ||
      control.value === undefined
    ) {
      return { emailstatus: false };
    }
    if (this.isExistingUser && control.value === this.user.EMail) {
      return null;
    }
    if (
      (this.userForm.controls.userModelForm.get("EMail").errors !== null &&
        this.userForm.controls.userModelForm.get("EMail").errors["email"] ===
        undefined &&
        this.userForm.controls.userModelForm.get("EMail").errors["required"] ===
        undefined) ||
      this.userForm.controls.userModelForm.get("EMail").errors === null ||
      control.value.length > 4
    ) {
      this.userApi.isUniqueEmail(control.value).subscribe(
        (isUnique) => {
          if (isUnique) {
            return null;
          } else {
            control.setErrors({ emailstatus: "duplicate" });
          }
        },
        (err) => {
          control.setErrors({ emailstatus: "error" });
        }
      );
    } else {
      control.setErrors({ emailstatus: false });
    }
  }

  customClientCategoryValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    if (this.userForm === undefined || control.value === undefined) {
      return { clientcat: false };
    }
    const isNameUnique = this.clientCategories.filter(
      (cat) => cat.name === control.value.name
    );
    if (isNameUnique.length > 1) {
      return { clientcat: "lazy" };
    } else {
      return null;
    }
  }
  requireOneCheckedBoxes(
  ): { [key: string]: any } | null {
    if (this.userForm === undefined) {
      return { roles: false };
    }
    let checked = 0;
    Object.keys(this.userForm.controls.checkboxControl.value).forEach((key) => {
      // const ctrl = this.userForm.controls.checkboxControl.get(key);
      if (this.userForm.controls.checkboxControl.value[key] === true) {
        checked++;
      }
    });
    if (checked < 1) {
      return { roles: false };
    }
    return null;
  }

  // For debug, used to display all Form errors in JSON
  // <pre>Form errors:<code>{{ getAllErrors(userForm) | json }}</code></pre>
  getAllErrors(form: FormGroup): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }

  onSubmit() {
    this.syncRoles = false;
    this.syncSSO = false;
    this.syncUser = false;
    this.sendEmail = false;
    this.error = "";
    let checked = 0;
    // Make sure a profile has at least 1 role.
    Object.keys(this.userForm.controls.checkboxControl.value).forEach((key) => {
      if (this.userForm.controls.checkboxControl.value[key] === true) {
        checked++;
      }
    });
    if (checked < 1 && !this.setDefaultAdminAccessRules && !this.isEmailUser) {
      this.userForm
        .get("userModelForm")
        .get("Id")
        .setErrors({ ...this.userForm.errors, roles: true });
      return;
    } else if (this.userForm.invalid) {
      this.userForm.setErrors({ ...this.userForm.errors, check: true });
      return;
    }
    this.progress_value = 20;
    const userExM = (<FormGroup>(
      this.userForm.controls.userModelForm
    )).getRawValue();
    const userRoleM = (<FormGroup>(
      this.userForm.controls.checkboxControl
    )).getRawValue();
    userExM.ClientCategory = userExM.ClientCategory.name;
    const usrRoles: ContentRole[] = [];
    Object.keys(userRoleM).forEach((key) => {
      const role = this.checkboxNameTypeLookup[key];
      if(this.isEmailUser){
        role.IsSet = false //no roles for email user
        return
      }
      role.IsSet =
        this.setDefaultAdminAccessRules && role.Source === "admin"
          ? this.setDefaultAdminAccessRules
          : userRoleM[key];
      if (this.setDefaultAdminAccessRules && role.Name === "Reon Admin") {
        role.IsSet = true;
      }
      usrRoles.push(role);
    });

    let syncSSOUserSubject = new BehaviorSubject<SyncSSOObject>(null);
    syncSSOUserSubject.subscribe((syncSSOObject: SyncSSOObject) => {
      if (syncSSOObject === null) return;
      this.syncSSOAndSendInvite(syncSSOObject);
      syncSSOUserSubject.unsubscribe();
    });

    this.submitUserObj(userExM).subscribe(
      (userId) => {
        this.userUpdated.emit({enabled: userExM.Enabled, isEmailUser: this.isEmailUser}) //used for email subscribtion to automatic disable that
        if (userExM.OriginalEmail !== userExM.EMail) {
          userExM.OriginalEmail = userExM.EMail;
          this.user.EMail = userExM.EMail;
          this.userForm.controls.userModelForm.patchValue({
            OriginalEmail: userExM.EMail,
          });
          const urlTree = this.router.createUrlTree(['/User/Edit'], { queryParams: { email: encodeURIComponent(userExM.EMail) } });
          this.location.go(urlTree.toString());
        }
        this.progress_value += 20;
        this.syncUser = true;
        if (userId) {
          this.userApi.setRoles(userId, usrRoles, false).subscribe(() => {
            this.progress_value += 20;
            this.syncRoles = true;
            syncSSOUserSubject.next({ userExM, usrRoles });
          });
        } else {
          throw new Error("Unexpected return: " + userId);
        }
      },
      (error) => {
        this.error = error.error;
      }
    );
  }

  syncSSOAndSendInvite(syncSSOObject: SyncSSOObject) {
    const userExM = syncSSOObject.userExM;
    const usrRoles = syncSSOObject.usrRoles;

    this.userApi
      .syncSSOuser(
        this.getSSOUserObj(userExM, usrRoles),
        this.auth.getSSOUpdatePath()
      )
      .subscribe(
        () => {
          this.progress_value += 20;
          this.syncSSO = true;

          // Must wait for user to be created
          this.sendEmailInviteIfChecked(userExM);
        },
        (err) => {
          this.error = err;
        }
      );
  }

  submitUserObj(usr: UserExModel) {
    if (this.isExistingUser) {
      return this.userApi.updateUser(usr);
    } else {
      return this.userApi.insertUser(usr);
    }
  }

  sendEmailInviteIfChecked(userExM: UserExModel) {
    if (this.sendEmailInvite.value === true) {
      this.userApi
        .sendInviteEmail(
          this.getInviteUserObj(userExM),
          this.auth.getInviteEmailPath()
        )
        .subscribe(
          () => {
            this.progress_value += 20;
            this.sendEmail = true;
          },
          (err) => {
            this.error = err;
          }
        );
    } else {
      this.progress_value += 20;
    }
  }
  getSSOUserObj(usr: UserExModel, roles: ContentRole[]): UserUpdateModel {
    const ret: UserUpdateModel = {
      Email: usr.EMail,
      Roles: roles
        .filter((role) => role.Source !== "admin" && role.IsSet)
        .map((role) => role.SSORole),
      SystemId: "REON",
    };
    return ret;
  }

  getInviteUserObj(usr: UserExModel): UserInviteModel {
    const ret: UserInviteModel = {
      ActivateLabel: "Activate account",
      ContactEmail: "research.online@handelsbanken.se",
      Header: "Welcome to Research Online",
      ReturnUrl: "https://www.researchonline.se/",
      Subject: "Welcome to Research Online",
      Username: usr.EMail,
    };
    return ret;
  }

  checkConfirmedEmailstatus() {
    this.emailconfirmedLoading = true;
    this.emailconfirmedLoaded = false;
    this.userApi
      .getSSOEmailConfirmed(this.auth.getEmailConfirmedPath())
      .subscribe((list) => {
        const u = list.find((u) => u.email === this.user.EMail);
        if (u) {
          this.emailconfirmedStatus = u.confirmed
            ? "This user has confirmed their email."
            : "This user has not confirmed their email yet";
        } else {
          this.emailconfirmedStatus =
            "This user does not exist in SSO. Please select the update button above to sync the user object. ";
        }
        this.emailconfirmed = u ? u.confirmed : null;
        this.emailconfirmedLoading = false;
        this.emailconfirmedLoaded = true;
      });
  }
}
