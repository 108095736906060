<div class="box-container">
    <div class="row">
        <div class="col-lg-6 col-md-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        {{ isExistingEmployee ? "Edit employee " : "Add new" }}
                    </mat-card-title>
                    <mat-card-subtitle>{{ isExistingEmployee ? employee.UserId : "new employee" }}</mat-card-subtitle>
                </mat-card-header>
                <mat-divider></mat-divider>

                <mat-card-content>
                    <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()">
                        <div>
                            <div class="form-group row">
                                <div class="col-6">
                                    <mat-form-field class="field-full-width" appearance="outline">
                                        <mat-label>User id *</mat-label>
                                        <input type="text" matInput formControlName="UserId">
                                        <mat-hint></mat-hint>
                                        <mat-error
                                            *ngIf="employeeForm.get('UserId').getError('useridstatus') === 'error' && !employeeForm.get('UserId').hasError('required')">
                                            Network error.
                                        </mat-error>
                                        <mat-error
                                            *ngIf="employeeForm.get('UserId').getError('useridstatus') === 'duplicate' && !employeeForm.get('UserId').hasError('required')">
                                            User id already exists. <strong>Unique</strong> id is required.
                                        </mat-error>
                                        <mat-error *ngIf="employeeForm.get('UserId').hasError('required')">
                                            User id is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field class="field-full-width" appearance="outline">
                                        <mat-label>Comment</mat-label>
                                        <textarea type="text" matInput formControlName="Comment"></textarea>
                                        <mat-hint></mat-hint>
                                    </mat-form-field>

                                    <mat-form-field class="field-full-width" floatLabel="auto" appearance="none">
                                        <mat-checkbox formControlName="Active"> Active </mat-checkbox>
                                        <br>
                                        <mat-checkbox formControlName="HasPicture">Has picture.
                                            <mat-hint style="text-decoration: underline; cursor: pointer;"
                                                (click)="clearEmployeeImage()">Remove</mat-hint>
                                        </mat-checkbox>
                                        <textarea matInput hidden></textarea>
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <img src="{{imageSrc}}" height="auto" title="profile picture (86x106)" />
                                    <label class="btn btn-primary btn-file">Browse
                                        <input type="file" hidden (change)="readFile($event);">
                                    </label>
                                </div>
                            </div>
                            <mat-divider></mat-divider>

                            <div class="form-group row">
                                <div class="col-md-6 col-sm-10">
                                    <mat-form-field class="field-full-width" appearance="outline">
                                        <mat-label>First name *</mat-label>
                                        <input type="text" matInput formControlName="FirstName">
                                        <mat-hint></mat-hint>
                                        <mat-error *ngIf="employeeForm.get('FirstName').hasError('required')">
                                            First name is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-6 col-sm-10">
                                    <mat-form-field class="field-full-width" appearance="outline">
                                        <mat-label>Last name *</mat-label>
                                        <input type="text" matInput formControlName="LastName">
                                        <mat-hint></mat-hint>
                                        <mat-error *ngIf="employeeForm.get('LastName').hasError('required')">
                                            Last name is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="form-group row">
                                <div formGroupName="ContactInfoList" class="col-12">
                                    <div
                                        *ngFor="let contact of employeeForm.get('ContactInfoList').value | keyvalue; let ind=index; trackBy:trackByFn">
                                        <div *ngIf="ind%2 == 0" class="col-6" style="float:left;">
                                            <mat-form-field class="field-full-width" appearance="outline">
                                                <mat-label> {{contact.value.ContactType}}
                                                    {{contact.value.Required == true ? "*" : ""}}</mat-label>
                                                <input type="text" matInput [value]="contact.value.Value ? contact.value.Value : ''"
                                                (input)="customSetValue(contact, $event)">
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="ind%2 != 0" class="col-6" style="float:right;">
                                            <mat-form-field class="field-full-width" appearance="outline">
                                                <mat-label> {{contact.value.ContactType}}
                                                    {{contact.value.Required == true ? "*" : ""}}</mat-label>
                                                    <input type="text" matInput [value]="contact.value.Value ? contact.value.Value : ''"
                                                    (input)="customSetValue(contact, $event)">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <mat-error *ngIf="employeeForm.get('Comment').getError('contact') === false">
                                        Missing required contact information (*).
                                    </mat-error>
                                </div>
                            </div>

                            <mat-divider></mat-divider>

                            <div class="col-12">
                                <mat-card-subtitle>Team memberships: </mat-card-subtitle>
                                <mat-table [dataSource]="employee.Memberships" class="mat-elevation-z4"
                                    *ngIf="employee && employee.Memberships.length > 0">
                                    <ng-container matColumnDef="TeamName">
                                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.TeamName}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="TeamDescription">
                                        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.TeamDescription}}
                                        </mat-cell>
                                    </ng-container>

                                    <ng-container matColumnDef="TeamTitle">
                                        <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                                        <mat-cell *matCellDef="let element"> {{element.TeamTitle}} </mat-cell>
                                    </ng-container>

                                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                                </mat-table>
                                <mat-hint>To manage team memberships, go to the <a
                                        routerLink="/Employee/Teams">Teams</a> section (after saving any changes
                                    that you wish to keep in this form)</mat-hint>
                            </div>
                        </div>
                    </form>
                    <br>
                    <mat-card-actions class="sticky_action_row">
                        <mat-divider></mat-divider>
                        <br>
                        <button type="submit" mat-raised-button color="primary" *ngIf="isExistingEmployee"
                            (click)="onSubmit()">
                            <mat-icon>upgrade</mat-icon> Save changes
                        </button>
                        <button type="submit" mat-raised-button color="primary" *ngIf="!isExistingEmployee"
                            (click)="onSubmit()">
                            <mat-icon>add</mat-icon> Add employee
                        </button>
                        <mat-form-field *ngIf="isExistingEmployee">
                            <mat-label>More</mat-label>
                            <mat-select name="delete">
                                <mat-option (click)="deleteEmployee()"
                                    style="background-color: rgba(255, 0, 0, 0.377);">
                                    <mat-icon
                                        matTooltip="This delete will initiate a cache reset that takes a while. 
                                    The delete may therefore not be reflected immediately here and on REON but rather after 5-10 minutes">
                                        info</mat-icon>
                                    Delete employee
                                </mat-option>
                                <mat-option></mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- <button mat-raised-button color="warn" *ngIf="isExistingEmployee" (click)="deleteEmployee()"
                            matTooltip="This will delete the employee the event and any related participation requests"
                            #tooltip="matTooltip" style="float: right;">
                            <mat-icon>delete</mat-icon> Delete employee
                        </button> -->
                        <mat-error *ngIf="saveError">
                            <pre>Error: {{errorMessage}}</pre>
                        </mat-error>
                        <mat-hint *ngIf="lastSave" style="background-color: white;">
                            <mat-icon style="color:green;">done</mat-icon>Last saved at {{lastSave}}
                        </mat-hint>
                        <mat-spinner style="display: flex" *ngIf="is_saving" [diameter]="20" mode="indeterminate">
                        </mat-spinner>
                    </mat-card-actions>
                </mat-card-content>
            </mat-card>

        </div>

        <!-- <div class="col-lg-6 col-md-12" style="border: 1px dotted red;">

            <div class="error" *ngIf="employeeForm.invalid">
                <pre>Form errors: <code>{{ getAllErrors(employeeForm) | json }}</code></pre>
            </div>

            <p>
                Form Status: {{ employeeForm.status }}
            </p>

            <pre>Form values: <code>{{ employeeForm.value |json }}</code></pre>

        </div> -->
    </div>
    <br>
</div>