import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserEmailModule } from '../user-email.module';
import { Rule, RuleCategoryEnum } from '../_models/rule.model';

// @Injectable({
//   providedIn: UserEmailModule
// })
@Injectable({
  providedIn: 'root'
})
export class RulesService {

  constructor(private http: HttpClient) { }

  public getUserRules(userId: string): Observable<{ rules: Array<Rule>, distributionDisabled: boolean }> {
    return new Observable(observer => {
      this.http.get<any>(
        `${environment.baseUrlEmailSubApi}rule?userId=${userId}`
      ).pipe(
        map(data => {
          let rules = new Array<Rule>()
          if (data?.rules)
            rules = data.rules.map(x => new Rule(x.id, RuleCategoryEnum[x.categoryType], x.publicationtypes, x.sectors,
              x.countries, x.companies, x.analysts, x.currencies, x.marketcap ? [x.marketcap?.min, x.marketcap?.max] : null, x.languages, x.regions))
          return { rules: rules, distributionDisabled: data?.distributionDisabled }
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete()
          return data.error.title
        })
      ).subscribe(data => {
        observer.next(data as any)
        observer.complete()
      });
    })
  }

  public deleteRule(ruleId: number): Observable<boolean> {
    return new Observable(observer => {
      this.http.request<any>(
        "delete",
        `${environment.baseUrlEmailSubApi}Rule`,
        {
          body: {
            RuleId: ruleId
          }
        }
      ).pipe(
        map(data => {
          return data
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete()
          return data.error.title
        })
      ).subscribe(data => {
        observer.next(data as boolean)
        observer.complete()
      });
    })
  }

  userUpdated: Subject<any> = new Subject<any>();
  public updateUser(userId: string, distributionDisabled: boolean): Observable<any> {
    return new Observable(observer => {
      this.http.request<any>(
        "put",
        `${environment.baseUrlEmailSubApi}User`,
        {
          body: {
            UserId: userId,
            DisableDistribution: distributionDisabled
          }
        }
      ).pipe(
        map(data => {
          return data
        }),
        catchError(data => {
          console.log(data)
          //this.error = data.error.Message;
          observer.error(`${data.error.status}: ${data.error.title}`)
          observer.complete()
          return data.error.title
        })
      ).subscribe(data => {
        observer.next(data.user)
        this.userUpdated.next(data.user)
        observer.complete()
      });
    })
  }
}
