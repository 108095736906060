import { Component, Input, SimpleChanges } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { UserRoleNode, UserRoleFlatNode, NewComponent } from '../user.component';

@Component({
  selector: 'role-tree',
  templateUrl: './role-tree.component.html',
  styleUrls: ['role-tree.component.css']
})
export class RoleTreeComponent {
  @Input() disabled: boolean = false
  flatNodeMap = new Map<UserRoleFlatNode, UserRoleNode>();
  nestedNodeMap = new Map<UserRoleNode, UserRoleFlatNode>();
  selectedParent: UserRoleFlatNode | null = null;

  treeControl: FlatTreeControl<UserRoleFlatNode>;
  treeFlattener: MatTreeFlattener<UserRoleNode, UserRoleFlatNode>;
  dataSource: MatTreeFlatDataSource<UserRoleNode, UserRoleFlatNode>;
  checklistSelection = new SelectionModel<UserRoleFlatNode>(true /* multiple */);

  constructor(private _database: NewComponent) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<UserRoleFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
      this.treeControl.dataNodes.forEach(dn => { if ( dn.isSelected === true ) { this.checklistSelection.select(dn)} });
      //this.treeControl.expandAll();
    });
  }
  getLevel = (node: UserRoleFlatNode) => node.level;
  isExpandable = (node: UserRoleFlatNode) => node.expandable;
  getChildren = (node: UserRoleNode): UserRoleNode[] => node.children;
  hasChild = (_: number, _nodeData: UserRoleFlatNode) => _nodeData.expandable;
  hasNoContent = (_: number, _nodeData: UserRoleFlatNode) => _nodeData.name === '';

  ngOnChanges(changes: SimpleChanges){
    if(changes.disabled.currentValue != changes.disabled.previousValue && changes.disabled.currentValue)
      this.checklistSelection = new SelectionModel<UserRoleFlatNode>(true);
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: UserRoleNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new UserRoleFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.source = node.source;
    flatNode.isSelected = node.isSelected;
    flatNode.order = node.order;
    flatNode.key = node.key;
    flatNode.require = node.require;
    flatNode.allows = node.allows;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: UserRoleFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: UserRoleFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  roleItemSelectionToggle(node: UserRoleFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    // update parent data
    descendants.forEach(desc => {
      const isChecked = this.checklistSelection.selected.includes(desc);
      const keys = this._database.updateItem(this.flatNodeMap.get(desc), isChecked);
      this.treeControl.dataNodes.map(dn => {
        if (keys.includes(dn.name)) {
          isChecked === true ? this.checklistSelection.select(dn) : this.checklistSelection.deselect(dn)} })
    })
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  roleLeafItemSelectionToggle(node: UserRoleFlatNode): void {
    this.checklistSelection.toggle(node);
    const isChecked = this.checklistSelection.selected.includes(node);
    const keys = this._database.updateItem(this.flatNodeMap.get(node), isChecked);
    this.treeControl.dataNodes.map(dn => {
      if (keys.includes(dn.name)) {
        isChecked === true ? this.checklistSelection.select(dn) : this.checklistSelection.deselect(dn) } })
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: UserRoleFlatNode): void {
    let parent: UserRoleFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: UserRoleFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: UserRoleFlatNode): UserRoleFlatNode | null {
    const currentLevel = this.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

}
