import { Component, OnInit, Pipe, PipeTransform, EventEmitter } from '@angular/core';
import { EditEmployeeModel, EmployeeApiService, TeamModel, TeamMemberModel, enumStatus } from '../employee-api.service';
import { environment } from 'environments/environment';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {
  transform(list: any[], filterText: string): any {
    return list ? list.filter(item => item.TeamType.search(new RegExp(filterText, 'i')) > -1) : [];
  }
}

@Component({
  selector: 'employee-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {
  isLoading = true;
  teams: TeamModel[] = [];
  searchModel;
  searchModelChange: EventEmitter<any> = new EventEmitter();
  selectedTeam: TeamModel;
  showSearchInput = false;
  searchControl = new BehaviorSubject('');
  filteredSearch: EditEmployeeModel[];
  teamForm: FormGroup;
  customSearchControl = new FormControl('');
  lastSave: string;
  panelOpenState = false;
  constructor(private contentApi: EmployeeApiService) {
    this.initForm();
  }
  teamSaving = false;

  ngOnInit() {
    this.contentApi.getTeams().subscribe(data => {
      this.teams = data;
      this.isLoading = false;
    }, (error) => console.log(error));

    this.searchControl.subscribe(value => {
      if (value !== '' && value !== undefined) {
        this.searchByUserId(value).then(data => this.filteredSearch = data)
      }
    });
  }

  initForm() {
    this.teamForm = new FormGroup({
      Id: new FormControl(null),
      TeamType: new FormControl({value: '', disabled: true }, {
        validators: [
          Validators.required,
        ],
        updateOn: 'change'
      }),
      TeamName: new FormControl({ value: '', disabled: true }, {
        validators: [
          Validators.required,
        ],
        updateOn: 'change'
      }),
      Description: new FormControl('', {
        validators: [
          Validators.required,
        ],
        updateOn: 'change'
      }),
      Members: new FormArray([ /* empty by design */]),
    }, { updateOn: 'submit' });
  }

  updateSearchModel(value) {
    this.searchModel = value;
    this.searchModelChange.emit(this.searchModel);
  }

  selectTeam(team: TeamModel) {
    this.selectedTeam = team;
    this.initForm();
    this.teamForm.get('TeamType').disable();
    this.teamForm.get('TeamName').disable();
    this.teamForm.patchValue({
      Id: team.Id,
      TeamType: team.TeamType,
      TeamName: team.TeamName,
      Description: team.Description,
    });
    team.Members.sort(t => t.Position);
    team.Members.forEach((member, index) => {
      const group = new FormGroup({
        UserId: new FormControl(member.UserId, { validators: Validators.required, updateOn: 'change' }),
        Position: new FormControl(index + 1, { updateOn: 'change' }),
        Title: new FormControl(member.Title, { updateOn: 'change' }),
        FirstName: new FormControl(member.FirstName, { validators: Validators.required, updateOn: 'change' }),
        LastName: new FormControl(member.LastName, { validators: Validators.required, updateOn: 'change' }),
        HasPicture: new FormControl(member.HasPicture, { updateOn: 'change' }),
      });
      (<FormArray>this.teamForm.get('Members')).push(group);
    });
  }
  customSearchInput(event) {
    this.searchControl.next(event.target.value);
    return;
  }

  getPictureUrl(id: string, hasPicture) {
    return hasPicture ?
      `${environment.baseUrlReonApi}employee/Image?id=${id}` :
      `${environment.baseUrlReonApi}employee/Image?id=None`;
  }
  removeMember(index, event) {
    event.preventDefault();
    (<FormArray>this.teamForm.get('Members')).removeAt(index);
  }
  addTeamMember(member: MatAutocompleteSelectedEvent) {
    const memb = member.option.value
    const group = new FormGroup({
      UserId: new FormControl(memb.UserId, { validators: Validators.required, updateOn: 'change' }),
      Position: new FormControl(memb.Position, { updateOn: 'change' }),
      Title: new FormControl(memb.Title, { updateOn: 'change' }),
      FirstName: new FormControl(memb.FirstName, { validators: Validators.required, updateOn: 'change' }),
      LastName: new FormControl(memb.LastName, { validators: Validators.required, updateOn: 'change' }),
      HasPicture: new FormControl(memb.HasPicture, { updateOn: 'change' }),
    });
    (<FormArray>this.teamForm.get('Members')).push(group);
    this.customSearchControl = new FormControl('');
  }

  searchByUserId(id: string): Promise<EditEmployeeModel[]> {
    return this.contentApi.searchEmployeesUserId(id, null).toPromise();
  }
  toggleSearchInput() {
    this.showSearchInput = !this.showSearchInput;
    this.customSearchControl = new FormControl('');
  }

  drop(event: CdkDragDrop<TeamMemberModel[]>) {
    const members = this.teamForm.get('Members').value as TeamMemberModel[];
    moveItemInArray(members, event.previousIndex, event.currentIndex);
    members.forEach((member, index) => {
      member.Position = index + 1;
    });
    (<FormArray>this.teamForm.get('Members')).patchValue(members)
  }

  addNewTeam() {
    const team: TeamModel = {
      Id: null,
      TeamType: null,
      TeamName: null,
      Description: null,
      Members: []
    }
    this.selectTeam(team);
    this.teamForm.get('TeamType').enable();
    this.teamForm.get('TeamName').enable();
  }

  delete() {
    if (this.selectedTeam == null) {
      return;
    }
    this.contentApi.deleteTeam(this.selectedTeam.Id).subscribe(success => {
      if (success) {
        this.teams = this.teams.filter(t => t.Id !== this.selectedTeam.Id);
        this.selectedTeam = null;
      }
    }, err => {
      console.log(err);
    })
  }
  saveTeam() {
    event.preventDefault();
    if (!this.teamForm.valid) {
      console.log(this.teamForm.errors);
      return;
    }

    const rawValues  = this.teamForm.getRawValue(); // includes disabled team-type value aswell

    this.teamSaving = true;
    this.contentApi.saveTeam(rawValues).subscribe(data => {
      // console.log('data', data);
      if(data.Status && (data.Status as enumStatus).toString() === "Failed") {
        if(data.ExceptionList.findIndex(str => str.includes('TeamName') && str.includes('UNIQUE KEY')) != -1) {
          this.teamForm.controls['TeamName'].setErrors({'unique': true}); // Todo: check if the error is for the Unique TeamName error from DB
        }
      } else {
        const now = new Date(Date.now());
        this.lastSave = ('0' + now.getHours()).slice(-2) + ':' + ('0' + now.getMinutes()).slice(-2);
      }
      this.teamSaving = false;
    }, (error) => {
      console.log(error)
    });
  }

}
