import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiService } from 'app/user/user-api.service';
import {
  MorningMeetingApi,
  MorningMeetingModel,
  CompanyNameModel,
} from '../nmw-contributions-api.service';
import { environment } from '../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { MmEditComponent } from '../mm-edit/mm-edit.component';
import { AuthorizeService } from 'api-authorization/authorize.service';


@Component({
  selector: 'app-nmw-contributions',
  templateUrl: './nmw-contributions_create.component.html',
  styleUrls: ['../nmw-contributions.component.css'],
})
export class ContributeComponent implements OnInit {
  displayedColumns: string[] = ['button', 'Company', 'Headline', 'file', 'RecChange', 'FastComment', 'Analyst'];
  isLoading = false;
  user_analyst;
  morningmeetingList: MorningMeetingModel[] = [];
  deadline = null;
  error = '';
  api = environment.baseUrlReonApi;

  constructor(
    public dialog: MatDialog,
    private mmApi: MorningMeetingApi,
    public authorization: AuthorizeService,
    private router: Router,
    private userApi: UserApiService
  ) { }

  ngOnInit() {
    this.whoAmI();
    this.loadMMContributions();
  }
  whoAmI() {
    this.authorization
      .getUser()
      .subscribe(u => {
        this.user_analyst = u.name.substring(0, u.name.indexOf('@'));
      });
  }

  public loadMMContributions() {
    this.isLoading = true;
    this.mmApi
      .getmorningmeeting()
      .subscribe(meetingList => {
        this.morningmeetingList = meetingList;
        this.isLoading = false;
      }, error => {
        this.error = error;
      });
    this.mmApi
      .getDeadline('analystClose')
      .subscribe(dt => {
        this.deadline = new Date(dt.dt);
      }, error => {
        this.error = error;
      });
  }

  public editMM(mm) {
    if (mm != null && !this.canEdit(mm)) {
      return;
    }
    const dialogRef = this.dialog.open(MmEditComponent, {
      width: '600px',
      data: mm
    });
    dialogRef.afterClosed().subscribe(result => {
      this.loadMMContributions();
    });
  }

  canEdit(mm: MorningMeetingModel): boolean {
    return mm.analystId === this.user_analyst;
  }

  getAttachmet(id: number) {
    this.mmApi
      .getAttachment(id)
      .subscribe(res => {
        const fileURL = URL.createObjectURL(res);
        window.open(fileURL, '_blank');
      }, err => {
        console.log(err)
      });
  }
}

@Component({
  selector: 'app-nmw-contributions',
  templateUrl: './nmw-contributions_locked.component.html',
  styleUrls: ['../nmw-contributions.component.css']
})
export class NMW_Timeout implements OnInit {
  constructor() { }

  ngOnInit() { }
}
