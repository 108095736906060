import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { EmployeeModel, TeamModel } from 'app/employee/employee-api.service';
import { UserModel } from 'app/user/user-api.service';

@Injectable()
export class EventApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) { }

  getEvent(id: string): Observable<FullEvent> {
    return this.http.get<FullEvent>(`${this.baseUrlReonApi}Event/GetEventForEdit?eventId=${id}`,
      { withCredentials: true });
  }

  getAllEvents(): Observable<EventShort[]> {
    return this.http.get<EventShort[]>(this.baseUrlReonApi + 'Event/GetAllEvents',
      { withCredentials: true });
  }

  getCategories(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrlReonApi + 'Event/GetUniqueCategories',
      { withCredentials: true });
  }
  getLocations(): Observable<LocationsModel> {
    return this.http.get<LocationsModel>(this.baseUrlReonApi + 'Event/GetUniqueLocations',
      { withCredentials: true });
  }
  geteventlink(id: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrlReonApi}Document/GetEventLink?id=${id}`,
      { withCredentials: true });
  }
  getCompanies(): Observable<NameModel[]> {
    return this.http
      .get<NameModel[]>(this.baseUrlReonApi + 'Company/GetCompanies', {
        withCredentials: true
      });
  }
  getSectors(): Observable<NameModel[]> {
    return this.http
      .get<NameModel[]>(this.baseUrlReonApi + 'Sector/GetFlatSectors', {
        withCredentials: true
      });
  }
  getEventParticipants(form: any): Observable<EventParticipantRequestView[]> {
    return this.http
      .get<EventParticipantRequestView[]>(`${this.baseUrlReonApi}EventParticipant/Requests?response=${form.Status}`,
        { withCredentials: true });
  }
  searchEvents(model: any): Observable<EventShort[]> {
    return this.http.get<EventShort[]>(
      `${this.baseUrlReonApi}Event/SearchAllEvents?name=${model.Name}&category=${model.Category}&upcoming=${model.Upcoming}
      &status=${model.Status}`,
      { withCredentials: true });
  }

  updateParticipantRequest(form: EventParticipantRequestView): Observable<any> {
    return this.http.post<boolean>(`${this.baseUrlReonApi}EventParticipant/Respond`, form,
      { withCredentials: true });
  }
  postFile(fileToUpload: File, eventId: string, documentId: number): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('eventAttachment', fileToUpload, fileToUpload.name);
    formData.append('eventId', eventId);
    formData.append('documentId', documentId ? documentId.toString() : '0');
    return this.http.post<any>(this.baseUrlReonApi + 'EventAttachment/File', formData,
      { withCredentials: true });
  }
  postBanner(id: string, bannerFile: File): Observable<boolean> {
    const formData = new FormData();
    formData.append('eventId', id);
    formData.append('bannerImage', bannerFile, 'banner');
    return this.http.post<boolean>(
      this.baseUrlReonApi + 'EventBanner/Image', formData,
      { withCredentials: true });
  }
  sendInvite(form: EventParticipantRequestView): Observable<any>{
    return this.http.post<boolean>(`${this.baseUrlReonApi}EventParticipant/Invite`, form,
    { withCredentials: true });
  }
  put(ev: EventLong): Observable<any> {
    return this.http.put(
      this.baseUrlReonApi + 'Event/Put', ev,
      { withCredentials: true });
  }
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrlReonApi}Event/Delete?id=${id}`,
      { withCredentials: true });
  }

  deleteRequest(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrlReonApi}EventParticipant/Delete?id=${id}`,
      { withCredentials: true });
  }

}

export interface EventShort {
  EventId: number;
  Name: string;
  StartDate: Date;
  City: string;
  Category: string;
}
export interface EventLong extends EventShort {
  InsertDate: Date;
  EditDate: Date;
  Description: string;
  StartTime: Date;
  EndDate: Date;
  EndTime: Date;
  Location: string;
  Country: string;
  Status: EventStatus;
  DocumentId: number;
  Banner: File;
  GrowCompanyId: number[];
  GrowSectorId: number[];
  Participants: EventParticipant[];
  EventTeamUserids: string[];
  BannerId: number;
}
export interface FullEvent extends EventLong {
  EventTeamMembers: EmployeeModel[];
  ParticipantsDetailed: EventParticipantRequestView[];
  UserStatus: EventParticipantStatus;
  DateStr: string;
  TimeStr: string;
  Banner: File;
}

export enum EventStatus {
  Open, Planned, Closed, Cancelled, Completed, Suspended
}
export enum EventParticipantStatus {
  Pending, Accepted, Declined, Invited, Tentative, Cancelled, Attended, NoShow, NotSet
}
export interface EventAttachment {
  File: File;
  Description: string;
}
export interface EventParticipant {
  RequestId: number;
  InsertDate: Date;
  EditDate: Date;
  UserId: number
  EventId: string;
  Status: EventParticipantStatus;
  Message: string;
}
export interface LocationsModel {
  Cities: string[];
  Countries: string[];
  Locations: string[]
}

export interface NameModel {
  Name: string;
  Id: number;
}

export interface EventParticipantRequestView {
  RequestId: number;
  UserId: string;
  UserEmail: string;
  EventId: string;
  EventName: string;
  EventDescription: string;
  EditDate: Date;
  Status: EventParticipantStatus;
  Message: string;
}
