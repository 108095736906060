import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { FileData } from 'app/common/file-data';

@Injectable()
export class ThirdPartyEventApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) { }

  private processFileResponse(res: HttpResponse<Blob>) {
    console.log('processFileResponse', res);
    const fileName = this.getFileName(res.headers.get('Content-Disposition'));
    const fileData: FileData = {
      fileName: fileName,
      blob: res.body
    };
    return fileData;
  }

  private getFileName(contentDisposition: string): string {
    if (!contentDisposition) { return null; }
    // decodeURIComponent
    const start = contentDisposition.indexOf('filename*=utf-8\'\'') + 17;
    let end = contentDisposition.indexOf(';', start);
    if (end <= 0) { end = contentDisposition.length; }
    let txt = contentDisposition.substring(start, end);
    txt = decodeURIComponent(txt);
    return txt.replace(/\"/g, '').replace(/ /g, '_');
  }


  getExcel(): Observable<FileData> {
    return this.http
      .get(`${this.baseUrlReonApi}event/getthirdpartyexcel/`, {
        responseType: 'blob' as 'json',
        withCredentials: true,
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<Blob>) => this.processFileResponse(res)));
  }


  // getExcel(): Observable<any> {
  //   return Observable.create(observer => {
  //     let xhr = new XMLHttpRequest();

  //     xhr.open(
  //       "GET",
  //       `${this.baseUrlReonApi}event/getthirdpartyexcel/`,
  //       true
  //     );
  //     xhr.setRequestHeader("Content-type", "application/json");
  //     xhr.responseType = "blob";
  //     xhr.withCredentials = true;

  //     xhr.onreadystatechange = function () {
  //       if (xhr.readyState === 4) {
  //         if (xhr.status === 200) {
  //           var contentType =
  //             "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  //           var blob = new Blob([xhr.response], { type: contentType });
  //           var header = xhr.getResponseHeader('Content-Disposition');
  //           observer.next({ blob: blob, header: header });
  //           observer.complete();
  //         } else {
  //           observer.error(xhr.response);
  //         }
  //       }
  //     };
  //     xhr.send();
  //   });
  // }
}
