import { Component } from '@angular/core';
import { AdminApiService } from './admin-api.service'

// New
@Component({
    selector: 'admin-deviceMessage',
    template: '<h2>Device Message</h2><p>Under construction</p>'
})

export class DeviceMessageComponent {

  constructor(private contentApi: AdminApiService){}

}