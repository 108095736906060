<li *ngFor="let c of media" style="margin-bottom:0.5em; text-transform:none; list-style-type: none" >

<h2>{{c.cname}}</h2>
<div class="flex-column">
  <li *ngFor="let group of c.groups" style="margin-bottom:0.5em; text-transform:none;">
    <div (click)="this['toggle' + group.id] = !this['toggle' + group.id]" class="hover">
      <h3>{{group.gname}}
        <fa-icon [icon]="['fas', this['toggle' + group.id] ? 'angle-down': 'angle-up' ]"
          [styles]="{ 'float': 'right', 'color': 'grey', 'padding-right': '0'}"
        ></fa-icon>
    </h3>
    </div>
    <ul class="flex-column" *ngIf="!this['toggle' + group.id]" is-open="false" style="font-size:14px;">
      <div class="row">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Url</th>
              <th>Expiry Date</th>
              <th>Group</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let clip of group.clips" (click)="selectClip(clip)" [ngClass]="{'table-active': clip.selected}">
              <td>{{clip.Name}}</td>
              <td>{{clip.MediaId}}</td>
              <td>{{clip.NeverExpire? "No expire date" : clip.Xdate | date:'yyyy-MM-dd'}}</td>
              <td>{{clip.GroupName}}</td>
            </tr>
          </tbody>
        </table>

        <button (click)="selectClip(null, group.gname, c.cname)" class="btn btn-outline-warning">Add link</button>
      </div>



    </ul>
  </li>

  <button (click)="selectClip(null, null, c.cname)" class="btn btn-success">New group</button>
</div>

</li>
<div *ngIf="selectedClip">
  <h2>Edit:</h2>
    <div class="row">
      <div class="col-2"><b>Name</b> <input [(ngModel)]="selectedClip.Name" class="form-control" [disabled]="updating" /></div>
      <div class="col-2"><b>Url</b> <input [(ngModel)]="selectedClip.MediaId" class="form-control" [disabled]="updating"/></div>
      <div class="col-2"><b>Expiry Date</b> <input *ngIf="!selectedClip.NeverExpire"
          [disabled]="selectedClip.NeverExpire || updating" type="date" [(ngModel)]="selectedClip.Xdate"
          class="form-control" />
        <br><button (click)="selectedClip.NeverExpire = !selectedClip.NeverExpire" [disabled]="updating"
          class="btn btn-success">{{selectedClip.NeverExpire? "Set date" : "No expiry date"}}</button>
      </div>
      <div class="col-2"><b>Group</b> <input [(ngModel)]="selectedClip.GroupName" class="form-control" [disabled]="updating"/></div>
    </div>

    <div style="float:right;padding-top:8px;">
      <button (click)="saveClip()" class="btn btn-success">{{selectedClip.Id == 0? "Create" : "Update"}}</button>
      <button (click)="this.selectedClip.selected = false; selectedClip = null" class="btn btn-outline-warning" >Cancel</button>
      <button (click)="deleteClip()" class="btn btn-danger" [disabled]="selectedClip.Id == 0">Delete</button>
    </div>
  </div>
  <p style="color: green;"> {{status}}</p>