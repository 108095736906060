import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserApiService, UserModel, ContentRole } from '../user-api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTableDataSource } from '@angular/material/table';
import { MatChipInputEvent } from '@angular/material/chips';
import { Sort } from '@angular/material/sort';

@Component({
  selector: "user-search",
  templateUrl: 'userSearch.component.html',
  styleUrls: ['userSearch.component.css'],
})
export class SearchComponent implements OnInit {
  @ViewChild('roleInput', {static: false}) roleInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  searchForm = new FormGroup({
    Email: new FormControl('', {
      validators: [Validators.maxLength(50)],
      updateOn: 'change'
    }),
    ClientCategory: new FormControl('', {}),
    ContentRoles: new FormControl('', {}),
    Enabled: new FormControl(true, {}),
    Expired: new FormControl(true, {}),
    disableEnablefilter: new FormControl(true, {}),
    disableExpirefilter: new FormControl(false, {}),
    filter: new FormControl('', {}),
  });
  displayedColumns: string[] = ['Email', 'Enabled', 'Expired', /* 'Id', */ 'action'];
  loading = false;
  userId = '';
  userEmail = '';
  userList = new MatTableDataSource(<UserModel[]>[]);
  visible = true;
  selectable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  clientCategories: string[];
  contentRoles: ContentRole[];
  roleCtrl = new FormControl();
  selectedRolesString: string[] = [];
  contentRolesMap: { [key: string]: ContentRole }[] = [];
  selectedRoles: ContentRole[] = [];
  filteredRoles: Observable<ContentRole[]>;

  constructor(
    private userApi: UserApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    ) {
      this.activatedRoute.queryParams.subscribe(params => {
        this.searchForm.patchValue({
          Email: params['Email'] ? params['Email'] : '',
          ClientCategory: params['ClientCategory'] ? params['ClientCategory'] : '',
          ContentRoles: params['ContentRoles'] ? params['ContentRoles'] : [],
          Enabled: params['Enabled'] !== undefined ?  (params['Enabled'] === 'true') : true,
          Expired: params['Expired'] !== undefined ?  (params['Expired'] === 'true') : true,
          disableEnablefilter: params['disableEnablefilter'] !== undefined ?  (params['disableEnablefilter'] === 'true') : true,
          disableExpirefilter: params['disableExpirefilter'] !== undefined ?  (params['disableExpirefilter'] === 'true') : false,
          filter: params['filter'] ? params['filter'] : '',
        });
      });
    }
  ngOnInit(): void {
    this.userList.paginator = this.paginator;
    this.searchForm.get('disableEnablefilter').valueChanges.subscribe(val =>
      val ? this.searchForm.get('Enabled').enable() : this.searchForm.get('Enabled').disable());
    this.searchForm.get('disableExpirefilter').valueChanges.subscribe(val =>
      val ? this.searchForm.get('Expired').enable() : this.searchForm.get('Expired').disable());

    this.userApi.getClientCategories().subscribe(
      categories => {
        this.clientCategories = categories;
      },
      (err) => { console.log(err) }
    );
    this.searchForm.get('filter').valueChanges.subscribe(val => this.userList.filter = val.trim());
    this.searchForm.get('filter').updateValueAndValidity();
    this.searchForm.get('disableEnablefilter').updateValueAndValidity();
    this.searchForm.get('disableExpirefilter').updateValueAndValidity();
    this.userApi.getContentRoles().subscribe(
      roles => {
        this.contentRoles = roles.sort((dn1, dn2) => dn1.Name < dn2.Name ? -1 : 1);
        roles.forEach(role => this.contentRolesMap[role.Name] = role);
        this.filteredRoles = this.roleCtrl.valueChanges.pipe(
          startWith(null),
          map((role: string | null) => role ? this._filter(role) : roles.slice()));
      },
      (err) => { console.log(err) }
    );
    // this.onSubmit();
  }
  private _filter(value: string): ContentRole[] {
    const filterValue = value.toLowerCase();
    return this.contentRoles.filter(role => role.Name.toLowerCase().indexOf(filterValue) === 0);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.selectedRolesString.push(value.trim());
      this.selectedRoles.push(this.contentRolesMap[value.trim()]);
    }
    if (input) {
      input.value = '';
    }
    this.roleCtrl.setValue(null);
  }

  remove(role: string): void {
    const index = this.selectedRolesString.indexOf(role);
    if (index >= 0) {
      this.selectedRolesString.splice(index, 1);
      this.selectedRoles.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedRolesString.push(event.option.viewValue);
    this.selectedRoles.push(this.contentRolesMap[event.option.viewValue]);
    this.roleInput.nativeElement.value = '';
    this.roleCtrl.setValue(null);
  }
  updateFilterModel(value) {
    this.userList.filter = value.trim();
  }
  sortData(sort: Sort) {
    const data = this.userList.data.slice();
    if (!sort.active || sort.direction === '') {
      this.userList.data = data;
      return;
    }

    this.userList.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Email': return compare(a.EMail, b.EMail, isAsc);
        case 'Enabled': return compare(a.Enabled, b.Enabled, isAsc);
        case 'Expired': return compare(a.Expired, b.Expired, isAsc);
        case 'Id': return compare(a.Id, b.Id, isAsc);
        default: return 0;
      }
    });
  }

  selectUser(u: UserModel): void {
    this.router.navigate(['/User/Edit'], { queryParams: { email: encodeURIComponent(u.EMail) } });
  }
  onSubmit() {
    this.router.navigate(['/User/Search'], { queryParams: this.searchForm.value });

    this.loading = true;
    this.searchForm.get('ContentRoles').setValue(this.selectedRolesString)
    this.userApi.searchUsers(this.searchForm.value).subscribe(data => {
      this.userList.data = data;
      this.loading = false;
    }, (error) => {
      this.loading = false;
      console.log(error)
    });

    this.searchForm.get('ContentRoles').setValue(null)
  }
}
function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
