<h2>
  Makrosvepet Plus - add new user
</h2>

<div class="row">
  <div class="col-xl-6">
    <ng-container *ngIf="user">
      <form #myForm="ngForm" (ngSubmit)="submitUser($event)" novalidate>
        <h5>User data</h5>
        <div class="form-group row yCenter">
          <label for="email" class="col-4 col-form-label">Email</label>
          <div class="col-8">
            <input
              required
              type="email"
              class="form-control"
              name="user.email"
              [(ngModel)]="user.email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            />
            <span
              *ngIf="
                myForm.control.controls['user.email'] &&
                myForm.control.controls['user.email'].errors
              "
              style="color:red"
              >*</span
            >
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="role" class="col-4 col-form-label">Role</label>
          <div class="col-8">
            <select
              required
              class="form-control form-control-sm"
              name="user.role"
              [(ngModel)]="user.role"
              (change)="changeRole()"
            >
              <option
                *ngFor="let role of makroSvepetRoles"
                value="{{ role.title }}"
                >{{ role.title }}</option
              >
            </select>
            <span
              *ngIf="
                myForm.control.controls['user.role'] &&
                myForm.control.controls['user.role'].errors
              "
              style="color:red"
              >*</span
            >
          </div>
        </div>

        <div class="form-group row yCenter">
          <label for="expdate" class="col-4 col-form-label">Expired Date</label>
          <div class="col-8">
            <input
              required
              type="date"
              class="form-control"
              min="1800-01-01"
              name="user.expdate"
              [(ngModel)]="user.expdate"
            />
            <span
              *ngIf="
                myForm.control.controls['user.expdate'] &&
                myForm.control.controls['user.expdate'].errors
              "
              style="color:red"
              >*</span
            >
          </div>
        </div>

        <div class="col-12" style="text-align:right">
          <img
            *ngIf="isSubmitting"
            style="margin-left:10px; height:25px; width:auto;"
            src="./../assets/ajax-loader.gif"
            alt="Loading..."
          />

          <button
            [disabled]="!myForm.valid || isSubmitting"
            type="submit"
            class="btn btn-success"
          >
            Add
          </button>
          <p *ngIf="lastSave">
            <br />
            Last saved at {{ lastSave }}
          </p>

          <div *ngIf="lastSave">
            <h5>Recently added</h5>
            <h6 *ngFor="let u of addedUsers">
              {{ u.email }}, {{ u.role }}, {{ u.expdate }}
            </h6>
          </div>

          <p *ngIf="saveError" style="color:red;">
            Error during save!
          </p>
        </div>
      </form>
    </ng-container>
  </div>
</div>
