import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class MiscApiService
{
    baseUrlReonApi = environment.baseUrlReonApi;

    constructor(private http: HttpClient) { }

    

}