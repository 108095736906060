import { RouterModule, Routes } from "@angular/router";
import {
  DocStatisticsComponent,
  UploadStatisticsComponent
} from "./statistics.component";
import { FailedLoginsComponent } from "./failed-logins.component";
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: "Statistics",
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
      { path: "Document-Statistics", component: DocStatisticsComponent },
      { path: "Upload-Statistics", component: UploadStatisticsComponent },
      { path: "Failed-logins", component: FailedLoginsComponent }
    ]
  }
];

export const routing = RouterModule.forChild(routes);
