import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { UserEmailComponent } from './user-email.component';
import { MatCardModule } from '@angular/material/card';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { CreateRuleModule } from './create-rule/create-rule.module';
import { RuleStringBuilderModule } from './string-creator/rule-string-builder.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    UserEmailComponent
  ],
  providers: [
    DecimalPipe
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    CreateRuleModule,
    RuleStringBuilderModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    MatProgressBarModule,
    MatCheckboxModule,
  ],
  exports: [
    UserEmailComponent
  ]
})
export class UserEmailModule { }
