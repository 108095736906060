import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class MakrosvepetPlusAdminApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) { }

  getNewsletter(reonUrlId: string): Observable<INewsletterModel> {
    return this.http.get<INewsletterModel>(this.baseUrlReonApi + "Newsletter/GetNewsletter", {
      withCredentials: true
    });
  }

  getLatestMakrosvepet(): Observable<INewsletterModel[]> {
    return this.http.get<INewsletterModel[]>(this.baseUrlReonApi + "Newsletter/GetLatestMakrosvepet",
      { withCredentials: true });
  }

  getMailingListAccountLogMakrosvepet(): Observable<IMailinglistAccountLog[]> {
    return this.http.get<IMailinglistAccountLog[]>(this.baseUrlReonApi + "Newsletter/GetMailingListAccountLogMakrosvepet?take=20",
      { withCredentials: true });
  }

  downloadMakrosvepet(): Observable<IMailinglistAccountLog> {
    return this.http.get<IMailinglistAccountLog>(this.baseUrlReonApi + "Newsletter/DownloadMakrosvepet",
      { withCredentials: true });
  }

  downloadAll(): Observable<IMailinglistAccountLog[]> {
    return this.http.get<IMailinglistAccountLog[]>(this.baseUrlReonApi + "Newsletter/DownloadAllMailinglists",
      { withCredentials: true });
  }

}

export interface IMailinglistAccountLog {
  Id: number;
  MailinglistAccountId: number;
  Created: Date;
  Status: string;
  LogMessage: string;
}

export interface IMailinglist {

  characterSet: string;
  created: string;
  description: string;
  folderID: string;
  fromEmail: string;
  fromName: string;
  id: string;
  mailinglistAccountId: string;
  mailinglistId: string;
  name: string;
  newsletters?: INewsletterModel[];
  updated: string;
}

export interface IMailinglistAccount {
  id: number;
  name: string;
  apiKey: string;
  description: string;
  contactPerson: string;
  created: Date;
  updated: Date;
}

export interface INewsletterModel {
  NewsletterId: number;
  Id: number;
  MailinglistId: number;
  MailinglistAccountId: number;
  FolderId: number;
  Name: string;
  Subject: string;
  Description: string;
  Created: Date;
  Updated: Date;
  ContentHtmlVersion: string;
  ContentTextVersion: string;
  ReonUrl: string;
  IsPublished: boolean;
  IsNewsLetter: boolean;
  ReonUrlComposed: string;
}
