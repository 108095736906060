import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SecServAdminApiService, IMailinglistAccountLog, INewsletterModel } from './SecServAdmin-api.service'
import { UserApiService, UserModel, UserExModel, UserStats, UserStatItem } from '../user/user-api.service'
import { environment } from 'environments/environment';

@Component({
  selector: 'downloadSecServ',
  templateUrl: 'SecServAdmin.component.html'
})

export class DownloadComponent {
  mailingListAccountLog: IMailinglistAccountLog;
  mailingListAccountLogHistory: IMailinglistAccountLog[];
  newsletterAll: INewsletterModel[];
  newsletter: INewsletterModel;
  downloadDone: boolean = false;
  downloadDisabled: boolean = false;
  baseUrlReon = environment.baseUrlReon;
  sending: boolean = false;
  amount: string = "";

  constructor(private api: SecServAdminApiService, private router: Router, private userApi: UserApiService) {
    this.getNewsletterPreview();
    this.getSecServLog();
  }

  copyLink(text: string) {
    var event = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
      document.removeEventListener('copy', event);
    }
    document.addEventListener('copy', event);
    document.execCommand('copy');

  }
  
  isDownloadDisabled(): boolean {
    return this.downloadDisabled;
  }

  downloadSecServ(): void {
    this.downloadDisabled = true;
    this.api.downloadSecServ().subscribe(data => {
      this.mailingListAccountLog = data;
      this.downloadDisabled = false;
      this.getSecServLog();
      this.getNewsletterList();
    });
  }

  getSecServLog(): void {
    this.api.getMailingListAccountLogSecServ().subscribe(log => this.mailingListAccountLogHistory = log);
  }

  showNewsletter(reonUrlId: string): void {
    this.api.getNewsletter(reonUrlId).subscribe(x => this.newsletter = x);
  }

  getNewsletterPreview(): void {
    this.api.getLatestSecServ().subscribe(x => this.newsletterAll = x);
    this.amount = "20 latest";
  }

  getNewsletterList(): void {
    this.api.getAllSecServ().subscribe(x => this.newsletterAll = x);
    this.amount = "All";
  }

  setVisibilityNewsLetter(n){
    this.sending = true; 
    this.api.updateVisibility(n.NewsletterId, n.visibleReon).subscribe(() => this.sending = false);
  }

}