import { RouterModule, Routes } from '@angular/router';
import {
  ContributeComponent,
  NMW_Timeout
} from './contribute/ContributeComponent';
import { ReviewComponent } from './review/ReviewComponent';
import { NmwCalendarEventsComponent } from './calendar/nmw-calendarevents.component';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
  {
    path: 'nordic_morning_wrap',
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
      { path: 'contribute', component: ContributeComponent },
      { path: 'review', component: ReviewComponent },
      { path: 'contribute_locked', component: NMW_Timeout },
      { path: 'review_locked', component: NMW_Timeout },
      { path: 'calendar_events', component: NmwCalendarEventsComponent }
    ]
  }
];

export const routing = RouterModule.forChild(routes);
