import { RouterModule, Routes } from '@angular/router';
import {
    DeviceMessageComponent
} from './admin.component';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';

const routes: Routes = [
	{
		path: 'Admin',
		canActivate: [AuthorizeGuard],
		data: {navCheck: true},
		children: [
			{ path: 'Device-Message', component: DeviceMessageComponent }
		]
	},
];

export const routing = RouterModule.forChild(routes);
