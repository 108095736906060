<span class="outer" [innerHTML]="ruleString"></span>

<!-- <ng-container *ngIf="publicationTypes != null">
    <span class='publication-span' *ngFor="let element of publicationTypes; last as isLast">
        {{element.value}}<ng-container *ngIf="!isLast">, </ng-container>
    </span>
</ng-container>

<ng-container *ngIf="sectors != null">
    <span class='sector-span' *ngFor="let element of sectors; last as isLast">
        {{element.value}}<ng-container *ngIf="!isLast">, </ng-container>
    </span>
</ng-container>
 -->
