import { RouterModule, Routes } from '@angular/router';
import { EmployeeEditComponent } from './employee-edit.component';
import { EmployeeSearchComponent } from './search/employee-search.component';
import { AuthorizeGuard } from 'api-authorization/authorize.guard';
import { TeamsComponent } from './teams/teams.component';

const routes: Routes = [ {
    path: 'Employee',
    canActivate: [AuthorizeGuard],
    data: {navCheck: true},
    children: [
        { path: 'New', component: EmployeeEditComponent },
        { path: 'Edit', component: EmployeeEditComponent, pathMatch: 'prefix' },
        { path: 'Search', component: EmployeeSearchComponent },
        { path: 'Teams', component: TeamsComponent },
    ]
},
];

export const routing = RouterModule.forChild(routes);
