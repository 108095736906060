import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { GetNavbarResponse, Nav, WhoAmIRsp } from "./auth.model";
import { environment } from 'environments/environment';

@Injectable()
export class AuthService
{
	accessUrls = new ReplaySubject<string[]>(1);
	constructor(private http: HttpClient)	{	}

	whoAmI(): Observable<{ Username: string, IsLoggedIn: boolean }>
	{
		return this.http.post<WhoAmIRsp>(environment.baseUrlReonApi + "auth/WhoAmI", {}, { withCredentials: true });
	}

	getNavbar(): Observable<{ Navs: Nav[], IsLoggedIn: boolean, EMail: string }>
	{
		let headersAdditional: HttpHeaders = new HttpHeaders();
		headersAdditional.append('Cache-control', 'no-cache');
		headersAdditional.append('Cache-control', 'no-store');
		headersAdditional.append('Expires', '0');
		headersAdditional.append('Pragma', 'no-cache');
		return this.http.get<GetNavbarResponse>(environment.baseUrlReonApi + "admin/GetNavbar", { withCredentials: true, headers: headersAdditional });
	}

}
