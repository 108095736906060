import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Logger } from "app/_helpers";
import { Observable, from } from "rxjs";
import { map } from "rxjs/operators";
import { FileData } from './file-data';

@Injectable({ providedIn: "root" })
export class FileService {
  constructor(private http: HttpClient, private logger: Logger) {}

  public saveData = (function () {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      return function (blob: any, fileName: string) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      };
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      return function (blob: any, fileName: string) {
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      };
    }
  })();

  public downloadBlob(url: string): Observable<HttpResponse<Blob>> {
    return this.http
      .get(url, {
        responseType: "blob",
        withCredentials: true,
        observe: "response",
      });
  }

  public downloadFile(url: string): Observable<FileData> {
    return this.downloadBlob(url)
      .pipe(map((res: HttpResponse<Blob>) => this.processFileResponse(res)));
  }

  public downloadAndSave(url: string): Observable<FileData> {
    
    const promise = new Promise<FileData>(resolve => {
        this.downloadFile(url).subscribe((fileData: FileData) => {
          this.saveData(fileData.blob, fileData.fileName);
          fileData.success = !fileData.errorMsg;
          this.logger.debug("after savedata", fileData);
          resolve(fileData);
        }, (res: HttpErrorResponse) => {
          
          const result: FileData = {
            success: false
          };

          const error = res as Error;
          if (error)
          {
            result.errorMsg = error.message
            return resolve(result);
          }

          const fileReader = new FileReader();
          fileReader.readAsText(res.error);
          fileReader.onload = function() {
            result.errorMsg = fileReader.result as string;
            resolve(result);
          }
        });
    });
    return from(promise);

  }

  private processFileResponse(res: HttpResponse<Blob>) {
    const fileData: FileData = {
      fileName: this.getFileName(res.headers.get("Content-Disposition")),
      blob: res.body
    };
    return fileData;
  }

  // private getContentDisposition(res: HttpResponse<Blob>) : string
  // {
  //   return res.headers.get("Content-Disposition");
  // }

  private getFileName(contentDisposition: string): string {
    if (!contentDisposition) {
      throw new Error("Content disposition is empty.\nPlease check that the 'Access-Control-Expose-Headers' header has been set to 'Content-Disposition'");
    }
    this.logger.debug("getFileName", contentDisposition);

    const prefixContentDisp = "attachment; filename=";

    let start = contentDisposition.indexOf(prefixContentDisp);
    if (start == 0 && contentDisposition.indexOf("utf-8") < 0)
    {
      start += prefixContentDisp.length;
    }
    else 
    {
      start = contentDisposition.indexOf("filename*=utf-8''")
      if (start >= 0) {
        start += 17;
      }
      else
      {
          throw new Error(`Invalid content disposition: ${contentDisposition}`)
      }
    } 



    let end = contentDisposition.indexOf(";", start);
    if (end <= 0) {
      end = contentDisposition.length;
    }

    let txt = contentDisposition.substring(start, end);
    txt = decodeURIComponent(txt);
    return txt.replace(/\"/g, "").replace(/ /g, "_");
  }
}
