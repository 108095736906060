import { Component, Input } from "@angular/core";
import {
  UserExModel,
  UserApiService,
  UserAdminNavAccess
} from "../user-api.service";

@Component({
  selector: "user-nav-access",
  templateUrl: "user-nav-access.html"
})
export class UserNavAccess {
  @Input() user: UserExModel | null = {};
  accessList: UserAdminNavAccess[] = [];

  constructor(private userApi: UserApiService) {}

  ngOnChange() {
    if (!this.accessList && this.user) this.loadAccessList();
  }

  loadAccessList() {
    this.userApi
      .getUserAccessList(this.user.EMail)
      .subscribe(accessList => (this.accessList = accessList));
  }

  changeAccessAllowed(access: UserAdminNavAccess) {
    this.userApi
      .changeUserAdminNavAccess(access.UserNavAccess)
      .subscribe(err => err);
  }

  // @computed 
  get anySelected() {
    const anySelected = this.accessList.reduce(
      (prev, currentAccess) => prev || currentAccess.UserNavAccess.Allow,
      false
    );

    return anySelected;
  }

  toggleAll() {
    const anySelected = this.anySelected;
    this.accessList.forEach(a => {
      a.UserNavAccess.Allow = !anySelected;
      this.changeAccessAllowed(a);
    });
  }
}
