<h2>Admin för MakrosvepetPlus</h2>
<div style="width:600px">
  <div class="form-group row">
    <div class="col-10">
      <button [disabled]="isDownloadDisabled()" class="btn btn-outline-warning" (click)="downloadMakrosvepet()">Hämta artiklar</button>
      <div *ngIf="isDownloadDisabled()">
        <div class="loader"></div>
      </div>
      <div *ngIf="mailingListAccountLog">
        <span>Nerladdning klar! {{mailingListAccountLog.LogMessage}}</span>
      </div>

    </div>
  </div>
</div>

<div class="table-wrapper">
  <div>
    <h2>Artiklar och nyhetsbrev - 20 senaste</h2>
  </div>

  <p *ngIf="!newsletterLatest">Laddar...</p>
  <table class="simple-table" sortable="True">
    <thead>
      <tr style="font-weight: bold; border-bottom: 1px solid black;">
        <th></th>
        <th>Namn</th>
        <th>Ämne</th>
        <th>Skapad</th>
        <th>Uppdaterad</th>
        <th>Preview</th>
        <!--<th sortable-column="Name">Namn</th>
        <th sortable-column="Created">Skapad</th>
        <th sortable-column="Updated">Uppdaterad</th>
        <th></th>
        <th sortable-column="Name">URL</th>-->
        <!--<th style="text-align: left">
          <a href="#" ng-click="orderByField='Name'; reverseSort = !reverseSort">
            Namn <span ng-show="orderByField == 'Name'"><span ng-show="!reverseSort">^</span><span ng-show="reverseSort">v</span></span>
          </a>
        </th>
        <th style="text-align: left">
          <a href="#" ng-click="orderByField='Subject'; reverseSort = !reverseSort">
            Ämne <span ng-show="orderByField == 'Subject'"><span ng-show="!reverseSort">^</span><span ng-show="reverseSort">v</span></span>
          </a>
        </th>
        <th style="text-align: right">
          <a href="#" ng-click="orderByField='Created'; reverseSort = !reverseSort">
            Skapad <span ng-show="orderByField == 'Created'"><span ng-show="!reverseSort">^</span><span ng-show="reverseSort">v</span></span>
          </a>
        </th>
        <th></th>
        <th></th>-->
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let i of newsletterLatest">
        <td><button class="btn" id="copyUrl" #url value="{{baseUrlReon}}makrosvepetplus/nyhetsbrev/{{i.ReonUrl}}" (click)="copyLink(url.value)">Kopiera</button></td>
        <td>{{i.Name}}</td>
        <td style="text-align: left">{{i.Subject}}</td>
        <td><span>{{i.Created | date:'yyyy-MM-dd HH:mm:ss'}}</span></td>
        <td><span>{{i.Updated | date:'yyyy-MM-dd HH:mm:ss'}}</span></td>
        <td><a id="preview" target="_blank" href="{{baseUrlReon}}makrosvepetplus/nyhetsbrev/{{i.ReonUrl}}" hreflang="SV" title="{{i.Name}} (MakrosvepetPlus nyhetsbrev)" translate="no">{{i.Name}}</a></td>
        <!--/makrosvepetplus/nyhetsbrev-->
      </tr>
    </tbody>
  </table>
</div>

<div>
  <div>
    <h2>Logg</h2>
  </div>

  <div class="table-wrapper">
    <p *ngIf="!mailingListAccountLogHistory">Laddar...</p>
    <div *ngIf="mailingListAccountLogHistory">
      <div>
        <table class="newsletter-table">
          <thead>
            <tr style="font-weight: bold; border-bottom: 1px solid black;">
              <th>Id</th>
              <th>Skapad</th>
              <th>Status</th>
              <th>Statustext</th>
          </thead>
          <tr *ngFor="let i of mailingListAccountLogHistory">
            <td>{{i.Id}}</td>
            <td>
              <span>{{i.Created | date:'yyyy-MM-dd HH:mm'}}</span>
            </td>
            <td>{{i.Status}}</td>
            <td>{{i.LogMessage}}</td>
          </tr>
        </table>
      </div>
    </div>
    <br />
  </div>
</div>
