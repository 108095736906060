import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';

@Injectable()
export class UserApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) { }

  getUser(email: string): Observable<UserExModel> {
    return this.http
      .get(`${this.baseUrlReonApi}user/GetUser?email=${email}`, {
        withCredentials: true
      })
      .pipe(
        map((res: UserExModel) => {
          if (!res.NeverExpire) res.NeverExpire = false;
          return res;
        }));
  }
  getClientCategories(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.baseUrlReonApi}user/GetClientCategories?`, {
        withCredentials: true
      });
  }
  getContentRoles(): Observable<ContentRole[]> {
    return this.http
      .get<ContentRole[]>(`${this.baseUrlReonApi}user/GetContentRoles?`, {
        withCredentials: true
      });
  }
  prepareUser(): Observable<UserExModel> {
    return this.http
      .get<any>(`${this.baseUrlReonApi}user/PrepareUserObject?`, {
        withCredentials: true
      });
  }
  isUniqueEmail(email: string): Observable<boolean> {
    return this.http
      .get<boolean>(
        `${this.baseUrlReonApi}user/isUnique?email=${email}`,
        { withCredentials: true }
      );

  }
  searchUsers(model: any): Observable<UserModel[]> {
    return this.http
      .get<UserModel[]>(
        `${this.baseUrlReonApi}user/Search?Email=${model.Email}&ClientCategory=${model.ClientCategory}
        &Enabled=${model.Enabled}&ContentRoles=${model.ContentRoles.length > 0 ? model.ContentRoles : ''}&Expired=${model.Expired}`,
        { withCredentials: true }
      )
  }
  searchUser(email: string, userId: string): Observable<UserModel[]> {
    return this.http
      .get<UserModel[]>(
        `${this.baseUrlReonApi}user/Search?email=${email}&userId=${userId}`,
        { withCredentials: true }
      );
  }

  syncSSOuser(user: any, apiPath: string): Observable<any> {
    return this.http.post<any>(apiPath, user, { withCredentials: true });
  }
  sendInviteEmail(invite: any, apiPath: string): Observable<any> {
    return this.http.post<any>(apiPath, invite, { withCredentials: true });
  }
  getSSOEmailConfirmed(apiPath: string): Observable<any> {
    return this.http.get<any>(apiPath, { withCredentials: true });
  }

  setRoles(
    userId: number,
    roles: ContentRole[],
    setDefaultAdminAccessRules: boolean
  ): Observable<any> {

    const data = {
      UserId: userId,
      Roles: roles,
      SetDefaultAdminAccessRules: setDefaultAdminAccessRules
    };

    return this.http
      .post(
        `${this.baseUrlReonApi}user/SetRoles`, data,
        { withCredentials: true }
      );
  }

  setPassword(email: string, newPassword: string): Observable<any> {
    const data = { Email: email, NewPassword: newPassword };
    return this.http
      .post(
        `${this.baseUrlReonApi}user/SetPassword`,
        data,
        { withCredentials: true }
      );
  }

  updateUser(user: UserExModel): Observable<any> {
    return this.http
      .post(`${this.baseUrlReonApi}user/UpdateUser`, user, {
        withCredentials: true
      });
  }
  insertUser(user: UserExModel): Observable<any> {
    return this.http
      .post(`${this.baseUrlReonApi}user/InsertUser`, user, {
        withCredentials: true
      });
  }

  getLastUserStat(cromId: string): Observable<UserStatItem> {
    return this.http
      .get<UserStatItem>(`${this.baseUrlReonApi}statistics/GetLastUserStat?cromId=${cromId}`, {
        withCredentials: true
      });
  }

  getUserStats(cromId: string): Observable<UserStats> {
    return this.http
      .get<UserStats>(`${this.baseUrlReonApi}statistics/GetUserStats?cromId=${cromId}`, {
        withCredentials: true
      });
  }

  // Temporary add user functions until CROM catches up:
  setNewUser(email: string, category: string, expdate: string, userType: string): Observable<any> {

    const data = {
      Email: email,
      ClientCategory: category,
      Date: expdate,
      Type: userType
    };

    return this.http
      .post(
        `${this.baseUrlReonApi}user/InternalInsertNewUser`,
        data,
        { withCredentials: true }
      );
  }

  getUserAccessList(email: string): Observable<UserAdminNavAccess[]> {
    return this.http
      .get<UserAdminNavAccess[]>(
        `${this.baseUrlReonApi}user/GetAdminNavAccessRights?email=${email}`,
        {
          withCredentials: true
        }
      );
  }

  changeUserAdminNavAccess(userAccess: TreoUserNavAccess): Observable<any> {
    return this.http
      .post(
        `${this.baseUrlReonApi}user/ChangeAdminNavAccessRights`,
        userAccess,
        {
          withCredentials: true
        }
      );
  }

}

export interface UserModel {
  EMail?: string;
  OriginalEMail?: string;
  Id?: string;
  UserId?: number;
  Enabled?: boolean;
  Expired?: boolean;
}
export interface UserExModel extends UserModel {
  ClientCategory?: string;
  MaxScreenings?: number;
  NeverExpire?: boolean;
  ExpireDate?: Date;
  LastActivity?: string;
  MustChangePassword?: boolean;
  Roles?: ContentRole[];
}
export interface UserRole {
  Id: number;
  IsSet: boolean;
  Name: string;
  IsReon: boolean;
  Source: string;
  Group: string;
  Order: number;
}
export interface ContentRole {
  Id: number;
  IsSet: boolean;
  Name: string;
  UserRole: string;
  SSORole: string;
  Source: string;
  Group: string;
  Order: number;
  Require: string[];
  Allows: string[];
}

export interface UserStatItem {
  Timestamp: string;
  Type: string;
  Referrer: string;
  UserAgent: number;
  Url: string;
  IpAddress: string;
  LoggedIn: boolean;
  CromId: string;
}

export interface UserStats {
  Items: UserStatItem[];
  UserAgents: string[];
  AllIps: string;
}

export interface UserAdminNavAccess {
  AdminNavUrl: AdminNavUrl;
  UserNavAccess: TreoUserNavAccess;
}

export class AdminNavUrl {
  Id: number;
  RelativeUrl: string;
}

export class TreoUserNavAccess {
  UserId: number;
  AdminNavUrlId: number;
  Allow: boolean;
}
