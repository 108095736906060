<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node"  matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node" [disabled]="disabled" [checked]="checklistSelection.isSelected(node)" [matTooltip]="node.require" matTooltipPosition="left"
            (change)="roleLeafItemSelectionToggle(node)">{{node.name}} <div class="mat-caption">{{node.source}}</div></mat-checkbox>
    </mat-tree-node>

    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)" [disabled]="disabled"  [indeterminate]="descendantsPartiallySelected(node)"
            (change)="roleItemSelectionToggle(node)">{{node.name}}</mat-checkbox>
    </mat-tree-node>
</mat-tree>
