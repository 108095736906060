<div class="container-fluid">
    <div class="row" style="margin-top:20px;"></div>
    <div class="col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title> Search users </mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12 col-lg-3" style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
                        <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                            <mat-card-subtitle> Filters</mat-card-subtitle>
                            <mat-divider></mat-divider>
                            <div class="col-12">
                                <mat-form-field class="field-full-width" appearance="fill">
                                    <mat-label>Client category</mat-label>
                                    <mat-select name="client_category" formControlName="ClientCategory">
                                        <mat-option *ngFor="let cats of clientCategories" [value]="cats">
                                            {{cats}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="col-12">
                                <mat-form-field class="field-full-width" appearance="outline">
                                    <mat-label>Select some roles</mat-label>
                                    <mat-chip-list #chipList aria-label="Role selection">
                                        <mat-chip
                                        *ngFor="let role of selectedRolesString"
                                        [selectable]="selectable"
                                        removable="true"
                                        (removed)="remove(role)">
                                        {{role}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                      </mat-chip>
                                    </mat-chip-list>
                                      <input
                                        #roleInput
                                        [formControl]="roleCtrl"
                                        [matAutocomplete]="auto"
                                        [matChipInputFor]="chipList"
                                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="add($event)">

                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                      <mat-option *ngFor="let role of filteredRoles | async" [value]="role.Name">
                                        {{role.Name}}
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="col-12">
                                <mat-label>Filter by:</mat-label>
                                <mat-form-field class="field-full-width" floatLabel="auto" appearance="none">
                                    <mat-checkbox class="filter-label-margin" formControlName="disableEnablefilter"><mat-label>Enabled</mat-label></mat-checkbox>
                                    <mat-slide-toggle
                                        class="filter-label-margin"
                                        color="primary"
                                        formControlName="Enabled" >
                                    </mat-slide-toggle>

                                    <mat-checkbox class="filter-label-margin" formControlName="disableExpirefilter"><mat-label>Expired</mat-label></mat-checkbox>
                                    <mat-slide-toggle
                                        class="filter-label-margin"
                                        color="primary"
                                        formControlName="Expired" >
                                    </mat-slide-toggle>
                                    <textarea matInput hidden></textarea>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="col-12" *ngIf="userList.data.length > 0">
                                <mat-form-field appearance="standard">
                                    <mat-label>Filter</mat-label>
                                    <input matInput placeholder="keyword" formControlName="filter">
                                        <mat-icon matPrefix>filter_alt</mat-icon>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                        </form>

                        <!-- <mat-divider vertical ></mat-divider> -->
                    </div>
                    <div class="col-md-12 col-lg-9">
                        <div class="col-12">
                            <form [formGroup]="searchForm" class="form-inline" (ngSubmit)="onSubmit()">
                                <mat-form-field class="field-full-width" appearance="outline">
                                    <input matInput placeholder="Email" formControlName="Email">
                                </mat-form-field>

                                <button type="submit" mat-raised-button color="primary">
                                    <mat-icon>search</mat-icon> Search
                                </button>
                                <mat-spinner *ngIf="loading" [diameter]="20" mode="indeterminate"></mat-spinner>
                            </form>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-label *ngIf="userList.data">{{userList.data.length}} results</mat-label>
                        <div class="col-12">
                            <table mat-table [dataSource]="userList" style="width: 100%;" *ngIf="userList"  matSort (matSortChange)="sortData($event)">

                                <ng-container matColumnDef="Email">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Email </th>
                                    <td mat-cell *matCellDef="let element"> {{element.EMail}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Enabled">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Enabled </th>
                                    <td mat-cell *matCellDef="let element"> <mat-icon>{{element.Enabled ? 'done' : 'clear'}}</mat-icon> </td>
                                </ng-container>

                                <ng-container matColumnDef="Expired">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Expired </th>
                                    <td mat-cell *matCellDef="let element"> {{element.Expired ? 'yes' : 'no'}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Id">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Id </th>
                                    <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-raised-button color="primary" (click)="selectUser(element)">
                                            <mat-icon>arrow_right_alt</mat-icon> Edit
                                        </button>
                                    </td>
                                </ng-container>
                                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 100, 1000]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>