<h2>Nordic Morning Wrap - Calendar Events Section</h2>

<p>Upload new calendar events here</p>

<br />
<form>
  <div class="form-check">
    <dl>
      <dt><i>Full path</i></dt>
      <dd>{{ calendarFile.FilePath }}</dd>
      <dt><i>File</i></dt>
      <dd>{{ calendarFile.FileName }}</dd>
      <dt><i>Type</i></dt>
      <dd>{{ calendarFile.Type }}</dd>
      <dt><i>Last modified</i></dt>
      <dd>{{ calendarFile.LastModified }}</dd>
      <dt><i>Exists</i></dt>
      <dd>{{ calendarFile.Exists }}</dd>
      <dt>
        <a [href]="calendarFile.DownloadLink" download>download here</a>
      </dt>
    </dl>
    <input
      type="file"
      style="width:550px;"
      (change)="selectFile($event)"
      placeholder="Select file"
      accept=".docx"
      required
      #fileInput
    />
  </div>
  <br /><br />
  <button
    type="button"
    class="btn btn-outline-warning"
    (click)="submit()"
    [disabled]="!fileReadyForUpload"
  >
    Submit
  </button>
  <p *ngIf="error" style="color:red;">{{ error }}</p>
</form>
