import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FullEvent, EventApiService, EventStatus, NameModel, EventParticipantRequestView } from '../event-api.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeeModel, TeamModel, EmployeeApiService } from 'app/employee/employee-api.service';
import { Observable, ReplaySubject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthorizeService } from 'api-authorization/authorize.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatCheckboxChange } from '@angular/material/checkbox';


// TODO:Flytta till bättre plats
declare class nicEditor {
  constructor(a: any);
  panelInstance(elementName: string): nicEditor;
  removeInstance(elementName: string): nicEditor;
  instanceById(elementName: string): nicEditorInstanse;
}
declare class nicEditorInstanse {
  void;//syncs the content of the editor with the textarea value
  getContent(): string;
  setContent(html: string): void;
  saveContent();
}

@Component({
  selector: 'app-event-edit',
  templateUrl: './event-edit.component.html',
  styleUrls: ['./event-edit.component.css']
})
export class EventEditComponent implements OnInit {
  eventForm = new FormGroup({
    EventId: new FormControl('', { validators: [], updateOn: 'change' }),
    Name: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
    Description: new FormControl('', { validators: [], updateOn: 'change' }),
    InsertDate: new FormControl('', { validators: [], updateOn: 'change' }),
    EditDate: new FormControl('', { validators: [], updateOn: 'change' }),
    Category: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
    Country: new FormControl('', { validators: [], updateOn: 'change' }),
    Location: new FormControl('', { validators: [], updateOn: 'change' }),
    City: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
    StartDate: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
    StartTime: new FormControl('', { validators: [], updateOn: 'change' }),
    EndDate: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
    EndTime: new FormControl('', { validators: [], updateOn: 'change' }),
    GrowCompanyId: new FormControl([], { validators: [], updateOn: 'change' }),
    GrowSectorId: new FormControl([], { validators: [], updateOn: 'change' }),
    EventTeamUserids: new FormControl([], { validators: [], updateOn: 'change' }),
    DocumentId: new FormControl('', { validators: [], updateOn: 'change' }),
    BannerId: new FormControl('', { validators: [], updateOn: 'change' }),
    Status: new FormControl('', { validators: [Validators.required], updateOn: 'change' }),
    Participants: new FormControl([], { validators: [], updateOn: 'change' }),
  }, { updateOn: 'submit' });

  inviteForm = new FormGroup({
    UserEmail: new FormControl('', {validators: [Validators.required], updateOn: 'change'}),
    EventId: new FormControl('', {validators: [Validators.required], updateOn: 'change'}),
    Status: new FormControl('Invited', {validators: [Validators.required], updateOn: 'change'}),
    Message: new FormControl('', {}),
  }, { updateOn: 'submit' });
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  displayedColumns: string[] = ['Status', 'UserEmail', 'EventName', 'Last change'];
  isLoading = true;
  isSaving = false;
  errorMessage: any;
  saveSuccess;

  disableStartTimeInput = new FormControl(false);
  disableEndTimeInput = new FormControl(false);
  teamControl = new FormControl();
  companyControl = new FormControl();
  sectorControl = new FormControl();

  add_new_category = false;
  temp_new_category = '';
  htmlContentArea: nicEditor;
  showRichEditor = false;

  eventQuery: string;
  event: ReplaySubject<FullEvent> = new ReplaySubject<FullEvent>();
  categories: string[] = [''];
  eventteams: TeamModel[];
  selectedEventTeam: EmployeeModel[];
  companyList: NameModel[];
  filteredcompanyList: Observable<NameModel[]>;
  sectorsList: NameModel[];
  filteredSectorsList: Observable<NameModel[]>;
  participantList = new MatTableDataSource(<EventParticipantRequestView[]>[]);

  cities: string[];
  filteredCities: Observable<string[]>;
  countries: string[];
  filteredCountries: Observable<string[]>;
  locations: string[];
  filteredLocations: Observable<string[]>;
  statusTypes = Object.keys(EventStatus).map(key => EventStatus[key]).filter(value => typeof value === 'string') as string[];
  fileToUpload: File = null;
  attachment: any;
  bannerImage: File = null;
  bannerDisplay;

  api = environment.baseUrlReonApi;
  constructor(
    private authorizeService: AuthorizeService,
    public eventApi: EventApiService,
    public employeeApi: EmployeeApiService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.eventForm.get('Category').valueChanges.subscribe(val => {
      if (val === 'Banner seminar') {
        this.showRichEditor = true;
        this.htmlContentArea = new nicEditor({ fullPanel: true, iconsPath: 'assets/nicEditorIcons.gif' }).panelInstance('htmlContentArea');
      } else {
        if (this.htmlContentArea != null) {
          this.htmlContentArea.removeInstance('htmlContentArea');
        }
        this.showRichEditor = false;
      }
    })
    this.participantList.paginator = this.paginator;
    this.event.subscribe(ev => this.EventViewModelChange(ev));
    this.teamControl.valueChanges.subscribe(val => this.eventTeamChange(val));
    this.eventApi.getCategories().subscribe(categories => {
      this.categories = [...categories, ''];
    });
    this.eventApi.getLocations().subscribe(locations => {
      this.cities = locations.Cities;
      this.countries = locations.Countries;
      this.locations = locations.Locations;
    });

    this.employeeApi.getEventTeams().subscribe(teams => {
      this.eventteams = teams;
      if (this.teamControl.value) {
        const members = this.teamControl.value;
        this.selectedEventTeam = [];
        this.teamControl.setValue(this.eventteams.find(team => team.Members.length === members.length &&
          team.Members.every((member, index) => member.UserId === members[index].UserId)), { emitEvent: false });
      }
      if (!this.isExistingEvent) {
        this.authorizeService.getUser().subscribe(u => {
          if (u && u.name) {
            const defaultTeam = teams.find(
              team => team.Members.find(
                memb => memb.UserId === u.name.substr(0, u.name.indexOf('@'))
              ) !== undefined);
            this.teamControl.setValue(defaultTeam)
          }
        })
      }
    });
    this.eventApi.getCompanies().subscribe(companies => {
      this.companyList = companies;
      const compList = this.eventForm.get('GrowCompanyId').value.map(id => this.companyList.find(comp => comp.Id === id));
      this.eventForm.get('GrowCompanyId').setValue(compList);
    })
    this.eventApi.getSectors().subscribe(sectors => {
      this.sectorsList = sectors;
      const sectlist = this.eventForm.get('GrowSectorId').value.map(id => this.sectorsList.find(comp => comp.Id === id));
      this.eventForm.get('GrowSectorId').setValue(sectlist);
    })
    this.eventQuery = this.route.snapshot.queryParams['eventId'];
    if (this.eventQuery) {
      this.eventApi.getEvent(this.eventQuery).subscribe((event: FullEvent) => {
        this.event.next(event);
        if (event.DocumentId && event.DocumentId !== 0) {
          this.eventApi.geteventlink(event.DocumentId).subscribe((attachment) => {
            this.attachment = attachment;
          });
        }
        this.isLoading = false;
      }, error => {
        this.errorMessage = error.error;
        this.isLoading = false;
      })
    } else {
      this.isLoading = false;
    }
    this.filteredCities = this.eventForm.get('City').valueChanges
      .pipe(startWith(''), map(value => this._filterCities(value)));
    this.filteredCountries = this.eventForm.get('Country').valueChanges
      .pipe(startWith(''), map(value => this._filterCountries(value)));
    this.filteredLocations = this.eventForm.get('Location').valueChanges
      .pipe(startWith(''), map(value => this._filterLocations(value)));
    this.filteredcompanyList = this.companyControl.valueChanges
      .pipe(startWith(''), map(value => this._filterCompanies(value)));
    this.filteredSectorsList = this.sectorControl.valueChanges
      .pipe(startWith(''), map(value => this._filterSectors(value)));
  }
  get isExistingEvent(): boolean {
    return !!this.eventQuery;
  }

  EventViewModelChange(ev: FullEvent) {
    if (!ev) { return; }
    this.bannerDisplay = ev.Banner;
    this.teamControl.setValue(this.eventteams ? this.eventteams.find(team => team.Members.length === ev.EventTeamMembers.length &&
      team.Members.every((member, index) => member.UserId === ev.EventTeamMembers[index].UserId)) :
      ev.EventTeamMembers);
    const sD = new Date(ev.StartDate);
    const sT = new Date(ev.StartTime);
    const eD = new Date(ev.EndDate);
    const eT = new Date(ev.EndTime);
    const sD_str = new Date(Date.UTC(sD.getUTCFullYear(), sD.getUTCMonth(), sD.getUTCDate(),
      ev.StartTime ? sT.getUTCHours() : null, ev.StartTime ? sT.getUTCMinutes() : null)).toISOString().substring(0, 16);
    const eD_str = new Date(Date.UTC(eD.getUTCFullYear(), eD.getUTCMonth(), eD.getUTCDate(),
      ev.EndTime ? eT.getUTCHours() : null, ev.EndTime ? eT.getUTCMinutes() : null)).toISOString().substring(0, 16);
    this.eventForm.patchValue({
      EventId: ev.EventId,
      Name: ev.Name,
      InsertDate: ev.InsertDate,
      EditDate: ev.EditDate,
      Description: ev.Description,
      Category: ev.Category,
      Country: ev.Country,
      Location: ev.Location,
      City: ev.City,
      StartDate: sD_str,
      StartTime: sD_str,
      EndDate: eD_str,
      EndTime: eD_str,
      EventTeamUserids: ev.EventTeamUserids,
      DocumentId: ev.DocumentId,
      BannerId: ev.BannerId,
      Status: ev.Status,
      Participants: ev.Participants,
    });
    if (this.companyList) {
      const compList = ev.GrowCompanyId.map(id => this.companyList.find(comp => comp.Id === id));
      this.eventForm.get('GrowCompanyId').setValue(compList);
    } else {
      this.eventForm.get('GrowCompanyId').setValue(ev.GrowCompanyId);
    }
    if (this.sectorsList) {
      const sectList = ev.GrowSectorId.map(id => this.sectorsList.find(comp => comp.Id === id));
      this.eventForm.get('GrowSectorId').setValue(sectList);
    } else {
      this.eventForm.get('GrowSectorId').setValue(ev.GrowSectorId);
    }

    if (ev.StartTime == null) { this.disableStartTimeInput.setValue(true) }
    if (ev.EndTime == null) { this.disableEndTimeInput.setValue(true) }
    this.participantList.data = ev.ParticipantsDetailed;
    this.inviteForm.patchValue({
      EventId: ev.EventId,
    })
  }
  eventTeamChange(ev: any) {
    this.selectedEventTeam = [];
    if (!ev) {
      this.eventForm.get('EventTeamUserids').setValue(ev);
    } else if (ev.Members) {
      this.eventForm.get('EventTeamUserids').setValue(ev.Members.map(me => me.UserId));
    } else if (ev) {
      this.eventForm.get('EventTeamUserids').setValue(ev);
    }
    if (!ev) { return; }
    if (!ev.Members) {
      ev.map(member => this.employeeApi.getEmployeeRegular(member.UserId).subscribe(emp =>
        this.selectedEventTeam.find(evt => evt.Email === emp.Email) == null ? this.selectedEventTeam.push(emp) : null)
        );
    } else {
      ev.Members.map(member => this.employeeApi.getEmployeeRegular(member.UserId).subscribe(emp =>
        this.selectedEventTeam.find(evt => evt.Email === emp.Email) == null ? this.selectedEventTeam.push(emp) : null)
        );
    }
  }
  setCustomCategory(value) {
    this.temp_new_category = value;
  }
  saveCustomCategory(event) {
    event.preventDefault();
    if (this.temp_new_category === '') {
      return;
    }
    const duplicateEntry = this.categories.find(cat =>
      cat === this.temp_new_category);
    if (duplicateEntry) {
      this.eventForm.get('JobTitleList').setErrors({ 'duplicate': true });
      return;
    }
    this.categories = [...this.categories, this.temp_new_category];
    this.eventForm.get('Category').patchValue(this.temp_new_category);
  }
  getPictureUrl(id: string, hasPicture) {
    return hasPicture ?
      `${environment.baseUrlReonApi}employee/Image?id=${id}` :
      `${environment.baseUrlReonApi}employee/Image?id=None`;
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  onBannerChange(files: FileList) {
    if (files && files.length) {
      const file = files.item(0);
      if (!file.type.startsWith('image')) {
        alert('Sorry, the file has to be of an image format.')
      } else {
        this.bannerImage = file;
      }
    }
  }

  optionClicked(event: Event) {
    event.stopPropagation();
  }
  sortData(sort: Sort) {
    const data = this.participantList.data.slice();
    if (!sort.active || sort.direction === '') {
      this.participantList.data = data;
      return;
    }

    this.participantList.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Status': return compare(a.Status, b.Status, isAsc);
        case 'UserId': return compare(a.UserId, b.UserId, isAsc);
        case 'EventId': return compare(a.EventId, b.EventId, isAsc);
        case 'Edited': return compare(a.EditDate, b.EditDate, isAsc);
        default: return 0;
      }
    });
  }

  toggleSelectionCompany(company: NameModel, ev: MatCheckboxChange) {
    const selectedComps = this.eventForm.get('GrowCompanyId').value;
    if (ev.checked) {
      this.eventForm.get('GrowCompanyId').setValue([...selectedComps, company]);
    } else {
      this.eventForm.get('GrowCompanyId').setValue(selectedComps.filter(co => co !== company));
    }
  }
  toggleSelectionSector(sector: NameModel, ev: MatCheckboxChange) {
    const selectedComps = this.eventForm.get('GrowSectorId').value;
    if (ev.checked) {
      this.eventForm.get('GrowSectorId').setValue([...selectedComps, sector]);
    } else {
      this.eventForm.get('GrowSectorId').setValue(selectedComps.filter(co => co !== sector));
    }
  }
  onSubmit() {
    this.eventForm.get('StartTime').setValue(this.disableStartTimeInput.value ? null : this.eventForm.get('StartDate').value)
    this.eventForm.get('EndTime').setValue(this.disableEndTimeInput.value ? null : this.eventForm.get('EndDate').value);
    const tempComp = this.eventForm.get('GrowCompanyId').value;
    const tempSect = this.eventForm.get('GrowSectorId').value
    this.eventForm.get('GrowCompanyId').setValue(tempComp.map(comp => comp.Id));
    this.eventForm.get('GrowSectorId').setValue(tempSect.map(sect => sect.Id));

    if (!this.eventForm.valid) {
      this.eventForm.setErrors({ ...this.eventForm.errors });
      console.log('not valid');
      return;
    }
    if (this.eventForm.get('Category').value === 'Banner seminar') {
      this.eventForm.get('Description').setValue(this.htmlContentArea.instanceById('htmlContentArea').getContent())
    }
    this.isSaving = true;
    const docId = this.eventForm.get('DocumentId').value;
    this.eventApi.put(this.eventForm.value).subscribe(eventId => {
      this.isSaving = false;
      this.saveSuccess = true;
      if (eventId && (this.fileToUpload || this.bannerImage)) {
        this.isSaving = true;
        this.saveSuccess = false;
        if (this.bannerImage != null) {
          this.eventApi.postBanner(eventId, this.bannerImage).subscribe(() => {
            this.isSaving = false;
            this.saveSuccess = true;
          }, error => {
            this.saveSuccess = false;
            this.errorMessage = error;
          });
        }
        if (this.fileToUpload) {
          this.eventApi.postFile(this.fileToUpload, eventId, docId).subscribe(dr => {
            this.eventApi.geteventlink(dr.TreoDocId).subscribe((attachment) => {
              console.log(attachment)
              this.attachment = attachment;
            });
            this.isSaving = false;
            this.saveSuccess = true;
          }, error => {
            this.saveSuccess = false;
            this.errorMessage = error;
          });
        }
      }
    }, (error) => {
      this.saveSuccess = false;
      this.errorMessage = error;
    });

    this.eventForm.get('GrowCompanyId').setValue(tempComp);
    this.eventForm.get('GrowSectorId').setValue(tempSect);
  }

  deleteEvent() {
    if (confirm('Are you sure to delete ' + name)) {
      console.log('Implement delete functionality here');
    }
    this.isSaving = true;
    if (!this.isExistingEvent) {
      return;
    }
    this.eventApi.delete(this.eventForm.get('EventId').value).subscribe(() => {
      this.isSaving = false;
      this.saveSuccess = true;
    }, error => {
      this.saveSuccess = false;
      this.errorMessage = error;
    })
  }

  sendInvite(){
    if (!this.inviteForm.valid) {
      this.inviteForm.setErrors({ ...this.eventForm.errors });
      console.log('not valid');
      return;
    }
    this.isSaving = true;
    this.eventApi.sendInvite(this.inviteForm.value).subscribe(data => {
      this.isSaving = false;
      this.saveSuccess = true;
    }, err => {
      console.log(err);
      this.errorMessage = err;
      this.saveSuccess = false;
      this.isSaving = false;
    });
  }
  getAllErrors(form: FormGroup): { [key: string]: any; } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors = (control instanceof FormGroup)
        ? this.getAllErrors(control)
        : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any; });
    return hasError ? result : null;
  }

  private _filterCities(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.cities.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterCountries(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.countries.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterLocations(value: string): string[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.locations.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterCompanies(value: any): NameModel[] {
    const filterValue = value;
    return this.companyList.filter(option => option.Name.toLowerCase().includes(filterValue) || option.Id.toString() === value);
  }
  private _filterSectors(value: any): NameModel[] {
    const filterValue = value.toLowerCase();
    return this.sectorsList.filter(option => option.Name.toLowerCase().includes(filterValue) || option.Id.toString() === value);
  }
}

function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
