import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';


import {
  Disclaimer,
  InFocusPublication,
  InFocusPublicationMod,
  DocumentsModel,
  Image,
  Banner,
  BannerPage,
  BannerFile,
  InternalPublicationAttachmentModel
} from './model';
import { CommandDataResponse } from 'app/commandResponse';
import { environment } from 'environments/environment';

@Injectable()
export class ContentApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) {}

  // Disclaimers
  getDisclaimers(): Observable<Disclaimer[]> {
    return this.http
      .get<Disclaimer[]>(this.baseUrlReonApi + 'Content/GetAllDisclaimers', {
        withCredentials: true
      });
  }
  saveDisclaimer(d: Disclaimer): Observable<any> {
    return this.http
      .post(environment.baseUrlReonApi + 'Content/SaveDisclaimer', d, {
        withCredentials: true
      });
  }

  deleteDisclaimer(id: number): Observable<any> {
    return this.http
      .post(
        environment.baseUrlReonApi + 'Content/DeleteDisclaimer',
        { id: id },
        { withCredentials: true }
      );
  }

  savePdfDisclaimer(file: File, d: Disclaimer): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('pdf', file, file.name);
    formData.append('data', JSON.stringify(d));
    return this.http
      .post(
        environment.baseUrlReonApi + 'Content/SavePdfDisclaimer',
        formData,
        { withCredentials: true }
      );
  }

  // In Focus Publications
  getInFocusPublications(type: string): Observable<InFocusPublication[]> {
    return this.http
      .get<InFocusPublication[]>(
        this.baseUrlReonApi + 'Content/GetCurrentPublications?idType=' + type,
        { withCredentials: true }
      );
  }

  updateAllInFocusPublication(
    data: InFocusPublicationMod[]
  ): Observable<InFocusPublication[]> {
    return this.http
      .post<InFocusPublication[]>(this.baseUrlReonApi + 'Content/UpdateAllInFocusPublication', data, {
        withCredentials: true
      });
  }

  getInFocusPublicationsSearch(term: string): Observable<DocumentsModel> {
    const start = new Date();
    start.setFullYear(start.getFullYear() - 2);
    const end = new Date();
    end.setDate(end.getDate() + 1);
    return this.http
      .get<DocumentsModel>(
        `${
          this.baseUrlReonApi
        }document/filterpublications?EndDate=${end.toISOString()}&Headline=${term}&PageNb=1&PageSize=30&StartDate=${start.toISOString()}&Category=All`
      );
  }

  // Images
  getAllImages(): Observable<Image[]> {
    return this.http
      .get<Image[]>(this.baseUrlReonApi + 'banner/getallimages', {
        withCredentials: true
      });
  }

  updateImage(image: Image): Observable<any> {
    return this.http
      .post(this.baseUrlReonApi + 'banner/updateImage', image, {
        withCredentials: true
      });
  }

  deleteImage(guid: any): Observable<any> {
    return this.http
      .post(
        this.baseUrlReonApi + 'banner/trashImage?guid=' + guid,
        {},
        {
          withCredentials: true
        }
      );
  }

  insertImage(
    name: string,
    description: string,
    file: Uint8Array,
    fileName: string
  ): Observable<Image> {
    return this.http
      .post<Image>(
        this.baseUrlReonApi + 'banner/insertImage',
        {
          name: name,
          description: description,
          file: this.convertUint8Array(file),
          filename: fileName
        },
        { withCredentials: true }
      );
  }

  // Banners
  getAllBanners(): Observable<Banner[]> {
    return this.http
      .get<Banner[]>(this.baseUrlReonApi + 'banner/getallbanners', {
        withCredentials: true
      });
  }

  insertBanner(
    banner: Banner,
    bannerPages: Array<BannerPage>,
    file: BannerFile
  ): Observable<any> | null {
    file.File = this.convertUint8Array(file.File);
    return this.http.post(
      this.baseUrlReonApi + 'banner/insertbanner',
      { banner: banner, bannerPages: bannerPages, bannerFile: file },
      { withCredentials: true }
    );
  }

  updateBanner(
    banner: Banner,
    bannerPages: Array<BannerPage>,
    file: BannerFile
  ): Observable<any> | null {
    file.File = this.convertUint8Array(file.File);
    return this.http.post(
      this.baseUrlReonApi + 'banner/updatebanner',
      { banner: banner, bannerPages: bannerPages, bannerFile: file },
      { withCredentials: true }
    );
  }

  deleteBanner(bannerId: number): Observable<any> | null {
    return this.http
      .post(
        this.baseUrlReonApi + 'banner/trashbanner?id=' + bannerId,
        {},
        {
          withCredentials: true
        }
      );
  }

  getBannerLocations(): Observable<Array<string>> {
    return this.http
      .get<Array<string>>(this.baseUrlReonApi + 'banner/getallbannerlocations', {
        withCredentials: true
      });
  }

  getBannerPagesByBannerId(bannerId: number): Observable<Array<BannerPage>> {
    return this.http
      .get<Array<BannerPage>>(
        this.baseUrlReonApi +
          'banner/GetBannerPagesByBannerId?bannerId=' +
          bannerId,
        { withCredentials: true }
      );
  }

  private convertUint8Array(file: Uint8Array) {
    if (file != null) {
      const param = [];
      Object.keys(file).forEach(key => {
        const byteForKey = file[key];
        param.push(byteForKey);
      });
      return param;
    }
  }

  GetTopLatestInternalPublicationAttachments(): Observable<InternalPublicationAttachmentModel[]> {
    return this.http
      .get<InternalPublicationAttachmentModel[]>(
        `${this.baseUrlReonApi}Content/GetTopLatestInternalPublicationAttachments`,
        {
          withCredentials: true
        }
      );
  }

  uploadInternalPublicationAttachment(form: FormData): Observable<CommandDataResponse<InternalPublicationAttachmentModel>> {
    return this.http
      .post<CommandDataResponse<InternalPublicationAttachmentModel>>(
        `${this.baseUrlReonApi}Content/UploadInternalPublicationFile`,
        form,
        {
          withCredentials: true
        }
      );
  }

}
