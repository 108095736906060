import { Component, OnInit } from '@angular/core';
import { BondFileInfo, BondApiService } from 'app/bond-admin/bond-admin-api.service'
@Component({
  selector: 'app-bond-admin',
  templateUrl: './bond-admin.component.html',
  styleUrls: ['./bond-admin.component.css']
})
export class BondAdminComponent implements OnInit {
  fileList: BondFileInfo[];
  displayedColumns: string[] = ['icon', 'Name', 'Size', 'Created', 'Action'];
  isLoading = false;
  newFile: File = null;
  errors: any;
  constructor(private api: BondApiService) { }

  ngOnInit() {
    this.GetFileInfo();
  }

  GetFileInfo() {
    this.isLoading = true;
    this.fileList = [];
    this.errors = null;
    this.api.loadExistingFileInfo().subscribe(list => {
      this.fileList = list;
      this.isLoading = false;
    });
  }

  getFileIcon(ext: string) {
    if (ext === '.zip') {
      return 'folder';
    } else if (ext == null) {
      return 'block';
    } else {
      return 'insert_drive_file'
    }
  }

  readFile(event: Event, bondFile: BondFileInfo) {
    this.newFile = null;
    const fileElement = <HTMLInputElement>event.target;
    if (!fileElement.files || !fileElement.files[0]) {
      alert('No file selected');
      return;
    }
    this.readFirstFile(fileElement);
    this.uploadFile(bondFile);
  }

  private readFirstFile(fileElement: HTMLInputElement) {
    this.newFile = fileElement.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.newFile);
  }

  private uploadFile(bondFile: BondFileInfo) {
    const fd = new FormData();
    fd.append('file', this.newFile, this.newFile.name);
    bondFile.Loading = true;
    this.api.uploadFile(fd).subscribe((data) => {
      this.errors = data.ExceptionList;
      if (data.ExceptionList.length < 1) {
        this.GetFileInfo();
      }
    },
      (err) => {
        this.errors = err;
      }, () => {
        bondFile.Loading = false;
      });
  }
}
