<div class="col-12">
  <button
    type="button"
    class="btn btn-info"
    (click)="getUserStats()"
    style="margin-bottom:12px;"
  >
    Load user statistics
  </button>
  <div *ngIf="allUserStats">
    <h3>User agents</h3>
    <table class="table">
      <tr>
        <th>Index</th>
        <th>UserAgent</th>
      </tr>
      <tr *ngFor="let ua of allUserStats.UserAgents; let i = index">
        <td>{{ i }}</td>
        <td>{{ ua }}</td>
      </tr>
    </table>

    <h3>All ip addresses:</h3>
    <p>{{ allUserStats.AllIps }}</p>

    <h3>User history (Last 12m, cuttoff at 3k entries)</h3>
    <div style="padding:4px;">
      Type filter:
      <select [(ngModel)]="typeFilter" (change)="filterStats()">
        <option value="">All</option>
        <option value="Attachment">Attachment</option>
        <option value="UserLoggedIn">UserLoggedIn</option>
        <option value="UserLoginFailed">UserLoginFailed</option>
        <option value="PageShow">PageShow</option>
        <option value="EmailSent">EmailSent</option>
        <option value="EmailRecipient">EmailRecipient</option>
        <option value="Admin">Admin</option>
        <option value="Document">Document</option>
        <option value="Company">Company</option>
        <option value="Sector">Sector</option>
        <option value="Event">Event</option>
        <option value="NotSet">NotSet</option>
      </select>
    </div>
    <table class="table">
      <tr>
        <th>Time</th>
        <th>Url</th>
        <th>Headline</th>
        <th>Subheadline</th>
        <th>Author</th>
        <th>Document type</th>
        <th>Publishing date</th>
        <th>Sector</th>
        <th>Primary analyst</th>
        <th>Type</th>
        <th>Ip</th>
        <th title="user was logged in">LI</th>
        <th title="User agent index">UA</th>
        <th>Referrer</th>
      </tr>
      <tr *ngFor="let item of userStats">
        <td>{{ item.Timestamp }}</td>
        <td>
          <a href="{{ item.Url }}">{{ getShortUrl(item.Url) }}</a>
        </td>
        <td>{{ item.HEADLINE }}</td>
        <td>{{ item.SUBHEADLINE }}</td>
        <td>{{ item.AUTHOR }}</td>
        <td>{{ item.DOCUMENTTYPE }}</td>
        <td>{{ item.PUBLISHINGDATETIME }}</td>
        <td>{{ item.SECTOR }}</td>
        <td>{{ item.ANALYSTPRIMARYID }}</td>
        <td>{{ item.Type }}</td>
        <td>{{ item.IpAddress }}</td>
        <td>{{ item.LoggedIn ? "Y" : "N" }}</td>
        <td>{{ item.UserAgent }}</td>
        <td>
          <a href="{{ item.Referrer }}">{{ getShortUrl(item.Referrer) }}</a>
        </td>
      </tr>
    </table>
  </div>
</div>
