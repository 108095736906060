import { CommonModule } from "@angular/common";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from "@angular/forms";
import { routing } from "./statistics.routing";
import { StatisticsApiService } from "./statistics-api.service";
import {
  DocStatisticsComponent,
  UploadStatisticsComponent
} from "./statistics.component";
import { FailedLoginsComponent } from "./failed-logins.component";
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { IconPack } from "@fortawesome/fontawesome-svg-core";

@NgModule({
  declarations: [
    DocStatisticsComponent,
    UploadStatisticsComponent,
    FailedLoginsComponent
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    routing,
    HttpClientModule
  ],
  exports: []
})
export class StatisticsModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas as IconPack, far as IconPack);
  }
  static forRoot(): ModuleWithProviders<StatisticsModule> {
    return {
      ngModule: StatisticsModule,
      providers: [StatisticsApiService]
    };
  }
}
