export class Disclaimer {
  Id?: number;
  Name?: string;
  Url?: string;
  ContentType?: number;
  TopMenu?: string;
  SortOrder?: number;
  HtmlContent?: string;
  PdfContent?: any;

  selected?: boolean;
}

export interface InFocusPublication {
  InFocusId?: number;
  Headline?: string;
  Ingress?: string;
  Guid?: string;
  SortOrder?: number;
  DocumentUrl?: string;
  ImageId?: string;
  DataIdType?: string;
}
export interface InFocusPublicationMod extends InFocusPublication {
  Removed?: boolean;
}

export interface DocumentsModel {
  Docs?: DocumentModel[];
  NbDocs?: number;
  TotDocs?: number;
}

export interface DocumentModel {
  Date: string;
  Guid: string;
  SGuid: string;
  Headline: string;
  SubHeadline: string;
  SimpleHeadline: string;
  PubType: string;
}

export class PubSearch {
  Docs: any[];
  NbDocs: number;
  TotDocs: number;
}

export class Image {
  Id: string;
  Name: string;
  Description: string;
  File: any[] | string;
  Filename: string;
}

export class Banner {
  Id: number;
  Name: string;
  Description: string;
  ShowFrom: Date;
  ShowUntil: Date;
  ImageId: string;
  Html: string;
  Link: string;
  OpenInNewWindow: boolean;
  SortOrder: number;
  DocumentId: number;
}

export class BannerPage {
  Id: number;
  BannerId: number;
  Location: string;
  LocationContextId: number;
}

export class BannerFile {
  Name: string;
  File: any;
}

export interface UploadInternalPublicationAttachmentModel {
  DocumentType: string;
  ContentType: string;
  FileName: string;
}

export interface AttachmentModel {
  guid: string;
  SGuid: string;
  LinkName: string;
  Icon: string;
  CommonId: number;
  DocumentGuid: string;
  FileDisplayName: string;
  Url: string;
  FriendlyUrl: string;
  PubDate: string;
  FileName: string;
  FileType: string;
}

export interface InternalPublicationAttachmentModel {
  Attachment: AttachmentModel;
  DocumentType: string;
}
