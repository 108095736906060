export class PublicationDownloads {
  noDocumentViewsTot: number;
  noDocumentViewUnique: number;
  noDocumentViewAnon: number;
  noSiteAttachmentDownloadsTot: number;
  noSiteAttachmentDownloadsUnique: number;
  noSiteAttachmentDownloadsAnon: number;
  Company: string;
  FullHeadline: string;
  DocTime: string;
  DocType: string;
  DocId: number;

  //Headline: string;
  //PubType: string;
  //Date: string;
  //DocId: number;
  //Id: number;
  //PageViewsTotal: number;
  //PageViewsUnique: number;
  //PageViewsAnon: number;
  //DownloadsTotal: number;
  //DownloadsUnique: number;
  //DownloadsAnon: number;
}

export class DocUploadStatistics {
  PublicationInfo: PublicationInfo;
  StatisticsInfo: StatisticsInfo;
}
export class PublicationInfo {
  AnalystPrimaryId: string;
  DocumeType: string;
  Headline: string;
  PublishDateTime: string;
  Subheadline: string;
  SubjectCompanyId: string;
}

export class StatisticsInfo {
  DocID: string;
  Email: string;
  Error: string;
  FileRow: number;
  ReadDate: Date;
  ReaderID: string;
}

export class DocumentStatistics {
  Headline: string;
  PubType: string;
  Analyst: string;
  PubDate: Date;
  Bloomberg: DocumentViews;
  CIQ: DocumentViews;
  Factset: DocumentViews;
  Markit: DocumentViews;
  Thomson: DocumentViews;
  Reon: DocumentViews;
  ReonDownload: DocumentViews;
}

export class DocumentViews {
  TotalReaders: number;
  UniqueReaders: number;
  AnonymousReaders: number;
  Readers: ReadersList[];
}

export class ReadersList {
  Email: string;
  ReadDate: Date;
  ReaderId: string;
}

export class MoreInfoDocStat {
  Readers: ReadersList[];
  Headline: string;
  Channel: string;
}

//user stats

export interface StatsQuery {
  From: string;
  To: string;
  CromId?: string;
  Types?: StatsLogType[];
  DocId?: string;
  PageSize?: number;
  PageNb?: number;
  ClientCategory?: IdentifierSource;
}

export interface UserStats {
  Items: UserStatItem[];
  UserAgents: string[];
  AllIps: string;
}

export interface UserStatItem {
  TimestampIso: string;
  Type: StatsLogType;
  Referrer: string;
  UserAgent: number;
  Url: string;
  IpAddress: string;
  LoggedIn: boolean;
  CromId: string;
  HEADLINE: string;
  SUBHEADLINE: string;
  PUBLISHINGDATETIME: string;
  DOCUMENTTYPE: string;
  AUTHOR: string;
  ANALYSTPRIMARYID: string;
  SECTOR: string;
}

export enum StatsLogType {
  Attachment = 0,
  UserLoggedIn,
  UserLoginFailed,
  PageShow,
  EmailSent,
  EmailRecipient,
  Admin,
  Document = 0x100,
  Company,
  Sector,
  Event,
  NotSet
}

export enum IdentifierSource {
  Unknown = 154, // 0x9A
  Email = 166, // 0xA6
  Web = 167, // 0xA7
  WebEmail = 168 // 0xA8
}
