import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { UserApiService } from '../user/user-api.service';
import { MacroAdminApiService, ForecastMetadata } from './MacroAdmin-api.service'
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'macroAdminForecast',
  styleUrls: [ './MacroAdminForecast.component.css'],
  templateUrl: './MacroAdminForecast.component.html',
})

export class MacroAdminForecastComponent implements OnInit  {
  error = '';
  syncing = false;
  date_sync = false;
  forecast_sync = false;
  displayedColumns = ['key', 'friendly_title', 'latest_change', 'sort_order'];

  policy: MatTableDataSource<ForecastMetadata>;
  three_mrates: MatTableDataSource<ForecastMetadata>;
  govt2y: MatTableDataSource<ForecastMetadata>;
  govt5y: MatTableDataSource<ForecastMetadata>;
  govt10y: MatTableDataSource<ForecastMetadata>;
  swaps2y: MatTableDataSource<ForecastMetadata>;
  swaps5y: MatTableDataSource<ForecastMetadata>;
  swaps10y: MatTableDataSource<ForecastMetadata>;
  fx_meta: MatTableDataSource<ForecastMetadata>;
  gdp: MatTableDataSource<ForecastMetadata>;
  inflation: MatTableDataSource<ForecastMetadata>;
  unemployment: MatTableDataSource<ForecastMetadata>;

  latestDateControl = new FormControl('');

  constructor(private macroApi: MacroAdminApiService, private router: Router, private userApi: UserApiService) {
    this.policy = new MatTableDataSource(null);
    this.three_mrates = new MatTableDataSource(null);
    this.govt2y = new MatTableDataSource(null);
    this.govt5y = new MatTableDataSource(null);
    this.govt10y = new MatTableDataSource(null);
    this.swaps2y = new MatTableDataSource(null);
    this.swaps5y = new MatTableDataSource(null);
    this.swaps10y = new MatTableDataSource(null);
    this.fx_meta = new MatTableDataSource(null);
    this.gdp = new MatTableDataSource(null);
    this.inflation = new MatTableDataSource(null);
    this.unemployment = new MatTableDataSource(null);
  }
  ngOnInit(): void {
    this.latestDateControl.valueChanges.subscribe(this.updateDate.bind(this));
  }
  SyncMacrobond() {
    this.syncing = true;
    this.macroApi.checkSystem().subscribe( () => {
      this.syncing = false;
    });
  }
  GetMacroForecastMetaData(table: string) {
    this.forecast_sync = true;
    this.macroApi.GetMacroForecastMetadata(table).subscribe(
      (d) => {
        this.forecast_sync = false;
        const data = d.sort((a, b) => (a.sortOrder < b.sortOrder) ? -1 : 1);
        switch (table) {
          case 'policy': this.policy.data = data;
          break;
          case 'interbank': this.three_mrates.data = data;
          break;
          case 'bond2': this.govt2y.data = data;
          break;
          case 'bond5': this.govt5y.data = data;
          break;
          case 'bond10': this.govt10y.data = data;
          break;
          case 'swap2': this.swaps2y.data = data;
          break;
          case 'swap5': this.swaps5y.data = data;
          break;
          case 'swap10': this.swaps10y.data = data;
          break;
          case 'fx': this.fx_meta.data = data;
          break;
          case 'gdp': this.gdp.data = data;
          break;
          case 'inflation': this.inflation.data = data;
          break;
          case 'unemployment': this.unemployment.data = data;
        }
    },
    (error) => {
      console.log('Err: ' + error);
      this.forecast_sync = false;
    })
  }

  request(type: string) {
    this.GetMacroForecastMetaData(type)
  }

  updateDate(date: Date) {
    this.date_sync = true;
    this.macroApi.SetForecastUpdatedField(date).subscribe(
      () => {
        this.date_sync = false;
      },
      (error) => {
        console.log('Err: ' + error);
        this.date_sync = false;
      });
  }
}
