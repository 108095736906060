<div class="container-fluid">
    <div class="row" style="margin-top:20px;"></div>
    <div class="col-12">
        <mat-card>
            <mat-card-header>
                <mat-card-title> Search events </mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content>
                <div class="row">
                    <div class="col-md-12 col-lg-3" style="border-right: 1px solid rgba(0, 0, 0, 0.12);">
                        <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                            <mat-card-subtitle> Search parameters</mat-card-subtitle>
                            <mat-divider></mat-divider>
                            <div class="col-12">
                                <mat-form-field class="field-full-width" appearance="fill">
                                    <mat-label>Category</mat-label>
                                    <mat-select name="category" formControlName="Category">
                                        <mat-option>--Any--</mat-option>
                                        <mat-option *ngFor="let cats of categories" [value]="cats">
                                            {{cats}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="field-full-width" appearance="fill">
                                    <mat-label>Status</mat-label>
                                    <mat-select name="category" formControlName="Status">
                                        <mat-option>--Any--</mat-option>
                                        <mat-option *ngFor="let status of statusTypes" [value]="status">
                                            {{status}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="col-12">
                                <mat-form-field class="field-full-width" floatLabel="auto" appearance="none">
                                    <mat-checkbox class="filter-label-margin" formControlName="defineUpcoming"><mat-label>Upcoming</mat-label></mat-checkbox>
                                    <mat-slide-toggle
                                        class="filter-label-margin"
                                        color="primary"
                                        formControlName="Upcoming" 
                                        [checked]="searchForm.get('Upcoming').value">
                                    </mat-slide-toggle>
                                    <textarea matInput hidden></textarea>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="col-12" *ngIf="eventList.data.length > 0">
                                <mat-form-field appearance="standard" class="topright">
                                    <mat-label>Filter</mat-label>
                                    <input matInput placeholder="Filter..." formControlName="filter">
                                        <mat-icon matPrefix>filter_alt</mat-icon>
                                </mat-form-field>
                            </div>
                            <mat-divider></mat-divider>
                        </form>

                    </div>
                    <div class="col-md-12 col-lg-9">
                        <div class="col-12">
                            <form [formGroup]="searchForm" class="form-inline" (ngSubmit)="onSubmit()">
                                <mat-form-field class="field-full-width" appearance="outline">
                                    <input matInput placeholder="Name..." formControlName="Name">
                                </mat-form-field>
                                <button type="submit" mat-raised-button color="primary">
                                    <mat-icon>search</mat-icon> Search
                                </button>
                                <mat-spinner *ngIf="loading" [diameter]="20" mode="indeterminate"></mat-spinner>
                            </form>
                        </div>
                        <mat-divider></mat-divider>
                        <mat-label *ngIf="eventList.data">{{eventList.data.length}} results</mat-label>
                        <div class="col-12">
                            <table mat-table [dataSource]="eventList" style="width: 100%;" *ngIf="eventList"  matSort 
                            (matSortChange)="sortData($event)" matSortActive="StartDate" matSortDirection="asc">
                        
                                <ng-container matColumnDef="Category">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Category </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> {{element.Category}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="City/Location">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> City/Location </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;">{{element.City}}{{element.Location ? '/ ' + element.Location : null }} </td>
                                </ng-container>
                        
                                <ng-container matColumnDef="StartDate">
                                    <th mat-sort-header mat-header-cell *matHeaderCellDef> StartDate </th>
                                    <td mat-cell *matCellDef="let element" style="word-break:normal;"> {{element.StartDate | date}} </td>
                                </ng-container>
                        
                                <ng-container matColumnDef="action">
                                    <th mat-header-cell *matHeaderCellDef>Action</th>
                                    <td mat-cell *matCellDef="let element">
                                        <button mat-raised-button color="primary" (click)="selectEvent(element)">
                                            <mat-icon>arrow_right_alt</mat-icon> Edit
                                        </button>
                                    </td>
                                </ng-container>
                                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 100, 1000]" showFirstLastButtons></mat-paginator>
                        </div>
                    </div>
                </div>

            </mat-card-content>
        </mat-card>
    </div>
</div>