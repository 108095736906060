import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class MacroAdminApiService {
  baseUrlReonApi = environment.baseUrlReonApi;

  constructor(private http: HttpClient) { }

  getAllMedia(): Observable<MediaModel[]> {
    return this.http.get<MediaModel[]>(this.baseUrlReonApi + "MacroAdmin/GetAllMedia",
      { withCredentials: true });
  }

  updateEmbedMedia(obj: MediaModel): Observable<any> {
    return this.http
      .post(this.baseUrlReonApi + "MacroAdmin/UpdateEmbedMedia", obj,
        { withCredentials: true });
  }

  deleteEmbedMedia(item: MediaModel): Observable<any> {
    return this.http
      .post(this.baseUrlReonApi + "MacroAdmin/DeleteEmbedMedia", item,
        { withCredentials: true });
  }

  checkSystem(): Observable<any> {
    return this.http.get(this.baseUrlReonApi + "MacroAdmin/DownloadMacrobond",
      { withCredentials: true });
  }

  GetMacroForecastMetadata(table: string): Observable<ForecastMetadata[]> {
    return this.http.get<ForecastMetadata[]>(this.baseUrlReonApi + "MacroAdmin/GetMacroForecastMetadata/?table=" + table,
      { withCredentials: true });
  }
  SetForecastUpdatedField(d: Date): Observable<any> {
    let dt_model: DateModel = { dt: d.toLocaleString('en-GB') };
    return this.http
      .post(this.baseUrlReonApi + "MacroAdmin/SetForecastUpdatedField", dt_model,
        { withCredentials: true });
  }

}

export interface MediaModel {
  Id: number;
  Name: string;
  MediaId: string;
  Xdate: Date;
  NeverExpire: boolean;
  GroupName: string;
  CategoryName: string;
  selected?: boolean;
}

export interface ForecastMetadata {
  Id: number;
  key: string;
  friendlyTitle: string;
  sortOrder: number;
  friendlyDate: Date;
  lastModified: Date;
}

export interface DateModel {
  dt: string;
}