import { Component } from "@angular/core";
import {
  StatisticsApiService,
  InsertDocumentResponse,
} from "./statistics-api.service";

import {
  PublicationDownloads,
  DocUploadStatistics,
  DocumentStatistics,
  MoreInfoDocStat,
} from "./model";
import { FileService } from "app/common/file-service";
import { Logger } from "app/_helpers";

// Document Statistics
@Component({
  selector: "site-docStatistics",
  templateUrl: "docStatistics.component.html",
})
export class DocStatisticsComponent {
  loading: boolean;
  error: string;
  pubDownloads: PublicationDownloads[];
  docStats: DocumentStatistics[];
  readStartdate: string;
  readEnddate: string;
  pubStartdate: string;
  pubEnddate: string;
  fileDistrPos: string[];
  channels: string[];
  showUnknownDocs: boolean = false;
  //currentMoreInfo: number;
  currentMoreInfo: MoreInfoDocStat;
  searchString: string = null;

  constructor(
    private statApi: StatisticsApiService,
    private fileService: FileService,
    private logger: Logger
  ) {}

  ngOnInit() {
    this.loading = false;
    this.fileDistrPos = [
      "Reon",
      "Bloomberg",
      "Thomson Reuters",
      "Factset",
      "Capital IQ",
      "Markit",
    ];
    this.channels = [
      "Reon",
    ];
    //this.pubDownloads = this.statApi.getDocStatsList();
    this.initDates();
  }

  initDates() {
    const endRead = new Date(Date.now());
    const startRead = new Date(Date.now());
    startRead.setMonth(startRead.getMonth() - 1);

    const endPub = new Date(Date.now());
    const startPub = new Date(Date.now());
    startPub.setFullYear(startPub.getFullYear() - 1);

    const formatDate = (dt: Date) => dt.toISOString().split("T")[0];

    this.pubStartdate = formatDate(startPub);
    this.pubEnddate = formatDate(endPub);
    this.readStartdate = formatDate(startRead);
    this.readEnddate = formatDate(endRead);
  }

  //@computed
  get disabledGetResult() {
    return (
      this.readStartdate == null ||
      this.readEnddate == null ||
      this.readStartdate >= this.readEnddate ||
      this.pubStartdate == null ||
      this.pubEnddate == null ||
      this.pubStartdate >= this.pubEnddate ||
      this.channels.length === 0
    );
  }

  showLoading() {
    if (this.pubDownloads == null && this.docStats == null && this.loading)
      return true;
    else return false;
  }

  checkError(val: string) {
    if (val == "read") {
      if (this.readEnddate < this.readStartdate) {
        return "1px solid red";
      }
    } else if (val == "published") {
      if (this.pubEnddate < this.pubStartdate) {
        return "1px solid red";
      }
    }
    return "1px solid rgba(0, 0, 0, 0.15)";
  }

  submit() {
    //this.pubDownloads = null;
    this.docStats = null;
    this.loading = true;
    //this.statApi.getDocStats(this.startdate, this.enddate);
    this.statApi
      .getDocumentStatistics(
        this.pubStartdate,
        this.pubEnddate,
        this.readStartdate,
        this.readEnddate,
        this.channels,
        this.searchString
      )
      .subscribe((docStats) => (this.docStats = docStats));
    this.pubDownloads = this.statApi.getDocStatsList();
  }

  // @computed
  get downloadAggregateLink() {
    return this.statApi.downloadAggregateHref(
      this.pubStartdate,
      this.pubEnddate,
      this.readStartdate,
      this.readEnddate,
      this.channels,
      this.searchString
    );
  }

  // @computed
  get downloadReaderDataLink() {
    return this.statApi.downloadReaderDataHref(
      this.pubStartdate,
      this.pubEnddate,
      this.readStartdate,
      this.readEnddate,
      this.channels,
      this.searchString
    );
  }

  downloadFile(url: string) {
    this.loading = true;
    this.error = null;
    this.logger.debug("Downloading file", url);
    this.fileService.downloadAndSave(url).subscribe((fileData) => {
      this.loading = false;
      if (!fileData.success) {
        this.error = `Error while retrieving statistics, reason: ${fileData.errorMsg}`;
      }
    });
  }

  addRemoveChannel(ch: string) {
    const index: number = this.channels.indexOf(ch);
    if (index != -1) this.channels.splice(index, 1);
    else this.channels.push(ch);
  }

  showFilter(item: DocumentStatistics) {
    if (this.showUnknownDocs) return item.Headline == null;
    else return item.Headline != null;
  }

  moreInfo(index: number, channel: string) {
    if (channel == "Reon") {
      this.currentMoreInfo = {
        Headline: this.docStats[index].Headline,
        Readers: this.docStats[index].Reon.Readers,
        Channel: channel,
      };
    } else if (channel == "Reon Download") {
      this.currentMoreInfo = {
        Headline: this.docStats[index].Headline,
        Readers: this.docStats[index].ReonDownload.Readers,
        Channel: channel,
      };
    } else if (channel == "Bloomberg") {
      this.currentMoreInfo = {
        Headline: this.docStats[index].Headline,
        Readers: this.docStats[index].Bloomberg.Readers,
        Channel: channel,
      };
    } else if (channel == "Thomson Reuters") {
      this.currentMoreInfo = {
        Headline: this.docStats[index].Headline,
        Readers: this.docStats[index].Thomson.Readers,
        Channel: channel,
      };
    } else if (channel == "Factset") {
      this.currentMoreInfo = {
        Headline: this.docStats[index].Headline,
        Readers: this.docStats[index].Factset.Readers,
        Channel: "Factset",
      };
    } else if (channel == "Capital IQ") {
      this.currentMoreInfo = {
        Headline: this.docStats[index].Headline,
        Readers: this.docStats[index].CIQ.Readers,
        Channel: channel,
      };
    } else if (channel == "Markit") {
      this.currentMoreInfo = {
        Headline: this.docStats[index].Headline,
        Readers: this.docStats[index].Markit.Readers,
        Channel: channel,
      };
    }
  }

  //Reon ej inlagt ännu
  readerCount(item: DocumentStatistics, val: string) {
    var tot = 0;
    if (val == "total") {
      tot =
        this.valueNull(item.Bloomberg.TotalReaders) +
        this.valueNull(item.Thomson.TotalReaders) +
        this.valueNull(item.Factset.TotalReaders) +
        this.valueNull(item.CIQ.TotalReaders) +
        this.valueNull(item.Markit.TotalReaders) +
        this.valueNull(item.Reon.TotalReaders);
    } else if (val == "unique") {
      tot =
        this.valueNull(item.Bloomberg.UniqueReaders) +
        this.valueNull(item.Thomson.UniqueReaders) +
        this.valueNull(item.Factset.UniqueReaders) +
        this.valueNull(item.CIQ.UniqueReaders) +
        this.valueNull(item.Markit.UniqueReaders) +
        this.valueNull(item.Reon.UniqueReaders);
    } else if (val == "anon") {
      tot =
        this.valueNull(item.Bloomberg.AnonymousReaders) +
        this.valueNull(item.Thomson.AnonymousReaders) +
        this.valueNull(item.Factset.AnonymousReaders) +
        this.valueNull(item.CIQ.AnonymousReaders) +
        this.valueNull(item.Markit.AnonymousReaders) +
        this.valueNull(item.Reon.AnonymousReaders);
    }
    return tot;
  }

  valueNull(val: number) {
    if (val != null) return val;
    else return 0;
  }

  /*
    ngOnInit() {
        this.pubDownloads = [];
        this.statApi.getDocStats().subscribe(
            userContent =>
                userContent.forEach(element => {
                        this.pubDownloads.push({
                            noDocumentViews: element.Count1,
                            noAppDocumentViews: element.Count2,
                            noSiteAttachmentDownloads: element.Count3,
                            noDirectDownloads: element.Count4,
                            noAppAttachmentDownloads: element.Count5,
                            Company: element.Description1,
                            FullHeadline: element.Description2,
                            DocTime: element.Description3,
                            DocType: element.Description4,
                            DocId: element.DocumnetId
                        });
                }));
    }
    */
}

// Upload Statistics
@Component({
  selector: "upload-statistics",
  templateUrl: "uploadStatistics.component.html",
})
export class UploadStatisticsComponent {
  pubDownloads: PublicationDownloads[];
  fileDistrPos: string[];
  fileDistrCurr: string;
  file: File;
  isValid: boolean;
  stats: DocUploadStatistics[];
  loading: boolean;

  errorValidate: string;
  errorReportID: string;
  errorPublication: string;

  constructor(private statApi: StatisticsApiService) {
    this.errorValidate =
      "NOT added to database, could not validate some or all data. Check if the email contains @ or is 'null', readDate is a date and that readerID contains at least six characters";
    this.errorReportID =
      "NOT added to database could not match third-channel-unique-report-ID (H00...) with REON equivalent";
    this.errorPublication =
      "Added to database, but could not get a headline for the publication";
  }

  ngOnInit() {
    this.fileDistrPos = [
      "Bloomberg",
      "Thomson Reuters",
      "Factset",
      "Capital IQ",
      "Markit",
    ];
    this.isValid = false;
    this.loading = false;
  }

  checkError(error: string) {
    if (error == "errorReportID") return "#ffb3b3";
    else if (error == "errorValidate") return "#ffe6cc";
    else if (error == "errorPublication") return "#b3b3ff";
  }
  checkNull(va: string) {
    if (va == null || va == "-") return "#ffffcc important";
  }

  showLoading() {
    if (this.stats == null && this.loading) return true;
    else return false;
  }

  submit() {
    this.loading = true;
    this.statApi
      .insertDocument(this.fileDistrCurr, this.file)
      .subscribe((userContent: InsertDocumentResponse) => {
        this.stats = userContent.List;
        if (userContent.ErrorMessage) {
          alert(userContent.ErrorMessage);
        }
      });
    this.stats = null;
  }

  fileToParse(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;
    this.file = files[0];

    if (this.file != null) this.isValid = true;
    else this.isValid = false;
  }
}

// Failed Logins
