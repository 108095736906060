<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="alert alert-danger" role="alert" *ngIf="errorString">{{errorString}}</div>

<ng-container *ngIf="!isLoading && !errorString">
    <app-rule-select [options]="publicationTypeOptions" name="Publication types"
        [selectedValues]="_selectedPublicationTypes" (selections)="updatePublicationTypes($event)">
    </app-rule-select>

    <app-rule-select [options]="regionOptions" name="Publication region"
        [selectedValues]="_selectedRegions" (selections)="updateRegions($event)">
    </app-rule-select>

    <app-rule-select [options]="languageOptions" name="Language"
        [selectedValues]="_selectedLanguages" (selections)="updateLanguages($event)">
    </app-rule-select>
</ng-container>