<h2>Internal publications</h2>
<em>(SourceType: InternalPublication, DocumentType: ADHOC)</em>

<div class="form-group row yCenter">
  <label class="col-2 col-form-label">
    Select file for upload
  </label>
  <div class="col-2">
    <label class="btn btn-primary" style="margin-bottom:0;cursor:pointer;">
      <fa-icon [icon]="['far','folder-open']"></fa-icon>
      {{ newAttachmentData.FileName ? newAttachmentData.FileName : "Browse..."}}
      <input type="file" hidden (change)="readFile($event)" />
    </label>
    <img *ngIf="isLoadingData" style="margin-left:10px; height:25px; width:auto;" src="./../../assets/ajax-loader.gif"
      alt="Loading..." />
  </div>
  <div class="col-2" *ngIf="newAttachmentData.FileName">
    <button type="submit" class="btn btn-success" (click)="uploadDocument()">
      Upload
    </button>
  </div>
  <div class="col-2">
    <strong *ngIf="lastSave">
      Last saved at {{ lastSave }}
    </strong>
    <strong *ngIf="saveError" style="color:red">Error during save!</strong>
  </div>
</div>
<div class="row">
  <div class="col-8">
    <h5 style="margin-bottom:0;">5 latest internal publications</h5>
    <table class="table table-hover" style="margin-top:1rem;">
      <thead>
        <tr>
          <th></th>
          <th>Url</th>
          <th>File</th>
          <th>Publication date</th>
        </tr>
      </thead>
      <tbody style="font-size:13px;">
        <tr *ngFor="let l of latestAttachments">
          <td><a href="{{ l.Attachment.FriendlyUrl }}" target="_blank">Download</a></td>
          <td>{{ l.Attachment.FriendlyUrl }}</td>
          <td>{{ l.Attachment.FileDisplayName }}</td>
          <td>{{ l.Attachment.PubDate | date:'yyyy-MM-dd HH:mm' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
